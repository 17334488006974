import { Action } from "@ngrx/store";
import { PaginationData } from "@store/models/pagination.model";

export enum PaginationActionType {
	SET_CUSTOMER_PAGINATION = "[Pagination] Set Customers",
	SET_PAYGROUP_PAGINATION = "[Pagination] Set Paygroups",
	SET_LEGAL_ENTITY_PAGINATION = "[Pagination] Set Legal_Entities",
	SET_EMPLOYEE_PAGINATION = "[Pagination] Set Employees",
	SET_MASTER_FILE_PAGINATION = "[Pagination] Set Master_Files",
	SET_PAYMENT_ORDER_PAGINATION = "[Pagination] Set Payment_Orders",
	SET_NET_PAYMENT_PAGINATION = "[Pagination] Set Net_Payments",
	SET_TPP_PAYMENT_PAGINATION = "[Pagination] Set TPP_Payments",
	SET_GLOBAL_DASHBOARD_PAGINATION = "[Pagination] Set Global_Dashboard",
	RESET_PAGINATION = "[Pagination] Set Reset"
}

export class ResetPagination implements Action {
	readonly type = PaginationActionType.RESET_PAGINATION;
	constructor(public payload: PaginationData) {}
}

export class SetCustomerPagination implements Action {
	readonly type = PaginationActionType.SET_CUSTOMER_PAGINATION;

	constructor(public payload: PaginationData) {}
}

export class SetPaygroupPagination implements Action {
	readonly type = PaginationActionType.SET_PAYGROUP_PAGINATION;

	constructor(public payload: PaginationData) {}
}

export class SetLegalEntityPagination implements Action {
	readonly type = PaginationActionType.SET_LEGAL_ENTITY_PAGINATION;

	constructor(public payload: PaginationData) {}
}

export class SetEmployeePagination implements Action {
	readonly type = PaginationActionType.SET_EMPLOYEE_PAGINATION;

	constructor(public payload: PaginationData) {}
}

export class SetMasterFilePagination implements Action {
	readonly type = PaginationActionType.SET_MASTER_FILE_PAGINATION;

	constructor(public payload: PaginationData) {}
}

export class SetPaymentOrderPagination implements Action {
	readonly type = PaginationActionType.SET_PAYMENT_ORDER_PAGINATION;

	constructor(public payload: PaginationData) {}
}

export class SetNetPaymentPagination implements Action {
	readonly type = PaginationActionType.SET_NET_PAYMENT_PAGINATION;

	constructor(public payload: PaginationData) {}
}

export class SetTPPPaymentPagination implements Action {
	readonly type = PaginationActionType.SET_TPP_PAYMENT_PAGINATION;

	constructor(public payload: PaginationData) {}
}

export class SetGlobalDashboardPagination implements Action {
	readonly type = PaginationActionType.SET_GLOBAL_DASHBOARD_PAGINATION;

	constructor(public payload: PaginationData) {}
}

export type PaginationActions =
	| ResetPagination
	| SetCustomerPagination
	| SetPaygroupPagination
	| SetLegalEntityPagination
	| SetEmployeePagination
	| SetMasterFilePagination
	| SetPaymentOrderPagination
	| SetNetPaymentPagination
	| SetTPPPaymentPagination
	| SetGlobalDashboardPagination;
