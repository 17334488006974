import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { CustomerListComponent } from "./customer-list.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { MatInputModule } from "@angular/material/input";
import { PaginationModule } from "../pagination/pagination.module";
import { NoItemsLayoutModule } from "../no-items-layout/no-items-layout.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatButtonModule } from "@angular/material/button";
import { SharedModule } from "@shared/shared.module";
import { CalendarActionsModule } from "@shared/components/calendar-actions/calendar-actions.module";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

@NgModule({
	declarations: [CustomerListComponent],
	imports: [
		CommonModule,
		MatCardModule,
		MatInputModule,
		FormsSharedModule,
		MatIconModule,
		PaginationModule,
		NoItemsLayoutModule,
		FlexLayoutModule,
		MatButtonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatPaginatorModule,
		SharedModule,
		CalendarActionsModule,
		MatSlideToggleModule
	],
	exports: [CustomerListComponent]
})
export class CustomerListModule {}
