import { Component, OnInit, Input, Inject } from "@angular/core";
import { SvgIconRegistryService } from "angular-svg-icon";

@Component({
	selector: "app-icon",
	templateUrl: "./icon.component.html",
	styleUrls: ["./icon.component.scss"]
})
export class IconComponent implements OnInit {
	@Input() name: string = "";
	@Input() size: string = "24";

	_color: string = "black";
	@Input() set color(col: string) {
		this._color = col;
	}

	@Input() lineWidth: string = "1.5";
	@Input() fill: string = "none";
	@Input() viewbox: string = "0 0 24 24";

	constructor(@Inject(SvgIconRegistryService) private _registry: SvgIconRegistryService) {}

	ngOnInit(): void {
		this._registry.unloadSvg(this.name); //Prevents cross-over styling

		switch (this.name) {
			//Menu Items

			case "global": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"  stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998"  stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M15 3C16.95 8.84 16.95 15.16 15 21" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16"  stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001"  stroke-linecap="round" stroke-linejoin="round"/>
						</svg>`
				);
				break;
			}

			case "login-1": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M11.6801 14.62L14.2401 12.06L11.6801 9.5"/>
						<path d="M4 12.0601H14.17"/>
						<path d="M12 4C16.42 4 20 7 20 12C20 17 16.42 20 12 20"/>
						</svg>`
				);
				break;
			}

			case "add_circle_outline": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"/>
						<path d="M16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z"/>
						<path d="M12 16.75C11.59 16.75 11.25 16.41 11.25 16V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V16C12.75 16.41 12.41 16.75 12 16.75Z"/>
						</svg>`
				);
				break;
			}
			case "slash_outline": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"/>
						<path d="M4.89984 19.7514C4.70984 19.7514 4.51984 19.6814 4.36984 19.5314C4.07984 19.2414 4.07984 18.7614 4.36984 18.4714L18.3698 4.47141C18.6598 4.18141 19.1398 4.18141 19.4298 4.47141C19.7198 4.76141 19.7198 5.24141 19.4298 5.53141L5.42984 19.5314C5.27984 19.6814 5.08984 19.7514 4.89984 19.7514Z"/>
						</svg>`
				);
				break;
			}
			case "shuffle_outline": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M5.56219 18.74C5.56219 18.74 5.55221 18.74 5.54221 18.74L2.99219 18.73C2.58219 18.73 2.24219 18.39 2.24219 17.98C2.24219 17.57 2.58219 17.23 2.99219 17.23L5.54221 17.24H5.55219C6.20219 17.24 6.81221 16.92 7.17221 16.37L13.5622 6.78998C14.2022 5.82998 15.2722 5.25 16.4322 5.25C16.4322 5.25 16.4422 5.25 16.4522 5.25L21.0022 5.27002C21.4122 5.27002 21.7522 5.61002 21.7522 6.02002C21.7522 6.43002 21.4122 6.77002 21.0022 6.77002L16.4522 6.75H16.4422C15.7922 6.75 15.1822 7.07 14.8222 7.62L8.43219 17.2C7.79219 18.17 6.72219 18.74 5.56219 18.74Z"/>
						<path d="M19.0014 20.7318C18.8114 20.7318 18.6214 20.6619 18.4714 20.5119C18.1814 20.2219 18.1814 19.7419 18.4714 19.4519L20.4714 17.4519C20.7614 17.1619 21.2414 17.1619 21.5314 17.4519C21.8214 17.7419 21.8214 18.2219 21.5314 18.5119L19.5314 20.5119C19.3814 20.6619 19.1914 20.7318 19.0014 20.7318Z"/>
						<path d="M8.89001 9.38171C8.66001 9.38171 8.43 9.27172 8.28 9.07172L7.20001 7.57172C6.83001 7.06172 6.21999 6.76172 5.60999 6.76172L3 6.77173C2.54 6.76173 2.25 6.44173 2.25 6.02173C2.25 5.61173 2.58 5.27173 3 5.27173L5.60999 5.26172C5.61999 5.26172 5.62 5.26172 5.63 5.26172C6.74001 5.26172 7.77999 5.80171 8.42999 6.69171L9.51001 8.19171C9.75001 8.53171 9.68 8.9917 9.34 9.2417C9.2 9.3317 9.05001 9.38171 8.89001 9.38171Z"/>
						<path d="M16.3195 18.7514C15.2595 18.7514 14.2395 18.2514 13.5895 17.4114L12.3695 15.8414C12.1195 15.5114 12.1795 15.0414 12.4995 14.7914C12.8295 14.5414 13.2995 14.6014 13.5495 14.9214L14.7695 16.4914C15.1395 16.9714 15.7295 17.2114 16.3195 17.2514L20.9895 17.2314C21.3995 17.2314 21.7395 17.5614 21.7395 17.9814C21.7395 18.3914 21.4095 18.7314 20.9895 18.7314L16.3195 18.7514C16.3295 18.7514 16.3195 18.7514 16.3195 18.7514Z"/>
						<path d="M21.0014 6.77097C20.8114 6.77097 20.6214 6.70094 20.4714 6.55094L18.4714 4.55094C18.1814 4.26094 18.1814 3.78094 18.4714 3.49094C18.7614 3.20094 19.2414 3.20094 19.5314 3.49094L21.5314 5.49094C21.8214 5.78094 21.8214 6.26094 21.5314 6.55094C21.3814 6.70094 21.1914 6.77097 21.0014 6.77097Z"/>
						</svg>`
				);
				break;
			}
			case "grid_view": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z"/>
          <path d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z"/>
          <path d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z" />
          <path d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"/>
          </svg>`
				);
				break;
			}
			case "description": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`
          <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"/>
          <path d="M8 11H16"/>
          <path d="M8 16H12"/>
          </svg>`
				);
				break;
			}

			case "file_copy": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6"/>
        <path d="M17 13.4H13.8C11.4 13.4 10.6 12.6 10.6 10.2V7L17 13.4Z"/>
        <path d="M11.6 2H15.6"/>
        <path d="M7 5C7 3.34 8.34 2 10 2H12.62"/>
        <path d="M21.9999 8V14.19C21.9999 15.74 20.7399 17 19.1899 17"/>
        <path d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z"/>
        </svg>`
				);
				break;
			}

			case "task": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M11 19.5H21" />
						<path d="M11 12.5H21"  />
						<path d="M11 5.5H21"  />
						<path d="M3 5.5L4 6.5L7 3.5" />
						<path d="M3 12.5L4 13.5L7 10.5" />
						<path d="M3 19.5L4 20.5L7 17.5" />
						</svg>
						`
				);
				break;
			}

			case "settings": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"/>
        <path d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"/>
        </svg>
        `
				);
				break;
			}

			case "setting-4": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M22 6.5H16"/>
						<path d="M6 6.5H2" />
						<path d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"/>
						<path d="M22 17.5H18"/>
						<path d="M8 17.5H2"/>
						<path d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"/>
						</svg>
        `
				);
				break;
			}

			case "calendar_month_menu_item": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M8 2V5"/>
        <path d="M16 2V5"/>
        <path d="M3.5 9.08997H20.5"/>
        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"/>
        <path d="M15.6947 13.7H15.7037" />
        <path d="M15.6947 16.7H15.7037" />
        <path d="M11.9955 13.7H12.0045"/>
        <path d="M11.9955 16.7H12.0045"/>
        <path d="M8.29431 13.7H8.30329"/>
        <path d="M8.29431 16.7H8.30329"/>
        </svg>`
				);
				break;
			}

			case "format_list_bulleted": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"/>
        <path d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5"/>
        <path d="M8 13H12" />
        <path d="M8 17H16" />
        </svg>`
				);
				break;
			}

			//Misc

			case "figma-info-circle": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M13.3949 9.882L9.95993 10.3125L9.83693 10.8825L10.5119 11.007C10.9529 11.112 11.0399 11.271 10.9439 11.7105L9.83693 16.9125C9.54593 18.258 9.99443 18.891 11.0489 18.891C11.8664 18.891 12.8159 18.513 13.2464 17.994L13.3784 17.37C13.0784 17.634 12.6404 17.739 12.3494 17.739C11.9369 17.739 11.7869 17.4495 11.8934 16.9395L13.3949 9.882ZM13.4999 6.75C13.4999 7.14782 13.3419 7.52936 13.0606 7.81066C12.7793 8.09196 12.3978 8.25 11.9999 8.25C11.6021 8.25 11.2206 8.09196 10.9393 7.81066C10.658 7.52936 10.4999 7.14782 10.4999 6.75C10.4999 6.35218 10.658 5.97064 10.9393 5.68934C11.2206 5.40804 11.6021 5.25 11.9999 5.25C12.3978 5.25 12.7793 5.40804 13.0606 5.68934C13.3419 5.97064 13.4999 6.35218 13.4999 6.75Z" stroke-linecap="round" stroke-linejoin="round"/>												
						</svg>
						`
				);

				break;
			}

			case "figma-warning": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M22 27.5V27.5183M22 16.5V20.1667V16.5Z" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M9.16392 34.8369H34.8306C35.4288 34.8327 36.0169 34.6822 36.5436 34.3985C37.0703 34.1148 37.5196 33.7065 37.8523 33.2093C38.185 32.7121 38.391 32.141 38.4522 31.5459C38.5135 30.9508 38.4283 30.3498 38.2039 29.7952L25.1873 7.33686C24.8702 6.76376 24.4054 6.28605 23.8411 5.9534C23.2769 5.62076 22.6339 5.44531 21.9789 5.44531C21.3239 5.44531 20.6809 5.62076 20.1167 5.9534C19.5525 6.28605 19.0877 6.76376 18.7706 7.33686L5.75392 29.7952C5.53382 30.3371 5.44653 30.9238 5.49936 31.5063C5.5522 32.0888 5.74361 32.6502 6.05762 33.1436C6.37163 33.637 6.79913 34.0482 7.30442 34.3428C7.80972 34.6373 8.37815 34.8067 8.96226 34.8369" stroke-linecap="round" stroke-linejoin="round"/>												
						</svg>
						`
				);

				break;
			}

			case "money-recive": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M9.5 13.75C9.5 14.72 10.25 15.5 11.17 15.5H13.05C13.85 15.5 14.5 14.82 14.5 13.97C14.5 13.06 14.1 12.73 13.51 12.52L10.5 11.47C9.91 11.26 9.51001 10.94 9.51001 10.02C9.51001 9.17999 10.16 8.48999 10.96 8.48999H12.84C13.76 8.48999 14.51 9.26999 14.51 10.24"/>
						<path d="M12 7.5V16.5"/>
						<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2"/>
						<path d="M17 3V7H21" />
						<path d="M22 2L17 7" />
						</svg>
						`
				);

				break;
			}

			case "wallet-add": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M17.7514 7.04997C17.5114 7.00997 17.2614 6.99998 17.0014 6.99998H7.00141C6.72141 6.99998 6.45141 7.01998 6.19141 7.05998C6.33141 6.77998 6.53141 6.52001 6.77141 6.28001L10.0214 3.02C11.3914 1.66 13.6114 1.66 14.9814 3.02L16.7314 4.78996C17.3714 5.41996 17.7114 6.21997 17.7514 7.04997Z"/>
						<path d="M9 19C9 19.75 8.79 20.46 8.42 21.06C7.73 22.22 6.46 23 5 23C3.54 23 2.27 22.22 1.58 21.06C1.21 20.46 1 19.75 1 19C1 16.79 2.79 15 5 15C7.21 15 9 16.79 9 19Z"/>
						<path d="M6.49172 18.9795H3.51172" />
						<path d="M5 17.5195V20.5095" />
						<path d="M22 12V17C22 20 20 22 17 22H7.63C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15C3.8 15 2.73 15.53 2 16.36V12C2 9.28 3.64 7.38 6.19 7.06C6.45 7.02 6.72 7 7 7H17C17.26 7 17.51 7.00999 17.75 7.04999C20.33 7.34999 22 9.26 22 12Z"/>
						<path d="M22 12.5H19C17.9 12.5 17 13.4 17 14.5C17 15.6 17.9 16.5 19 16.5H22"/>
						</svg>`
				);

				break;
			}

			case "notification": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12.02 2.90991C8.70997 2.90991 6.01997 5.59991 6.01997 8.90991V11.7999C6.01997 12.4099 5.75997 13.3399 5.44997 13.8599L4.29997 15.7699C3.58997 16.9499 4.07997 18.2599 5.37997 18.6999C9.68997 20.1399 14.34 20.1399 18.65 18.6999C19.86 18.2999 20.39 16.8699 19.73 15.7699L18.58 13.8599C18.28 13.3399 18.02 12.4099 18.02 11.7999V8.90991C18.02 5.60991 15.32 2.90991 12.02 2.90991Z"/>
          <path d="M13.87 3.19994C13.56 3.10994 13.24 3.03994 12.91 2.99994C11.95 2.87994 11.03 2.94994 10.17 3.19994C10.46 2.45994 11.18 1.93994 12.02 1.93994C12.86 1.93994 13.58 2.45994 13.87 3.19994Z"/>
          <path d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601"/>
          </svg>`
				);

				break;
			}

			case "home": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 18V15"/>
        <path d="M10.07 2.81997L3.14002 8.36997C2.36002 8.98997 1.86002 10.3 2.03002 11.28L3.36002 19.24C3.60002 20.66 4.96002 21.81 6.40002 21.81H17.6C19.03 21.81 20.4 20.65 20.64 19.24L21.97 11.28C22.13 10.3 21.63 8.98997 20.86 8.36997L13.93 2.82997C12.86 1.96997 11.13 1.96997 10.07 2.81997Z"/>
        </svg>`
				);
				break;
			}

			case "home-2": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M8.65737 1.95968L2.77799 6.85968C1.79628 7.67635 1 9.41468 1 10.733V19.378C1 22.0847 3.0616 24.3013 5.59224 24.3013H18.2236C20.7543 24.3013 22.8159 22.0847 22.8159 19.3897V10.8963C22.8159 9.48468 21.9323 7.67635 20.8524 6.87135L14.1113 1.81968C12.5842 0.676349 10.1299 0.734682 8.65737 1.95968Z"/>
						<path d="M11.9062 19.6406V16.1406"  />
						</svg>`
				);
				break;
			}

			case "add": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M6 12H18"/>
            <path d="M12 18V6" />
            </svg>`
				);
				break;
			}

			case "rotate-right": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M14.89 5.0799C14.02 4.8199 13.06 4.6499 12 4.6499C7.20996 4.6499 3.32996 8.5299 3.32996 13.3199C3.32996 18.1199 7.20996 21.9999 12 21.9999C16.79 21.9999 20.67 18.1199 20.67 13.3299C20.67 11.5499 20.13 9.8899 19.21 8.5099"/>
						<path d="M16.13 5.32L13.24 2"/>
						<path d="M16.13 5.32007L12.76 7.78007"/>
						</svg>
            `
				);
				break;
			}

			case "refresh-2": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 3.11 16.44 3.11 16.44M3.11 16.44H7.63M3.11 16.44V21.44M2 12C2 6.48 6.44 2 12 2C18.67 2 22 7.56 22 7.56M22 7.56V2.56M22 7.56H17.56"/>
            </svg>
            `
				);
				break;
			}

			case "edit": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"/>
            <path d="M16.04 3.02001L8.16 10.9C7.86 11.2 7.56 11.79 7.5 12.22L7.07 15.23C6.91 16.32 7.68 17.08 8.77 16.93L11.78 16.5C12.2 16.44 12.79 16.14 13.1 15.84L20.98 7.96001C22.34 6.60001 22.98 5.02001 20.98 3.02001C18.98 1.02001 17.4 1.66001 16.04 3.02001Z"/>
            <path d="M14.91 4.1499C15.58 6.5399 17.45 8.4099 19.85 9.0899"/>
            </svg>`
				);
				break;
			}

			case "info-circle": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"/>
						<path d="M12 8V13"/>
						<path d="M11.9945 16H12.0035"/>
					</svg>`
				);
				break;
			}

			case "tick-circle": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"/>
						<path d="M7.75 12L10.58 14.83L16.25 9.17004"/>
						</svg>`
				);
				break;
			}

			//Arrows

			case "arrow-up-2": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19.9201 15.0499L13.4001 8.52989C12.6301 7.75989 11.3701 7.75989 10.6001 8.52989L4.08008 15.0499" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>`
				);
				break;
			}

			case "arrow-right-1": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008"/>
        				</svg>`
				);
				break;
			}

			case "arrow-right": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M14.4301 5.92993L20.5001 11.9999L14.4301 18.0699"/>
						<path d="M3.5 12H20.33"/>
						</svg>
						`
				);
				break;
			}

			case "arrow-down": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"/>
        				</svg>`
				);
				break;
			}

			case "arrow-down-3": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>`
				);
				break;
			}

			case "arrow-down-bold": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"/>
        				</svg>`
				);
				break;
			}

			case "arrow-left": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M9.57 5.92993L3.5 11.9999L9.57 18.0699"/>
						<path d="M20.5 12H3.67004"/>
						</svg>`
				);
				break;
			}

			case "arrow-left-1": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M15 19.9201L8.47997 13.4001C7.70997 12.6301 7.70997 11.3701 8.47997 10.6001L15 4.08008"/>
						</svg>
						`
				);
				break;
			}

			case "arrow-3": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M10.45 6.71997L6.72998 3L3.01001 6.71997"/>
						<path d="M6.72998 21V3"/>
						<path d="M13.55 17.28L17.2701 21L20.9901 17.28"/>
						<path d="M17.27 3V21"/>
						</svg>`
				);
				break;
			}

			case "group-11": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M3 17.9799L5.54999 17.9899C6.45999 17.9899 7.31 17.5399 7.81 16.7899L14.2 7.20994C14.7 6.45994 15.55 5.99993 16.46 6.00993L21.01 6.02994"/>
						<path d="M19 19.98L21 17.98"/>
						<path d="M8.89001 8.61993L7.81 7.11993C7.3 6.40993 6.47999 5.98993 5.60999 5.99993L3 6.00994"/>
						<path d="M12.97 15.3799L14.19 16.9499C14.7 17.6099 15.5 17.9999 16.34 17.9999L21.01 17.9799"/>
						<path d="M21 6.02002L19 4.02002"/>
						</svg>
						
            `
				);
				break;
			}

			//Essentials

			case "calendar_month": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M8 2V5"/>
						<path d="M16 2V5"/>
						<path d="M3.5 9.08997H20.5"/>
						<path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"/>
						<path d="M15.6947 13.7H15.7037" />
						<path d="M15.6947 16.7H15.7037" />
						<path d="M11.9955 13.7H12.0045"/>
						<path d="M11.9955 16.7H12.0045"/>
						<path d="M8.29431 13.7H8.30329"/>
						<path d="M8.29431 16.7H8.30329"/>
						</svg>`
				);
				break;
			}

			case "calendar_1": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M8 2V5"/>
						<path d="M16 2V5"/>
						<path d="M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z"/>
						<path d="M20.75 17.6H3.25"/>
						<path d="M12 8.25C10.77 8.25 9.73 8.92 9.73 10.22C9.73 10.84 10.02 11.31 10.46 11.61C9.85 11.97 9.5 12.55 9.5 13.23C9.5 14.47 10.45 15.24 12 15.24C13.54 15.24 14.5 14.47 14.5 13.23C14.5 12.55 14.15 11.96 13.53 11.61C13.98 11.3 14.26 10.84 14.26 10.22C14.26 8.92 13.23 8.25 12 8.25ZM12 11.09C11.48 11.09 11.1 10.78 11.1 10.29C11.1 9.79 11.48 9.5 12 9.5C12.52 9.5 12.9 9.79 12.9 10.29C12.9 10.78 12.52 11.09 12 11.09ZM12 14C11.34 14 10.86 13.67 10.86 13.07C10.86 12.47 11.34 12.15 12 12.15C12.66 12.15 13.14 12.48 13.14 13.07C13.14 13.67 12.66 14 12 14Z" stroke="transparent" stroke-width="0.5px" fill="` +
						this._color +
						`" />
						</svg>`
				);
				break;
			}

			case "calendar": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M8 2V5"/>
						<path d="M16 2V5"/>
						<path d="M3.5 9.08997H20.5"/>
						<path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"/>
						<path d="M15.6947 13.7H15.7037"/>
						<path d="M15.6947 16.7H15.7037"/>
						<path d="M11.9955 13.7H12.0045"/>
						<path d="M11.9955 16.7H12.0045"/>
						<path d="M8.29431 13.7H8.30329"/>
						<path d="M8.29431 16.7H8.30329"/>
						</svg>`
				);
				break;
			}

			case "dollar-square": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984"/>
						<path d="M12 6V18"/>
						<path d="M15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22Z"/>
						</svg>`
				);
				break;
			}

			case "gallery-add": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"/>
						<path d="M13 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V10"/>
						<path d="M15.75 5H21.25"/>
						<path d="M18.5 7.75V2.25"/>
						<path d="M2.67004 18.9501L7.60004 15.6401C8.39004 15.1101 9.53004 15.1701 10.24 15.7801L10.57 16.0701C11.35 16.7401 12.61 16.7401 13.39 16.0701L17.55 12.5001C18.33 11.8301 19.59 11.8301 20.37 12.5001L22 13.9001"/>
						</svg>
						`
				);
				break;
			}

			case "search-normal-1": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"/>
						<path d="M22 22L20 20"/>
						</svg>
						`
				);
				break;
			}

			case "trash": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"/>
						<path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"/>
						<path d="M18.85 9.14001L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79002C6.00002 22 5.91002 20.78 5.80002 19.21L5.15002 9.14001"/>
						<path d="M10.33 16.5H13.66"/>
						<path d="M9.5 12.5H14.5"/>
						</svg>
						`
				);
				break;
			}

			case "tick": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M7.75 12L10.58 14.83L16.25 9.17004"/>
						</svg>
						`
				);
				break;
			}

			case "eye": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M15.58 12C15.58 13.98 13.98 15.58 12 15.58C10.02 15.58 8.42004 13.98 8.42004 12C8.42004 10.02 10.02 8.42004 12 8.42004C13.98 8.42004 15.58 10.02 15.58 12Z"/>
						<path d="M12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.39997C18.82 5.79997 15.53 3.71997 12 3.71997C8.46997 3.71997 5.17997 5.79997 2.88997 9.39997C1.98997 10.81 1.98997 13.18 2.88997 14.59C5.17997 18.19 8.46997 20.27 12 20.27Z"/>
						</svg>`
				);
				break;
			}

			case "eye-slash": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M14.53 9.47004L9.47004 14.53C8.82004 13.88 8.42004 12.99 8.42004 12C8.42004 10.02 10.02 8.42004 12 8.42004C12.99 8.42004 13.88 8.82004 14.53 9.47004Z"/>
						<path d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998C8.46997 3.72998 5.17997 5.80998 2.88997 9.40998C1.98997 10.82 1.98997 13.19 2.88997 14.6C3.67997 15.84 4.59997 16.91 5.59997 17.77"/>
						<path d="M8.42004 19.5301C9.56004 20.0101 10.77 20.2701 12 20.2701C15.53 20.2701 18.82 18.1901 21.11 14.5901C22.01 13.1801 22.01 10.8101 21.11 9.40005C20.78 8.88005 20.42 8.39005 20.05 7.93005"/>
						<path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52"/>
						<path d="M9.47 14.53L2 22"/>
						<path d="M22 2L14.53 9.47"/>
						</svg>`
				);
				break;
			}

			case "document-download": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M9 11V17L11 15"/>
						<path d="M9 17L7 15"/>
						<path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke-linejoin="round"/>
						<path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke-linejoin="round"/>
						</svg>`
				);
				break;
			}

			case "document-1": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke-linejoin="round" />
						<path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke-linejoin="round"/>`
				);
				break;
			}

			case "clock-1": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"/>
						<path d="M15.71 15.18L12.61 13.33C12.07 13.01 11.63 12.24 11.63 11.61V7.51001"/>
						</svg>
						`
				);
				break;
			}

			case "close": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M9.16998 14.83L14.83 9.17004"/>
						<path d="M14.83 14.83L9.16998 9.17004"/>
						</svg>
						`
				);
				break;
			}

			case "close-circle": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"/>
						<path d="M9.16998 14.83L14.83 9.17004"/>
						<path d="M14.83 14.83L9.16998 9.17004"/>
						</svg>
						`
				);
				break;
			}
			case "edit-2": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z" />
						<path d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2" />
						<path d="M3 22H21" />
						</svg>
						`
				);
				break;
			}

			case "2user": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M9.16006 10.87C9.06006 10.86 8.94006 10.86 8.83006 10.87C6.45006 10.79 4.56006 8.84 4.56006 6.44C4.56006 3.99 6.54006 2 9.00006 2C11.4501 2 13.4401 3.99 13.4401 6.44C13.4301 8.84 11.5401 10.79 9.16006 10.87Z"/>
						<path d="M16.41 4C18.35 4 19.91 5.57 19.91 7.5C19.91 9.39 18.41 10.93 16.54 11C16.46 10.99 16.37 10.99 16.28 11" />
						<path d="M4.15997 14.56C1.73997 16.18 1.73997 18.82 4.15997 20.43C6.90997 22.27 11.42 22.27 14.17 20.43C16.59 18.81 16.59 16.17 14.17 14.56C11.43 12.73 6.91997 12.73 4.15997 14.56Z" />
						<path d="M18.3401 20C19.0601 19.85 19.7401 19.56 20.3001 19.13C21.8601 17.96 21.8601 16.03 20.3001 14.86C19.7501 14.44 19.0801 14.16 18.3701 14"/>
						</svg>
						`
				);
				break;
			}

			case "user-square": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M18.14 21.62C17.26 21.88 16.22 22 15 22H8.99998C7.77998 22 6.73999 21.88 5.85999 21.62C6.07999 19.02 8.74998 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62Z"/>
						<path d="M15 2H9C4 2 2 4 2 9V15C2 18.78 3.14 20.85 5.86 21.62C6.08 19.02 8.75 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62C20.86 20.85 22 18.78 22 15V9C22 4 20 2 15 2ZM12 14.17C10.02 14.17 8.42 12.56 8.42 10.58C8.42 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58C15.58 12.56 13.98 14.17 12 14.17Z"/>
						<path d="M15.58 10.58C15.58 12.56 13.98 14.17 12 14.17C10.02 14.17 8.42004 12.56 8.42004 10.58C8.42004 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58Z" />
						</svg>
						`
				);
				break;
			}

			case "sms-search": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V11.5"/>
						<path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"/>
						<path d="M18.2 21.4C19.9673 21.4 21.4 19.9673 21.4 18.2C21.4 16.4327 19.9673 15 18.2 15C16.4327 15 15 16.4327 15 18.2C15 19.9673 16.4327 21.4 18.2 21.4Z"/>
						<path d="M22 22L21 21"/>
						</svg>`
				);
				break;
			}

			case "close-circle-bold": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"/>
						</svg>
						`
				);
				break;
			}

			case "profile-circle": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z"/>
						<path d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z"/>
						<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"/>
						</svg>
						`
				);
				break;
			}

			case "bank-figma": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M3.25 22.75H22.75" />
						<path d="M3.25 10.834H22.75" />
						<path d="M5.41699 6.5L13.0003 3.25L20.5837 6.5"/>
						<path d="M4.33301 10.834V22.7507" />
						<path d="M21.667 10.834V22.7507" />
						<path d="M8.66699 15.166V18.416" />
						<path d="M13 15.166V18.416" />
						<path d="M17.333 15.166V18.416"/>
						</svg>`
				);
				break;
			}

			case "money-change": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M2 11V9C2 5.5 4 4 7 4H17C20 4 22 5.5 22 9V15C22 18.5 20 20 17 20H12"/>
						<path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z"/>
						<path d="M18.5 9.5V14.5"/>
						<path d="M2 15.5H7.34003C7.98003 15.5 8.5 16.02 8.5 16.66V17.94"/>
						<path d="M3.21997 14.28L2 15.5L3.21997 16.72"/>
						<path d="M8.5 20.7801H3.15997C2.51997 20.7801 2 20.2601 2 19.6201V18.3401"/>
						<path d="M7.28125 22.0003L8.50122 20.7803L7.28125 19.5603"/>
						</svg>
						`
				);
				break;
			}

			case "coin": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8 11.3992C8 12.1692 8.6 12.7992 9.33 12.7992H10.83C11.47 12.7992 11.99 12.2492 11.99 11.5792C11.99 10.8492 11.67 10.5892 11.2 10.4192L8.8 9.57922C8.32 9.40922 8 9.14922 8 8.41922C8 7.74922 8.52 7.19922 9.16 7.19922H10.66C11.4 7.20922 12 7.82922 12 8.59922" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10 12.8496V13.5896" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10 6.41016V7.19016" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M9.99 17.98C14.4028 17.98 17.98 14.4028 17.98 9.99C17.98 5.57724 14.4028 2 9.99 2C5.57724 2 2 5.57724 2 9.99C2 14.4028 5.57724 17.98 9.99 17.98Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M12.98 19.88C13.88 21.15 15.35 21.98 17.03 21.98C19.76 21.98 21.98 19.76 21.98 17.03C21.98 15.37 21.16 13.9 19.91 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						
						`
				);
				break;
			}

			case "document-copy": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6" stroke-linejoin="round" stroke-linecap="round"/>
						<path d="M17 13.4H13.8C11.4 13.4 10.6 12.6 10.6 10.2V7L17 13.4Z" stroke-linejoin="round" stroke-linecap="round"/>
						<path d="M11.6 2H15.6" stroke-linejoin="round" stroke-linecap="round"/>
						<path d="M7 5C7 3.34 8.34 2 10 2H12.62" stroke-linejoin="round" stroke-linecap="round" />
						<path d="M21.9999 8V14.19C21.9999 15.74 20.7399 17 19.1899 17" stroke-linejoin="round" stroke-linecap="round"/>
						<path d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z" stroke-linejoin="round" stroke-linecap="round"/>
						</svg>						
						`
				);
				break;
			}

			case "document-text": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"/>
						<path d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5"/>
						<path d="M8 13H12"/>
						<path d="M8 17H16"/>
						</svg>					
						`
				);
				break;
			}

			case "building": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12.5 22.0001H4.07997C2.91997 22.0001 1.96997 21.0701 1.96997 19.9301V5.09011C1.96997 2.47011 3.91997 1.2801 6.30997 2.4501L10.75 4.63011C11.71 5.10011 12.5 6.3501 12.5 7.4101V22.0001Z"/>
						<path d="M21.97 15.0602V18.8402C21.97 21.0002 20.97 22.0002 18.81 22.0002H12.5V10.4202L12.97 10.5202L17.47 11.5302L19.5 11.9802C20.82 12.2702 21.9 12.9502 21.96 14.8702C21.97 14.9302 21.97 14.9902 21.97 15.0602Z"/>						
						<path d="M5.5 9H8.97" />
						<path d="M5.5 13H8.97"/>
						<path d="M17.47 11.53V14.75C17.47 15.99 16.46 17 15.22 17C13.98 17 12.97 15.99 12.97 14.75V10.52L17.47 11.53Z"/>
						<path d="M21.96 14.87C21.9 16.05 20.92 17 19.72 17C18.48 17 17.47 15.99 17.47 14.75V11.53L19.5 11.98C20.82 12.27 21.9 12.95 21.96 14.87Z"/>
						</svg>`
				);
				break;
			}

			case "buildings": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13 22H5C3 22 2 21 2 19V11C2 9 3 8 5 8H10V19C10 21 11 22 13 22Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10.11 4C10.03 4.3 10 4.63 10 5V8H5V6C5 4.9 5.9 4 7 4H10.11Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M14 8V13" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M18 8V13" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M17 17H15C14.45 17 14 17.45 14 18V22H18V18C18 17.45 17.55 17 17 17Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M6 13V17" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10 19V5C10 3 11 2 13 2H19C21 2 22 3 22 5V19C22 21 21 22 19 22H13C11 22 10 21 10 19Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>`
				);
				break;
			}

			case "book-square": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M18.3792 15.27V7.57999C18.3792 6.80999 17.7591 6.25 16.9991 6.31H16.9592C15.6192 6.42 13.5892 7.11001 12.4492 7.82001L12.3392 7.89002C12.1592 8.00002 11.8491 8.00002 11.6591 7.89002L11.4991 7.79001C10.3691 7.08001 8.33915 6.40999 6.99915 6.29999C6.23915 6.23999 5.61914 6.81001 5.61914 7.57001V15.27C5.61914 15.88 6.11913 16.46 6.72913 16.53L6.90912 16.56C8.28912 16.74 10.4292 17.45 11.6492 18.12L11.6791 18.13C11.8491 18.23 12.1291 18.23 12.2891 18.13C13.5091 17.45 15.6591 16.75 17.0491 16.56L17.2592 16.53C17.8792 16.46 18.3792 15.89 18.3792 15.27Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M12 8.1001V17.6601" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>`
				);
				break;
			}

			case "card": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M2 8.50488H22"/>
						<path d="M6 16.5049H8" />
						<path d="M10.5 16.5049H14.5" />
						<path d="M6.44 3.50488H17.55C21.11 3.50488 22 4.38488 22 7.89488V16.1049C22 19.6149 21.11 20.4949 17.56 20.4949H6.44C2.89 20.5049 2 19.6249 2 16.1149V7.89488C2 4.38488 2.89 3.50488 6.44 3.50488Z"/>
						</svg>`
				);
				break;
			}

			case "more": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z" />
						<path d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z" />
						<path d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"/>
						</svg>`
				);
				break;
			}

			case "menu": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M3 7H21" stroke="#292D32"/>
						<path d="M3 12H21" stroke="#292D32"/>
						<path d="M3 17H21" stroke="#292D32"/>
						</svg>
						`
				);
				break;
			}

			case "money-change": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M2 11V9C2 5.5 4 4 7 4H17C20 4 22 5.5 22 9V15C22 18.5 20 20 17 20H12" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M18.5 9.5V14.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M2 15.5H7.34003C7.98003 15.5 8.5 16.02 8.5 16.66V17.94" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3.21997 14.28L2 15.5L3.21997 16.72" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M8.5 20.7801H3.15997C2.51997 20.7801 2 20.2601 2 19.6201V18.3401" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M7.28125 22.0003L8.50122 20.7803L7.28125 19.5603" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						`
				);
				break;
			}

			case "profile-2user": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M9.16006 10.87C9.06006 10.86 8.94006 10.86 8.83006 10.87C6.45006 10.79 4.56006 8.84 4.56006 6.44C4.56006 3.99 6.54006 2 9.00006 2C11.4501 2 13.4401 3.99 13.4401 6.44C13.4301 8.84 11.5401 10.79 9.16006 10.87Z" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M16.41 4C18.35 4 19.91 5.57 19.91 7.5C19.91 9.39 18.41 10.93 16.54 11C16.46 10.99 16.37 10.99 16.28 11" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M4.15997 14.56C1.73997 16.18 1.73997 18.82 4.15997 20.43C6.90997 22.27 11.42 22.27 14.17 20.43C16.59 18.81 16.59 16.17 14.17 14.56C11.43 12.73 6.91997 12.73 4.15997 14.56Z" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M18.3401 20C19.0601 19.85 19.7401 19.56 20.3001 19.13C21.8601 17.96 21.8601 16.03 20.3001 14.86C19.7501 14.44 19.0801 14.16 18.3701 14" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						
						`
				);
				break;
			}

			case "user-add": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"/>
						<path d="M3.40991 22C3.40991 18.13 7.25991 15 11.9999 15C12.9599 15 13.8899 15.13 14.7599 15.37"/>
						<path d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z"/>
						<path d="M19.49 17.98H16.51"/>
						<path d="M18 16.52V19.51" />
						</svg>
						`
				);
				break;
			}

			case "receipt-add": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M22 6V8.42C22 10 21 11 19.42 11H16V4.01C16 2.9 16.91 2 18.02 2C19.11 2.01 20.11 2.45 20.83 3.17C21.55 3.9 22 4.9 22 6Z"  stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M2 7V21C2 21.83 2.94001 22.3 3.60001 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.28999 22.29C8.67999 22.68 9.32001 22.68 9.71001 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7H6C3 2 2 3.79 2 6V7Z"  stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M6.25 10H11.75"  stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M9 12.75V7.25"   stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						`
				);
				break;
			}

			case "danger": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M12 9V14"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M12.0001 21.41H5.94005C2.47005 21.41 1.02005 18.93 2.70005 15.9L5.82006 10.28L8.76006 5.00003C10.5401 1.79003 13.4601 1.79003 15.2401 5.00003L18.1801 10.29L21.3001 15.91C22.9801 18.94 21.5201 21.42 18.0601 21.42H12.0001V21.41Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M11.9945 17H12.0035"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						
						`
				);
				break;
			}

			case "document-upload": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M9 17V11L7 13" stroke-linejoin="round"/>
						<path d="M9 11L11 13"  stroke-linejoin="round"/>
						<path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke-linejoin="round"/>
						<path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke-linejoin="round"/>`
				);
				break;
			}

			case "broom": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<path d="M9.87 5.66998L6.45 7.74999L4.89001 5.19C4.32001 4.25 4.62 3.01 5.56 2.44C6.5 1.87 7.74 2.16998 8.31 3.10998L9.87 5.66998Z" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M11.82 9.15997L8.66 11.08C6.82 12.2 6.25999 14.46 7.14999 16.26L9.19999 20.44C9.85999 21.79 11.46 22.26 12.74 21.47L19.17 17.56C20.46 16.78 20.77 15.15 19.88 13.94L17.11 10.2C15.91 8.58001 13.66 8.03997 11.82 9.15997Z" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10.7567 5.09791L5.63208 8.21851L7.71248 11.6349L12.8371 8.51431L10.7567 5.09791Z" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M14.31 16.8101L15.96 19.5201"  stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M11.75 18.37L13.4 21.08" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M16.87 15.25L18.52 17.96" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						
						
						`
				);
				break;
			}

			case "exit-button": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" fill="none">
						<path d="M22.9446 4.6665L4.27792 23.3332M22.9446 23.3332L4.27792 4.6665L22.9446 23.3332Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>`
				);
				break;
			}

			case "exit-back-arrow": {
				this._registry.addSvg(
					this.name,
					this.setParameters() +
						`<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 34 34" fill="none">
						<path d="M13.5575 8.40088L4.95831 17L13.5575 25.5992" stroke="white" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M29.0417 17H5.19916" stroke="white" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						`
				);
				break;
			}

			case "vector": {
				this._registry.addSvg(
					this.name,
					`${this.setParameters()}
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 23" fill="none">
					<path d="M20.5192 15.5385C23.3444 15.5385 25.6559 14.4678 25.6559 11.7221C25.6559 8.97647 22.9334 8.01329 20.7247 7.90577C20.268 3.61188 17.0777 1 13.3279 1C9.78365 1 7.50093 3.31151 6.75303 5.60385C3.67105 5.89159 1 7.4151 1 10.5712C1 13.7272 3.77378 15.5385 7.16397 15.5385M10.0405 18.7743L13.3279 22L16.6154 18.7743M13.3279 9.88461V21.1938" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>`
				);
				break;
			}

			case "folder": {
				this._registry.addSvg(
					this.name,
					`${this.setParameters()}
					<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M22 11V17C22 21 21 22 17 22H7C3 22 2 21 2 17V7C2 3 3 2 7 2H8.5C10 2 10.33 2.44 10.9 3.2L12.4 5.2C12.78 5.7 13 6 14 6H17C21 6 22 7 22 11Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
					</svg>`
				);
				break;
			}

			case "history-log": {
				this._registry.addSvg(
					this.name,
					`${this.setParameters()}
					<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M19.5 10.22V19C19.5 21 19 22 16.5 22H7.5C5 22 4.5 21 4.5 19V10.22" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M5 2H19C21 2 22 3 22 5V7C22 9 21 10 19 10H5C3 10 2 9 2 7V5C2 3 3 2 5 2Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M10.1797 14H13.8197" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>`
				);
				break;
			}

			case "warning": {
				this._registry.addSvg(
					this.name,
					`${this.setParameters()}
					<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M12 9V14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M11.9994 21.4112H5.93944C2.46944 21.4112 1.01944 18.9312 2.69944 15.9012L5.81944 10.2812L8.75944 5.00125C10.5394 1.79125 13.4594 1.79125 15.2394 5.00125L18.1794 10.2913L21.2994 15.9113C22.9794 18.9413 21.5194 21.4212 18.0594 21.4212H11.9994V21.4112Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M11.9922 17H12.0012" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>`
				);
				break;
			}

			case "category": {
				this._registry.addSvg(
					this.name,
					`${this.setParameters()}
					<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M4.78906 9.58073H6.70573C8.6224 9.58073 9.58073 8.6224 9.58073 6.70573V4.78906C9.58073 2.8724 8.6224 1.91406 6.70573 1.91406H4.78906C2.8724 1.91406 1.91406 2.8724 1.91406 4.78906V6.70573C1.91406 8.6224 2.8724 9.58073 4.78906 9.58073Z" stroke="#1B1D1F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M16.2891 9.58073H18.2057C20.1224 9.58073 21.0807 8.6224 21.0807 6.70573V4.78906C21.0807 2.8724 20.1224 1.91406 18.2057 1.91406H16.2891C14.3724 1.91406 13.4141 2.8724 13.4141 4.78906V6.70573C13.4141 8.6224 14.3724 9.58073 16.2891 9.58073Z" stroke="#1B1D1F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M16.2891 21.0807H18.2057C20.1224 21.0807 21.0807 20.1224 21.0807 18.2057V16.2891C21.0807 14.3724 20.1224 13.4141 18.2057 13.4141H16.2891C14.3724 13.4141 13.4141 14.3724 13.4141 16.2891V18.2057C13.4141 20.1224 14.3724 21.0807 16.2891 21.0807Z" stroke="#1B1D1F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M4.78906 21.0807H6.70573C8.6224 21.0807 9.58073 20.1224 9.58073 18.2057V16.2891C9.58073 14.3724 8.6224 13.4141 6.70573 13.4141H4.78906C2.8724 13.4141 1.91406 14.3724 1.91406 16.2891V18.2057C1.91406 20.1224 2.8724 21.0807 4.78906 21.0807Z" stroke="#1B1D1F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>`
				);
				break;
			}

			default: {
				this._registry.addSvg(
					this.name,
					`<svg  stroke="red" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8 22.3199C7.72 22.3199 7.42998 22.2499 7.16998 22.1099C6.59998 21.8099 6.25 21.2099 6.25 20.5699V19.15C3.23 18.84 1.25 16.6199 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.8799 20.44 19.1899 17 19.1899H13.23L8.96997 22.03C8.67997 22.22 8.34 22.3199 8 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.43C2.75 16.01 4.42 17.68 7 17.68C7.41 17.68 7.75 18.02 7.75 18.43V20.56C7.75 20.69 7.83 20.75 7.88 20.78C7.93001 20.81 8.03001 20.84 8.14001 20.77L12.59 17.81C12.71 17.73 12.86 17.68 13.01 17.68H17.01C19.59 17.68 21.26 16.01 21.26 13.43V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z"/>
					<path d="M11.9998 12.1099C11.5898 12.1099 11.2498 11.7699 11.2498 11.3599V11.1499C11.2498 9.9899 12.0998 9.41989 12.4198 9.19989C12.7898 8.94989 12.9098 8.7799 12.9098 8.5199C12.9098 8.0199 12.4998 7.60986 11.9998 7.60986C11.4998 7.60986 11.0898 8.0199 11.0898 8.5199C11.0898 8.9299 10.7498 9.2699 10.3398 9.2699C9.92984 9.2699 9.58984 8.9299 9.58984 8.5199C9.58984 7.1899 10.6698 6.10986 11.9998 6.10986C13.3298 6.10986 14.4098 7.1899 14.4098 8.5199C14.4098 9.6599 13.5698 10.2299 13.2598 10.4399C12.8698 10.6999 12.7498 10.8699 12.7498 11.1499V11.3599C12.7498 11.7799 12.4098 12.1099 11.9998 12.1099Z" />
					<path d="M12 14.6001C11.58 14.6001 11.25 14.2601 11.25 13.8501C11.25 13.4401 11.59 13.1001 12 13.1001C12.41 13.1001 12.75 13.4401 12.75 13.8501C12.75 14.2601 12.42 14.6001 12 14.6001Z"/>
					</svg>`
				);
				break;
			}
		}
	}

	setParameters(): string {
		return (
			`<svg class="flex-align-center-center" 
			width="` +
			this.size +
			`" height="` +
			this.size +
			`" stroke="` +
			this._color +
			`"  fill="` +
			this.fill +
			`" stroke-width="` +
			this.lineWidth +
			`" viewBox="` +
			this.viewbox +
			`"  stroke-miterlimit="10" stroke-linecap="round" xmlns="http://www.w3.org/2000/svg">`
		);
	}
}
