import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ReportingService } from "@modules/reporting/_services/reporting.service";
import { Store, select } from "@ngrx/store";
import { PaginationService } from "@shared/components/pagination/_services/pagination.service";
import { PaginationOptions } from "@shared/constants/pagination";
import { correctConvertToISODate } from "@shared/utils/date.util";
import { PaginationData } from "@store/models/pagination.model";
import { Observable, Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { PayGroup } from "../../../../shared/models/pay-groups";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import { ToastService } from "../../../../shared/services/toast/toast.service";
import { getCustomerEntityGroupState, getPagination } from "../../../../store";
import { AppState } from "../../../../store/models/state.model";
import { ApiService } from "../../services/api.service";

@Component({
	selector: "app-pay-group-list",
	templateUrl: "./pay-group-list.component.html",
	styleUrls: ["./pay-group-list.component.scss"]
})
export class PayGroupListComponent implements OnInit, OnDestroy {
	payGroupSubscription!: Subscription;

	selectedCustomerId: string = "";
	selectedLegalEntityId: string = "";
	payGroupSearchQuery: string = "";

	payGroups$!: Observable<PayGroup[]>;

	//pagination
	pageIndex: number = 0;
	pageSize: number = 10;
	totalPageCount!: number;

	statusFilter: string = "";
	startDateFilter: string = "";
	endDateFilter: string = "";
	private destroy$: Subject<void> = new Subject<void>();

	canCreatePayGroups: boolean = false;

	customerName: string = "";
	customerId: string = "";
	legalEntitiesCountriesList: string[] = [];
	paginationType: PaginationOptions = PaginationOptions.PAYGROUPS;
	initialRun = true;

	constructor(
		private paygroupApiService: ApiService,
		private router: Router,
		private toastService: ToastService,
		private store: Store<AppState>,
		private permissions: PermissionsService,
		private reportingService: ReportingService,
		private paginationService: PaginationService
	) {}

	ngOnInit(): void {
		this.permissions
			.canCreatePayGroups()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canCreatePayGroups = res;
				this.getCustomerEntityGroupStateFromStore();
				this.setupPaginationSubscription();
			});
	}

	getCustomerEntityGroupStateFromStore() {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			if (state?.customerId) {
				this.selectedCustomerId = state.customerId ? state.customerId : "";
				this.selectedLegalEntityId = state.legalEntityId ? state.legalEntityId : "";
			}
		});
	}

	setupPaginationSubscription(): void {
		this.store.pipe(select(getPagination), takeUntil(this.destroy$)).subscribe(pagination => {
			if (pagination && pagination.hasOwnProperty("PAYGROUPS")) {
				const paygroupPagination = pagination["PAYGROUPS"];
				this.pageSize = paygroupPagination.pageSize;
				this.pageIndex = paygroupPagination.pageIndex;
				this.getPayGroupsList(paygroupPagination.pageSize, paygroupPagination.pageIndex);
			}
		});
	}

	filterPaygroups(searchedTerm: string): void {
		this.payGroupSearchQuery = searchedTerm ? searchedTerm : "";
		this.resetPagination();
	}

	getPayGroupsList(pageSize: number, pageNumber: number): void {
		this.payGroups$ = this.paygroupApiService
			.getPayGroups(
				this.selectedCustomerId,
				this.selectedLegalEntityId,
				this.statusFilter,
				this.startDateFilter,
				this.endDateFilter,
				pageSize,
				pageNumber,
				this.payGroupSearchQuery
			)
			.pipe(
				map(response => {
					this.totalPageCount = response.totalPages;
					return response.items;
				})
			);
	}

	resetPagination(): void {
		const paginationSelection: PaginationData = {
			pageSize: this.pageSize,
			pageIndex: 0
		};

		this.paginationService.updatePaginationSate(this.paginationType, paginationSelection);
	}

	createClicked() {
		if (!this.selectedLegalEntityId) {
			this.toastService.showWarning("Please select a Legal Entity before you create");
		} else {
			this.router.navigate(["/pay-groups/group"], {
				state: { customerId: this.selectedCustomerId, legalEntityId: this.selectedLegalEntityId }
			});
		}
	}

	filtersChanged(filters: { status: string; start: string; end: string }): void {
		this.statusFilter = filters.status ? filters.status.toUpperCase() : "";
		const startDateFilterISO = correctConvertToISODate(new Date(filters.start));
		const endDateFilterISO = correctConvertToISODate(new Date(filters.end));
		this.startDateFilter = filters.start ? startDateFilterISO.toISOString() : "";
		this.endDateFilter = filters.end ? endDateFilterISO.toISOString() : "";
		this.resetPagination();
	}

	downloadPaygroups(): void {
		const startDate: string = this.startDateFilter ? this.startDateFilter : "";
		const endDate: string = this.endDateFilter ? this.endDateFilter : "";
		const status: string = this.statusFilter ? this.statusFilter : "";
		const customerId: string = this.selectedCustomerId ? this.selectedCustomerId : "";
		const legalEntityId: string = this.selectedLegalEntityId ? this.selectedLegalEntityId : "";

		var filename: string = "";

		if (startDate === "" && endDate === "") {
			filename = `Paygroups ${customerId} ${legalEntityId}`;
		} else if (endDate === "") {
			filename = `Paygroups ${customerId} ${legalEntityId} from ${this.reportingService.formatDate(
				new Date(startDate)
			)} `;
		} else {
			filename = `Paygroups ${customerId} ${legalEntityId} from (${this.reportingService.formatDate(
				new Date(startDate)
			)} to ${this.reportingService.formatDate(new Date(endDate))})`;
		}

		this.payGroupSubscription = this.reportingService
			.getPayGroupsReports(startDate, endDate, status, customerId, legalEntityId)
			.subscribe((response: Blob) => {
				let downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(response);
				if (filename) downloadLink.setAttribute("download", filename);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
