import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/dialog";
import { timeout } from "rxjs/operators";
import { FileManagementUploadService } from "@shared/services/file-management/file-management-upload.service";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { HistoryReset, Milestone, PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { resetDialog } from "src/app/shared/models/reset-payroll-dialog.interface";

@Component({
	selector: "app-reset-payroll-dialog",
	templateUrl: "./reset-payroll-dialog.component.html",
	styleUrls: ["./reset-payroll-dialog.component.scss"]
})
export class ResetPayrollDialogComponent implements OnInit {
	payCycle!: PayCycle;
	milestoneType!: string;
	errorStatus: number = 0;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: resetDialog,
		public dialogRef: MatDialogRef<ResetPayrollDialogComponent>,
		private payCycleService: PayCycleService,
		private commonFileManagerService: FileManagementUploadService
	) {}

	ngOnInit(): void {
		this.payCycle = this.data.payCycle;
		this.milestoneType = this.data.milestoneType;
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}

	onConfirm(): void {
		this.resetCurrentPayCycle();
	}

	resetCurrentPayCycle() {
		this.payCycleService.resetPayCycle(this.payCycle.id, this.milestoneType).subscribe({
			next: res => {
				switch (res.status) {
					case 200:
						this.commonFileManagerService.updateFileList$.emit();
						this.dialogRef.close(true);
						break;
					default:
						this.dialogRef.close(false);
						break;
				}
			},
			error: _ => {
				this.dialogRef.close(false);
			}
		});
	}
}
