import { DatePipe, formatDate } from "@angular/common";
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SelectOption } from "src/app/shared/models/select-option.interface";

@Component({
	selector: "app-pay-group-list-filters",
	templateUrl: "./pay-group-list-filters.component.html",
	styleUrls: ["./pay-group-list-filters.component.scss"]
})
export class PayGroupListFiltersComponent implements OnInit {
	statusOptions: SelectOption[] = [
		{ value: "", text: "All Status" },
		{ value: "Live", text: "Live" },
		{ value: "Implementation", text: "Implementation" },
		{ value: "Inactive", text: "Inactive" }
	];

	@Output() filtersChanged = new EventEmitter<{ status: string; start: string; end: string }>();

	destroy$: Subject<void> = new Subject();

	form!: FormGroup;
	startDate: string = "Start with";
	endDate: string = "End with";

	constructor(private formBuilderService: FormBuilder, public datepipe: DatePipe) {}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		this.form = this.formBuilderService.group({
			status: [""],
			start: [""],
			end: [""]
		});

		this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(values => this.filtersChanged.emit(values));
		this.form
			.get("start")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => (this.startDate = this.datepipe.transform(value, "MMM d, yyyy")!));
		this.form
			.get("end")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => (this.endDate = this.datepipe.transform(value, "MMM d, yyyy")!));
	}

	clearDate(): void {
		this.form.controls["start"].reset();
		this.form.controls["end"].reset();
		this.startDate = "Start with";
		this.endDate = "End with";
	}
}
