import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
	forwardRef
} from "@angular/core";
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormGroup,
	NG_VALUE_ACCESSOR,
	Validators
} from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { SelectOption } from "@shared/models/select-option.interface";
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, map, startWith, takeUntil } from "rxjs";
import { InputAutocompleteService } from "@shared/components/input-autocomplete/_services/input-autocomplete.service";

@Component({
	selector: "wpay-multi-select-auto-complete",
	templateUrl: "./wpay-multi-select-auto-complete.component.html",
	styleUrls: ["./wpay-multi-select-auto-complete.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => WpayMultiSelectAutoCompleteComponent),
			multi: true
		}
	]
})
export class WpayMultiSelectAutoCompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
	// Inputs
	@Input() set disableDropDown(value: boolean) {
		value ? this.control.disable() : this.control.enable();
		this._disabled = value;
	}

	@Input() set options(options: SelectOption[]) {
		if (options) {
			if (options.length === 0 && this.allOptions) {
				this._defaultValue = [];
				this.clearInput(false);
			}

			this.allOptions = [...options];

			this.filteredOptions$.next(this.organiseOptionsBySelection(this.allOptions));

			// Set the flag to indicate options initialization
			this.optionsInitialized = true;

			// For store: Call writeValueById if defaultValue is already initialized
			if (this._defaultValue && this._defaultValue.length > 0) {
				this.control.patchValue(["Fetching..."], { emitEvent: false });
				this.writeValueById(this._defaultValue);
			}
		}
	}

	@Input() dropDownType!: string;

	@Input() set defaultValue(value: string[] | undefined) {
		value ? this.setupDefualtValue(value) : this.clearDefaultValue();
	}

	@Input() set showLoadingOption(value: boolean) {
		value ? ((this._disabled = true), this.control.disable()) : ((this._disabled = false), this.control.enable());
		this.indicateLoading = value;
	}

	@Input() emitClearInSeperateFunction = false;

	@Input() label?: string;
	@Input() labelDisappears?: boolean = true;
	@Input() required?: boolean;

	//Outputs
	@Output() emitScrollerReachedTheBottom: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	@Output() multipleSelectOptionListClosed: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	@Output() selectionCleared: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	//DOM selectors
	@ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
	@ViewChild("input") input!: ElementRef<HTMLInputElement>;

	//If you want to load in more options - when the scroller hits the bottom of the container
	@ViewChild("scrollContainer") scrollContainerRef!: ElementRef;

	//Public variables
	control: FormControl = new FormControl();
	optionsObject: { [key: string]: { imagePath: string; text: string } } = {};
	filteredOptions$: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>([]);
	destroy$: Subject<void> = new Subject();
	selectedOptions: SelectOption[] = [];
	_defaultValue: string[] | undefined;
	_disabled: boolean = false;
	onChange: any = () => {};
	onTouched: any = () => {};
	panelOpen: boolean = false;
	indicateLoading = false;
	searchForm!: FormGroup;
	extraOptionsSelectedCounter = 0;
	maxOptionsToDisplay = 0;
	showLoadingTest = "Loading...";

	//Private variables
	private allOptions!: SelectOption[];
	private optionsInitialized = false;
	@ViewChild("optionContainer") optionContainer!: ElementRef;
	@ViewChild("searchInput") searchInput!: ElementRef;

	constructor(
		public inputAutocompleteService: InputAutocompleteService,
		private formBuilder: FormBuilder,
		private cdr: ChangeDetectorRef,
		private renderer: Renderer2
	) {}

	ngOnInit(): void {
		this.initForm();

		if (this.required) {
			this.control.setValidators([Validators.required]);
			this.control.updateValueAndValidity();
		}

		this.inputAutocompleteService.clearinputs.pipe(takeUntil(this.destroy$)).subscribe(clear => {
			if (clear) {
				this.clearInput(false);
			}
		});
	}

	ngAfterViewInit() {
		this.attachScrollListener();
		this.updateOptionsDisplay();
		window.addEventListener("resize", () => {
			this.cdr.detectChanges();
			this.updateOptionsDisplay();
		});
	}

	initForm() {
		this.searchForm = this.formBuilder.group({
			search: [""]
		});

		this.searchForm
			.get("search")
			?.valueChanges.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				startWith(""),
				map((text: string) => {
					if (text) {
						return this.filter(this.allOptions, text);
					} else {
						return this.allOptions;
					}
				})
			)
			.subscribe(options => {
				options && options.length > 0
					? this.filteredOptions$.next(this.organiseOptionsBySelection(options))
					: this.filteredOptions$.next([{ text: "No items found", value: "noOptions" }]);
			});
	}

	get disabled(): boolean {
		return this._disabled;
	}

	setupDefualtValue(value: string[]) {
		value.length ? (this._defaultValue = value) : this.clearDefaultValue();

		if (this.optionsInitialized && value.length) {
			this.showLoadingTest = "Fetching...";
			this.writeValueById(value);
		}
	}
	clearDefaultValue() {
		this.selectedOptions = [];
		if (this.allOptions) this.allOptions.forEach(options => (options.selected = false));
		this.control.patchValue([], { emitEvent: false });
		this.clearOptionsDisplay();
	}

	attachScrollListener(): void {
		const scrollContainer = this.scrollContainerRef?.nativeElement;
		if (scrollContainer) {
			scrollContainer.addEventListener("scroll", this.onScroll.bind(this));
		}
	}

	onScroll(): void {
		const scrollContainer = this.scrollContainerRef?.nativeElement;
		if (
			scrollContainer &&
			scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight
		) {
			this.emitScrollerReachedTheBottom.emit(true);
		}
	}

	writeValueById(defaultValue: string | string[]) {
		const output = this.inputAutocompleteService.writeValueById(this.allOptions, defaultValue);
		this.filteredOptions$.next(this.organiseOptionsBySelection(output.filteredOptions));

		if (output.selectOptions) {
			this.control.patchValue(output.selectOptions);
			if (Array.isArray(output.selectOptions)) {
				this.selectedOptions = output.selectOptions;
			}
		}
		this.updateOptionsDisplay();
	}
	organiseOptionsBySelection(filteredOptions: SelectOption[]): SelectOption[] {
		const allOption: SelectOption = {
			text: `All ${this.dropDownType}`,
			value: `${this.dropDownType}`,
			selected: false
		};

		let selectedOptions: SelectOption[] = filteredOptions.filter(option => option.selected).length
			? filteredOptions.filter(option => option.selected)
			: [];

		let unselectedAllOptions: SelectOption[] = filteredOptions.filter(
			option => !option.selected || option.selected === undefined
		).length
			? filteredOptions.filter(option => !option.selected || option.selected === undefined)
			: [];

		if (this.isAllOptionNotRenderedYet(unselectedAllOptions, selectedOptions, this.allOptions)) {
			this.allOptions = [allOption, ...this.allOptions];
			unselectedAllOptions = [allOption, ...unselectedAllOptions];
		} else {
			selectedOptions = [...this.sortAllOptions(selectedOptions)];
			unselectedAllOptions = [...this.sortAllOptions(unselectedAllOptions)];
		}

		return selectedOptions.concat([...unselectedAllOptions]);
	}

	isAllOptionNotRenderedYet(
		unselectedAllOptions: SelectOption[],
		selectedOptions: SelectOption[],
		allOptions: SelectOption[]
	): boolean {
		return (
			!unselectedAllOptions.find(options => options.value === this.dropDownType) &&
			!selectedOptions.find(options => options.value === this.dropDownType) &&
			!allOptions.find(options => options.value === this.dropDownType)
		);
	}

	sortAllOptions(options: SelectOption[]): SelectOption[] {
		return options.sort((a, b) => {
			if (a.value === this.dropDownType) {
				return 1;
			} else if (b.value === this.dropDownType) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	writeValue(value: any): void {}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	openDropDown(state?: boolean): void {
		if (!this._disabled) {
			this.control.setErrors(null);
			this.autocomplete.openPanel();
			this.panelOpen = state ? state : !this.panelOpen;
			this.panelOpen ? this.setFocusOnSearch() : this.onClosed(true);
		}
	}
	setFocusOnSearch(): void {
		setTimeout(() => {
			if (this.searchInput) {
				this.searchInput.nativeElement.focus();
			}
		}, 100);
	}

	onClosed(emit: boolean): void {
		if (this.selectedOptions.length > 0) {
			this.control.patchValue(this.selectedOptions);
			this.onChange(this.getCodes(this.selectedOptions));
			emit ? this.multipleSelectOptionListClosed.emit(true) : null;
		} else {
			this.control.patchValue([]);
			this.selectionCleared.emit(true);
			this.allOptions.forEach(options => (options.selected = false));
		}

		this.searchForm.get("search")?.patchValue("", { emitEvent: false });

		this.panelOpen = false;

		if (this.optionContainer) this.optionContainer.nativeElement.blur();
	}

	handleEmptyInput($event: any): void {
		//When value empty output null value
		if (
			$event.target.value === "" ||
			(Array.isArray($event.target.value.length) && $event.target.value.length === 0)
		) {
			this.onChange(null);
		}
	}

	getCodes(selectedOptions: SelectOption[]): string[] {
		let codes: string[] = [];
		selectedOptions.map(opt => codes.push(opt.value));
		return codes;
	}

	filter(options: SelectOption[], text: string): SelectOption[] {
		const filterValue = text.toLowerCase();
		return options.filter(
			option =>
				option.text.toLowerCase().includes(filterValue) ||
				(option.externalId && option.externalId.toLowerCase().includes(filterValue))
		);
	}

	optionClicked(event: Event, option: SelectOption): void {
		event.stopPropagation();
		if (option.value !== "noOptions") {
			this.toggleSelection(option);
		}
		this.setFocusOnSearch();
	}

	showDivider(option: SelectOption, options: SelectOption[], i: number): boolean {
		return (
			this.selectedOptions.length > 0 &&
			i !== options.length - 1 &&
			option.selected === true &&
			(!options[i + 1].selected || options[i + 1].selected === false)
		);
	}

	toggleSelection(option: SelectOption): void {
		option.selected = option.selected === undefined ? (option.selected = true) : !option.selected;

		this.allOptions = this.setAllOptionsSelectionState(this.allOptions);

		if (option.value === this.dropDownType) {
			if (option.selected === true) {
				this.updateValues([
					...this.allOptions.map(option => {
						option.selected = true;
						return option;
					})
				]);
			} else {
				this.allOptions = [
					...this.allOptions.map(option => {
						option.selected = false;
						return option;
					})
				];

				this.updateValues([]);
			}
		} else {
			this.updateValues(
				this.inputAutocompleteService.formatSelectedOptions(option, this.selectedOptions, this.dropDownType)
			);
		}
	}
	setAllOptionsSelectionState(allOptions: SelectOption[]): SelectOption[] {
		let allOptionIndex: number = allOptions.findIndex(option => option.value === this.dropDownType);

		let unSelectedFound = allOptions.filter(
			option => (!option.selected || option.selected === undefined) && option.value !== this.dropDownType
		);

		if (unSelectedFound.length > 0) {
			if (allOptionIndex > -1) {
				allOptions[allOptionIndex] = {
					value: this.dropDownType,
					text: allOptions[allOptionIndex].text,
					selected: false
				};

				return allOptions;
			}
			return allOptions;
		} else {
			if (allOptionIndex > -1) {
				allOptions[allOptionIndex] = {
					value: this.dropDownType,
					text: allOptions[allOptionIndex].text,
					selected: true
				};

				return allOptions;
			}
			return allOptions;
		}
	}
	updateValues(selectedOptions: SelectOption[]): void {
		this.selectedOptions = selectedOptions;
		this.filteredOptions$.next(this.organiseOptionsBySelection(this.allOptions));
		this.onChange(this.getCodes(selectedOptions));
		this.cdr.detectChanges();
		this.searchForm.get("search")?.patchValue("", { emitEvent: false });
		this.updateOptionsDisplay();
	}

	clearInput(emit: boolean): void {
		if (!this._disabled) {
			this.allOptions.forEach(option => {
				option.selected = false;
			});

			this.filteredOptions$.next(this.organiseOptionsBySelection(this.allOptions));
			this.selectedOptions = [];
			this.control.setValue([]);
			this.onChange([]);
			this.onClosed(emit);
			this.clearSelection();
		}
	}

	clearSelection() {
		if (this.optionContainer) {
			const container = this.optionContainer.nativeElement;
			while (container.firstChild) {
				container.removeChild(container.firstChild);
			}
		}
	}

	clearButtonClicked(event: Event) {
		event.stopPropagation();
		if (!this._disabled) {
			this.emitClearInSeperateFunction ? this.clearProceedingFilters() : this.clearInput(true);
		}
	}

	clearProceedingFilters(): void {
		//emit to filters to clear proceeding filter selection
		this.selectionCleared.emit(true);
		this.clearInput(false);
	}

	clearOptionsDisplay() {
		if (this.optionContainer) {
			const container = this.optionContainer.nativeElement;
			if (container) {
				// Clear the container
				while (container.firstChild) {
					container.removeChild(container.firstChild);
				}
			}
		}
	}

	updateOptionsDisplay(): void {
		if (this.optionContainer) {
			const container = this.optionContainer.nativeElement;
			// Clear the container
			while (container.firstChild) {
				container.removeChild(container.firstChild);
			}

			const selectedOptions = this.selectedOptions;
			const maxOptionsToDisplay = this.calculateMaxOptionsToDisplay();

			for (let i = 0; i < selectedOptions.length; i++) {
				if (i >= maxOptionsToDisplay) {
					// If we've reached the maximum number of displayed options, exit the loop
					break;
				}

				if (selectedOptions[i].value !== this.dropDownType) {
					this.addOptionToContainer(container, selectedOptions[i].text, false);
				}
			}

			if (selectedOptions.length > maxOptionsToDisplay) {
				// Create and add the counter element
				this.addCounterToContainer(container, maxOptionsToDisplay, false);
			}
		}
	}

	calculateMaxOptionsToDisplay(): number {
		const container = this.optionContainer.nativeElement;
		if (container) {
			const containerWidth = container.clientWidth;
			const selectedOptions = this.selectedOptions;
			let totalOptionsWidth = 0;
			let maxOptionsToDisplay = 0;

			for (let i = 0; i < selectedOptions.length; i++) {
				if (selectedOptions[i].value !== this.dropDownType) {
					const optionWidth: number = this.addOptionToContainer(container, selectedOptions[i].text, true);
					totalOptionsWidth += optionWidth;

					if (i >= maxOptionsToDisplay) {
						const counterWidth = this.addCounterToContainer(container, maxOptionsToDisplay, true);
						totalOptionsWidth += counterWidth;
					}

					if (totalOptionsWidth <= containerWidth) {
						maxOptionsToDisplay++;
					} else {
						break;
					}
				} else {
					maxOptionsToDisplay++;
				}
			}

			return maxOptionsToDisplay;
		}
		return 0;
	}

	addCounterToContainer(container: ElementRef, maxOptionsToDisplay: number, removeAfterwards: boolean): number {
		const counterElement = this.renderer.createElement("span");

		if (removeAfterwards) this.renderer.setStyle(counterElement, "position", "absolute");
		this.renderer.addClass(counterElement, "counter");
		this.renderer.setProperty(
			counterElement,
			"textContent",
			`+ ${this.selectedOptions.length - maxOptionsToDisplay}`
		);

		this.renderer.appendChild(container, counterElement);

		const offsetWidth: number = counterElement.offsetWidth;

		if (removeAfterwards) this.renderer.removeChild(container, counterElement);

		return offsetWidth;
	}

	addOptionToContainer(container: ElementRef, optionText: string, removeAfterwards: boolean): number {
		const optionElement = this.renderer.createElement("span");

		if (removeAfterwards) this.renderer.setStyle(optionElement, "position", "absolute");
		this.renderer.setAttribute(optionElement, "data-test", optionText);
		this.renderer.addClass(optionElement, "input-option");
		this.renderer.setProperty(optionElement, "textContent", optionText);

		this.renderer.appendChild(container, optionElement);

		const offsetWidth: number = optionElement.offsetWidth;

		if (removeAfterwards) this.renderer.removeChild(container, optionElement);

		return offsetWidth;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
