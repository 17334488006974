import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DashboardFilters } from "@modules/global-dashboard/models/global-dashboard-interface";
import { ToastService } from "@shared/services/toast/toast.service";
import { CustomerEntityGroupSelect } from "@store/models/customerEnitityGroupSelection.model";
import { Observable, of } from "rxjs";
import { catchError, map, take } from "rxjs/operators";
import {
	PayGroup,
	PayGroupHistoryReport,
	PayGroupPagination,
	PaygroupHistoryEndpoint,
	statusType
} from "src/app/shared/models/pay-groups";
import { SelectOption } from "src/app/shared/models/select-option.interface";

import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class PaygroupsService {
	private sortField = "data.externalId";
	private sortDir = "DESC";

	constructor(private http: HttpClient, private toast: ToastService) {}

	getPayGroupWithCustomerId(customerId: string): Observable<PayGroupPagination> {
		return this.http.get<PayGroupPagination>(
			`${environment.apiUri}/v1/pay-groups?customerIds=${customerId}&pageSize=-1`
		);
	}
	getAllPayGroups(pageSize: number, pageNumber: number): Observable<PayGroupPagination> {
		return this.http.get<PayGroupPagination>(
			`${environment.apiUri}/v1/pay-groups?sortField=${this.sortField}&sortDir=${this.sortDir}&pageSize=${pageSize}&pageNumber=${pageNumber}`
		);
	}

	getPaygroupWithPaygroupId(payGroupId: string): Observable<PayGroup> {
		return this.http.get<PayGroup>(`${environment.apiUri}/v1/pay-groups/${payGroupId}`);
	}

	getPaygroupWithLegalEntityIds(legalEntityIds: string[]): Observable<PayGroupPagination> {
		return this.http.get<PayGroupPagination>(
			`${environment.apiUri}/v1/pay-groups?legalEntityIds=${legalEntityIds}&pageSize=-1`
		);
	}

	getPayGroupsByLegalEntityIdsPagination(
		legalEntityIds: string[],
		status: string,
		pageSize: number,
		pageNumber: number
	): Observable<PayGroupPagination> {
		return this.http.get<PayGroupPagination>(
			`${environment.apiUri}/v1/pay-groups?legalEntityIds=${legalEntityIds}&statuses=${status}&pageSize=${pageSize}&pageNumber=${pageNumber}&sortField=${this.sortField}&sortDir=${this.sortDir}`
		);
	}

	getPayGroups(
		customerIds: string[],
		legalEntityIds: string[],
		status: string,
		pageSize: number,
		pageNumber: number,
		queryString: string
	): Observable<PayGroupPagination> {
		return this.http.get<PayGroupPagination>(
			`${environment.apiUri}/v1/pay-groups?customerIds=${customerIds}&legalEntityIds=${legalEntityIds}&statuses=${status}&pageSize=${pageSize}&pageNumber=${pageNumber}&sortField=${this.sortField}&sortDir=${this.sortDir}&queryString=${queryString}`
		);
	}

	getPayGroupHistory(payGroupId: string, type: statusType): Observable<PayGroupHistoryReport> {
		let endpointOptions: PaygroupHistoryEndpoint = this.getHistoryConfiguration(type, payGroupId);

		return this.http.get<PayGroupHistoryReport>(endpointOptions.endpoint).pipe(
			catchError((error: any) => {
				this.toast.showError(endpointOptions.errorMessage);
				return of({} as PayGroupHistoryReport);
			})
		);
	}
	getHistoryConfiguration(type: statusType, payGroupId: string): PaygroupHistoryEndpoint {
		switch (type) {
			case statusType.TREASURYGOLIVE:
				return {
					endpoint: `${environment.apiUri}/v1/pay-groups/treasury/${payGroupId}/go-live-history-report`,
					errorMessage: "No treasury go live history was found for this paygroup"
				};
			case statusType.GOLIVE:
				return {
					endpoint: `${environment.apiUri}/v1/pay-groups/${payGroupId}/go-live-history-report`,
					errorMessage: "No go live history was found for this paygroup"
				};
			default:
				return {
					endpoint: `${environment.apiUri}/v1/pay-groups/${payGroupId}/status-history-report`,
					errorMessage: "No status history was found for this paygroup"
				};
		}
	}

	getPaygroupsAsSelectionOptions(legalEntityIds: string[]): Observable<SelectOption[]> {
		let selection: SelectOption[] = [];

		if (legalEntityIds && legalEntityIds.length > 0) {
			return this.getPaygroupWithLegalEntityIds(legalEntityIds).pipe(
				map((payGroups: PayGroupPagination) => {
					payGroups.items.forEach(payGroup => {
						selection.push({
							value: payGroup.id,
							text: `${payGroup.data.name}`,
							placeholder: payGroup.data.name,
							imagePath:
								"assets/svg-country-flags/svg/" +
								payGroup.legalEntity.data.country.toLowerCase() +
								".svg"
						});
					});

					return selection;
				})
			);
		} else {
			return of(selection);
		}
	}

	getPaygroupsByGlobalDasboardFiltersAsSelectOptions(filters: DashboardFilters): Observable<SelectOption[]> {
		let selection: SelectOption[] = [];

		if (filters.legalEntities && filters.legalEntities.length > 0) {
			return this.getPaygroupWithGlobalDashoardFilters(filters).pipe(
				map((payGroups: PayGroupPagination) => {
					payGroups.items.forEach(payGroup => {
						selection.push({
							value: payGroup.id,
							text: `${payGroup.data.name}`,
							placeholder: payGroup.data.name,
							imagePath:
								"assets/svg-country-flags/svg/" +
								payGroup.legalEntity.data.country.toLowerCase() +
								".svg",
							externalId: payGroup.externalId,
							additionalInformation: new Map<string, string>([
								["customerId", payGroup.customer.id],
								["legalEntityId", payGroup.legalEntityId]
							])
						});
					});

					return selection;
				})
			);
		} else {
			return of(selection);
		}
	}

	getPaygroupWithGlobalDashoardFilters(filters: DashboardFilters): Observable<PayGroupPagination> {
		let params = new HttpParams();
		params = params.append("customerIds", filters.customers.join(", "));
		params = params.append("legalEntityIds", filters.legalEntities.join(", "));
		params = params.append("pageSize", -1);

		return this.http.get<PayGroupPagination>(`${environment.apiUri}/v1/pay-groups`, { params });
	}

	getCustomerEntityGroupSelectByPaygroupId(payGroupId: string): Observable<CustomerEntityGroupSelect> {
		return this.getPaygroupWithPaygroupId(payGroupId).pipe(
			take(1),
			map(paygroup => {
				const customerObject: CustomerEntityGroupSelect = {
					customerId: paygroup.customer.id,
					customerName: paygroup.customer.name,
					legalEntityId: paygroup.legalEntityId,
					payGroupId: payGroupId
				};
				return customerObject;
			})
		);
	}

	getPayGroupWithCustomerIdForClient(customerId: string): Observable<PayGroupPagination> {
		return this.http.get<PayGroupPagination>(
			`${environment.apiUri}/v1/pay-groups?customerIds=${customerId}&pageSize=-1&statuses=LIVE`
		);
	}
}
