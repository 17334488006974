<div class="breadcrumb-container" [ngClass]="{ responsive: addResponsiveness }">
	<div class="title-container">
		<span class="header-title" *ngIf="headerTitleWithFlag">
			<span class="customerName">{{ headerTitleWithFlag[0] }}</span> /
			<span class="header-flag"
				><img src="assets/svg-country-flags/svg/{{ headerTitleWithFlag[1].toLowerCase() }}.svg"
			/></span>
			<span class="paygroupName">{{ headerTitleWithFlag[2] }}</span>
		</span>

		<span *ngIf="!headerTitleWithFlag" class="header-title">
			<img
				*ngIf="headerTitle === 'GLOBAL DASHBOARD' && gdHeaderFlag"
				class="header-title-flag"
				src="{{ gdHeaderFlag }}"
			/>{{ headerTitle.toUpperCase() }}
		</span>
	</div>
	<div class="crumbs-container">
		<div
			[ngClass]="{
				'crumbs-sub-container-full': !buttonText.length,
				'crumbs-sub-container-quarterly': buttonText.length
			}"
			id="crumb-container"
			#breadCrumbElementContainer
		>
			<span class="breadcrumb-home">
				<app-icon
					class="breadcrumb-home-icon"
					[name]="'home-2'"
					[size]="'30'"
					[lineWidth]="'2px'"
					[color]="
						this.currentRoute === '/client-landing'
							? 'var(--color-primary-1000) '
							: 'var(--color-role-titl)'
					"
					[viewbox]="'0 0 30 30'"
					(click)="navigateHome()"
				>
				</app-icon>

				<span
					*ngIf="addOverviewTitle && canSeeMap"
					class="router-crumb-text cursor"
					[ngClass]="{
						blueCrumb: this.currentRoute === '/client-landing'
					}"
					(click)="navigateHome()"
					>All Countries</span
				>

				<span
					class="crumb-arrow"
					(click)="setDropDownKey('countryMenu', $event)"
					*ngIf="canSeeMap && !isClientGlobalDashboard && displayGlobalDashboardHome && clientSelectedCountry"
				>
					<app-icon
						class="breadcrumb-arrow-icon"
						[name]="'arrow-right-1'"
						[lineWidth]="'3px'"
						[color]="'var(--color-role-titl)'"
					>
					</app-icon>
				</span>

				<span
					*ngIf="
						addOverviewTitle && canSeeMap && currentRoute !== '/client-landing' && !isClientGlobalDashboard
					"
					class="router-crumb-text country-cursor"
					>{{ clientSelectedCountry }}</span
				>

				<span
					class="crumb-arrow"
					(click)="setDropDownKey('mainMenu', $event)"
					*ngIf="addOverviewTitle && displayGlobalDashboardHome"
				>
					<app-icon
						class="breadcrumb-arrow-icon"
						[name]="'arrow-right-1'"
						[lineWidth]="'3px'"
						[color]="'var(--color-role-titl)'"
					>
					</app-icon>
				</span>

				<span
					*ngIf="
						addOverviewTitle &&
						currentRoute !== '/client-landing' &&
						currentRoute !== '/employee-data/master-data' &&
						currentRoute !== '/employee-data/paygroups'
					"
					class="router-crumb-text"
					[ngClass]="{
						blueCrumb:
							displayGlobalDashboardHome && !isDashboardCalendarView && !isDashboardPayGroupsListView,
						'crumb-text':
							!displayGlobalDashboardHome || isDashboardCalendarView || isDashboardPayGroupsListView
					}"
					(click)="displayGlobalDashboardHome ? navigateToGlobalDashboard() : navigateHome()"
				>
					<ng-container *ngIf="!canSeeMap">{{ breadCrumbText | titlecase }}</ng-container>
					<ng-container
						*ngIf="
							canSeeMap &&
							(isClientGlobalDashboard || (clientSelectedCountry && !isClientGlobalDashboard))
						"
					>
						<span
							[ngClass]="{
								blueCrumb: dashboardCrumbIsBlue && currentRoute !== '/global-dashboard/dashboard',
								'crumb-text': !dashboardCrumbIsBlue
							}"
							>{{ breadCrumbText | titlecase }}</span
						>
					</ng-container>
				</span>
			</span>
			<!-- Routes from routing.ts files data params -->

			<ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
				<span
					class="crumb-arrow"
					(click)="setDropDownKey('mainMenu', $event)"
					*ngIf="last && breadcrumbs[breadcrumbs.length - 1].label !== headerTitle && breadcrumb.label !== ''"
				>
					<app-icon
						*ngIf="breadcrumbs[breadcrumbs.length - 1].label !== headerTitle && last"
						class="breadcrumb-arrow-icon"
						[name]="'arrow-right-1'"
						[lineWidth]="'3px'"
						[color]="'var(--color-role-titl)'"
					>
					</app-icon>
				</span>

				<span
					class="router-crumb-text"
					*ngIf="last && breadcrumbs[breadcrumbs.length - 1].label !== headerTitle && breadcrumb.label !== ''"
					[ngClass]="{
						blueCrumb: last && getCrumbArray().length === 0 || hiddenIDCrumbsRoutes.includes(this.currentRoute) && this.currentRoute !== '/global-dashboard',
						'crumb-text': getCrumbArray().length > 0 && breadcrumbs[breadcrumbs.length - 1].routeTo !== '' ,
					}"
					(click)="
						this.disableHeaderRoute
							? ''
							: navigate(
									breadcrumbs[breadcrumbs.length - 1].routeTo !== ''
										? breadcrumbs[breadcrumbs.length - 1].routeTo!
										: ''
							  )
					"
					#breadCrumbElementHeaderTitle
				>
					{{ crumbsTextIsOverFlowing ? "..." : (breadcrumbs[breadcrumbs.length - 1].label | titlecase) }}
				</span>
			</ng-container>

			<!-- customer, legal and paygroup crumbs + externalCrumbs from @Input -->

			<ng-container *ngFor="let obj of getCrumbArray() as crumbs; let last = last">
				<span
					class="crumb-arrow"
					(click)="setDropDownKey(obj.key, $event, obj)"
					*ngIf="
						(obj.crumb && obj.crumb !== '') ||
						(obj.key &&
							!hiddenIDCrumbsRoutes.includes(this.currentRoute) &&
							this.currentRoute !== '/global-dashboard')
					"
				>
					<app-icon
						class="breadcrumb-arrow-icon"
						[name]="'arrow-right-1'"
						[lineWidth]="'3px'"
						[color]="'var(--color-role-titl)'"
					>
					</app-icon>
				</span>

				<span
					class="crumb-text"
					[ngClass]="{ blueCrumb: last }"
					[matTooltip]="obj.externalId ? obj.externalId : ''"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="obj.externalId ? 'tooltip-option' : 'tool-noDisplay'"
					(click)="crumbClicked(obj, last)"
					*ngIf="
						obj.key &&
						obj.key === 'customerName' &&
						!hiddenIDCrumbsRoutes.includes(this.currentRoute) &&
						this.currentRoute !== '/global-dashboard'
					"
					id="customer-crumb"
					#breadCrumbElementCustomer
				>
					{{
						fullyTruncateCustomerCrumb && obj.key === "customerName"
							? "..."
							: (obj.value || obj.crumb | titlecase)
					}}
				</span>

				<span
					class="crumb-text"
					[ngClass]="{ blueCrumb: last }"
					[matTooltip]="obj.externalId ? obj.externalId : ''"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="obj.externalId ? 'tooltip-option' : 'tool-noDisplay'"
					(click)="crumbClicked(obj, last)"
					*ngIf="
						obj.key &&
						obj.key === 'payGroupId' &&
						!hiddenIDCrumbsRoutes.includes(this.currentRoute) &&
						this.currentRoute !== '/global-dashboard'
					"
					id="paygroup-crumb"
					#breadCrumbElementPaygroup
				>
					<span class="flag" *ngIf="obj.flag || selectedCountry !== ''">
						<img
							src="assets/svg-country-flags/svg/{{
								obj.flag ? obj.flag.toLowerCase() : selectedCountry.toLowerCase()
							}}.svg"
						/>
					</span>

					{{ legalExternalId || "" | titlecase }}

					{{ obj.value || obj.crumb | titlecase }}

					{{ payGroupExternalId || "" | titlecase }}
				</span>

				<span
					class="crumb-text"
					[ngClass]="{ blueCrumb: last }"
					[matTooltip]="obj.externalId ? obj.externalId : ''"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="obj.externalId ? 'tooltip-option' : 'tool-noDisplay'"
					(click)="crumbClicked(obj, last)"
					*ngIf="
						obj.key &&
						obj.key !== 'payGroupId' &&
						obj.key !== 'customerName' &&
						!hiddenIDCrumbsRoutes.includes(this.currentRoute) &&
						this.currentRoute !== '/global-dashboard'
					"
					#breadCrumbElementPaygroup
				>
					<span class="flag">
						<img src="assets/svg-country-flags/svg/{{ selectedCountry.toLowerCase() }}.svg" />
					</span>

					{{ legalExternalId || "" | titlecase }}

					{{ obj.value || obj.crumb | titlecase }}
				</span>

				<span
					class="crumb-text"
					[ngClass]="{
						blueCrumb: last && !lastCrumbClickable,
						blueCrumbClickable: last && lastCrumbClickable
					}"
					[matTooltip]="obj.externalId ? obj.externalId : ''"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="obj.externalId ? 'tooltip-option' : 'tool-noDisplay'"
					(click)="crumbClicked(obj, last)"
					*ngIf="!obj.key"
				>
					<span *ngIf="obj.flag" class="flag">
						<img
							src="assets/svg-country-flags/svg/{{
								obj.flag ? obj.flag.toLowerCase() : selectedCountry.toLowerCase()
							}}.svg"
						/>
					</span>
					{{ obj.value || obj.crumb | titlecase }}
				</span>
			</ng-container>
		</div>
		<!-- action button -->
		<div class="action-button" *ngIf="buttonText.length" (click)="headerButtonAction()">
			<span class="action-button-text body-small-bold">
				<app-icon
					*ngIf="buttonIconObj"
					matPrefix
					class="buttonIcon"
					[size]="buttonIconObj.size"
					[name]="buttonIconObj.name"
					[color]="buttonIconObj.color"
					[lineWidth]="buttonIconObj.lineWidth"
				></app-icon>

				{{ buttonText }}</span
			>
		</div>

		<div
			class="time-and-date"
			*ngIf="
				(addOverviewTitle && this.currentRoute === '/global-dashboard/dashboard') ||
				(addOverviewTitle && this.currentRoute === '/client-landing')
			"
		>
			<span class="body-semibold">Today</span>
			<span class="body-normal">{{ todaysDate | date : "MMMM d, y" | uppercase }}</span>
		</div>
	</div>

	<!-- drop down modal -->
	<app-breadcrumbs-modal
		*ngIf="modalOpen"
		[dropDownData]="dropDownData"
		[externalDropDownData]="getExternalDropDownData()!"
		[modalOpenPosition]="modalOpenPosition"
		[idKey]="dropDownKey"
		(mouseleave)="closeModal()"
		(closeModal)="closeModal()"
		(onDropDownCrumbItemSelected)="dropDownItemSelected()"
		(onExternalDropDownCrumbItemSelected)="externalDropDownItemSelected($event)"
		[clientCountryList]="clientCountryList"
		[clientCustomerId]="clientCustomerId"
		(clientSelectedCountry)="modalClientSelectedCountry($event)"
		[zIndex]="zIndex"
	/>
</div>
