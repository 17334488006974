<div class="filter-container" *ngIf="filters">
	<div class="filter-buttons-container">
		<span class="text">Filters</span>
		<div class="filter-buttons">
			<button
				class="filter"
				[ngClass]="{ selected: showFilters }"
				(click)="showFilter(showFilters)"
				id="show-filter-btn"
				data-test="show-filter-btn"
			>
				<app-icon
					class="filter-icon settings"
					[name]="'setting-4'"
					[size]="'24'"
					[color]="'var(--color-filter-button)'"
					[lineWidth]="'2'"
				></app-icon>
				<app-icon
					[ngClass]="{ 'filter-up-arrow': showFilters }"
					class="filter-icon filter-arrow"
					[name]="'arrow-down-3'"
					[size]="'24'"
					[color]="'var(--color-filter-button)'"
					[lineWidth]="'2'"
				></app-icon>
			</button>
			<button
				class="clear-filters"
				[ngClass]="{ disabled: disableClearFiltersButton }"
				[disabled]="disableClearFiltersButton"
				(click)="clearFilters()"
				id="clear-filter-btn"
				data-test="show-filter-btn"
			>
				<app-icon
					[name]="'broom'"
					[size]="'24'"
					[color]="'var(--color-filter-button)'"
					[lineWidth]="'2'"
				></app-icon>
			</button>
		</div>
	</div>
	<div class="filters" [formGroup]="filters" [ngClass]="{ open: showFilters }">
		<div class="first filter-layer o-grid-3">
			<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['CLIENT']">
				<input-wrapper class="field customers">
					<wpay-multi-select-auto-complete
						formControlName="customers"
						id="customers"
						data-test="customers"
						label="Select a customer"
						[dropDownType]="'customers'"
						[showLoadingOption]="!customerSelectOptions || customerSelectOptions.length === 0"
						[options]="customerSelectOptions"
						(selectionCleared)="clearFormProperties(dashboardMultiSelectFilters.CUSTOMERS)"
						[defaultValue]="
							filters.get(dashboardMultiSelectFilters.CUSTOMERS)
								? filters.get(dashboardMultiSelectFilters.CUSTOMERS)?.value
								: []
						"
						[emitClearInSeperateFunction]="true"
						(multipleSelectOptionListClosed)="emitNewData()"
					>
					</wpay-multi-select-auto-complete>
				</input-wrapper>
			</ng-template>

			<ng-template [appUserRolePermissions] [action]="'show'" [roles]="['CLIENT']">
				<input-wrapper class="field customers">
					<wpay-multi-select-auto-complete
						formControlName="customers"
						id="customers"
						data-test="customers"
						label="Select a customer"
						[dropDownType]="'customers'"
						[options]="customerSelectOptions"
						(selectionCleared)="clearFormProperties(dashboardMultiSelectFilters.CUSTOMERS)"
						[defaultValue]="
							filters.get(dashboardMultiSelectFilters.CUSTOMERS)
								? filters.get(dashboardMultiSelectFilters.CUSTOMERS)?.value
								: []
						"
						[emitClearInSeperateFunction]="true"
						[disableDropDown]="true"
						(multipleSelectOptionListClosed)="emitNewData()"
					>
					</wpay-multi-select-auto-complete>
				</input-wrapper>
			</ng-template>

			<input-wrapper
				class="field legal-entities"
				*ngIf="!canSeeMap || (canSeeMap && isEnableClientFilter); else disabledLegal"
			>
				<wpay-multi-select-auto-complete
					formControlName="legalEntities"
					id="legal-entities"
					data-test="legal-entities"
					label="Select a legal entity"
					[dropDownType]="'legal entities'"
					[options]="legalEntitiesSelectOptions"
					[disableDropDown]="!legalEntitiesSelectOptions || legalEntitiesSelectOptions.length === 0"
					[emitClearInSeperateFunction]="true"
					(selectionCleared)="clearFormProperties(dashboardMultiSelectFilters.LEGALENTITIES)"
					[defaultValue]="
						filters.get(dashboardMultiSelectFilters.LEGALENTITIES)
							? filters.get(dashboardMultiSelectFilters.LEGALENTITIES)?.value
							: []
					"
					(multipleSelectOptionListClosed)="emitNewData()"
				>
				</wpay-multi-select-auto-complete>
			</input-wrapper>

			<ng-template #disabledLegal>
				<input-wrapper class="field legal-entities">
					<wpay-multi-select-auto-complete
						formControlName="legalEntities"
						id="legal-entities"
						data-test="legal-entities"
						label="Select a legal entity"
						[dropDownType]="'legal entities'"
						[options]="legalEntitiesSelectOptions"
						(selectionCleared)="clearFormProperties(dashboardMultiSelectFilters.LEGALENTITIES)"
						[defaultValue]="
							filters.get(dashboardMultiSelectFilters.LEGALENTITIES)
								? filters.get(dashboardMultiSelectFilters.LEGALENTITIES)?.value
								: []
						"
						[emitClearInSeperateFunction]="true"
						[disableDropDown]="true"
						(multipleSelectOptionListClosed)="emitNewData()"
					>
					</wpay-multi-select-auto-complete>
				</input-wrapper>
			</ng-template>

			<input-wrapper class="field paygroups">
				<wpay-multi-select-auto-complete
					formControlName="paygroups"
					id="paygroups"
					data-test="paygroups"
					label="Select a paygroup"
					[dropDownType]="'paygroups'"
					[options]="payGroupSelectOptions"
					[disableDropDown]="!payGroupSelectOptions || payGroupSelectOptions.length === 0"
					[emitClearInSeperateFunction]="true"
					(selectionCleared)="clearFormProperties(dashboardMultiSelectFilters.PAYGROUPS)"
					[defaultValue]="
						filters.get(dashboardMultiSelectFilters.PAYGROUPS)
							? filters.get(dashboardMultiSelectFilters.PAYGROUPS)?.value
							: null
					"
					(multipleSelectOptionListClosed)="emitNewData()"
				>
				</wpay-multi-select-auto-complete>
			</input-wrapper>
		</div>

		<div class="second filter-layer o-grid-3">
			<input-wrapper class="field groups">
				<wpay-multi-select-auto-complete
					formControlName="groups"
					id="groups"
					data-test="groups"
					label="Select group(s)"
					[dropDownType]="'groups'"
					[options]="groupSelectOptions"
					[disableDropDown]="
						!groupSelectOptions || groupSelectOptions.length === 0 || !availableFilters.includes('second')
					"
					[emitClearInSeperateFunction]="true"
					(selectionCleared)="clearFormProperties(dashboardMultiSelectFilters.GROUPS)"
					[defaultValue]="
						filters.get(dashboardMultiSelectFilters.GROUPS)
							? filters.get(dashboardMultiSelectFilters.GROUPS)?.value
							: null
					"
					(multipleSelectOptionListClosed)="emitNewData()"
				>
				</wpay-multi-select-auto-complete>
			</input-wrapper>

			<input-wrapper class="field deadline">
				<mat-form-field
					appearance="outline"
					[ngClass]="{ 'mat-form-field-disabled': !availableFilters.includes('second') }"
					class="deadlineField"
					id="deadlineFieldRange"
					data-test="deadlineFieldRange"
					(click)="availableFilters.includes('second') ? picker.open() : null"
				>
					<app-icon
						class="mat-calendar-icon"
						matPrefix
						[color]="'var(--color-role-titl)'"
						[name]="'calendar_month'"
					></app-icon>
					<mat-date-range-input [rangePicker]="picker" class="range-picker">
						<input
							matStartDate
							matInput
							formControlName="milestoneStartDate"
							id="start-date-input"
							data-test="start-date-input"
							(dateChange)="checkIfOnlyOneDateSelected('start', $event)"
						/>
						<input
							matEndDate
							matInput
							formControlName="deadline"
							id="end-date-input"
							data-test="end-date-input"
							(dateChange)="checkIfOnlyOneDateSelected('end', $event)"
						/>
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker">
						<app-icon
							id="date-picker-arrow-btn"
							data-test="date-picker-arrow-btn"
							class="mat-toggle-icon"
							matDatepickerToggleIcon
							[name]="'arrow-down'"
							[size]="'24'"
							[color]="'var(--color-filter-button)'"
							[lineWidth]="'2'"
						></app-icon>
					</mat-datepicker-toggle>
					<mat-date-range-picker #picker>
						<mat-date-range-picker-actions>
							<button mat-raised-button matDateRangePickerApply id="date-range-done-btn">DONE</button>
						</mat-date-range-picker-actions>
					</mat-date-range-picker>
				</mat-form-field>
			</input-wrapper>
			<input-wrapper class="field statuses">
				<wpay-multi-select-auto-complete
					formControlName="statuses"
					id="statuses"
					data-test="statuses"
					label="Select a status"
					[dropDownType]="'statuses'"
					[options]="statusesSelectOptions"
					[disableDropDown]="
						!statusesSelectOptions ||
						statusesSelectOptions.length === 0 ||
						!availableFilters.includes('third')
					"
					[emitClearInSeperateFunction]="true"
					(selectionCleared)="clearFormProperties(dashboardMultiSelectFilters.STATUSES)"
					[defaultValue]="
						filters.get(dashboardMultiSelectFilters.STATUSES)
							? filters.get(dashboardMultiSelectFilters.STATUSES)?.value
							: null
					"
					(multipleSelectOptionListClosed)="emitNewData()"
				>
				</wpay-multi-select-auto-complete>
			</input-wrapper>
		</div>
		<div class="third filter-layer">
			<input-wrapper [formGroup]="milestoneSelection" class="checkbox-group milestones">
				<span class="title body-bold">Milestone:</span>
				<mat-checkbox id="dataInput" data-test="dataInput" class="check-box" formControlName="paymentOrders"
					><span class="body-normal item orders">Orders</span></mat-checkbox
				>
				<mat-checkbox id="fundingDate" data-test="fundingDate" class="check-box" formControlName="funding"
					><span class="body-normal item">Funding</span></mat-checkbox
				>
				<mat-checkbox id="bookingDate" data-test="bookingDate" class="check-box" formControlName="payments"
					><span class="body-normal item">Payments</span></mat-checkbox
				>
			</input-wrapper>
		</div>
	</div>
</div>
