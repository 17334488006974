<modal-wrapper
	title="Add new beneficiary"
	(onTitleButtonAction)="backFromModal()"
	[modalStyle]="'dark'"
	[noBreadCrumbs]="true"
	[exitIconBool]="true"
	[subHeaderColour]="'transparent'"
	[modalFullHeight]="true"
	class="add-ben-modal-wrapper"
>
	<div id="addBeneficiaryContainer" class="flex flex-column flex-gap-grid" style="--gap-grid: 15px">
		<div
			id="genericFieldsContainer"
			class="generic-fields-container flex flex-column"
			*ngIf="selectedCountryName"
			[formGroup]="newBeneficiaryForm"
		>
			<span id="genericFieldsTitleText" class="container-header-text"> Generic fields </span>
			<div class="generic-inputs-row flex flex-row flex-gap" style="--gap: 60px">
				<input-wrapper [label]="'ACCOUNT NAME *'">
					<input
						id="accountNameInput"
						type="text"
						placeholder="Enter account client"
						formControlName="account_name"
						[disabled]="!canEdit"
					/>
				</input-wrapper>

				<input-wrapper class="select" label="SELECT A COUNTRY *">
					<input-autocomplete
						id="countryInout"
						formControlName="country"
						[options]="countries$ | async"
						[label]="'Select country'"
						[disabled]="true"
					>
					</input-autocomplete>
				</input-wrapper>

				<input-wrapper class="select" label="BENEFICIARY CURRENCY *">
					<input-autocomplete
						id="currencyInput"
						formControlName="currency"
						[options]="currencies$ | async"
						[label]="'Select currency'"
						[disabled]="!canEdit"
					>
					</input-autocomplete>
				</input-wrapper>
			</div>
		</div>

		<div id="bankAccountFieldsContainer" class="bank-account-fields-container flex flex-column">
			<bank-account-fields
				id="bankAccountFields"
				class="account-fields"
				[country]="selectedCountryName"
				[values]="bankAccountValues"
				[bankName]="bankName"
				[editing]="false"
				[canEdit]="canEdit"
				[fromTPPService]="true"
				*ngIf="selectedCountryName"
			></bank-account-fields>
		</div>

		<div id="saveButtonContainerRow" class="generic-inputs-row-save flex flex-row flex-align-center-end">
			<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
				<button
					id="saveBtn"
					[disabled]="!formIsValid || !formIsComplete || formIsPristine"
					mat-button
					[ngClass]="
						!formIsValid || !formIsComplete || formIsPristine ? 'save-button disabled' : 'save-button'
					"
					(click)="saveBeneficiary()"
					class="flex-px"
					style="--basis: 300px; --max-px-width: 300px; --min-px-width: 300px"
					[hidden]="selectedCountryName && bankAccountFields && newBeneficiaryForm"
				>
					<span id="saveButtonText" class="save-text">{{ btText }}</span>
				</button>
			</ng-template>
		</div>
	</div>
</modal-wrapper>
