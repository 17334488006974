import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { EmployeeService } from "@shared/services/employee/employee.service";
import { Subject, Subscription } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";

import { breadcrumbDataObject } from "@shared/components/modal-wrapper/_models/breadcrumb.interface";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { DropDownDataExpanded, ExternalCrumb } from "src/app/shared/models/breadcrumbs.interface";
import { UpdateProvider } from "src/app/store/actions/providerSelect.action";
import { Employee } from "../../../../shared/models/employee.interface";
import { PayGroup } from "../../../../shared/models/pay-groups";
import { getCustomerEntityGroupState, getGlobalDashboardFilterState, getProviderState } from "../../../../store";
import { AppState } from "../../../../store/models/state.model";
import { ApiService } from "../../../pay-groups/services/api.service";
import { PayElectiveCommonService } from "../../services/pay-elective-common.service";
import { PayElectiveBankAccountsService } from "@modules/employee-data/services/pay-elective-bank-accounts.service";

@Component({
	selector: "app-pay-elective",
	templateUrl: "./pay-elective.component.html",
	styleUrls: ["./pay-elective.component.scss"]
})
export class PayElectiveComponent implements OnInit, OnDestroy {
	selectedCustomerId: string = "";
	selectedLegalEntityId: string = "";
	selectedPayGroupId: string = "";
	routerStatePayCycleId!: string;
	destroy$: Subject<void> = new Subject();
	selectedEmployee!: Employee;
	selectedProvider!: string;
	employeeName: string = "";
	payGroupName: string = "";
	createdAt: string | null = "";
	breadcrumbArray: breadcrumbDataObject[] = [];
	subscription: Subscription = new Subscription();
	payGroup!: PayGroup;
	customerName: string = "";
	country: string = "";
	tabSelect: number = 0;
	createdFirstTime: boolean = false;
	payCycleRunName!: string;
	headerTitle = "EMPLOYEE DATA";
	externalCrumbs: ExternalCrumb[] = [];
	employeesDropDownData!: DropDownDataExpanded[];
	isFromGlobalDashboard = false;
	headerCrumbs!: string[];

	constructor(
		private router: Router,
		private payGroupApiService: ApiService,
		private store: Store<AppState>,
		private payElectiveCommonService: PayElectiveCommonService,
		private readonly _location: Location,
		private serviceProviderService: ServiceProviderService,
		private employeeService: EmployeeService,
		private location: Location,
		private payElectiveBankAccountsService: PayElectiveBankAccountsService
	) {}

	ngOnInit(): void {
		this.setupComponentGivenStoreValues();
	}

	initSubscriptions(): void {
		this.subscription = this.payElectiveCommonService.employeeStored$
			.pipe(takeUntil(this.destroy$))
			.subscribe(employee => {
				this.selectedEmployee = employee;
				if (this.createdFirstTime) {
					this.tabSelect = 1;
				}
			});
	}

	next() {
		this.createdFirstTime = true;
	}

	getFirstProvider(): void {
		this.serviceProviderService
			.getProviderNamesByEntityId(this.selectedLegalEntityId)
			.pipe(
				takeUntil(this.destroy$),
				map(_providers => {
					if (_providers.length > 0) {
						this.store.dispatch(
							new UpdateProvider({
								provider: _providers[0].value
							})
						);
					}
				})
			)
			.subscribe();
	}

	alterBread(fullname: string) {
		this.employeeName = fullname;
		if (this.payCycleRunName) {
			this.externalCrumbs = [
				{ crumb: this.payCycleRunName, url: "1" },
				{ crumb: this.employeeName, dropDownData: this.employeesDropDownData }
			];
		} else {
			this.externalCrumbs = [{ crumb: this.employeeName, dropDownData: this.employeesDropDownData }];
		}
	}

	goBack() {
		this._location.back();
	}

	getPayGroup(payGroupId: string) {
		this.payGroupApiService
			.getPayGroup(payGroupId)
			.pipe(take(1))
			.subscribe(payGroup => {
				this.headerCrumbs = [payGroup.customer.name, payGroup.legalEntity.data.country, payGroup.data.name];
				this.payGroup = payGroup;
			});
	}

	goHome(): void {
		this.router.navigate(["/employee-data/pay-electives"]);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	routeBackToEmployeeList(routeBack: boolean): void {}

	private setupComponentGivenStoreValues() {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			if (state && state.customerId && state.legalEntityId && state.payGroupId) {
				this.selectedCustomerId = state.customerId;
				this.selectedLegalEntityId = state.legalEntityId;
				this.selectedPayGroupId = state.payGroupId;
				this.initializeComponentGivenHasPayGroupId();
				this.getPayGroup(state.payGroupId);
			} else if (state && !state.payGroupId && !this.router.navigated) {
				this.router.navigate(["/employee-data/master-data"]);
			}
		});

		this.store.pipe(takeUntil(this.destroy$), select(getProviderState)).subscribe(state => {
			if (state && state.provider && state.provider !== "all" && state.provider !== "") {
				this.selectedProvider = state.provider;
			} else {
				this.getFirstProvider();
			}
		});
	}

	private initializeComponentGivenHasPayGroupId() {
		if (history.state) {
			if (history.state.payCycleId) {
				this.routerStatePayCycleId = history.state.payCycleId;
			} else {
				this.routerStatePayCycleId = "";
			}

			if (history.state.employeesDropDownData) {
				this.employeesDropDownData = history.state.employeesDropDownData;
			}

			if (history.state.employee) {
				this.selectedEmployee = history.state.employee;
				this.employeeName = this.selectedEmployee.data.firstName + " " + this.selectedEmployee.data.lastName;
			} else {
				this.employeeName = "Add New Employee";
			}

			if (history.state.payCycleRunName) {
				this.payCycleRunName = history.state.payCycleRunName;
				this.tabSelect = 2;
				//Comes from Net payment screen
				if (this.router.url === "/global-dashboard/employee") {
					this.isFromGlobalDashboard = true;
					this.getSelectedKPI(history.state.payCycleRunName);
				} else {
					this.headerTitle = "DASHBOARD";
					this.externalCrumbs = [
						{ crumb: this.payCycleRunName, url: "1" },
						{
							crumb: this.employeeName,
							dropDownData: this.employeesDropDownData
						}
					];
				}
			} else {
				if (this.router.url === "/global-dashboard/employee") {
					this.isFromGlobalDashboard = true;
					this.getSelectedKPI();
				} else {
					this.externalCrumbs = [
						{
							crumb: this.employeeName,
							dropDownData: this.employeesDropDownData
						}
					];
				}
			}
		} else {
			this.routerStatePayCycleId = "";
			this.externalCrumbs = [{ crumb: this.employeeName, dropDownData: this.employeesDropDownData }];
		}

		this.initSubscriptions();
		this.getPayGroup(this.selectedPayGroupId);
	}

	getSelectedKPI(payCycleRunName?: string): void {
		this.store.pipe(takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe(state => {
			if (state?.globalDashboard?.kpiSelected !== undefined) {
				if (payCycleRunName) {
					this.externalCrumbs = [
						{ crumb: state.globalDashboard.kpiSelected, url: "/global-dashboard" },
						{ crumb: payCycleRunName, url: "1" },
						{
							crumb: this.employeeName,
							dropDownData: this.employeesDropDownData
						}
					];
				} else {
					this.externalCrumbs = [
						{ crumb: state.globalDashboard.kpiSelected, url: "/global-dashboard" },
						{ crumb: "Employee Data", url: "/global-dashboard/master-data" },
						{
							crumb: this.employeeName,
							dropDownData: this.employeesDropDownData
						}
					];
				}
			} else {
				this.router.navigate(["/global-dashboard"]);
			}
		});
	}

	regress() {
		this.location.back();
	}

	reselectEmployee($event: string) {
		this.employeeService
			.getEmployeesById($event)
			.pipe(take(1))
			.subscribe({
				next: res => {
					this.selectedEmployee = res;
					this.employeeName = `${res.data.firstName} ${res.data.lastName}`;
					this.isFromGlobalDashboard
						? (this.externalCrumbs[2].crumb = this.employeeName)
						: (this.externalCrumbs[0].crumb = this.employeeName);

					this.payElectiveBankAccountsService.close.emit();
				}
			});
	}
}
