import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, take, takeUntil } from "rxjs/operators";

import { InputAutocompleteComponent } from "@shared/components/input-autocomplete/input-autocomplete.component";
import { ApiService } from "@modules/pay-groups/services/api.service";
import { TppServiceDefinitionService } from "@modules/service-definition/_services/tpp-service-definition/tpp-service-definition.service";
import { TppServiceManagementService } from "@modules/tpp-catalogue/_services/management/tpp-service-management.service";
import { SelectOptionsFromTppaccountPipe } from "@modules/tpp-catalogue/pipes/select-options-from-tppaccount.pipe";
import { TppAccountsService } from "@shared/services/tpp-accounts/tpp-accounts.service";
import { TppServicesService } from "@shared/services/tpp-services/tpp-services.service";
import { TPP_GROUPS } from "src/app/shared/constants/tpp-groups";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { TppAccount } from "src/app/shared/models/tpp-account.interface";
import {
	ISubServicefigFormGroup,
	SubService,
	SubServiceStoreDataForm,
	TPPFrequency,
	TPPGroupDTO,
	TPPPaymentOriginator,
	TPPReferenceType,
	TPPServiceDefinitionDTO,
	TppServiceBeneficiaryDTO
} from "src/app/shared/models/tpp-service.interface";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/store/models/state.model";
import { getUserDataState } from "@store/index";

@Component({
	selector: "app-tpp-sub-service-item",
	templateUrl: "./tpp-sub-service-item.component.html",
	styleUrls: ["./tpp-sub-service-item.component.scss"]
})
export class TppSubServiceItemComponent implements OnInit, OnDestroy {
	@Input() panelOpenState: boolean = false;
	@Input() panelDisabled: boolean = false;
	@Input() countryCode: string = "";
	@Input() subService!: SubService;

	//AddTPPGroup capabilities
	@Input() forAdhocService: boolean = false;
	@Input() selectedPayGroupId: string = "";
	@Input() index: number = 0;

	@ViewChild("dropDownButton") dropDownButton!: ElementRef;

	@Input() set _index(i: number) {
		this.index = i;
	}

	_isEditService = false;

	@Input()
	set isEditService(value: boolean) {
		this._isEditService = value;
		this.intializeComponent();
	}

	get isEditService(): boolean {
		return this._isEditService;
	}

	tppGroupOptions: SelectOption[] = TPP_GROUPS;
	selectedTPPGroupOptions: SelectOption[] = [];
	subServiceSelected: boolean = false;
	tppOptionsMenuList = false;

	containerClass: string = "";
	textClass: string = "";
	displayItems: boolean = false;

	paygroupFrequency: string = "";

	managedInEnabled: boolean = true;

	@Output() sendSubServiceData = new EventEmitter<SubService>();

	@Output() sendSubServiceGroupData = new EventEmitter<TPPGroupDTO>();

	colorEye: string = "#8992A3"; //#231F20  #F3F3F3
	colorTrash: string = "#8992A3"; // "#ED4C5C" "#8992A3"
	tppAccounts!: TppAccount[];
	beneficiarySelectOptions!: SelectOption[];
	form!: FormGroup;
	groupForm!: FormGroup;
	newSubService: boolean = true;
	subServiceTitle: string = "Sub Service Name";
	tppFrequencyOptions: SelectOption[] = TPPFrequency;
	tppReferenceType: SelectOption[] = TPPReferenceType;
	tppPaymentOriginator: SelectOption[] = TPPPaymentOriginator;
	tppServiceId: string = "";
	btEyeDisabled: boolean = true;

	beneficiaryName: string = "";

	managedInSelection!: SelectOption;

	managedInSelectOptions: SelectOption[] = [];

	showAddBeneficiaryForm: boolean = false;
	showEditBeneficiaryForm: boolean = false;

	private destroy$: Subject<void> = new Subject<void>();

	beneficiaryLabel = "Beneficiary";
	subIDLabel = "Sub Service ID";

	selectedManagedIn: string = "";
	canEditService = false;
	canDeleteSubService = false;
	canEditBeneficiary = false;
	canAddBeneficiary = false;
	isViewOnly = false;

	@ViewChild("beneficairyAccount") autocomplete!: InputAutocompleteComponent;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private tppAccountService: TppAccountsService,
		private tppServicesService: TppServicesService,
		private tppServiceManagementService: TppServiceManagementService,
		private tppServiceDefinitionService: TppServiceDefinitionService,
		private apiService: ApiService,
		private permissions: PermissionsService,
		private store: Store<AppState>
	) {}

	ngOnInit(): void {
		this.getPermissions();
	}
	getPermissions() {
		if (this.forAdhocService) {
			this.permissions
				.canEditAdhocService()
				.pipe(take(1))
				.subscribe(res => {
					this.canEditService = res;
					this.intializeComponent();
				});
			this.permissions
				.canAddBeneficiaryDef()
				.pipe(take(1))
				.subscribe(res => {
					this.canAddBeneficiary = res;
				});

			this.permissions
				.canEditBeneficiaryDef()
				.pipe(take(1))
				.subscribe(res => {
					this.canEditBeneficiary = res;
				});

			this.permissions
				.canDeleteSubServiceDef()
				.pipe(take(1))
				.subscribe(res => {
					this.canDeleteSubService = res;
				});
		} else {
			this.permissions
				.canEditCatService()
				.pipe(take(1))
				.subscribe(res => {
					this.canEditService = res;
					this.intializeComponent();
				});
			this.permissions
				.canAddBeneficiaryCat()
				.pipe(take(1))
				.subscribe(res => {
					this.canAddBeneficiary = res;
				});

			this.permissions
				.canEditBeneficiaryCat()
				.pipe(take(1))
				.subscribe(res => {
					this.canEditBeneficiary = res;
				});

			this.permissions
				.canDeleteSubServiceCat()
				.pipe(take(1))
				.subscribe(res => {
					this.canDeleteSubService = res;
				});
		}

		this.store.pipe(select(getUserDataState)).subscribe({
			next: res => {
				this.isViewOnly = res.roles?.includes("TS_VO")!;
			}
		});
	}

	intializeComponent() {
		//Checks if there is info on service - if main service is created -> should be for you to edit this form
		if (this.tppServiceManagementService.tppServiceData && this.tppServiceManagementService.tppServiceData.id) {
			this.tppServiceId = this.tppServiceManagementService.tppServiceData.id;
		}

		this.tppServiceManagementService
			.getIsNewTppService()
			.pipe(takeUntil(this.destroy$))
			.subscribe((isNew: boolean) => {
				//Checks if it is a new service or old one
				if (!isNew) {
					if (!this.subService) this.panelOpenState = true;

					this.tppServiceId = this.tppServiceManagementService.tppServiceData.id; //Gets ID
				}

				if (this.tppServiceManagementService.tppServiceData?.id && this.form) {
					this.form.enable(); //If there is a main service id - then enable input for subservice
				}
			});

		if (this.subService && this.forAdhocService) {
			this.resetGroupVeriables();
		}

		this.tppServiceManagementService
			.getIsNewSubService()
			.pipe(takeUntil(this.destroy$))
			.subscribe((isNewSubService: boolean) => {
				if (this.subService) {
					this.panelOpenState = false;
				} else {
					this.panelOpenState = true;
					//this.enableTPPSelection = true; //After subservice has been saved - enable group option
				}
			});

		this.tppServicesService.sendSubServiceDataFromServices.pipe(takeUntil(this.destroy$)).subscribe(subservice => {
			if (subservice && this.subService) {
				if (subservice.id === this.subService.id) {
					this.sendSubServiceData.emit(subservice);
					this.subService = subservice; //get latest version of subservice
				}
			} else if (subservice) {
				this.sendSubServiceData.emit(subservice);
				this.subService = subservice; //get latest version of subservice
			}
		});

		//close ben
		this.tppServiceManagementService
			.getBeneficiaryAccountState()
			.subscribe((enableBeneficiaryAccountForm: boolean) => {
				if (!enableBeneficiaryAccountForm) {
					this.showAddBeneficiaryForm = false;
					this.showEditBeneficiaryForm = false;
				}
			});

		//Paygroup info
		if (this.selectedPayGroupId) {
			this.apiService
				.getPayGroup(this.selectedPayGroupId)
				.pipe(take(1))
				.subscribe(paygroup => {
					if (paygroup.data && paygroup.data.frequency) {
						this.paygroupFrequency = paygroup.data.frequency;
					}
				});
		}

		this.initForm(); //Step 1 - Sets up form and "beneficiary listener"
		this.formValuesChanges();
		this.getTPPAccounts(); //Step 2
	}
	setNewBeneficiaryInformation(tppAccount: TppServiceBeneficiaryDTO) {
		if (tppAccount) {
			this.form.get("beneficiaryName")?.setValue(tppAccount.name);

			const pageNumber = 0;
			const pageSize = -1;

			this.tppAccountService
				.searchTPPAccountsByTppServiceId(this.tppServiceId, pageNumber, pageSize)
				.subscribe((response: TppAccount[]) => {
					if (response.length) {
						this.tppAccounts = response;
						this.beneficiarySelectOptions = new SelectOptionsFromTppaccountPipe().transform(response);
					}
				});
		}
	}

	initForm(): void {
		if (this.forAdhocService) {
			this.form = this.formBuilder.group({
				name: ["", Validators.required],
				beneficiaryName: ["", Validators.required],
				externalId: ["", Validators.required],
				paymentOriginator: ["", Validators.required],
				referenceType: ["", Validators.required],
				frequency: ["", Validators.required],
				group: ["", Validators.required],
				managed_in: ["", Validators.required]
			}) as ISubServicefigFormGroup;

			if (this._isEditService && !this.canEditService) {
				this.form.disable();
			}

			this.beneficiaryLabel = "Beneficiary *";
			this.subIDLabel = "Sub Service ID *";

			//get group data

			this.tppServiceDefinitionService
				.getServiceDefinitionTemp(this.selectedPayGroupId)
				.pipe(take(1))
				.subscribe(result => {
					if (result.status === 200) {
						let serviceDef: TPPServiceDefinitionDTO = result.body;

						serviceDef.services.forEach((service, index) => {
							if (this.subService && service.subServiceId === this.subService.id) {
								if (service.managedIn && service.frequency) {
									this.form.get("frequency")?.patchValue(service.frequency, { emitEvent: false });

									this.setFrequencyAndManagedInSelection(service.frequency, false, service.managedIn);
								}

								if (service.group) {
									this.form.get("group")?.setValue(service.group, { emitEvent: false });
									let item: SelectOption;

									this.tppGroupOptions.filter(group => {
										if (group.value === service.group) {
											item = group;
										}
									});

									this.addSelection(item!, false);
								}
							}
						});
					}
				});
		} else {
			this.form = this.formBuilder.group({
				name: ["", Validators.required],
				externalId: [""],
				beneficiaryName: [""],
				paymentOriginator: ["", Validators.required],
				referenceType: ["", Validators.required],
				frequency: ["", Validators.required]
			}) as ISubServicefigFormGroup;
		}

		if (this.panelDisabled || (this._isEditService && !this.canEditService)) {
			this.form.disable(); //Main service has no info
		}
	}

	checkForFieldErrors(): void {
		if (!this.form.get("name")?.value || this.form.get("name")?.value === "") {
			this.form.get("name")!.setErrors({ empty: "empty" });
		}

		if (!this.form.get("paymentOriginator")?.value || this.form.get("paymentOriginator")?.value === "") {
			this.form.get("paymentOriginator")!.setErrors({ empty: "empty" });
		}

		if (!this.form.get("referenceType")?.value || this.form.get("referenceType")?.value === "") {
			this.form.get("referenceType")!.setErrors({ empty: "empty" });
		}

		if (!this.form.get("frequency")?.value || this.form.get("frequency")?.value === "") {
			this.form.get("frequency")!.setErrors({ empty: "empty" });
		}

		if (this.forAdhocService) {
			if (!this.form.get("externalId")?.value || this.form.get("externalId")?.value === "") {
				this.form.get("externalId")!.setErrors({ empty: "empty" });
			}

			if (!this.form.get("group")?.value || this.form.get("group")?.value === "") {
				this.form.get("group")?.setErrors({ empty: "empty" });
			}

			if (!this.form.get("beneficiaryName")?.value || this.form.get("beneficiaryName")?.value === "") {
				this.form.get("beneficiaryName")!.setErrors({ empty: "empty" });
			}

			if (
				!this.form.get("managed_in")?.value ||
				this.form.get("managed_in")?.value === "" ||
				!this.managedInSelection ||
				this.managedInSelection === ({} as SelectOption)
			) {
				this.form.get("managed_in")?.setErrors({ empty: "empty" });
			}
		}
	}

	setFrequencyAndManagedInSelection(value: string, emit: boolean, managedIn?: string) {
		if (value === "MAIN_RUN" || value === "Main run") {
			this.form.get("managed_in")?.patchValue("Every Main run", { emitEvent: false });
			this.managedInSelection = { text: "Every Main run", value: "MAIN_RUN" };
			this.selectedManagedIn = this.managedInSelection.text;
			this.managedInEnabled = false;
		} else if (value === "PER_RUN" || value === "Per run") {
			this.form.get("managed_in")?.patchValue("Every run", { emitEvent: false });
			this.managedInSelection = { text: "Every run", value: "EVERY_RUN" };
			this.selectedManagedIn = this.managedInSelection.text;
			this.managedInEnabled = false;
		} else {
			if (managedIn) {
				this.setSelectOptions(value, false, managedIn);
			} else {
				this.setSelectOptions(value, true);
			}

			this.managedInEnabled = true;
		}
	}

	formValuesChanges() {
		this.form
			.get("frequency")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				if (this.forAdhocService && value && value !== "") {
					this.setFrequencyAndManagedInSelection(value, true);
				}
			});

		this.form
			.get("managed_in")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((value: SelectOption | string) => {
				let managedInSelection: SelectOption = this.managedInSelectOptions.find(
					selection => selection.value === value
				)!;

				if (managedInSelection) {
					this.selectedManagedIn = managedInSelection.text!;
					this.managedInSelection = managedInSelection;
				}
			});

		this.form.valueChanges
			.pipe(takeUntil(this.destroy$), debounceTime(1000), distinctUntilChanged())
			.subscribe(values => {
				//if you change one input and the rest is empty - border red

				this.checkForFieldErrors();

				if (this.form.valid) {
					this.beneficiaryName = values.beneficiaryName === "" ? null : values.beneficiaryName;
					values.externalId = values.externalId === "" ? null : values.externalId;

					let beneficiaryId: string | null = null;

					if (this.beneficiaryName) {
						const benId = this.tppAccounts.filter(
							(item: TppAccount) => item.name === values.beneficiaryName //Get id of selected ben
						)[0].id;

						beneficiaryId = benId ? benId : null;
					}

					if (!this.isEditService) {
						this.tppServicesService.configureService(
							values,
							this.form,
							this.subService,
							this.forAdhocService,
							this.selectedPayGroupId,
							this.managedInSelection,
							this.tppServiceId,
							beneficiaryId
						);
					} else {
						this.tppServicesService.stageConfigureService(
							values,
							this.form,
							this.subService,
							this.forAdhocService,
							this.selectedPayGroupId,
							this.managedInSelection,
							this.tppServiceId,
							beneficiaryId
						);
					}
				}
			});

		this.form.get("name")?.valueChanges.subscribe(data => {
			this.subServiceTitle = data;
		});

		this.checkForFieldErrors();

		this.loadSubServiceFormStoredData();
	}

	setSelectOptions(frequency: string, emit: boolean, managedIn?: string) {
		this.tppServiceDefinitionService
			.getFrequencyManagedIn(this.selectedPayGroupId, frequency)
			.pipe(take(1))
			.subscribe(result => {
				if (result.length > 0) {
					this.managedInSelectOptions = result;
					if (managedIn && managedIn.length) {
						this.managedInSelectOptions = result;
						this.managedInSelection = this.managedInSelectOptions.find(
							selection => selection.value === managedIn
						)!;
						this.selectedManagedIn = this.managedInSelection.text!;
						this.form.get("managed_in")?.patchValue(this.selectedManagedIn, { emitEvent: emit });
					} else {
						this.managedInSelectOptions = result;
						this.managedInSelection = { text: result[0].text, value: result[0].value };
						this.selectedManagedIn = result[0].text;
						this.form.get("managed_in")?.patchValue(result[0].text, { emitEvent: emit });
					}
				}
			});
	}

	formatDropDownText(text: string): string {
		if (text) {
			let format: string = text.toLowerCase().replace("_", " ");
			format = format.charAt(0).toUpperCase() + format.slice(1);
			return format;
		}
		return text;
	}

	updateSubServiceValues(): void {
		if (this.subService && this.subService.id) {
			//If this sub service already exists
			this.colorEye = "#231f20";
			this.colorTrash = "#ed4c5c";

			let tppAccount: string | undefined =
				this.subService.beneficiaryId && this.tppAccounts
					? this.tppAccounts.find((item: TppAccount) => item.id === this.subService.beneficiaryId)?.name
					: "";

			this.form.patchValue(
				{
					//Set the form informantion
					name: this.subService.name,
					externalId: this.subService.externalId,
					beneficiaryName: tppAccount, //Set saved ben info
					paymentOriginator: this.subService.paymentOriginator,
					referenceType: this.subService.referenceType,
					frequency: this.subService.frequency
				},
				{ emitEvent: false }
			);

			this.subServiceTitle = this.subService.name;
			this.btEyeDisabled = false;
		}
	}

	getTPPAccounts(): void {
		const pageNumber = 0;
		const pageSize = -1;
		if (this.tppServiceId) {
			//If this main service exists - should for you to edit this form
			this.tppAccountService
				.searchTPPAccountsByTppServiceId(this.tppServiceId, pageNumber, pageSize)
				.subscribe((response: TppAccount[]) => {
					if (response.length) {
						//If we have existing bens
						this.tppAccounts = response; //Gets our TPPAccounts -> this is our list of Bens for this Service
						this.beneficiarySelectOptions = new SelectOptionsFromTppaccountPipe().transform(response); //extract bens form TPPaccount
						this.reviewControlChanges(); //Listener for ben dropdown
					}

					this.updateSubServiceValues(); //Setup saved info -> if sub already exists
					//this.formValuesChanges(); //If we have no Bens
				});
		} else {
			//	this.formValuesChanges();
		}
	}

	addNewBeneficiary() {
		if (!this.subService) this.tppServiceManagementService.setNewSubserviceData(this.form.value); //quick store data

		this.tppServiceManagementService.beneficiaryAccount = {} as TppAccount;

		this.tppServiceManagementService.setBeneficairyAccountState(true);

		this.showAddBeneficiaryForm = true;
	}

	loadSubServiceFormStoredData() {
		if (
			!this.subService &&
			this.tppServiceManagementService.getNewSubserviceForm() &&
			Object.keys(this.tppServiceManagementService.getNewSubserviceForm()).length > 0
		) {
			const values: SubServiceStoreDataForm = this.tppServiceManagementService.getNewSubserviceForm();

			this.subServiceTitle = values["name"] ? values.name : "";

			this.form.patchValue(
				{
					name: values["name"],
					paymentOriginator: values["paymentOriginator"],
					referenceType: values["referenceType"],
					frequency: values["frequency"]
				},
				{ emitEvent: false }
			);
		}
	}

	reviewControlChanges() {
		this.autocomplete.control.valueChanges.subscribe(data => {
			if (!data) this.btEyeDisabled = true;
			else this.btEyeDisabled = false;
		});
	}
	show() {
		if (!this.autocomplete.control.value) this.form.get("beneficiaryName")?.setValue("", { emitEvent: false });

		const beneficiaryName: string = this.form.value.beneficiaryName;
		const beneficiaryId: string = this.tppAccounts.filter((item: TppAccount) => item.name === beneficiaryName)[0]
			.id;

		if (this.tppAccounts && this.tppAccounts.length > 0) {
			const accountBeneficiary: TppAccount = this.tppAccounts.filter(
				(item: TppAccount) => item.id === beneficiaryId
			)[0];

			this.tppServiceManagementService.beneficiaryAccount = accountBeneficiary;
			this.tppServiceManagementService.setBeneficairyAccountState(true);

			this.showEditBeneficiaryForm = true;
		}
	}

	deleteSubService() {
		if (this.subService && this.subService.id) {
			if (this.forAdhocService) {
				//First delete from service def then delete service
				this.tppServicesService
					.deleteSubServiceForAdhocService(this.subService.id, this.selectedPayGroupId)
					.pipe(take(1))
					.subscribe(subService => {
						this.sendSubServiceData.emit(this.subService);
					});
			} else {
				this.tppServicesService.deleteSubService(this.subService.id).subscribe((response: any) => {
					this.sendSubServiceData.emit(this.subService);
				});
			}
		}
	}

	resetGroupVeriables(): void {
		this.selectedTPPGroupOptions = [];
		this.containerClass = "";
		this.textClass = "";
	}

	//Group selection

	addSelection(item: SelectOption, emit: boolean = true) {
		this.selectedTPPGroupOptions = [];
		this.selectedTPPGroupOptions.push(item);
		if (item.value === "TPP1") {
			this.containerClass = "group-1-container";
			this.textClass = "group-1-text";
		} else if (item.value === "TPP2") {
			this.containerClass = "group-2-container";
			this.textClass = "group-2-text";
		} else if (item.value === "TPP3") {
			this.containerClass = "group-3-container";
			this.textClass = "group-3-text";
		} else if (item.value === "TPP4") {
			this.containerClass = "group-4-container";
			this.textClass = "group-4-text";
		}

		this.form.get("group")?.patchValue(item.value, { emitEvent: emit });
	}

	showList() {
		this.tppOptionsMenuList = true;
	}

	triggerDropDownButtonClick() {
		const buttonElement = this.dropDownButton.nativeElement;
		buttonElement.click();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
