<div class="parent-container flex flex-column" [formGroup]="newBankAccountForm">
	<div class="header">
		<div class="header-content flex flex-row flex-align-center-space-between flex-item" style="--flex: 100">
			<div class="section badge-container flex flex-row flex-align-center-start flex-item" style="--flex: 30">
				<div class="badge" *ngIf="!isCardUpdate">
					<app-icon [name]="'bank-figma'" [lineWidth]="'2'"></app-icon>
				</div>
				<div class="badge-card" *ngIf="isCardUpdate">
					<app-icon [name]="'card'" [lineWidth]="'2'"></app-icon>
				</div>

				<div class="account-info">
					<span class="bank-name" *ngIf="!isCardUpdate">{{ bankName ? bankName : "Your Bank" }}</span>

					<span class="bank-name" *ngIf="isCardUpdate"
						>{{ bankAccountDetails?.details?.holder?.firstName }}
						{{ bankAccountDetails?.details?.holder?.lastName }}</span
					>
					<div class="subtitle">
						<div
							*ngIf="
								bankAccountDetails?.payMethod &&
								displayRanking &&
								bankAccountDetails?.payMethod?.ranking &&
								bankAccountDetails?.payMethod?.ranking?.type === 'AMOUNT'
							"
						>
							Rank {{ bankAccountDetails?.payMethod?.ranking?.rank! + 1 }}:
							{{ bankAccountDetails?.payMethod?.ranking?.amount }}
							{{ bankAccountDetails?.details?.currency! | currencySymbol }}
						</div>
						<div
							class="subtitle"
							*ngIf="
								bankAccountDetails?.payMethod &&
								displayRanking &&
								bankAccountDetails?.payMethod?.ranking &&
								bankAccountDetails?.payMethod?.ranking?.type === 'PERCENTAGE'
							"
						>
							Rank {{ bankAccountDetails?.payMethod?.ranking?.rank! + 1 }}:
							{{ bankAccountDetails?.payMethod?.ranking?.amount }} %
						</div>
						<div
							class="subtitle"
							*ngIf="
								bankAccountDetails?.payMethod &&
								displayRanking &&
								bankAccountDetails?.payMethod?.ranking &&
								bankAccountDetails?.payMethod?.ranking?.type === 'PERCENTAGE_REMAINING'
							"
						>
							Rank {{ bankAccountDetails?.payMethod?.ranking?.rank! + 1 }}:
							{{ bankAccountDetails?.payMethod?.ranking?.amount }} % Remaining
						</div>
						<div
							class="subtitle"
							*ngIf="
								bankAccountDetails?.payMethod &&
								displayRanking &&
								bankAccountDetails?.payMethod?.ranking &&
								bankAccountDetails?.payMethod?.ranking?.type === 'ALL_REMAINING'
							"
						>
							All Remaining
						</div>
					</div>
				</div>
			</div>
			<!-- Effective from -->

			<!--Toogle -->

			<input-wrapper
				formGroupName="paymentTypes"
				class="section flex flex-align-center-center flex-item"
				label=""
				style="--flex: 70"
			>
				<div class="flex flex-row flex-align-center-space-between">
					<div
						class="flex flex-align-center"
						*ngIf="bankAccountDetails | employeeTransactionsFallback : supportedPayMethodTypes"
					>
						<mat-slide-toggle class="toogle-style toggle-default fallback-toggle" formControlName="fallback"
							>Fall back</mat-slide-toggle
						>
					</div>
					<mat-checkbox id="netCheck" color="primary" class="check-box" formControlName="net"
						>NET</mat-checkbox
					>
					<mat-checkbox id="tppCheck" color="primary" class="check-box" formControlName="tpp"
						>TPP</mat-checkbox
					>
					<mat-checkbox id="ewaCheck" color="primary" class="check-box" formControlName="ewa"
						>EWA</mat-checkbox
					>
				</div>

				<div class="hint">*Required: It is mandatory to select Fallback or NET/TPP/EWA</div>
			</input-wrapper>

			<div class="right-box">
				<!-- If it is not a card -->
				<ng-container *ngIf="bankAccountDetails?.payMethod && bankAccountDetails?.payMethod?.type !== 'CARD'">
					<!-- Status -->
					<div class="data-container">
						<!-- Badge -->
						<div
							class="status-badge"
							id="statusBadge"
							[ngClass]="{
								created: bankAccountDetails?.payMethod?.status?.name === 'CREATED',
								active: bankAccountDetails?.payMethod?.status?.name === 'ACTIVE',
								inactive: bankAccountDetails?.payMethod?.status?.name === 'INACTIVE'
							}"
						></div>

						<!-- Label -->
						<div id="beneficiaryStatus" class="status-label">
							{{
								bankAccountDetails?.payMethod?.status?.name === "INACTIVE"
									? "FAILED"
									: (bankAccountDetails?.payMethod?.status?.name | payElectiveStatus | titlecase)
							}}
						</div>
					</div>

					<div class="data-container"></div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="country-currency-container" *ngIf="!isCardUpdate">
		<div ngClass="o-grid-2" class="flex flex-align-center-start flex-gap" style="--gap: 50px">
			<input-wrapper label="Country*" class="select wrapper-height input-component-bank">
				<input-autocomplete
					id="country"
					formControlName="country"
					[options]="countries$ | async"
					[label]="'Select country'"
					[required]="true"
					[disabled]="!this.newBankAccount || !canCreateBankAccount"
				>
				</input-autocomplete>
			</input-wrapper>
			<input-wrapper label="Currency*" class="select wrapper-height input-component-bank">
				<input-autocomplete
					id="currency"
					formControlName="currency"
					label="Select currency"
					[options]="currencies$ | async"
					[required]="true"
					[disabled]="!canCreateBankAccount"
				>
				</input-autocomplete>
			</input-wrapper>
		</div>
	</div>
	<div class="content flex flex-column">
		<div class="bank-dates flex flex-row flex-wrap flex-align-center-start" style="--gap: 50px">
			<input-wrapper label="Effective from *" class="section input-component-bank">
				<mat-form-field appearance="outline" (click)="effectiveFromDate.open()">
					<mat-datepicker-toggle matSuffix [for]="effectiveFromDate">
						<app-icon
							matDatepickerToggleIcon
							class="icon-effective-calendar"
							[name]="'calendar_month'"
							[color]="'var(--color-primary-1000)'"
						></app-icon>
					</mat-datepicker-toggle>
					<input
						id="dateFromSelect"
						readonly
						matInput
						[matDatepicker]="effectiveFromDate"
						placeholder="Select a date"
						formControlName="effectiveFrom"
					/>
					<mat-datepicker #effectiveFromDate></mat-datepicker>
				</mat-form-field>

				<div
					input-errors
					*ngIf="
						newBankAccountForm.controls.effectiveFrom.touched &&
						newBankAccountForm.controls.effectiveFrom.errors?.required
					"
				>
					Effective from is requireds
				</div>
			</input-wrapper>
			<!-- Effective to -->
			<input-wrapper label="Effective to" class="section input-component-bank">
				<mat-form-field appearance="outline" (click)="effectiveToDate.open()">
					<mat-datepicker-toggle matSuffix [for]="effectiveToDate">
						<app-icon
							matDatepickerToggleIcon
							class="icon-effective-calendar"
							[name]="'calendar_month'"
							[color]="'var(--color-primary-1000)'"
						></app-icon>
					</mat-datepicker-toggle>
					<input
						id="dateToSelect"
						readonly
						matInput
						[matDatepicker]="effectiveToDate"
						placeholder="Select a date"
						formControlName="effectiveTo"
					/>
					<div class="flex flex-row flex-align-center-end">
						<mat-icon
							*ngIf="newBankAccountForm.controls.effectiveTo.value"
							matDatepickerToggleIcon
							(click)="clearDate()"
							>clear</mat-icon
						>
						<mat-datepicker #effectiveToDate></mat-datepicker>
					</div>
				</mat-form-field>

				<div input-errors *ngIf="newBankAccountForm.controls.effectiveTo.errors?.required">
					Effective to is required
				</div>
			</input-wrapper>
		</div>

		<div
			*ngIf="isTypeNet"
			class="rank-inputs-container flex flex-align-center-space-between flex-gap"
			style="--gap: 40px"
			ngClass="o-grid-3"
			ngStyle.lg="{'height': '10rem', 'width': '82rem'}"
			ngStyle.sm="{'height': '29rem', 'width': '18rem'}"
		>
			<input-wrapper class="select rank-section wrapper-height input-component-bank" label="Value Type">
				<input-autocomplete
					id="rankingValueType"
					formControlName="rankingValueType"
					[options]="rankingValueTypes"
					[disabled]="isAllRemaining"
				>
				</input-autocomplete>
			</input-wrapper>

			<input-wrapper
				label="{{
					isAllRemaining ? 'ALL_REMAINING' : newBankAccountForm.get('rankingValueType')?.value + ' *'
				}}"
				class="rank-section input-component-bank"
			>
				<input
					placeholder="{{ formatRankingValueType(newBankAccountForm.get('rankingValueType')?.value) }}"
					id="rankingAmount"
					formControlName="rankingAmount"
				/>
				<div
					input-errors
					*ngIf="
						newBankAccountForm.get('rankingAmount')?.invalid &&
						newBankAccountForm.get('rankingAmount')?.errors?.invalidPercentage
					"
				>
					Cannot be higher than 100% or lower than 1%.
				</div>
			</input-wrapper>

			<input-wrapper label="Rank" class="rank-section input-component-bank">
				<input id="rank" placeholder="{{ rankingRank }}" disabled />
			</input-wrapper>
		</div>

		<div
			*ngIf="isCardUpdate"
			class="card-details-container flex flex-align-center-start flex-gap"
			style="--gap: 50px"
			ngClass="o-grid-3"
			ngStyle.lg="{'height': '10rem', 'width': '82rem'}"
			ngStyle.sm="{'height': '29rem', 'width': '18rem'}"
		>
			<ng-container>
				<!-- Card number -->
				<div class="data-box">
					<div class="title-2">Card number</div>
					<div class="subtitle-2">**** **** **** {{ bankAccountDetails?.details?.lastFour }}</div>
				</div>

				<!-- Card holder name -->
				<div class="data-box">
					<div class="title-2">Card holder name</div>
					<div class="subtitle-2">
						{{ bankAccountDetails?.details?.holder?.firstName }}
						{{ bankAccountDetails?.details?.holder?.lastName }}
					</div>
				</div>
			</ng-container>
		</div>

		<bank-account-fields
			class="account-fields"
			[country]="defaultCountry"
			[values]="bankAccountValues"
			[bankName]="bankAccountDetails?.details?.bankName!"
			[editing]="!newBankAccount"
			[canEdit]="canCreateBankAccount"
			*ngIf="!isCardUpdate"
		></bank-account-fields>

		<div class="submit-container flex flex-align-center-start">
			<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
				<button id="cancelBtn" mat-button (click)="closeForm()" class="button-start">Cancel</button>
				<button
					id="saveBtn"
					action-buttons
					mat-flat-button
					class="save-button"
					color="dark"
					(click)="createAccount()"
					[disabled]="
						!newBankAccountForm.valid ||
						bankAccountFieldsComponent?.bankAccountForm?.invalid ||
						!typeIsSelected() ||
						formIsPristine
					"
				>
					{{ newBankAccount ? "Save" : "Update" }}
				</button>
			</ng-template>
		</div>
	</div>
</div>
