import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { ToastService } from "@shared/services/toast/toast.service";
import { CountryBankAccountField, CountryBankAccountFormField } from "src/app/shared/models/bank-account.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { formatStringsForSelect } from "src/app/shared/utils/format-strings-select";
import { BankAccountsCommonService } from "../../_services/bank-accounts-common.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-bank-account-fields",
	templateUrl: "./bank-account-fields.component.html",
	styleUrls: ["./bank-account-fields.component.scss"]
})
export class BankAccountFieldsComponent implements OnInit, OnDestroy {
	selectedCountry: string = "";
	fields: CountryBankAccountField[] = [];

	destroy$: Subject<void> = new Subject();

	bankAccountFieldsForm!: FormGroup;

	accountFieldSubscription: Subscription = new Subscription();
	countryNameSubscription: Subscription = new Subscription();
	copyFieldsSubscription: Subscription = new Subscription();

	private fieldTypes = ["STRING", "INT", "DOUBLE"];

	selectOptionsFieldTypes: SelectOption[] = [];
	canAddBankField = false;
	canEditBankField = false;
	canRemoveBankField = false;

	constructor(
		private readonly bankAccountService: BankAccountsCommonService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly toastService: ToastService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canRemoveBankField()
			.pipe(take(1))
			.subscribe(res => {
				this.canRemoveBankField = res;
			});

		this.permissions
			.canEditBankFields()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditBankField = res;
			});

		this.permissions
			.canAddBankFields()
			.pipe(take(1))
			.subscribe(res => {
				this.canAddBankField = res;
				this.initForm();
				this.selectOptionsFieldTypes = formatStringsForSelect(this.fieldTypes);
				this.initSubscriptions();
			});
	}

	initForm(): void {
		this.bankAccountFieldsForm = this.formBuilder.group({
			fields: this.formBuilder.array([])
		});
	}

	initSubscriptions(): void {
		this.accountFieldSubscription = this.bankAccountService.countyBankAccountFields$
			.pipe(takeUntil(this.destroy$))
			.subscribe(bankAccount => {
				this.fields = [];
				// this.getBankAccountFields().clear();
				if (bankAccount.fields.length) {
					this.fields = bankAccount.fields;
					this.setFields(this.fields);
				}
			});

		this.countryNameSubscription = this.bankAccountService.setSelectedCountry$
			.pipe(takeUntil(this.destroy$))
			.subscribe(country => {
				this.getBankAccountFields().clear();
				this.selectedCountry = country;
			});

		this.copyFieldsSubscription = this.bankAccountService.copyCountyBankAccountFields$
			.pipe(takeUntil(this.destroy$))
			.subscribe(fieldsToCopy => {
				this.setFields(fieldsToCopy);

				this.toastService.showInfo("Fileds Copies Successfully");
			});
	}

	hasFields(): boolean {
		const fields = this.bankAccountFieldsForm.get("fields")?.value as FormArray;
		return !!fields.length;
	}

	addField(): void {
		if (this.fieldsAreValid()) {
			//push field object to form
			const newFieldSet = this.formBuilder.group({
				key: ["", Validators.required],
				name: ["", Validators.required],
				type: ["", Validators.required],
				unique: [false],
				maxLength: [null],
				minLength: [null],
				exactLength: [null],
				required: [false]
			});

			newFieldSet
				.get("exactLength")
				?.valueChanges.pipe(takeUntil(this.destroy$))
				.subscribe(exactLength => {
					if (exactLength) {
						newFieldSet.get("minLength")?.patchValue(null, { emitEvent: false });
						newFieldSet.get("minLength")?.disable();
						newFieldSet.get("maxLength")?.patchValue(null, { emitEvent: false });
						newFieldSet.get("maxLength")?.disable();
					} else {
						newFieldSet.get("minLength")?.enable();
						newFieldSet.get("maxLength")?.enable();
					}
				});

			newFieldSet
				.get("unique")
				?.valueChanges.pipe(takeUntil(this.destroy$))
				.subscribe(isUnique => {
					if (isUnique) {
						newFieldSet.get("required")?.patchValue({ value: isUnique }, { emitEvent: false });
					} else {
						newFieldSet.get("required")?.patchValue({ value: !isUnique }, { emitEvent: false });
					}
				});
			this.getBankAccountFields().push(newFieldSet);
			this.toastService.showInfo("New Field Set Added");
		} else {
			this.toastService.showError("Not all fields are valid");
		}
	}

	setFields(fields: CountryBankAccountField[]) {
		fields.filter(field => {
			const newFieldSet = this.formBuilder.group({
				key: [field.key, Validators.required],
				name: [field.name, Validators.required],
				type: [field.type, Validators.required],
				unique: [field.unique],
				maxLength: [field.rules.MAX],
				minLength: [field.rules.MIN],
				exactLength: [field.rules.EXACT_LENGTH],
				required: [field.rules.REQUIRED],
				keyAliases: [field.keyAliases]
			});

			newFieldSet
				.get("exactLength")
				?.valueChanges.pipe(takeUntil(this.destroy$))
				.subscribe(exactLength => {
					if (exactLength) {
						newFieldSet.get("minLength")?.patchValue(null, { emitEvent: false });
						newFieldSet.get("minLength")?.disable();
						newFieldSet.get("maxLength")?.patchValue(null, { emitEvent: false });
						newFieldSet.get("maxLength")?.disable();
					} else {
						if (this.canEditBankField) {
							newFieldSet.get("minLength")?.enable();
							newFieldSet.get("maxLength")?.enable();
						}
					}
				});

			newFieldSet
				.get("unique")
				?.valueChanges.pipe(takeUntil(this.destroy$))
				.subscribe(isUnique => {
					if (isUnique) {
						newFieldSet.get("required")?.patchValue({ value: isUnique }, { emitEvent: false });
					} else {
						newFieldSet.get("required")?.patchValue({ value: !isUnique }, { emitEvent: false });
					}
				});

			if (!this.canEditBankField) {
				newFieldSet.disable();
			}

			this.getBankAccountFields().push(newFieldSet);
		});
	}

	getBankAccountFields(): FormArray {
		return this.bankAccountFieldsForm.get("fields") as FormArray;
	}

	isString(index: number): boolean {
		const type: string = this.getBankAccountFields().controls[index].get("type")?.value;
		if (type === "STRING") {
			return true;
		}
		return false;
	}

	shouldBeUnique(index: number): boolean {
		return this.getBankAccountFields().controls[index].get("unique")?.value;
	}

	isRequired(index: number): boolean {
		return this.getBankAccountFields().controls[index].get("required")?.value;
	}

	setMinLength(event: any, index: number): void {
		const value = event.checked ? 1 : null;
		this.getBankAccountFields().controls[index].get("minLength")?.setValue(value);
	}

	removeFields(index: number): void {
		this.getBankAccountFields().removeAt(index);
	}

	fieldsAreValid(): boolean {
		return this.getBankAccountFields().valid;
	}

	updateFields(): void {
		const formData: CountryBankAccountFormField[] = this.bankAccountFieldsForm.getRawValue().fields;
		const fields = formData.map((field: CountryBankAccountFormField) => {
			field.keyAliases =
				typeof field.keyAliases === "string" ? field.keyAliases.trim().split(",") : field.keyAliases;
			return field;
		});
		this.bankAccountService.updateCountryAccounts(fields);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
