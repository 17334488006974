import { Component, OnDestroy, OnInit } from "@angular/core";
import { PageEvent as PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import { Pagination } from "src/app/shared/models/pagination.interface";
import { CustomerEntitySelection } from "../../../../shared/models/customer-entity-selection.interface";
import { PayGroup } from "../../../../shared/models/pay-groups";
import { getCustomerEntityGroupState } from "../../../../store";
import { UpdateCustomerEntityGroupAction } from "../../../../store/actions/customerEntityGroupSelect.action";
import { AppState } from "../../../../store/models/state.model";
import { ApiService } from "../../../pay-groups/services/api.service";

@Component({
	selector: "app-customer-paygroups",
	templateUrl: "./customer-paygroups.component.html",
	styleUrls: ["./customer-paygroups.component.scss"]
})
export class CustomerPaygroupsComponent implements OnInit, OnDestroy {
	payGroups$!: Observable<PayGroup[]>;
	selectedCustomerId: string = "";
	selectedLegalEntityId: string = "";

	pageSize: number = 10;
	pageIndex: number = 0;
	totalPageCount!: number;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(private router: Router, private paygroupApiService: ApiService, private store: Store<AppState>) {}

	ngOnInit(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			this.selectedCustomerId = state.customerId;
			this.selectedLegalEntityId = state.legalEntityId;
		});
	}

	getPayGroupsList(pageSize: number, pageNumber: number): void {
		const statuses: string = "LIVE, IMPLEMENTATION";
		this.payGroups$ = this.paygroupApiService
			.getPayGroups(this.selectedCustomerId, this.selectedLegalEntityId, statuses, "", "", pageSize, pageNumber)
			.pipe(
				tap(result => (this.totalPageCount = result.totalCount)),
				map(response => {
					return response.items;
				})
			);
	}

	viewEntitiesClicked() {
		this.router.navigate(["/legal-entities"]);
	}

	paygroupSelected(payGroup: PayGroup) {
		this.store.dispatch(
			new UpdateCustomerEntityGroupAction({
				customerId: this.selectedCustomerId,
				customerName: payGroup.customer.name,
				legalEntityId: payGroup.legalEntityId,
				payGroupId: payGroup.id
			})
		);
		this.router.navigate(["dashboard/file-management/files"]);
	}

	selectionChange(data: CustomerEntitySelection): void {
		this.selectedCustomerId = data.customerId;
		this.selectedLegalEntityId = data.legalEntityId;
		this.getPayGroupsList(this.pageSize, this.pageIndex);
	}

	paginate(pagination: PageEvent) {
		this.pageSize = pagination.pageSize;
		this.pageIndex = pagination.pageIndex;
		this.getPayGroupsList(pagination.pageSize, pagination.pageIndex);
	}

	onPayElectives() {
		this.router.navigate(["employee-data/paygroups"]);
	}

	onDashboard() {
		this.router.navigate(["employee-data/paygroups"]);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
