import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatCheckboxChange as MatCheckboxChange } from "@angular/material/checkbox";
import { TppPayouts } from "../../models/tpp-payment.interface";
import { Transaction } from "src/app/shared/models/payouts.types";
import { MatDialog } from "@angular/material/dialog";
import { PayoutStatusHistoryDialogComponent } from "../../../_components/payouts/payout-list-item/payout-status-history-dialog/payout-status-history-dialog.component";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { Subject, take, takeUntil } from "rxjs";
import { EmployeeService } from "@shared/services/employee/employee.service";

@Component({
	selector: "app-tpp-payment-payout-item-detail-list-item",
	templateUrl: "./tpp-payment-payout-item-detail-list-item.component.html",
	styleUrls: ["./tpp-payment-payout-item-detail-list-item.component.scss"]
})
export class TppPaymentPayoutItemDetailListItemComponent implements OnDestroy {
	@Input() set _payout(payout: TppPayouts) {
		this.payout = payout;
		this.payoutTransactions = payout.transactions;
		this.setupPermissions();
		this.employeeIdConversion();
	}

	@Input() set _isCancelledRun(cancelled: boolean) {
		this.isCancelledRun = cancelled;
	}
	@Input() set _isLast(last: boolean) {
		this.isLast = last;
	}

	@Input() set _reprocessPayoutIdsList(ids: string[]) {
		this.reprocessIds = ids;
	}

	isInActivatedPayout!: boolean;
	@Input() set _isInActivatedPayout(val: boolean) {
		this.isInActivatedPayout = val;
	}

	@Output() pushToReprocessList: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() popFromReprocessList: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() inActivatePayouts: EventEmitter<string[]> = new EventEmitter<string[]>();

	payout!: TppPayouts;
	payoutTransactions!: Transaction[];
	reprocessIds: string[] = [];
	isLast: boolean = false;
	isCancelledRun: boolean = false;
	canViewTransactionHistory: boolean = false;
	canReprocessPayment = false;

	private destroy$: Subject<void> = new Subject();

	constructor(
		public dialog: MatDialog,
		private permissions: PermissionsService,
		private employeeService: EmployeeService
	) {}

	employeeIdConversion(): void {
		if (this.payout.employeeId) {
			this.employeeService
				.getEmployeesById(this.payout.employeeId)
				.pipe(take(1))
				.subscribe(result => {
					this.payout.employeeId = result.externalId;
				});
		}
	}

	setupPermissions() {
		this.permissions
			.canViewTransactionHistoryTPP()
			.pipe(takeUntil(this.destroy$))
			.subscribe(viewRes => (this.canViewTransactionHistory = viewRes));

		this.permissions
			.canReprocessBookings()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canReprocessPayment = res));
	}

	setIds(event: MatCheckboxChange, payoutIds: string[]) {
		if (event.source.checked === true) {
			this.pushToReprocessList.emit(payoutIds);
		} else {
			this.popFromReprocessList.emit(payoutIds);
		}
	}

	showHistory() {
		this.openDialog();
	}

	openDialog(): void {
		let dialogRefData = {
			payoutTransactions: this.payoutTransactions
		};

		const dialogRef = this.dialog.open(PayoutStatusHistoryDialogComponent, {
			width: "1100px",
			panelClass: "dialog-container",
			data: dialogRefData
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				//this.location.back();
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
