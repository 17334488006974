import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { deleteDialog } from "src/app/shared/models/delete-dialog.interface";

export interface ActionConfirmModalData {
	description: string;
	confirmText: string;
	cancelText: string;
}

@Component({
	selector: "app-action-confirm-dialog",
	templateUrl: "./action-confirm-dialog.component.html",
	styleUrls: ["./action-confirm-dialog.component.scss"]
})
export class ActionConfirmModalComponent implements OnInit {
	description!: string;
	cancelText!: string;
	confirmText!: string;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			description: string;
			confirmText: string;
			cancelText: string;
		},
		public dialogRef: MatDialogRef<ActionConfirmModalComponent>
	) {}

	ngOnInit(): void {
		this.description = this.data.description;
		this.confirmText = this.data.confirmText ? this.data.confirmText : "Yes, sure.";
		this.cancelText = this.data.cancelText ? this.data.cancelText : "No, thanks.";
	}

	onDeleteClick() {
		this.dialogRef.close(true);
	}

	onCancelClick() {
		this.dialogRef.close(false);
	}
}
