<div [formGroup]="form" class="form flex flex-column flex-justify-content">
	<div class="flex flex-column flex-wrap flex-align-start flex-gap" style="--gap: 16px; --wrap: wrap">
		<div class="flex flex-row flex-wrap flex-align-center-start flex-gap" style="--gap: 16px" ngClass="o-grid-3">
			<input-wrapper label="Employee ID *" class="employee-input-wrapper">
				<input type="text" id="employeeId" placeholder="Employee ID" formControlName="externalId" />
				<div input-errors *ngIf="form.get('externalId')?.dirty && form.get('externalId')?.errors?.required">
					Employee ID is required
				</div>
			</input-wrapper>
			<input-wrapper label="First Name *" class="employee-input-wrapper">
				<input type="text" id="firstName" placeholder="First Name" formControlName="firstName" />
				<div input-errors *ngIf="form.get('firstName')?.dirty">
					<ng-container *ngIf="form.get('firstName')?.errors?.required">
						First Name is required
					</ng-container>
					<ng-container
						*ngIf="!form.get('firstName')?.errors?.required && form.get('firstName')?.errors?.maxlength"
					>
						Max length is {{ form.controls["firstName"].errors?.maxlength.requiredLength }}
					</ng-container>
					<ng-container
						*ngIf="
							!form.get('firstName')?.errors?.required &&
							!form.get('firstName')?.errors?.maxlength &&
							form.get('firstName')?.errors?.pattern
						"
					>
						Please enter a valid name
					</ng-container>
				</div>
			</input-wrapper>
			<input-wrapper label="Last Name *" class="employee-input-wrapper">
				<input type="text" id="lastName" placeholder="Last Name" formControlName="lastName" />
				<div input-errors *ngIf="form.get('lastName')?.dirty">
					<ng-container *ngIf="form.get('lastName')?.errors?.required"> Last Name is required </ng-container>
					<ng-container
						*ngIf="!form.get('lastName')?.errors?.required && form.get('lastName')?.errors?.maxlength"
					>
						Max length is {{ form.controls["lastName"].errors?.maxlength.requiredLength }}
					</ng-container>
					<ng-container
						*ngIf="
							!form.get('lastName')?.errors?.required &&
							!form.get('lastName')?.errors?.maxlength &&
							form.get('lastName')?.errors?.pattern
						"
					>
						Please enter a valid name
					</ng-container>
				</div>
			</input-wrapper>
		</div>
		<div
			class="flex flex-row flex-wrap flex-align-center-start flex-gap"
			style="--gap: 16px; --wrap: wrap"
			ngClass="o-grid-3"
		>
			<input-wrapper label="Entity Reference" class="employee-input-wrapper" id="entity-reference">
				<input
					type="text"
					id="enityReference"
					placeholder="Entity Reference"
					formControlName="enityReference"
				/>
				<span class="add-config flex flex-align-end"> </span>
			</input-wrapper>

			<input-wrapper label="PayGroup ID" class="employee-input-wrapper">
				<input type="text" id="payRollId" formControlName="payGroupExternalId" />

				<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
					<span
						id="changePayGroup"
						(click)="onChangePayGroup()"
						*ngIf="payGroup.id"
						class="add-config flex-align-end"
					>
						<mat-icon class="select-icon">add</mat-icon>
						<span class="flex-align-center">Change PG</span>
					</span>
				</ng-template>
			</input-wrapper>

			<input-wrapper label="Filler" class="employee-input-wrapper filler">
				<input type="text" />
			</input-wrapper>
		</div>
	</div>

	<div class="btn-container">
		<button id="cancel" class="cancel-btn" (click)="cancel()" action-buttons mat-stroked-button>Cancel</button>
		<button
			*ngIf="!isDisabled"
			id="submit"
			class="submit-btn"
			(click)="storePayElective()"
			action-buttons
			mat-flat-button
			[disabled]="!form.valid || form.pristine"
		>
			{{ isEdit ? "Update" : "Save" }}
		</button>
	</div>
</div>
