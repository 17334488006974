import { HttpClient, HttpParams } from "@angular/common/http";
import { forkJoin, Observable, Subject, BehaviorSubject, throwError, of } from "rxjs";
import { catchError, map, shareReplay, take } from "rxjs/operators";
import { MONTH_NAMES } from "src/app/shared/constants/months";
import {
	Milestone,
	MilestoneGroup,
	MilestoneUpdateDTO,
	OffCycleCreateDTO,
	PayCycle,
	PayCyclePagination,
	TppPayCycleSubServices
} from "src/app/shared/models/pay-cycle.interface";
import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { DashboardFilters } from "@modules/global-dashboard/models/global-dashboard-interface";
import { createISOStringFromDate } from "@shared/utils/date.util";

@Injectable({
	providedIn: "root"
})
export class PayCycleService {
	constructor(private http: HttpClient) {}

	private offCycleCreated$ = new Subject<{}>();

	tempMonthName: Record<string, string>[] = MONTH_NAMES;
	organisedMonthNames: string[] = [];

	createdOffCycleObservable(): Observable<any> {
		return this.offCycleCreated$.asObservable();
	}

	getPayCycleById(payCycleId: string): Observable<PayCycle> {
		return this.http.get<PayCycle>(`${environment.apiUri}/v1/calendars/pay-cycles/${payCycleId}`);
	}

	findPayCylesSortAscBy(payCycleIds: string[], dateFrom: Date, dateTo: Date): Observable<PayCycle[]> {
		const rangeStart: string = this.formatDate(dateFrom);
		const rangeEnd: string = this.formatDate(dateTo);

		return this.http
			.get<any>(
				environment.apiUri +
					"/v1/calendars/pay-cycles?" +
					"pageSize=1000&" +
					"payGroupIds=" +
					payCycleIds.join() +
					"&" +
					"rangeStart=" +
					rangeStart +
					"&" +
					"rangeEnd=" +
					rangeEnd
			)
			.pipe(
				map(data => {
					return this.sort(data.items);
				})
			);
	}

	getOrderedPayCycles(payGroupId: string): Observable<PayCycle[]> {
		return this.getPayCycles(payGroupId).pipe(
			map((data: any) => {
				return data.items.sort((a: PayCycle, b: PayCycle) => {
					const dateA: Date = new Date(a.start);
					const dateB: Date = new Date(b.start);

					return dateA.getTime() - dateB.getTime();
				});
			})
		);
	}

	getPayCycles(payGroupId: string): Observable<PayCycle[]> {
		return this.http
			.get<any>(environment.apiUri + "/v1/calendars/pay-cycles?pageSize=1000&payGroupIds=" + payGroupId)
			.pipe(shareReplay(1));
	}

	getPayCyclesByPaygroupIds(payGroupIds: string[]): Observable<PayCyclePagination> {
		return this.http.get<PayCyclePagination>(
			environment.apiUri + "/v1/calendars/pay-cycles?pageSize=1000&payGroupIds=" + payGroupIds
		);
	}

	getPayCyclesByPaygroupIdAsSelectOptions(payGroupIds: string[]): Observable<SelectOption[]> {
		let selection: SelectOption[] = [];

		if (payGroupIds && payGroupIds.length > 0) {
			return this.getPayCyclesByPaygroupIds(payGroupIds).pipe(
				map((payCycles: PayCyclePagination) => {
					payCycles.items.forEach(payCycle => {
						selection.push({
							value: payCycle.id,
							text: `${payCycle.name}`,
							placeholder: payCycle.name,
							additionalValue: payCycle.payGroupId
						});
					});

					return selection;
				})
			);
		} else {
			return of(selection);
		}
	}

	getPayCyclesByGlobalDashboard(filters: DashboardFilters): Observable<PayCyclePagination> {
		let params = new HttpParams();
		params = params.append("customerIds", filters.customers.join(", "));
		params = params.append("legalEntityIds", filters.legalEntities.join(", "));
		params = params.append("payGroupIds", filters.paygroups.join(", "));
		params = params.append("pageSize", 1000);

		return this.http.get<PayCyclePagination>(environment.apiUri + "/v1/calendars/pay-cycles", {
			params
		});
	}

	getPayCyclesByGlobalDasboardFiltersAsSelectOptions(filters: DashboardFilters): Observable<SelectOption[]> {
		let selection: SelectOption[] = [];

		if (filters.paygroups && filters.paygroups.length > 0) {
			return this.getPayCyclesByGlobalDashboard(filters).pipe(
				map((payCycles: PayCyclePagination) => {
					payCycles.items.forEach(payCycle => {
						selection.push({
							value: payCycle.externalId,
							text: `${payCycle.name}`,
							placeholder: payCycle.name,
							additionalValue: payCycle.payGroupId,
							externalId: payCycle.externalId
						});
					});

					return selection;
				})
			);
		} else {
			return of(selection);
		}
	}

	getNavigationMenuYears(orderedAllPayCycles$: Observable<PayCycle[]>): Observable<number[]> {
		return orderedAllPayCycles$.pipe(
			map((sortedData: any) => {
				let navYears: number[] = [];
				let navigationMenuYears: number[] = [];

				sortedData.forEach((x: PayCycle) => {
					navYears.push(new Date(x.start).getFullYear());
				});

				let navYearsUnique = [...new Set(navYears)];

				navYearsUnique.forEach(x => {
					navigationMenuYears.push(x);
				});

				return navigationMenuYears;
			})
		);
	}

	createOffCycle(offCycle: OffCycleCreateDTO): Observable<PayCycle> {
		return this.http.post<PayCycle>(`${environment.apiUri}/v1/calendars/pay-cycles`, offCycle);
	}

	editOffCycle(offCycle: OffCycleCreateDTO): Observable<PayCycle> {
		return this.http.put<PayCycle>(`${environment.apiUri}/v1/calendars/pay-cycles`, offCycle);
	}

	viewCreatedOffCycle(cycleId: string): void {
		const createdCycleData = { isReload: true, id: cycleId };
		this.offCycleCreated$.next(createdCycleData);
	}

	getTPPGroupsForPaycycle(payCycleId: string): Observable<TppPayCycleSubServices[]> {
		return this.http
			.get<TppPayCycleSubServices[]>(
				`${environment.apiUri}/v1/tpp-service-definitions/pay-cycle/${payCycleId}/sub-services`
			)
			.pipe(
				catchError((error: any): Observable<TppPayCycleSubServices[]> => {
					if (error.status === 404) {
						return of([]);
					}
					return throwError(() => error);
				})
			);
	}

	getOrganisedTPPGroupsForPaycycle(payCycleId: string): Observable<MilestoneGroup[]> {
		let milestoneGroups: MilestoneGroup[] = [
			{
				group: "NETS",
				type: "NETS",
				text: "NET"
			}
		];

		return this.getTPPGroupsForPaycycle(payCycleId).pipe(
			take(1),
			map(subServices => {
				if (subServices.length > 0) {
					subServices = subServices.filter(subService => subService.group !== null);

					subServices.sort((a, b) =>
						a.group[a.group.length - 1] > b.group[b.group.length - 1]
							? 1
							: b.group[b.group.length - 1] > a.group[a.group.length - 1]
							? -1
							: 0
					);

					subServices.forEach(subService => {
						if (!milestoneGroups.find(group => group.group === subService.group) && subService.group)
							//Avoid duplicate entries

							milestoneGroups.push({
								group: subService.group,
								type: "TPP",
								text: subService.group
							});
					});

					return milestoneGroups;
				} else {
					return milestoneGroups;
				}
			})
		);
	}

	resetPayCycle(payCycleId: string, milestoneType: string): Observable<any> {
		return this.http.post(
			`${environment.apiUri}/v1/calendars/pay-cycles/${payCycleId}/reset?milestoneGroup=${milestoneType}`,
			null,
			{
				observe: "response"
			}
		);
	}

	formatPayDateForMilestones(milestoneDate: string | Date): Date {
		const time: string = milestoneDate.toString().substring(milestoneDate.toString().indexOf("T") + 1);
		const date: Date = new Date(milestoneDate.toString().substring(0, milestoneDate.toString().indexOf("T")));
		date.setHours(12);

		date.setUTCHours(+time.split(":")[0]);
		date.setUTCMinutes(+time.split(":")[1]);

		return date;
	}

	private formatDate(date: Date): string {
		const year: string = date.toLocaleDateString("US", { year: "numeric" });
		const month: string = date.toLocaleDateString("US", { month: "2-digit" });
		const day: string = date.toLocaleDateString("US", { day: "2-digit" });

		return year + "-" + month + "-" + day;
	}

	private sort(payCycles: PayCycle[]) {
		let sortedData = payCycles.sort(function (a: PayCycle, b: PayCycle) {
			var keyA = new Date(a.start),
				keyB = new Date(b.start);
			// Compare the 2 dates
			if (keyA < keyB) return -1;
			if (keyA > keyB) return 1;
			return 0;
		});

		sortedData = sortedData.sort(function (a: PayCycle, b: PayCycle) {
			var keyA = new Date(a.externalId),
				keyB = new Date(b.externalId);
			// Compare the 2 ids
			if (keyA < keyB) return -1;
			if (keyA > keyB) return 1;
			return 0;
		});

		let regularPayCycles: PayCycle[] = sortedData.filter((x: PayCycle) => {
			return x.type === "REGULAR";
		});

		let offCycles = sortedData.filter((x: PayCycle) => {
			return x.type === "OFF_CYCLE";
		});

		const sortedOffCycles = offCycles.sort(function (a: PayCycle, b: PayCycle) {
			var keyA = new Date(a.start),
				keyB = new Date(b.start);
			// Compare the 2 dates
			if (keyA < keyB) return 1;
			if (keyA > keyB) return -1;
			return 0;
		});
		if (sortedOffCycles) {
			sortedOffCycles.forEach((offCycle: PayCycle) => {
				const startDate = offCycle.start;
				const relatedRegular = regularPayCycles.find((regularCycle: PayCycle) => {
					return (
						(regularCycle.start === startDate && regularCycle.type === "REGULAR") ||
						(new Date(regularCycle.start).getMonth() === new Date(startDate).getMonth() &&
							regularCycle.type === "REGULAR")
					);
				});
				if (relatedRegular) {
					const offCycleNewIndex = regularPayCycles.map((x: PayCycle) => x.id).indexOf(relatedRegular.id);

					regularPayCycles.splice(offCycleNewIndex + 1, 0, offCycle);
				} else {
					regularPayCycles.unshift(offCycle);
				}
			});
		}

		return regularPayCycles;
	}
}
