import { EventEmitter, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ContactPersonService } from "../../../shared/services/contact-person/contact-person.service";
import { CurrencyService } from "../../../shared/services/currency/currency.service";
import { ToastService } from "../../../shared/services/toast/toast.service";
import { ContactPerson, ContactPersonObject } from "../../../shared/models/contact-person.interface";
import { PayGroupCreateDTO, PayGroupCreateRawFormData, PayGroupUpdateDTO } from "../../../shared/models/pay-groups";
import { SelectOption } from "../../../shared/models/select-option.interface";
import { ApiService } from "./api.service";
import { formatISO, parseISO } from "date-fns";
import { switchMap } from "rxjs/operators";
import { formatDateService } from "../../payment-calendar/_services/formatDate.service";
import { createISOStringFromDate } from "../../../shared/utils/date.util";
import _ from "lodash";

@Injectable({
	providedIn: "root"
})
export class CommonService {
	payGroupStored: EventEmitter<boolean> = new EventEmitter();

	constructor(
		private currencyService: CurrencyService,
		private formatDateService: formatDateService,
		private payGroupApiService: ApiService,
		private contactPersonService: ContactPersonService,
		private toasterService: ToastService
	) {}

	getCurrencies(): Observable<SelectOption[]> {
		return this.currencyService.getCurrencyOptions();
	}

	getStatusOptions(): SelectOption[] {
		return [
			{ value: "IMPLEMENTATION", text: "Implementation" },
			{ value: "LIVE", text: "Live" },
			{ value: "INACTIVE", text: "Inactive" }
		];
	}

	getSupportedPayMethodOptions(): SelectOption[] {
		return [
			{ value: "BANK", text: "BANK" },
			{ value: "CARD", text: "CARD" }
		];
	}

	getFrequencyOptions(): SelectOption[] {
		return [
			{ value: "MONTHLY", text: "Monthly" },
			{ value: "FOUR_WEEKLY", text: "Four-Weekly" },
			{ value: "BI_MONTHLY", text: "Bi-Monthly" },
			{ value: "BI_WEEKLY", text: "Bi-Weekly" },
			{ value: "WEEKLY", text: "Weekly" }
		];
	}

	getRunStartDayOptions(): SelectOption[] {
		return [
			{ value: "MONDAY", text: "Monday" },
			{ value: "TUESDAY", text: "Tuesday" },
			{ value: "WEDNESDAY", text: "Wednesday" },
			{ value: "THURSDAY", text: "Thursday" },
			{ value: "FRIDAY", text: "Friday" },
			{ value: "SATURDAY", text: "Saturday" },
			{ value: "SUNDAY", text: "Sunday" }
		];
	}

	getCalendarYearStartWeekBiWeekly(): SelectOption[] {
		return [
			{ value: "FIRST_WEEK", text: "1st week" },
			{ value: "SECOND_WEEK", text: "2nd week" }
		];
	}

	getCalendarYearStartWeekFourWeekly(): SelectOption[] {
		return [
			{ value: "FIRST_WEEK", text: "1st week" },
			{ value: "SECOND_WEEK", text: "2nd week" },
			{ value: "THIRD_WEEK", text: "3rd week" },
			{ value: "FOURTH_WEEK", text: "4th week" }
		];
	}

	getPayrollFrequencues(): SelectOption[] {
		return [
			{ value: "0", text: "0 hours" },
			{ value: "12", text: "12 hours" },
			{ value: "24", text: "24 hours" },
			{ value: "custom", text: "Custom" }
		];
	}

	savePayGroup(
		formData: PayGroupCreateRawFormData,
		contactPersons: ContactPerson[],
		isUpdateExistingPayGroup: boolean
	): void {
		if (isUpdateExistingPayGroup) {
			this.saveUpdatePayGroup(formData, contactPersons);
		} else {
			this.saveCreatePayGroup(formData, contactPersons);
		}
	}

	private saveUpdatePayGroup(formData: PayGroupCreateRawFormData, contactPersons: ContactPerson[]): void {
		const payGroup: PayGroupUpdateDTO = {
			id: formData.id!,
			version: formData.version!,
			status: formData.status!,
			treasuryStatus: formData.treasuryStatus!,
			supportedPayMethodTypes: [formData.supportedPayMethodTypes],
			externalId: formData.externalId,
			legalEntityId: formData.legalEntityId,
			data: {
				name: formData.name,
				currency: formData.currency,
				frequency: formData.frequency,
				runStartDay: formData.runStartDay,
				goLiveAt: formData.goLiveAt ? formData.goLiveAt : null,
				treasuryGoLiveAt: formData.treasuryGoLiveAt ? formData.treasuryGoLiveAt : null,
				avoidPennyTest: formData.avoidPennyTest,
				paymentAutomation: formData.paymentAutomation
			},
			integrationFrequency: formData.integrationFrequency
		};

		if (
			formData.frequency === "BI_WEEKLY" ||
			formData.frequency === "FOUR_WEEKLY" ||
			formData.frequency === "WEEKLY"
		) {
			payGroup.data.runStartDay = formData.runStartDay;
			if (formData.frequency === "BI_WEEKLY" || formData.frequency === "FOUR_WEEKLY") {
				payGroup.data.calendarYearStartWeek = formData.calendarYearStartWeek;
			}
		}

		if (contactPersons) {
			this.payGroupApiService
				.updatePayGroup(payGroup)
				.pipe(
					switchMap(storedPayGroup => {
						const newContactPersons: ContactPersonObject = {
							objectId: storedPayGroup.id!,
							contactPersons
						};

						this.contactPersonService
							.addContactPerson(newContactPersons)
							.pipe(
								switchMap(storedContactPersons => {
									storedContactPersons
										? storedContactPersons.filter((contact: ContactPerson) =>
												contact.userId
													? this.contactPersonService
															.linkContactToPayGroup(storedPayGroup.id, contact.userId)
															.subscribe()
													: void 0
										  )
										: of([]);
									return of(storedPayGroup);
								})
							)
							.subscribe();

						return of(storedPayGroup);
					})
				)
				.subscribe({
					next: _ => {
						this.toasterService.showSuccess("Pay Group Updated Successfully");
						this.payGroupStored.emit(true);
					},
					error: error => console.log(error)
				});
		} else {
			this.payGroupApiService
				.updatePayGroup(payGroup)
				.pipe(
					switchMap(storedPayGroup => {
						return of(storedPayGroup);
					})
				)
				.subscribe({
					next: _ => {
						this.toasterService.showSuccess("Pay Group Updated Successfully");
						this.payGroupStored.emit(true);
					},
					error: error => console.log(error)
				});
		}
	}

	private saveCreatePayGroup(formData: PayGroupCreateRawFormData, contactPersons: ContactPerson[]): void {
		const payGroup: PayGroupCreateDTO = {
			externalId: formData.externalId,
			legalEntityId: formData.legalEntityId,
			status: formData.status,
			treasuryStatus: formData.treasuryStatus,
			supportedPayMethodTypes: [formData.supportedPayMethodTypes],
			data: {
				name: formData.name,
				currency: formData.currency,
				frequency: formData.frequency,
				goLiveAt: formData.goLiveAt ? formData.goLiveAt : null,
				treasuryGoLiveAt: formData.treasuryGoLiveAt ? formData.treasuryGoLiveAt : null,
				runStartDay: formData.runStartDay,
				avoidPennyTest: formData.avoidPennyTest,
				paymentAutomation: formData.paymentAutomation
			},
			integrationFrequency: formData.integrationFrequency
		};

		if (
			formData.frequency === "BI_WEEKLY" ||
			formData.frequency === "FOUR_WEEKLY" ||
			formData.frequency === "WEEKLY"
		) {
			payGroup.data.runStartDay = formData.runStartDay;
			if (formData.frequency === "BI_WEEKLY" || formData.frequency === "FOUR_WEEKLY") {
				payGroup.data.calendarYearStartWeek = formData.calendarYearStartWeek;
			}
		}

		if (contactPersons.length) {
			this.payGroupApiService
				.createPayGroup(payGroup)
				.pipe(
					switchMap(storedPayGroup => {
						const newContactPersons: ContactPersonObject = {
							objectId: storedPayGroup.id!,
							contactPersons
						};
						this.contactPersonService
							.addContactPerson(newContactPersons)
							.pipe(
								switchMap(storedContactPersons => {
									storedContactPersons.filter((contact: ContactPerson) =>
										contact.userId
											? this.contactPersonService
													.linkContactToPayGroup(storedPayGroup.id, contact.userId)
													.subscribe()
											: void 0
									);
									return of(storedPayGroup);
								})
							)
							.subscribe();
						return of(storedPayGroup);
					})
				)
				.subscribe({
					next: _ => {
						this.toasterService.showSuccess("Pay Group Created Successfully");
						this.payGroupStored.emit(true);
					},
					error: error => console.log(error)
				});
		} else {
			this.payGroupApiService
				.createPayGroup(payGroup)
				.pipe(
					switchMap(storedPayGroup => {
						return of(storedPayGroup);
					})
				)
				.subscribe({
					next: _ => {
						this.toasterService.showSuccess("Pay Group Created Successfully");
						this.payGroupStored.emit(true);
					},
					error: error => console.log(error)
				});
		}
	}
}
