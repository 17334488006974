<div class="tpp-services-container">
	<app-breadcrumbs
		[headerTitle]="'SERVICE DEFINITION'"
		[buttonText]="canAddAdhocService ? 'ADD NEW AD HOC SERVICE' : ''"
		(onHeaderButtonAction)="addAdhocService()"
		(onDropDownCrumbItemSelected)="resetPage()"
		[buttonIconObj]="breadCrumbIcon"
	/>

	<div style="position: relative; top: -50px">
		<div class="option-container" fxLayout="row" *ngIf="formInitialized">
			<div fxFlex="20" fxLayout="column" fxLayoutAlign="center start">
				<div
					id="serviceListInputContainer"
					fxLayout="row"
					class="generic-inputs-row-selector"
					fxLayoutAlign="start"
					fxFlex="50"
				>
					<form id="serviceSelectForm" [formGroup]="serviceSelectForm">
						<input-wrapper
							[ngStyle]="{ visibility: canEditListOfServices ? 'visible' : 'hidden' }"
							label="AVAILABLE SERVICES"
							fxFlex="350px"
						>
							<input-select
								[_options]="servicesAsSelectOption"
								[placeholder]="'Select 1 or more services'"
								formControlName="service_type"
								[multiple]="true"
								[required]="true"
								[labelDisappears]="true"
								[_disabled]="
									servicesAsSelectOption?.length === 0 || !canEditListOfServices || isLoading
								"
								class="select-services-input"
							>
							</input-select>
						</input-wrapper>
					</form>
				</div>
			</div>

			<div fxFlex="80" fxLayout="column" fxLayoutAlign="center end">
				<button
					(click)="navigateToGroupConfig()"
					[disabled]="groupsButtonDisabled"
					mat-button
					id="tppGroupsButton"
					style="height: 54px; width: 240px"
					class="button"
					color="dark"
				>
					<div fxLayout="row">
						<app-icon
							style="padding-top: 7px; padding-right: 10px"
							size="30"
							name="coin"
							color="#ffffff"
						></app-icon>
						<div style="padding-top: 4px">TPP GROUPS FUNDING</div>
					</div>
				</button>
			</div>
		</div>

		<div class="tpp-services">
			<no-items-layout
				*ngIf="
					selectedServices == undefined || (selectedServices.size === 0 && !countryHasNoServices);
					else showServices
				"
				message="No Services Selected Yet"
			></no-items-layout>

			<no-items-layout *ngIf="countryHasNoServices" message="No Created Services Yet"></no-items-layout>

			<ng-template #showServices>
				<div id="serviceListContainer" class="generic-fields-container" fxLayout="column">
					<div *ngIf="paymentSummary$ | async as summary">
						<app-beneficary-status-by-provider-summary
							[summary]="summary"
							[payGroupId]="this.payGroup.id"
							(providerSelected)="onProviderFilterChange($event)"
						>
						</app-beneficary-status-by-provider-summary>
					</div>
					<div *ngIf="ttpServices$ | async as services">
						<app-ttp-service-list
							(reloadData)="loadServiceData()"
							[services]="services"
							[payGroup]="payGroup"
						></app-ttp-service-list>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>
