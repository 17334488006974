import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	AfterViewInit,
	ViewChild
} from "@angular/core";
import { tableColumns, User } from "../../_models/user-management-api.interface";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DeleteDialogComponent } from "@shared/components/delete-dialog/delete-dialog.component";
import { MatSlideToggle, MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
	selector: "app-user-management-table",
	templateUrl: "./user-management-table.component.html",
	styleUrls: ["./user-management-table.component.scss"]
})
export class UserManagementTableComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() dataSource!: User[];

	@Output() editUserSelectedEvent = new EventEmitter<User>();
	@Output() deleteUserSelectedEvent = new EventEmitter<User>();
	@Output() changeStatusEvent = new EventEmitter<User>();
	@Output() showUserLogInfo = new EventEmitter<User>();

	@ViewChild("rolesContainer") rolesContainer!: ElementRef;

	tableColumns!: string[];
	maxRolesToDisplay = 4;

	constructor(private dialog: MatDialog, private renderer: Renderer2, private elementRef: ElementRef) {}

	ngOnInit(): void {
		this.tableColumns = tableColumns;
	}

	ngAfterViewInit() {
		this.updateMaxRolesToDisplay();
		window.addEventListener("resize", () => {
			this.updateMaxRolesToDisplay();
		});
	}

	editUser(itemData: User) {
		let user: User = { ...itemData };
		this.editUserSelectedEvent.emit(user);
	}

	changeStatus(event: MatSlideToggleChange, user: User) {
		let matSlideToggle: MatSlideToggle = event.source;
		if (!event.checked) {
			this.inactivateUser(user, matSlideToggle);
		} else {
			this.activateUser(user, matSlideToggle);
		}
	}

	inactivateUser(user: User, matSlideToggle: MatSlideToggle) {
		const dialogRef = this.dialog.open(DeleteDialogComponent, {
			panelClass: "delete-dialog-container",
			width: "660px",
			height: "412px",
			disableClose: true,
			autoFocus: true,
			data: {
				text: "Are you sure you want to inactivate the user?",
				btnYesText: "Yes, inactive"
			}
		});

		dialogRef.afterClosed().subscribe((data: boolean) => {
			if (data) {
				user.blocked = true;
				this.changeStatusEvent.emit(user);
			} else {
				user.blocked = false;
				matSlideToggle.checked = true;
			}
		});
	}

	showUserLog(itemData: User) {
		this.showUserLogInfo.emit(itemData);
	}

	activateUser(user: User, matSlideToggle: MatSlideToggle) {
		user.blocked = false;
		this.changeStatusEvent.emit(user);
	}
	updateMaxRolesToDisplay() {
		if (this.rolesContainer && this.rolesContainer.nativeElement) {
			const containerWidth = this.rolesContainer.nativeElement.clientWidth;
			if (containerWidth > 0) {
				const roleWidth = 70;
				const maxRolesToDisplay = Math.floor(containerWidth / roleWidth);
				this.maxRolesToDisplay = maxRolesToDisplay;
			}
		}
	}

	ngOnDestroy(): void {}
}
