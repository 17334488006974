import { Domain, referrerUrl } from "@shared/utils/helpers";
import config from "../../auth_config.json";
import packageJson from "../../package.json";

const connection = referrerUrl.endsWith(Domain.MAIN) ? config.connection : "";

export const environment = {
	appVersion: packageJson.version + "-dev",
	production: false,
	auth: {
		domain: config.domain,
		clientId: config.clientId,
		redirectUri: window.location.origin,
		audience: config.audience,
		connection,
		forgeRockDomain: config.forgeRockDomain
	},
	httpInterceptor: {
		allowedList: [`${config.apiUri}/*`]
	},
	apiUri: config.apiUri,
	showGlobalDashboard: true,
	typeEnvForgeRock: "rc-"
};
