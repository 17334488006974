import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, effect, input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";

import { PaginationOptions } from "@shared/constants/pagination";
import { getPagination } from "@store/index";
import { CustomerService } from "@shared/services/customer/customer.service";
import { Customer, DashboardData } from "@shared/models/customer.interface";
import { UpdateCustomerEntityGroupAction } from "src/app/store/actions/customerEntityGroupSelect.action";
import { AppState } from "src/app/store/models/state.model";
import { createCustomerLogoPath } from "@shared/utils/customer-logo-path.util";
import { ReportsService } from "@shared/services/reports/reports.service";

import {
	PayElectivePayGroupBeneficiaryData,
	PayElectivePayGroupBeneficiaryPagination
} from "src/app/shared/models/employee.interface";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
	selector: "app-customer-list",
	templateUrl: "./customer-list.component.html",
	styleUrls: ["./customer-list.component.scss"]
})
export class CustomerListComponent implements OnInit, OnDestroy {
	@Input() onlyApproved: boolean = true;
	@Input() isDashboard: boolean = false;
	@Input() payGroupStatuses: string = "";
	@Input() onlyWithLegalEnities: boolean = false;
	isCpNow = input(false);

	@Output() newCustomerSelectedEvent = new EventEmitter<Customer>();

	customers: Customer[] = [];
	pageSize: number = 10;
	pageIndex: number = 0;
	totalPageCount: number = 1;
	searchForm!: FormGroup;
	cpNowServicesForm!: FormGroup;
	destroy$ = new Subject<void>();
	payMethodsErrorCount: string[] = [];
	isCalendarPage = false;
	searchValue: string = "";
	paginationType: PaginationOptions = PaginationOptions.CUSTOMERS;
	fetchingCustomers = true;
	checkedToggle = false;

	selectedCustomer!: Customer;

	constructor(
		private reportsService: ReportsService,
		private sanitizer: DomSanitizer,
		private customerService: CustomerService,
		private fb: FormBuilder,
		private store: Store<AppState>,
		private router: Router
	) {
		this.setFlagForCalendarPage();
	}

	ngOnInit(): void {
		this.initForm();

		this.setupPaginationSubscription();
	}

	setupPaginationSubscription(): void {
		this.store.pipe(select(getPagination), takeUntil(this.destroy$)).subscribe({
			next: pagination => {
				if (pagination && pagination.hasOwnProperty("CUSTOMERS")) {
					const customersPagination = pagination["CUSTOMERS"];

					this.pageSize = customersPagination.pageSize;
					this.pageIndex = customersPagination.pageIndex;
					this.fetchingCustomers = true;
					this.searchCustomersWithPagination(
						this.searchValue,
						customersPagination.pageSize,
						customersPagination.pageIndex,
						this.payGroupStatuses
					);
				}
			}
		});
	}

	initForm(): void {
		this.searchForm = this.fb.group({
			search: new FormControl("")
		});

		this.searchForm
			.get("search")
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
			.subscribe(searchValue => {
				this.searchValue = searchValue;
				this.pageIndex = 0;
				this.fetchingCustomers = true;
				this.searchCustomersWithPagination(searchValue, this.pageSize, this.pageIndex, this.payGroupStatuses);
			});
	}

	searchCustomersWithPagination(
		searchValue: string,
		pageSize: number,
		pageIndex: number,
		payGroupStatuses: string
	): void {
		this.customerService
			.searchCustomersWithPagination(searchValue, pageSize, pageIndex, payGroupStatuses)
			.pipe(
				map(res => {
					if (this.isDashboard) {
						res.items.filter(customer => {
							this.getReportData(customer.id).subscribe(res => {
								res.filter(item => (customer.dashboardData = item));
							});
						});
					}

					this.totalPageCount = res.totalPages;
					res.items.map(customer => createCustomerLogoPath(customer));
					return res;
				})
			)
			.subscribe(res => {
				this.customers = res.items;
				this.fetchingCustomers = false;
			});
	}

	showLogo(imagePath: string): SafeUrl {
		return this.sanitizer.bypassSecurityTrustUrl(imagePath);
	}

	selectCustomer(customer: Customer): void {
		if (this.isCpNow()) {
			return;
		}
		this.store.dispatch(
			new UpdateCustomerEntityGroupAction({
				customerId: customer.id,
				customerName: customer.name,
				legalEntityId: "",
				payGroupId: ""
			})
		);
		this.newCustomerSelectedEvent.emit(customer);
	}

	getBeneficiariesReports(customerId: string): void {
		const paygroupId: string = "";
		this.reportsService
			.getBeneficiariesReports(paygroupId, customerId)
			.pipe(
				map((response: PayElectivePayGroupBeneficiaryPagination) => {
					return response.items;
				})
			)
			.subscribe((res: PayElectivePayGroupBeneficiaryData[]) => {
				if (res.length > 0 && res[0].statusCounts.INACTIVE > 0) this.payMethodsErrorCount.push(customerId);
			});
	}

	getReportData(customerId: string): Observable<DashboardData[]> {
		return this.customerService.getCustomersReportData(customerId);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	updateCustomer(cpNowValue: MatSlideToggleChange, customer: Customer): void {
		cpNowValue.source._onChangeEvent;
		this.checkedToggle = true;
		customer = { ...customer, cloudPayNow: cpNowValue.checked };
		this.selectedCustomer = customer;
		this.customerService.updateCustomer(customer).subscribe(_ => this.setupPaginationSubscription());
	}

	private setFlagForCalendarPage(): void {
		if (this.router.url === "/calendar") {
			this.isCalendarPage = true;
		}
	}
}
