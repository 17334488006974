import { Action } from "@ngrx/store";
import { GlobalDashboardFilterSelect } from "../models/globalDashboardFilterSelection.model";
import {
	IDashboardFiltersFormGroup,
	globalDashboardState
} from "@modules/global-dashboard/models/global-dashboard-interface";
import {
	GlobalDashboardFilterActionType,
	GlobalDashboardFilterSelectAction
} from "../actions/globalDashboardFilterSelect.action";
import { createRehydrateReducer } from "./state-persistance";

const initialState: GlobalDashboardFilterSelect = {
	globalDashboard: {
		kpiSelected: "",
		filters: {} as IDashboardFiltersFormGroup,
		isExpand: false
	}
};

const localStorageKey = "GlobalDashboardFilterSelectState";

export const globalDashboardFilterSelectReducerFn = (
	state = initialState,
	action: Action
): GlobalDashboardFilterSelect => {
	const selectAction = action as GlobalDashboardFilterSelectAction;

	switch (selectAction.type) {
		case GlobalDashboardFilterActionType.SET_SELECTED:
			return {
				...state,
				globalDashboard: selectAction.payload.globalDashboard
			};

		default:
			return state;
	}
};

// Create the final reducer by combining the rehydrate logic with the custom reducer function
export const globalDashboardFilterSelectReducer = createRehydrateReducer<GlobalDashboardFilterSelect, Action>(
	localStorageKey,
	globalDashboardFilterSelectReducerFn
);
