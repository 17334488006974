import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TppCatalogueComponent } from "./tpp-catalogue.component";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputAutocompleteModule } from "@shared/components/input-autocomplete/input-autocomplete.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { TppServiceComponent } from "./_components/tpp-service/tpp-service.component";
import { TppServiceCreationComponent } from "./_components/tpp-service/tpp-service-creation/tpp-service-creation.component";
import { MatCardModule } from "@angular/material/card";
import { MatPaginatorModule as MatPaginatorModule } from "@angular/material/paginator";
import { MatButtonModule as MatButtonModule } from "@angular/material/button";
import { SharedModule } from "@shared/shared.module";
import { OrderServicesTypePipe } from "./pipes/order-services-type.pipe";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { BankAccountFieldsModule } from "@shared/components/bank-account-fields/bank-account-fields.module";
import { TppSubServiceListComponent } from "./_components/tpp-service/tpp-sub-service-list/tpp-sub-service-list.component";
import { TppSubServiceItemComponent } from "./_components/tpp-service/tpp-sub-service-list/tpp-sub-service-item/tpp-sub-service-item.component";
import { SelectOptionsFromTppaccountPipe } from "./pipes/select-options-from-tppaccount.pipe";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { TppDescriptionDialogComponent } from "./_components/tpp-service/tpp-service-creation/tpp-description-dialog/tpp-description-dialog.component";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { MatMenuModule as MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule as MatTooltipModule } from "@angular/material/tooltip";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { TppCatalogueRoutingModule } from "./tpp-catalogue-routing.module";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { TppAddBeneficiaryModule } from "./_components/tpp-add-beneficiary/tpp-add-beneficiary.module";

@NgModule({
	declarations: [
		TppCatalogueComponent,
		TppServiceComponent,
		TppServiceCreationComponent,
		OrderServicesTypePipe,
		TppSubServiceListComponent,
		TppSubServiceItemComponent,
		SelectOptionsFromTppaccountPipe,
		TppDescriptionDialogComponent
	],
	imports: [
		TppCatalogueRoutingModule,
		CommonModule,
		SharedModule,
		InputWrapperModule,
		InputAutocompleteModule,
		InputSelectModule,
		FormsModule,
		ReactiveFormsModule,
		NoItemsLayoutModule,
		MatCardModule,
		MatPaginatorModule,
		MatButtonModule,
		FlexLayoutModule,
		ModalWrapperModule,
		MatDialogModule,
		BankAccountFieldsModule,
		MatExpansionModule,
		MatMenuModule,
		MatTooltipModule,
		PipesModule,
		BreadcrumbsModule,
		TppAddBeneficiaryModule
	],

	exports: [TppServiceCreationComponent, TppSubServiceListComponent, TppSubServiceItemComponent]
})
export class TppCatalogueModule {}
