import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { saveAs } from "file-saver";
import { Observable, Subject, forkJoin, of } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";

import { ToastService } from "@shared/services/toast/toast.service";
import { FileErorrDialogComponent } from "@modules/file-management/_components/file-error-dialog/file-erorr-dialog/file-erorr-dialog.component";
import { UserService } from "@modules/user-management/_services/user.service";
import { Milestone, PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { FileManagementUploadService } from "../../../../../shared/services/file-management/file-management-upload.service";
import { FileManagementService } from "../../../../../shared/services/file-management/file-management.service";
import {
	FileHistory,
	FileHistoryPagination,
	FileManagerPlaceholdersPagination,
	FileTypes
} from "../../../../../shared/models/file-manager";
import { PayGroup } from "../../../../../shared/models/pay-groups";
import { TppPaymentsApiService } from "../../../services/tpp-payments-api.service";
import { cancelReport, cancelReportWithPagination } from "../../../tpp-payments/models/tpp-payment.interface";

@Component({
	selector: "app-file-list",
	templateUrl: "./file-list.component.html",
	styleUrls: ["./file-list.component.scss"]
})
export class FileListComponent implements OnInit, OnDestroy {
	@Input() payGroup!: PayGroup;
	@Input() payCycleExternalId = "";
	@Input() payrollCycleId = "";
	@Input() payCycle!: PayCycle;
	@Input() milestone!: Milestone;

	@Output() onFileClick: EventEmitter<null> = new EventEmitter();
	@Output() resetStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() cancelStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() totalFileHistoryCount: EventEmitter<number> = new EventEmitter<number>();
	@Output() currentFileHistoryCount: EventEmitter<number> = new EventEmitter<number>();

	resetHistoryFormat$!: PayCycle;
	files$!: Observable<FileHistory[]>;
	history$!: Observable<FileHistory[]>;
	cancelReports!: cancelReport[];
	payCycle$!: Observable<PayCycle>;
	destroy$: Subject<void> = new Subject();
	canUploadFiles = false;
	lastResetIndex = 10;
	disableIntegrationButton = true;
	defaultFileHistoryCount = 20;
	fileHistoryCount = this.defaultFileHistoryCount;
	isFetching = true;

	constructor(
		public dialog: MatDialog,
		public userService: UserService,
		private fileManagerApiService: FileManagementService,
		private commonFileManagerService: FileManagementUploadService,
		private tppPaymentsApiService: TppPaymentsApiService,
		private toastService: ToastService
	) {}

	ngOnInit(): void {
		this.payCycle = history.state.payCycle;
		this.history$ = this.getFiles().pipe(take(1));
		this.commonFileManagerService.updateFileList$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.history$ = this.getFiles().pipe(take(1));
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * Use to show more file history list
	 * @param count: Number of file list to add on display
	 */
	loadMoreFileHistory(count: number) {
		this.fileHistoryCount += count;
		this.isFetching = true;
		this.history$ = this.getFiles().pipe(take(1));
	}

	getFiles(): Observable<FileHistory[]> {
		if (this.milestone.id) {
			let milestoneId = this.milestone.id;

			return forkJoin([
				this.fileManagerApiService.getFilesG2NFileHistoryByPaygroupIncludingPayCycleID(
					this.payGroup.id,
					this.payCycle.id,
					-1,
					0
				),
				this.tppPaymentsApiService.getCancelReport(this.payGroup.id, milestoneId, 1000, 0),
				this.fileManagerApiService.fetchG2NIntegrationHistory(this.payCycle.id)
			]).pipe(
				takeUntil(this.destroy$),
				map((data: [FileHistoryPagination, cancelReportWithPagination, FileManagerPlaceholdersPagination]) => {
					const [fileHistory, cancelReport, payrollIntegration] = data;

					// Normal History
					let history: FileHistory[] = fileHistory.items;

					// make deep copy
					// This prevents changes to fileResults from affecting initialFileResults, ensuring that initialFileResults retains the original data.
					const initialFileResults = JSON.parse(JSON.stringify(history));

					// Insert usernames into the 'history' array

					// Milestone Reset History
					for (let i = 0; i < this.milestone.resetHistory.length; i++) {
						history.push({
							userReset: this.milestone.resetHistory[i].userId,
							createdAt: this.milestone.resetHistory[i].resetAt
						});
					}
					// Cancel History
					if (cancelReport.items.length) {
						this.cancelReports = cancelReport.items;

						for (let i = 0; i < this.cancelReports.length; i++) {
							history.push({
								userCancelled: this.cancelReports[i].canceledBy,
								createdAt: this.cancelReports[i].canceledAt
							});
						}
					}

					// PayRoll placeholder data
					if (payrollIntegration?.items) {
						payrollIntegration?.items.forEach(payroll => {
							if (
								this.fileManagerApiService.isValidIntegrationTaskHistoryId(payroll, initialFileResults)
							) {
								let historyItem: FileHistory = {
									createdAt: new Date(payroll.createdAt),
									fileId: "",
									taskId: "payrollTest",
									fileName: "-",
									fileType: FileTypes.PAYROLL_INTEGRATION,
									status: payroll.status.name,
									integrationErrorMessages: []
								};

								if (!payroll.status.messages.length) {
									historyItem.integrationErrorMessages?.push({
										timeStamp: payroll.createdAt,
										fileName: "Payroll Integration",
										message: ""
									});
								} else {
									this.extractMessageFromError(payroll.status.messages).forEach(message => {
										historyItem.integrationErrorMessages?.push({
											timeStamp: payroll.createdAt,
											fileName: "Payroll Integration",
											message: message
										});
									});
								}

								history.push(historyItem);
							}
						});
					}

					//Sort by date
					const fileResults = history.sort(
						(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
					);

					// Check to see if reset banner should show
					if (fileResults[0]?.userReset) {
						this.resetStatus.emit(true);
						this.lastResetIndex = 0;
					}

					// Check to see if cancel banner should show
					if (fileResults[0]?.userCancelled) {
						this.cancelStatus.emit(true);
						this.lastResetIndex = 0;
					}

					// Check to see from where the files entries should be greyed out
					if (this.lastResetIndex !== 0) {
						let resetFound: boolean = false;
						let i: number = 0;
						while (i < fileResults.length && !resetFound) {
							if (fileResults[i].userReset || fileResults[i].userCancelled) {
								this.lastResetIndex = i;
								resetFound = true;
							}
							i++;
						}
					}

					this.isFetching = false;
					this.currentFileHistoryCount.emit(this.fileHistoryCount);
					this.totalFileHistoryCount.emit(fileResults.length);

					return fileResults.filter((f, i) => i < this.fileHistoryCount);
				})
			);
		} else {
			return of([]); // Return an empty observable if milestone.id is not set
		}
	}
	extractMessageFromError(messages: string[]): string[] {
		const defualtMessage =
			"Sorry, it seems the integration you're attempting isn't possible. Please reach out the administrator for assistance";

		let user_friendly_messages: string[] = [];

		if (!messages.length) return [defualtMessage];

		messages.forEach(message => {
			if (message.toLowerCase().includes("there is a transaction on the current run"))
				user_friendly_messages.push("There is a transaction on the current run");
			if (message.toLowerCase().includes("has not been approved by payroll for"))
				user_friendly_messages.push("Run has not been approved by payroll");
			if (message.toLowerCase().includes("payrollcycleid is not informed for"))
				user_friendly_messages.push("The Run ID is not informed");
		});

		if (!user_friendly_messages.length) user_friendly_messages.push(defualtMessage);

		return user_friendly_messages;
	}

	hasRecordOfAutomaticIntegration(fileHistory: FileHistory[]): boolean {
		return fileHistory.filter((file: FileHistory) => !this.isXlsxFile(file.fileName!)).length > 0;
	}

	getUserName(id: string): Observable<string> {
		return this.userService.getUserById(id).pipe(
			takeUntil(this.destroy$),
			map(user => user.firstName + " " + user.lastName)
		);
	}

	refreshFiles(): void {
		this.fileHistoryCount = this.defaultFileHistoryCount;
		this.isFetching = true;
		this.history$ = this.getFiles().pipe(take(1));
	}

	viewErrors(file: FileHistory): void {
		const dialogRef = this.dialog.open(FileErorrDialogComponent, {
			width: "995px",
			height: "570px",
			panelClass: "dialog-container",
			data: {
				taskId: file.taskId,
				errorType: file.fileType,
				payGroup: this.payGroup,
				integrationErrorMessages: file.integrationErrorMessages ? file.integrationErrorMessages : {}
			}
		});
	}

	downloadFile(fileId: string, fileName: string): void {
		this.fileManagerApiService
			.getFileObject(fileId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(buffer => {
				const data: Blob = new Blob([buffer], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				});

				saveAs(data, fileName);
			});
	}

	fileClick() {
		this.onFileClick.emit();
	}

	runPayrollIntegration(): void {
		this.fileManagerApiService
			.refreshPayrollIntegrationNets(this.payCycle.id)
			.pipe(take(1))
			.subscribe({
				next: res => {
					this.toastService.showSuccess("Request to trigger payroll integration was sent sucessfully");
					this.history$ = this.getFiles().pipe(take(1));
				},
				error: _ => {
					this.toastService.showError(
						"An error has occured while trying to send a request to trigger the payroll integration"
					);
				}
			});
	}

	viewLogs(taskId: string | undefined): void {
		const dialogRef = this.dialog.open(FileErorrDialogComponent, {
			width: "995px",
			height: "570px",
			panelClass: "dialog-container",
			data: {
				taskId: taskId,
				errorType: "G2N",
				payGroup: this.payGroup
			}
		});

		dialogRef.afterClosed().subscribe();
	}

	isXlsxFile(fileName: string): boolean {
		return fileName.toLowerCase().includes(".xlsx");
	}
}
