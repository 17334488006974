<table mat-table [dataSource]="dataSourceEvents.data" class="events-table" *ngIf="headers">
	<ng-container matColumnDef="select">
		<th mat-header-cell *matHeaderCellDef>&nbsp;</th>
		<td class="data-text first-column" mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
			<ng-template
				[appUserRolePermissions]
				[action]="'hide'"
				[roles]="['CLIENT', 'TS_VO', 'TS_OPERATION_CONSULTANT', 'TS_IMPLEMENTATION_LEAD', 'TS_OCB']"
			>
				<mat-checkbox
					class="example-margin"
					*ngIf="element.milestoneDetails"
					(click)="$event.stopPropagation()"
					(change)="selectEventHandler(element, $event.checked)"
					[disabled]="isHidingDisabled(element)"
				></mat-checkbox>
			</ng-template>
		</td>
	</ng-container>

	<ng-container [matColumnDef]="headers[1]">
		<th mat-header-cell *matHeaderCellDef>{{ headers[1] }}</th>
		<td mat-cell *matCellDef="let element; let i = index" class="second-column">
			<div class="customerDetail">
				<span
					id="customer-cell-paygroup-text-{{ i }}"
					class="paygroup text"
					[matTooltip]="element.payGroupDetails.name + ' - ' + element.payGroupDetails.externalId"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="'tooltip-events'"
				>
					{{ element.payGroupDetails.name }} {{ element.payGroupDetails.externalId }}
				</span>
				<span
					id="customer-cell-customer-text-{{ i }}"
					class="customer text"
					[matTooltip]="element.customerDetails.name"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="'tooltip-events'"
				>
					{{ element.customerDetails.name }}
				</span>
			</div>
		</td>
	</ng-container>

	<ng-container matColumnDef="Paycycle/run">
		<th mat-header-cell *matHeaderCellDef>Paycycle/run</th>
		<td class="data-text third-column" mat-cell *matCellDef="let element; let i = index">
			<span
				id="paycycle-cell-{{ i }}"
				[matTooltip]="element.payCycleDetails.name"
				[matTooltipPosition]="'above'"
				[matTooltipClass]="'tooltip-events'"
				class="paycycle text"
				>{{ element.payCycleDetails.name }}</span
			>
		</td>
	</ng-container>

	<ng-container matColumnDef="Payments">
		<th mat-header-cell *matHeaderCellDef>Payments</th>
		<td class="data-text fourth-column" mat-cell *matCellDef="let element; let i = index">
			<div class="group-container">
				<span id="payments-cell-{{ i }}" class="group text">{{ element.group }} Payments</span>
			</div>
		</td>
	</ng-container>

	<ng-container matColumnDef="Milestone">
		<th mat-header-cell *matHeaderCellDef>Milestone</th>

		<ng-container *matCellDef="let element; let i = index">
			<td *ngIf="element.milestoneDetails" class="data-text fifth-column" mat-cell>
				<span class="type" *ngIf="element.milestoneDetails">
					<app-icon
						class="icon"
						[name]="element.milestoneDetails!.type | milestoneTypeToIcon"
						[size]="'28px'"
						[color]="'var(--color-disabled)'"
					>
					</app-icon>

					<span
						id="milestone-cell-{{ i }}"
						class="milestone text"
						[matTooltip]="element.milestoneDetails | milestoneTypes"
						[matTooltipPosition]="'above'"
						[matTooltipClass]="'tooltip-events'"
						>{{ element.milestoneDetails | milestoneTypes }}</span
					>
				</span>
			</td>

			<td *ngIf="!element.milestoneDetails" mat-cell class="data-text" colspan="4">
				<span class="no-milestone-found text"><i>Awaiting Milestone Date</i></span>
			</td>
		</ng-container>
	</ng-container>

	<ng-container matColumnDef="Status">
		<th mat-header-cell *matHeaderCellDef>Status</th>
		<td
			[ngClass]="{ hidden: !element.milestoneDetails }"
			class="data-text sixth-column"
			mat-cell
			*matCellDef="let element; let i = index"
		>
			<app-milestone-status-dropdown
				*ngIf="element.milestoneDetails"
				[_milestone]="element"
				(updateStatus)="updateStatus.emit()"
				[locatorIndex]="i"
			></app-milestone-status-dropdown>
		</td>
	</ng-container>

	<ng-container matColumnDef="Deadline">
		<th mat-header-cell *matHeaderCellDef>Deadline</th>
		<td
			[ngClass]="{ hidden: !element.milestoneDetails }"
			class="data-text seventh-column"
			mat-cell
			*matCellDef="let element; let i = index"
		>
			<span
				id="deadline-cell-{{ i }}"
				class="deadline text"
				*ngIf="element.milestoneDetails"
				[matTooltip]="(element.milestoneDetails.deadline | date : 'MMM d, yyyy')!"
				[matTooltipPosition]="'above'"
				[matTooltipClass]="'tooltip-events'"
			>
				{{ element.milestoneDetails.deadline | date : "MMM d, yyyy" }}
			</span>
		</td>
	</ng-container>

	<ng-container matColumnDef="More">
		<th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
		<td
			[ngClass]="{ hidden: !element.milestoneDetails }"
			mat-cell
			*matCellDef="let element; let i = index"
			class="button-container eighth-column"
		>
			<button
				id="more-button-cell-{{ i }}"
				*ngIf="element.milestoneDetails"
				mat-icon-button
				class="more-button"
				aria-label="expand row"
				element="element"
				(click)="$event.stopPropagation()"
				[cdkMenuTriggerFor]="menu"
				mat-button
			>
				<app-icon class="more-icon" [name]="'more'" [size]="'24px'" [lineWidth]="'1.5px'"></app-icon>
			</button>
			<ng-template #menu>
				<div class="sub-menu flex flex-column" cdkMenu>
					<button
						class="event-actions"
						cdkMenuItem
						(click)="updateMilestoneEventHistoryLogSubject(element); $event.stopPropagation()"
					>
						<span class="button-text">
							<app-icon
								class="button-icon"
								[name]="'history-log'"
								[color]="'black'"
								[lineWidth]="'1px'"
								[size]="'24'"
							></app-icon>
							<span class="text-spacing">History Log</span>
						</span>
					</button>
					<ng-template
						[appUserRolePermissions]
						[action]="'hide'"
						[roles]="['CLIENT', 'TS_VO', 'TS_OPERATION_CONSULTANT', 'TS_IMPLEMENTATION_LEAD', 'TS_OCB']"
					>
						<button
							class="event-actions"
							cdkMenuItem
							(click)="hideEventHandler(element)"
							[disabled]="isHidingDisabled(element)"
						>
							<span class="button-text">
								<app-icon
									class="button-icon"
									[name]="'eye-slash'"
									[color]="'black'"
									[lineWidth]="'1px'"
									[size]="'24'"
								></app-icon>
								<span class="text-spacing">Hide Event</span>
							</span>
						</button>
					</ng-template>
					<button
						class="event-actions"
						cdkMenuItem
						(click)="
							goToDashboard(element.payGroupDetails.id, element.payCycleDetails.id, element.group);
							$event.stopPropagation()
						"
					>
						<span class="button-text">
							<app-icon
								class="button-icon"
								[name]="'category'"
								[color]="'black'"
								[lineWidth]="'1px'"
								[size]="'24'"
							></app-icon>
							<span class="text-spacing">Go to Dashboard</span>
						</span>
					</button>
				</div>
			</ng-template>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="headers"></tr>
	<tr
		mat-row
		*matRowDef="let row; columns: headers"
		[ngStyle]="{
			cursor: !row.milestoneDetails || row.milestoneDetails?.type === 'FUNDING_DATE' ? 'default' : 'pointer'
		}"
		(click)="row.milestoneDetails ? routeToPayments(row) : null"
		class="event-list"
	></tr>
</table>
