import { EventEmitter, Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

import { ServiceProviderService } from "../../../shared/services/service-provider/service-provider.service";
import { ToastService } from "../../../shared/services/toast/toast.service";
import {
	Employee,
	EmployeeCreateDTO,
	EmployeeCreateRawFormData,
	EmployeeUpdateDTO
} from "../../../shared/models/employee.interface";
import { Ranking } from "../types/accounts";
import { PayGroup } from "../../../shared/models/pay-groups";
import { SelectOption } from "../../../shared/models/select-option.interface";
import { ApiService } from "../../pay-groups/services/api.service";
import { PayElectiveApiService } from "./pay-elective-api.service";
import { Transaction } from "src/app/shared/models/payouts.types";
import { PayElectiveAdapterService } from "@shared/services/adapter/pay-elective/pay-elective-adapter.service";
import { ModalLauncherService } from "@shared/components/wpay-ui/modal/modal-launcher.service";
import { PayElectiveHistoryModalData } from "@shared/components/wpay-ui/modal/templates/pay-elective-history/model/PayElectiveHistoryModalData";
import { EntityPaygroupSelectDialogComponent } from "@shared/components/entity-paygroup-select-dialog/entity-paygroup-select-dialog.component";

@Injectable({
	providedIn: "root"
})
export class PayElectiveCommonService {
	employeeStored$: EventEmitter<Employee> = new EventEmitter<Employee>();
	entityPayGroupSelection$: EventEmitter<PayGroup> = new EventEmitter<PayGroup>();

	showUpdateBannerCount: number = 0;

	constructor(
		private payElectiveApiService: PayElectiveApiService,
		private payGroupService: ApiService,
		private toast: ToastService,
		public dialog: MatDialog,
		private serviceProviderService: ServiceProviderService,
		private payElectiveAdapterServices: PayElectiveAdapterService,
		private modalLauncherService: ModalLauncherService
	) {}

	getStatusOptions(): SelectOption[] {
		return [
			{ value: "all", text: "All Status" },
			{ value: "INACTIVE", text: "Failed" },
			{ value: "ACTIVE", text: "Success" }
		];
	}

	getProviderOptions(): Observable<SelectOption[]> {
		//get providers
		return this.serviceProviderService.getProvidersAsSelectOptions();
	}

	openDialog(customerId: string, legalEntityId: string, payGroup: PayGroup, employeeId: string): void {
		const dialogRef = this.dialog.open(EntityPaygroupSelectDialogComponent, {
			panelClass: "select-modalbox",
			width: "660px",
			data: {
				customerId,
				legalEntityId,
				payGroup,
				employeeId
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.payGroupService
					.getPayGroup(result.payGroup)
					.subscribe(payGroup => this.entityPayGroupSelection$.emit(payGroup));
			}
		});
	}

	openHistoryDialog(payMethodId: string, providerName: string): void {
		this.payElectiveAdapterServices
			.getHistoryModalData(payMethodId, providerName === "all" ? "" : providerName)
			.pipe(take(1))
			.subscribe((historyModalData: PayElectiveHistoryModalData) => {
				this.modalLauncherService.showPayElectiveHistoryModal(historyModalData);
			});
	}

	requireCheckboxesToBeCheckedValidator(formGroup: FormGroup, minRequired = 1): boolean {
		let checked = 0;

		Object.keys(formGroup.controls).filter(key => {
			const control = formGroup.controls[key];

			if (control.value === true) {
				checked++;
			}
		});

		if (checked >= minRequired) {
			return true;
		}

		return false;
	}

	storePayElective(form: EmployeeCreateRawFormData, isEdit: boolean): void {
		if (isEdit) {
			const employee: EmployeeUpdateDTO = {
				id: form.id!,
				version: form.version!,
				externalId: form.externalId,
				payGroupId: form.payGroupId,
				data: { ...form }
			};

			this.payElectiveApiService.updateEmployee(employee).subscribe({
				next: employee => {
					this.toast.showSuccess("Employee updated successfully");
					this.employeeStored$.emit(employee);
				},
				error: err => {
					if (err.status === 409) {
						this.toast.showError("Error updating employee. Duplicated employee ID");
						return;
					}
					this.toast.showError("Could not update employee");
				}
			});
		} else {
			const employee: EmployeeCreateDTO = {
				externalId: form.externalId,
				payGroupId: form.payGroupId,
				data: { ...form }
			};

			this.payElectiveApiService.createNewEmployee(employee).subscribe({
				next: employee => {
					this.toast.showSuccess("Employee created successfully");
					this.employeeStored$.emit(employee);
				},
				error: err => {
					if (err.status === 409) {
						this.toast.showError("Error creating employee. Duplicated employee ID");
						return;
					}
					this.toast.showError("Could not create employee");
				}
			});
		}
	}

	getShowUpdateBannerCount() {
		return this.showUpdateBannerCount;
	}

	resetShowUpdateRankBannerCount(): void {
		this.showUpdateBannerCount = 0;
	}

	allRemainingCheck(type: string, amount: number, index: number, payMethodsArrayLength: number): Ranking {
		let rankingObject: Ranking;

		let bannerShowCount: number = 0;

		if (type === "ALL_REMAINING") {
			if (index < payMethodsArrayLength - 1) {
				// all remaining to top"
				rankingObject = {
					type: "AMOUNT",
					amount: 0,
					rank: index
				};

				this.showUpdateBannerCount++;
			} else if (index === payMethodsArrayLength - 1) {
				// all remaining to bottom"
				rankingObject = {
					type: "ALL_REMAINING",
					amount: 0,
					rank: index
				};
			}
		} else if (type !== "ALL_REMAINING") {
			if (index === payMethodsArrayLength - 1) {
				// "not all remaining to bottom"
				rankingObject = {
					type: "ALL_REMAINING",
					amount: 0,
					rank: index
				};
			} else if (index < payMethodsArrayLength - 1) {
				//"notall remaining to top"
				rankingObject = {
					type: type,
					amount: amount,
					rank: index
				};
			}
		}

		return rankingObject!;
	}

	updateToStatus(
		effectiveTo: Date,
		netCheckBox: boolean,
		tppCheckBox: boolean,
		ewaCheckBox: boolean,
		fallback: boolean
	) {
		let updateTo: string;

		if (effectiveTo === null || effectiveTo.toString() === "") {
			// if we dont have an effective to date then this account will be valid
			updateTo = this.paymentTypeCheck(netCheckBox, tppCheckBox, ewaCheckBox, fallback);
		} else {
			// if we do have the date then check if its in future or past or today
			if (this.dateIsToday(effectiveTo) || !this.dateIsInFuture(effectiveTo)) {
				// if the effective to date is today or NOT in the future this account will be invalid
				updateTo = "invalid";
			} else {
				updateTo = this.paymentTypeCheck(netCheckBox, tppCheckBox, ewaCheckBox, fallback);
			}
		}

		return updateTo;
	}

	dateIsInFuture(effectiveToDate: Date | null): boolean {
		if (!effectiveToDate) {
			return true;
		}
		let isInTheFuture: boolean = false;

		let effectiveTo = new Date(effectiveToDate);

		let now = new Date();

		switch (effectiveTo > now) {
			case true:
				isInTheFuture = true;
				break;
			case false:
				isInTheFuture = false;
				break;
		}

		return isInTheFuture;
	}

	dateIsToday(effectiveToDate: Date | null): boolean {
		if (!effectiveToDate) {
			return true;
		}
		let isToday: boolean = false;

		let effectiveTo = new Date(effectiveToDate);

		let now = new Date();

		switch (effectiveTo === now) {
			case true:
				isToday = true;
				break;
			case false:
				isToday = false;
				break;
		}

		return isToday;
	}

	paymentTypeCheck(net: Boolean, tpp: Boolean, ewa: Boolean, fallback: boolean): string {
		let payType: string;

		if (
			(net === true && tpp === false) ||
			(net === true && tpp === true) ||
			(net === true && ewa === true) ||
			(net === true && fallback === true)
		) {
			payType = "nets";
		} else if (
			(net === false && tpp === true) ||
			(net === false && tpp === true && fallback === true) ||
			fallback === true
		) {
			payType = "tpp";
		} else if ((net === false && ewa === true) || (net === false && ewa === true)) {
			payType = "ewa";
		}

		return payType!;
	}

	filterTransactions(tranasctions: Transaction[]): Transaction[] {
		const transactionsMap: Map<string, Transaction[]> =
			tranasctions.length > 0 ? this.groupTransactionsForPaymentMethods(tranasctions) : new Map();
		const idsFallbackTransactions: string[] =
			this.getTransactionsIdsForPayoutsWithPaidToFallbackStatus(tranasctions);
		const transactionsFilter: Transaction[] = this.getArrayWithDiferenteTransactions(
			transactionsMap,
			idsFallbackTransactions
		);

		return transactionsFilter;
	}

	getNewTransactionWithSamePaymentMethod(transactionsSamePayMethod: Transaction[]): Transaction[] {
		let date: Date;

		transactionsSamePayMethod.forEach((item: Transaction) => {
			if (!date) date = new Date(item.createdAt);

			if (date && date.getTime() < new Date(item.createdAt).getTime()) date = new Date(item.createdAt);
		});

		const transaction: Transaction | undefined = transactionsSamePayMethod.find(
			(item: Transaction) => new Date(item.createdAt).getTime() === date.getTime()
		);

		const transactions: Transaction[] = [];
		if (transaction) transactions.push(transaction);

		return transactions;
	}

	getArrayWithDiferenteTransactions(
		transactionMapByPMId: Map<string, Transaction[]>,
		idsFallbackTransactions: string[]
	): Transaction[] {
		let transactions: Transaction[] = [];

		transactionMapByPMId.forEach((value: Transaction[]) => {
			transactions = transactions.concat(
				this.splitTransactionsWithSamePaymentMethod(value, idsFallbackTransactions)
			);
		});
		return transactions;
	}

	splitTransactionsWithSamePaymentMethod(
		transactionsSameMP: Transaction[],
		idsFallbackTransactions: string[]
	): Transaction[] {
		const transactionsSplit: Transaction[] = [];
		const transactionsNoFallback: Transaction[] = [];

		if (transactionsSameMP.length === 1) transactionsSplit.push(transactionsSameMP[0]);
		else if (transactionsSameMP.length > 1) {
			idsFallbackTransactions.forEach((id: string) => {
				const filterid: Transaction[] = transactionsSameMP.filter((tx: Transaction) => tx.id === id);
				if (filterid.length > 0)
					transactionsSplit.push(this.getNewTransactionWithSamePaymentMethod(filterid)[0]);
			});

			transactionsSameMP.forEach((tx: Transaction) => {
				const isId: boolean = idsFallbackTransactions.includes(tx.id);
				if (!isId) transactionsNoFallback.push(tx);
			});

			transactionsSplit.push(this.getNewTransactionWithSamePaymentMethod(transactionsNoFallback)[0]);
		}

		return transactionsSplit;
	}

	getTransactionsIdsForPayoutsWithPaidToFallbackStatus(transactionsList: Transaction[]): string[] {
		const transactionsIds: string[] = [];
		const status = "PAID_TO_FALLBACK";

		const transactionsFallback: Transaction[] = transactionsList.filter(
			(tx: Transaction) => tx.status.name === status
		);

		transactionsFallback.forEach((tx: Transaction) => {
			if (transactionsIds.length == 0 && tx.attributes["FALLBACK_TRANSACTION_ID"])
				transactionsIds.push(tx.attributes["FALLBACK_TRANSACTION_ID"]);

			if (
				transactionsIds.length > 0 &&
				tx.attributes["FALLBACK_TRANSACTION_ID"] &&
				!transactionsIds.includes(tx.attributes["FALLBACK_TRANSACTION_ID"])
			)
				transactionsIds.push(tx.attributes["FALLBACK_TRANSACTION_ID"]);
		});

		return transactionsIds;
	}

	groupTransactionsForPaymentMethods(tranasctions: Transaction[]): Map<string, Transaction[] | undefined> {
		const transactionsMap: Map<string, Transaction[] | undefined> = new Map();

		tranasctions.forEach((item: Transaction) => {
			if (item.status.name != "PAID_TO_FALLBACK") {
				if (transactionsMap.has(item.payMethodId)) {
					const arrayTransactions = transactionsMap.get(item.payMethodId);
					arrayTransactions!.push(item);
					transactionsMap.set(item.payMethodId, arrayTransactions);
				} else transactionsMap.set(item.payMethodId, [item]);
			}
		});

		return transactionsMap;
	}
}
