import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ApiService } from "@modules/pay-groups/services/api.service";
import { CustomerService } from "@shared/services/customer/customer.service";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { LegalEntityPagination } from "@shared/models/legal-entity.interface";
import { PayGroup } from "@shared/models/pay-groups";
import { createISOStringFromDateWithoutHours } from "@shared/utils/date.util";
import { Observable, Subject, catchError, map, of, switchMap, take, takeUntil } from "rxjs";
import { CustomerCacheService } from "@shared/services/customer/customer-cache.service";
import { CustomerPagination } from "src/app/shared/models/customer.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { environment } from "src/environments/environment";
import {
	CardDetails,
	CustomerTableDetailItem,
	CustomerTableDetailWithPagination,
	DashboardFilters,
	EventsTableDetailsWithPagination,
	FailedBeneficiariesTableDetailWithPagination,
	FailedPaymentOrdersTableDetailWithPagination,
	FailedTransactionsTableDetailWithPagination,
	KpiData,
	LegalEntityDetailsWithPaygroups,
	MissingBankAccountsTableDetailWithPagination,
	MissingCardTableDetailWithPagination,
	PayGroupDetails,
	PaygroupTableDetailItem,
	PaygroupTableDetailWithPagination
} from "../models/global-dashboard-interface";
import { DataFormattingService } from "./data-fomatting.service";

@Injectable({
	providedIn: "root"
})
export class GlobalDashboardApiService {
	private cancelPreviousRequest: Subject<void> = new Subject<void>();

	constructor(
		private http: HttpClient,
		private customerCache: CustomerCacheService,
		private customerService: CustomerService,
		private legalEntityService: LegalEntityService,
		private payGroupService: ApiService,
		private dataFormattingService: DataFormattingService
	) {}

	getCustomerSelectOptions(pageSize: number, pageNumber: number): Observable<SelectOption[]> {
		return this.customerCache.getCustomersMapper();
	}

	getGroupSelectionOptions(): SelectOption[] {
		return [
			{
				text: "GROUP NETS",
				value: "NETS"
			},
			{
				text: "GROUP TPP1",
				value: "TPP1"
			},
			{
				text: "GROUP TPP2",
				value: "TPP2"
			},
			{
				text: "GROUP TPP3",
				value: "TPP3"
			},
			{
				text: "GROUP TPP4",
				value: "TPP4"
			}
		];
	}

	getStatusSelectionOptions(): SelectOption[] {
		return [
			{
				text: "Pending",
				value: "PENDING"
			},
			{
				text: "In Progress",
				value: "IN_PROGRESS"
			},
			{
				text: "Complete",
				value: "COMPLETE"
			},
			{
				text: "Has Errors",
				value: "ERROR"
			}
		];
	}

	getCardDetails(formData: DashboardFilters, availableFilters: string[]): Observable<CardDetails[]> {
		const customerIds = formData.customers.join(",");
		const legalEntityIds = formData.legalEntities.join(",");
		const payGroupIds = formData.paygroups.join(",");
		const milestoneGroups = formData.groups.join(",");
		const deadline = formData.deadline ? createISOStringFromDateWithoutHours(formData.deadline) : "";

		let queryParams: string = "";

		if (availableFilters.includes("first"))
			queryParams = `customerIds=${customerIds}&legalEntityIds=${legalEntityIds}&payGroupIds=${payGroupIds}`;
		if (availableFilters.includes("second"))
			queryParams += `&milestoneGroups=${milestoneGroups}&deadline=${deadline}`;

		return this.http
			.get<KpiData>(`${environment.apiUri}/v1/customers/dashboard/kpi-card-count/reports?${queryParams}`)
			.pipe(
				map((kpiData: KpiData) => {
					const cardDetails: CardDetails[] = [];
					const kpiOrderKeys = ["beneficiaries", "cards", "banks", "dataInputMilestones", "transactions"];

					// Iterate through the keys of the KpiData object
					kpiOrderKeys.forEach(key => {
						if (kpiData.hasOwnProperty(key)) {
							const item = kpiData[key];
							const count = item.count;
							const totalCount = item.totalCount;

							// Calculate the percentage
							const percentage = totalCount !== 0 ? (count / totalCount) * 100 : 0;

							const kpiType: KpiData = {
								[this.dataFormattingService.getKpiTypeFromString(key)]: {
									count: count,
									totalCount: totalCount,
									percentage: percentage
								}
							};

							const cardDetail: CardDetails = {
								kpiType: kpiType,
								icon: this.dataFormattingService.getCardsDetails(key, true),
								theme: this.dataFormattingService.getCardsDetails(key, false)
							};

							cardDetails.push(cardDetail);
						}
					});

					return cardDetails;
				}),
				takeUntil(this.cancelPreviousRequest)
			);
	}

	getMissingCardsTableDetails(
		formData: DashboardFilters,
		pageSize: number,
		pageNumber: number
	): Observable<MissingCardTableDetailWithPagination | { failed: boolean }> {
		const customerIds = formData.customers.join(",");
		const legalEntityIds = formData.legalEntities.join(",");
		const payGroupIds = formData.paygroups.join(",");
		const queryParams = `pageSize=${pageSize}&pageNumber=${pageNumber}&customerIds=${customerIds}&legalEntityIds=${legalEntityIds}&payGroupIds=${payGroupIds}`;

		return this.http
			.get<MissingCardTableDetailWithPagination>(
				`${environment.apiUri}/v1/beneficiaries/missing-cards/report?${queryParams}`
			)
			.pipe(
				switchMap(response => {
					return of(response); // Continue with the new request's response
				}),
				catchError(error => {
					return of({ failed: true }); // Return a failed boolean in case of error
				}),
				takeUntil(this.cancelPreviousRequest)
			);
	}

	getMissingBankAccountsTableDetails(
		formData: DashboardFilters,
		pageSize: number,
		pageNumber: number
	): Observable<MissingBankAccountsTableDetailWithPagination | { failed: boolean }> {
		const customerIds = formData.customers.join(",");
		const legalEntityIds = formData.legalEntities.join(",");
		const payGroupIds = formData.paygroups.join(",");
		const queryParams = `pageSize=${pageSize}&pageNumber=${pageNumber}&customerIds=${customerIds}&legalEntityIds=${legalEntityIds}&payGroupIds=${payGroupIds}`;

		return this.http
			.get<MissingBankAccountsTableDetailWithPagination>(
				`${environment.apiUri}/v1/beneficiaries/missing-bank-accounts/report?${queryParams}`
			)
			.pipe(
				switchMap(response => {
					return of(response); // Continue with the new request's response
				}),
				catchError(error => {
					return of({ failed: true }); // Return a failed boolean in case of error
				}),
				takeUntil(this.cancelPreviousRequest)
			);
	}

	getFailedBeneficiariesTableDetails(
		formData: DashboardFilters,
		pageSize: number,
		pageNumber: number
	): Observable<FailedBeneficiariesTableDetailWithPagination | { failed: boolean }> {
		const customerIds = formData.customers.join(",");
		const legalEntityIds = formData.legalEntities.join(",");
		const payGroupIds = formData.paygroups.join(",");
		const queryParams = `pageSize=${pageSize}&pageNumber=${pageNumber}&customerIds=${customerIds}&legalEntityIds=${legalEntityIds}&payGroupIds=${payGroupIds}`;

		return this.http
			.get<FailedBeneficiariesTableDetailWithPagination>(
				`${environment.apiUri}/v1/beneficiaries/failed-beneficiaries/report?${queryParams}`
			)
			.pipe(
				switchMap(response => {
					return of(response); // Continue with the new request's response
				}),
				catchError(error => {
					return of({ failed: true }); // Return a failed boolean in case of error
				}),
				takeUntil(this.cancelPreviousRequest)
			);
	}

	getFailedTransactionsTableDetails(
		formData: DashboardFilters,
		pageSize: number,
		pageNumber: number
	): Observable<FailedTransactionsTableDetailWithPagination | { failed: boolean }> {
		const customerIds = formData.customers.join(",");
		const legalEntityIds = formData.legalEntities.join(",");
		const payGroupIds = formData.paygroups.join(",");
		const milestoneGroups = formData.groups.join(",");
		const deadline = formData.deadline ? createISOStringFromDateWithoutHours(formData.deadline) : "";

		const queryParams = `pageSize=${pageSize}&pageNumber=${pageNumber}&customerIds=${customerIds}&legalEntityIds=${legalEntityIds}&payGroupIds=${payGroupIds}&milestoneGroups=${milestoneGroups}&deadline=${deadline}`;

		return this.http
			.get<FailedTransactionsTableDetailWithPagination>(
				`${environment.apiUri}/v1/payouts/failed-transactions-search/report?${queryParams}`
			)
			.pipe(
				switchMap(response => {
					return of(response); // Continue with the new request's response
				}),
				catchError(error => {
					return of({ failed: true }); // Return a failed boolean in case of error
				}),
				takeUntil(this.cancelPreviousRequest)
			);
	}

	getFailedPaymentOrdersTableDetails(
		formData: DashboardFilters,
		pageSize: number,
		pageNumber: number
	): Observable<FailedPaymentOrdersTableDetailWithPagination | { failed: boolean }> {
		const customerIds = formData.customers.join(",");
		const legalEntityIds = formData.legalEntities.join(",");
		const payGroupIds = formData.paygroups.join(",");
		const milestoneGroups = formData.groups.join(",");
		const deadline = formData.deadline ? createISOStringFromDateWithoutHours(formData.deadline) : "";

		const queryParams = `pageSize=${pageSize}&pageNumber=${pageNumber}&customerIds=${customerIds}&legalEntityIds=${legalEntityIds}&payGroupIds=${payGroupIds}&milestoneGroups=${milestoneGroups}&deadline=${deadline}`;

		return this.http
			.get<FailedPaymentOrdersTableDetailWithPagination>(
				`${environment.apiUri}/v1/uploading/failed-data-input-milestones/report?${queryParams}`
			)
			.pipe(
				switchMap(response => {
					return of(response); // Continue with the new request's response
				}),
				catchError(error => {
					return of({ failed: true }); // Return a failed boolean in case of error
				}),
				takeUntil(this.cancelPreviousRequest)
			);
	}

	getEventsTableDetails(
		formData: DashboardFilters,
		pageSize: number,
		pageNumber: number
	): Observable<EventsTableDetailsWithPagination | { failed: boolean }> {
		const customerIds = formData.customers;
		const legalEntityIds = formData.legalEntities;
		const payGroupIds = formData.paygroups;
		const milestoneGroups = formData.groups;
		const deadline = formData.deadline ? createISOStringFromDateWithoutHours(formData.deadline) : "";
		const milestoneStatuses = formData.statuses;
		const milestoneTypes = formData.milestoneTypes;

		//Add Milestone Status and status filter

		const payload = {
			pageNumber,
			pageSize,
			customerIds,
			legalEntityIds,
			payGroupIds,
			milestoneGroups,
			deadline,
			milestoneStatuses,
			milestoneTypes
		};

		return this.http
			.post<EventsTableDetailsWithPagination>(
				`${environment.apiUri}/v1/customers/dashboard/milestones/reports`,
				payload
			)
			.pipe(
				switchMap(response => {
					return of(response); // Continue with the new request's response
				}),
				catchError(error => {
					return of({ failed: true }); // Return a failed boolean in case of error
				}),
				takeUntil(this.cancelPreviousRequest)
			);
	}

	getCustomerDetails(
		customerids: string[],
		pageSize: number,
		pageNumber: number,
		legalEntitiesSelected: string[],
		legalEntityData: SelectOption[],
		paygroupsSelected: string[],
		paygroupData: SelectOption[]
	): Observable<CustomerTableDetailWithPagination | { failed: boolean }> {
		let customerData: CustomerTableDetailWithPagination = {} as CustomerTableDetailWithPagination;

		return this.customerService.getCustomersFilteredByIdsWithPagination(pageSize, pageNumber, customerids).pipe(
			map((customersWithPagination: CustomerPagination) => {
				customerData.totalPages = customersWithPagination.totalPages;
				customerData.currentPage = customersWithPagination.pageNumber;
				customerData.totalCount = customersWithPagination.totalCount;
				customerData.items = [];

				customersWithPagination.items.forEach(customer => {
					const customerInfo: CustomerTableDetailItem = {
						customerDetails: {
							id: customer.id,
							externalId: customer.externalId,
							name: customer.name,
							logo: customer.logo,
							imagePath: customer.imagePath,
							legalEntityCount:
								legalEntitiesSelected.length > 0
									? this.getLegalEntitiyCount(customer.id, legalEntityData, legalEntitiesSelected)
									: customer.legalEntityCount,
							payGroupCount: this.getPaygroupCount(
								customer.id,
								customer.payGroupCount,
								legalEntityData,
								legalEntitiesSelected,
								paygroupData,
								paygroupsSelected
							)
						}
					};

					customerData.items.push(customerInfo);
				});

				return customerData;
			}),
			catchError(error => {
				return of({ failed: true }); // Return a failed boolean in case of error
			}),
			takeUntil(this.cancelPreviousRequest)
		);
	}

	getPaygroupCount(
		customerId: string,
		payGroupCount: number,
		legalEntityData: SelectOption[],
		legalEntitiesSelected: string[],
		paygroupData: SelectOption[],
		paygroupsSelected: string[]
	): number {
		if (!legalEntitiesSelected.length) return payGroupCount;

		if (!paygroupsSelected.length)
			return this.getPaygroupCountBasedOnLegalEntity(customerId, legalEntityData, legalEntitiesSelected);

		return this.getPaygroupCountBasedSelection(customerId, paygroupData, paygroupsSelected);
	}

	getPaygroupCountBasedSelection(
		customerId: string,
		paygroupData: SelectOption[],
		paygroupsSelected: string[]
	): number {
		let applicablePaygroups: SelectOption[] = this.getCorrespondingSelectOptions(
			customerId,
			paygroupData,
			paygroupsSelected,
			"customerId"
		);

		return applicablePaygroups ? applicablePaygroups.length : 0;
	}

	getPaygroupCountBasedOnLegalEntity(
		customerId: string,
		legalEntityData: SelectOption[],
		legalEntitiesSelected: string[]
	): number {
		let paygroupCount: number = 0;

		this.getCorrespondingSelectOptions(customerId, legalEntityData, legalEntitiesSelected, "customerId").forEach(
			legal => (paygroupCount += parseInt(legal.additionalInformation?.get("payGroupCount") ?? "0"))
		);

		return paygroupCount;
	}

	getLegalEntitiyCount(customerId: string, legalEntityData: SelectOption[], legalEntitiesSelected: string[]): number {
		let applicableLegalEntities: SelectOption[] = this.getCorrespondingSelectOptions(
			customerId,
			legalEntityData,
			legalEntitiesSelected,
			"customerId"
		);

		return applicableLegalEntities ? applicableLegalEntities.length : 0;
	}

	getCorrespondingSelectOptions(
		id: string,
		optionData: SelectOption[],
		selectedList: string[],
		type: string
	): SelectOption[] {
		return optionData.filter(
			(option: SelectOption) =>
				option.additionalInformation?.get(type) === id && selectedList.includes(option.value)
		);
	}

	getLegalEntities(
		customerId: string,
		pageSize: number,
		pageNumber: number,
		selectedLegalEnities: string[],
		paygroupData: SelectOption[],
		selectedPaygroups: string[]
	): Observable<LegalEntityDetailsWithPaygroups[]> {
		return this.getLegalEntitiesWithPagination(
			customerId,
			pageSize,
			pageNumber,
			selectedLegalEnities ? selectedLegalEnities : [],
			paygroupData,
			selectedPaygroups
		).pipe(
			take(1),
			map((legalEntities: PaygroupTableDetailWithPagination) => {
				let legalList: LegalEntityDetailsWithPaygroups[] = [];
				legalEntities.items.forEach(legalEntity => {
					if (!selectedLegalEnities.length) {
						legalList.push(legalEntity.legalEntityDetails);
					} else if (selectedLegalEnities.includes(legalEntity.legalEntityDetails.id)) {
						legalList.push(legalEntity.legalEntityDetails);
					}
				});
				return legalList;
			})
		);
	}

	getLegalEntitiesWithPagination(
		customerId: string,
		pageSize: number,
		pageNumber: number,
		selectedLegalEnities: string[],
		paygroupData: SelectOption[],
		selectedPaygroups: string[]
	): Observable<PaygroupTableDetailWithPagination> {
		let legalEntityData: PaygroupTableDetailWithPagination = {} as PaygroupTableDetailWithPagination;
		return this.legalEntityService
			.getLegalEntitiesViaCustomers(
				customerId,
				selectedLegalEnities ? selectedLegalEnities : [],
				pageSize,
				pageNumber
			)
			.pipe(
				map((legalEnitiesWithPagination: LegalEntityPagination) => {
					legalEntityData.currentPage = legalEnitiesWithPagination.pageNumber;
					legalEntityData.totalCount = legalEnitiesWithPagination.totalCount;
					legalEntityData.totalPages = legalEnitiesWithPagination.totalPages;
					legalEntityData.items = [];

					legalEnitiesWithPagination.items.forEach(legalEntity => {
						const LegalEntityInfo: PaygroupTableDetailItem = {
							legalEntityDetails: {
								id: legalEntity.id,
								externalId: legalEntity.data.externalId,
								name: legalEntity.data.name,
								country: legalEntity.data.country,
								paygroupCount:
									selectedPaygroups.length > 0
										? this.payGroupCountForLegalEntities(
												legalEntity.id,
												paygroupData,
												selectedPaygroups
										  )
										: legalEntity.payGroupCount
							}
						};

						legalEntityData.items.push(LegalEntityInfo);
					});

					return legalEntityData;
				})
			);
	}

	payGroupCountForLegalEntities(legalId: string, paygroupData: SelectOption[], paygroupsSelected: string[]): number {
		let applicableLegalEntities: SelectOption[] = this.getCorrespondingSelectOptions(
			legalId,
			paygroupData,
			paygroupsSelected,
			"legalEntityId"
		);

		return applicableLegalEntities ? applicableLegalEntities.length : 0;
	}

	getPaygroups(legalEntityId: string, selectedPaygroups?: string[]): Observable<PayGroupDetails[]> {
		return this.payGroupService.getPayGroupsByLegalEntityIdOnly(legalEntityId).pipe(
			map((paygroups: PayGroup[]) => {
				let payGroups: PayGroupDetails[] = [];

				paygroups.forEach(paygroup => {
					if (
						!selectedPaygroups ||
						selectedPaygroups.length === 0 ||
						selectedPaygroups.includes(paygroup.id)
					) {
						payGroups.push({
							id: paygroup.id,
							externalId: paygroup.externalId,
							name: paygroup.data.name,
							country: paygroup.legalEntity.data.country
						});
					}
				});

				return payGroups;
			})
		);
	}

	cancelPendingRequest() {
		this.cancelPreviousRequest.next();
	}
}
