<div class="payment-orders-table">
	<div class="table-header flex">
		<div class="header-text flex flex-1 col-empid" id="employeeId">EMPLOYEE ID</div>
		<div class="header-text flex flex-1 col-empname" id="employeeName">NAME</div>
		<div class="header-text flex flex-1 col-surname" id="surname">SURNAME</div>
		<div class="header-text flex flex-1 col-net" id="amount">TOTAL NET</div>
		<div class="header-text flex flex-1 col-source" id="source">UPLOADED BY</div>
	</div>
	<div class="table-body">
		<div
			class="table-row flex"
			*ngFor="let data of datasource?.items"
			[ngClass]="{ 'row-integration': data.source === 'integration' }"
		>
			<div class="table-row-item flex flex-1 col-emp">{{ data.employeeId }}</div>
			<div class="table-row-item flex flex-1 col-empname">{{ data.employeeName }}</div>
			<div class="table-row-item flex flex-1 col-surname">{{ data.surname }}</div>
			<div class="table-row-item flex flex-1 col-net field-amount">
				{{ data.amount | currency : "USD" : "symbol" : "1.2-2" }}
			</div>
			<div class="table-row-item flex flex-1 col-source field-source">
				<app-icon
					class="source-icon"
					[name]="data.source === 'file' ? 'document-upload' : 'receipt-add'"
					[color]="'var(--color-role-titl)'"
					[size]="'24'"
					[lineWidth]="'1.5px'"
				></app-icon>
				<div class="source-value">
					{{ (data.source === "integration" ? "payroll integration" : data.source) | uppercase }}
				</div>
			</div>
		</div>
	</div>
	<ng-container>
		<no-items-layout *ngIf="!datasource.items?.length" message="No records yet"></no-items-layout>
	</ng-container>
</div>
