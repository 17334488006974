<div class="main-container" #breadcrumbs (mouseleave)="closeDropDowns()">
	<div
		class="modal-header"
		[ngClass]="{
			'white-header': modalStyle.toLowerCase() === 'light',
			'black-header': modalStyle.toLowerCase() === 'dark',
			'blue-header': modalStyle.toLowerCase() === 'blue'
		}"
		*ngIf="!hideHeader"
	>
		<div
			class="modal-header-top"
			[ngStyle]="{ 'min-height': modalheaderPreferredHeight }"
			fxLayout="start center"
			fxLayoutAlign="space-between"
		>
			<button
				id="actionButton"
				mat-button
				class="modal-header-button"
				[ngClass]="{ 'two-row-button': titleRows === 2 }"
				(click)="headerAction()"
			>
				<!--back button - Choice of Icon -->
				<app-icon
					*ngIf="addTitleButton"
					name="{{ titleButtonType }}"
					[ngClass]="{
						'white-button': modalStyle.toLowerCase() === 'dark' || modalStyle.toLowerCase() === 'blue',
						'black-button': modalStyle.toLowerCase() === 'light'
					}"
				></app-icon>
				<!--Page title-->
				<div
					*ngIf="titleRows === 1"
					class="modal-header-title section-name"
					[ngClass]="{
						'header-with-button': addTitleButton === true
					}"
				>
					{{ title }}
				</div>

				<div *ngIf="titleRows === 2" class="two-line-header-title-container">
					<div class="sub-title">
						<span class="sub-title-flag">
							<img src="assets/svg-country-flags/svg/{{ addFlag.toLowerCase() }}.svg" />
						</span>
						<span class="sub-title-text">{{ subTitle }}</span>
					</div>
					<span class="modal-title xs-header-breadcrumb" style="margin-top: 5px">
						{{ title }}
					</span>
				</div>
			</button>

			<!--Exit/close icon on right hand side used in dark header FILE MANAGEMENT-->
			<button *ngIf="exitIconBool" mat-button class="modal-header-button exit-btn" (click)="headerAction()">
				<app-icon
					id="exit"
					class="exit-btn"
					name="exit-button"
					[lineWidth]="'4'"
					[ngClass]="{
						'white-button': modalStyle.toLowerCase() === 'dark' || modalStyle.toLowerCase() === 'blue',
						'black-button': modalStyle.toLowerCase() === 'light'
					}"
				></app-icon>
			</button>
		</div>

		<div
			*ngIf="!noBreadCrumbs"
			class="modal-header-bottom"
			fxLayout="row"
			fxLayoutAlign="space-between start"
			[ngClass]="{
				block: showAllCrumbs
			}"
		>
			<span
				fxLayout="row"
				[ngClass]="{
					'modal-header-allbreadcrumbs': showAllCrumbs,
					'modal-header-breadcrumbs': !showAllCrumbs
				}"
			>
				<span id="home-icon" (click)="headerHomeAction()">
					<app-icon
						class="breadcrumb-home-icon"
						*ngIf="(displayBreadcrumbCount < 4 || showAllCrumbs) && modalStyle.toLowerCase() === 'light'"
						[name]="'home'"
						[size]="'28'"
						[lineWidth]="'2px'"
						[ngClass]="{
							blueCrumb: displayBreadcrumbCount === 1,
							greyCrumb: displayBreadcrumbCount > 1
						}"
					>
					</app-icon>
				</span>

				<span
					*ngIf="displayBreadcrumbCount > 3 && !showAllCrumbs"
					class="breadcrumb greyCrumb dots"
					(click)="showAll()"
				>
					...
				</span>
				<span
					*ngFor="let crumb of allBreadcrumb$ | async as list; let i = index"
					fxLayout="row"
					fxLayoutAlign="start start"
					class="mainBreadcrumb"
					[ngClass]="{
						blueCrumb: i === list.length - 1,
						greyCrumb: i < list.length - 1,
						'full-width': displayBreadcrumbCount < 3 || i === list.length - 1 || showAllCrumbs,
						elipsified: displayBreadcrumbCount > 2 && i !== list.length - 1 && !showAllCrumbs,
						dropdown: crumb.type,
						allDisplay: showAllCrumbs
					}"
				>
					<span class="arrow" *ngIf="crumb.display === true && rangeCheck(i) && !crumb.type">
						<app-icon
							class="breadcrumb-arrow-icon"
							[ngClass]="{
								blueCrumb: i === 0 && displayBreadcrumbCount === 1,
								greyCrumb: displayBreadcrumbCount > 1
							}"
							[name]="'arrow-right-1'"
							[lineWidth]="'3px'"
							[color]="'var(--color-role-titl)'"
						>
						</app-icon>
					</span>

					<div *ngIf="crumb.display === true && rangeCheck(i) && crumb.type" class="breadcrumb-select">
						<app-modal-dropdown
							[crumb]="crumb"
							[index]="i"
							[firstToDisplay]="getFirstToDisplayIndex()"
							[showAllCrumbs]="showAllCrumbs || displayBreadcrumbCount < 4"
						>
						</app-modal-dropdown>
					</div>

					<span class="flag" *ngIf="flagCheck(i)">
						<img src="assets/svg-country-flags/svg/{{ addFlag.toLowerCase() }}.svg" />
					</span>

					<span
						id="crumb-{{ i }}"
						class="breadcrumb xs-header-breadcrumb"
						*ngIf="crumb.display === true && rangeCheck(i)"
						(click)="goback(crumb)"
						[matTooltip]="breadcrumbList"
						[matTooltipPosition]="'above'"
						[matTooltipClass]="
							!showAllCrumbs && displayBreadcrumbCount > 3 ? 'tooltip-pd' : 'tolltip-no-display'
						"
						[ngClass]="{
							elipsified: displayBreadcrumbCount > 2 && !showAllCrumbs,
							cursor: crumb.link != null || crumb.emit
						}"
						style="margin-top: 5px"
					>
						{{ crumb.crumb }}
					</span>
				</span>
			</span>

			<!-- file upload button -->
			<ng-content select="[header-upload-button]"></ng-content>

			<!--Optional button-->
			<div *ngIf="addButton" class="modal-button" fxLayout="row">
				<button mat-button class="{{ btnClass }}" (click)="headerButtonAction()">
					{{ btnText }}
				</button>
			</div>

			<!--Optional Date-->
			<div *ngIf="displayDate" class="date">
				<div class="date-day">Today</div>
				<div class="date-string">{{ date }}</div>
			</div>
		</div>
	</div>
	<div
		class="breadcrumb-subheader"
		#refbread
		[ngStyle]="{ 'background-color': subHeaderColour === 'white' ? '#fff' : subHeaderColour }"
		[ngClass]="{ 'no-display': !refbread.children.length }"
	>
		<ng-content select="[breadcrumb-sub-header]"></ng-content>
	</div>
</div>
<div class="sub-content">
	<div
		class="modal-subheader"
		#ref
		[ngStyle]="{ 'background-color': subHeaderColour === 'white' ? '#fff' : subHeaderColour }"
		[ngClass]="{ 'no-display': !ref.children.length, close: hideSubHeader }"
	>
		<ng-content select="[sub-header]"></ng-content>
	</div>

	<div
		class="modal-content"
		[ngStyle]="{ padding: noModalPadding ? 0 + 'px' : 'var(--view-padding-horitzontal)' }"
		[ngClass]="{ fullHeight: modalFullHeight }"
		[style.margin]="!noModalMargin ? marginSize : 0 + 'px'"
	>
		<ng-content> </ng-content>
	</div>
</div>
