import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { PaginationService } from "@shared/components/pagination/_services/pagination.service";
import { PaginationOptions } from "@shared/constants/pagination";
import { PaginationData } from "@store/models/pagination.model";
import { Observable, Subject } from "rxjs";
import { finalize, map, take, takeUntil } from "rxjs/operators";
import { UpdateCustomerEntityGroupAction } from "src/app/store/actions/customerEntityGroupSelect.action";
import { PayGroup } from "../../../../shared/models/pay-groups";
import { getCustomerEntityGroupState, getPagination } from "../../../../store";
import { AppState } from "../../../../store/models/state.model";
import { ApiService } from "../../../pay-groups/services/api.service";

@Component({
	selector: "app-paygroup-list",
	templateUrl: "./paygroup-list.component.html",
	styleUrls: ["./paygroup-list.component.scss"]
})
export class PaygroupListComponent implements OnInit, OnDestroy {
	payGroups$!: Observable<PayGroup[]>;
	selectedCustomerId: string = "";
	selectedLegalEntityId: string = "";
	pageIndex: number = 0;
	pageSize: number = 10;
	totalPageCount!: number;
	busy = true;
	paginationType: PaginationOptions = PaginationOptions.PAYGROUPS;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private payGroupService: ApiService,
		private router: Router,
		private store: Store<AppState>,
		private paginationService: PaginationService
	) {}

	ngOnInit(): void {
		this.fetchCustomerFromState();
		this.setupPaginationSubscription();
	}

	fetchCustomerFromState(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			if (state?.customerId) {
				this.selectedCustomerId = state.customerId;
				this.selectedLegalEntityId = state.legalEntityId;
			}
		});
	}

	setupPaginationSubscription(): void {
		this.store.pipe(select(getPagination), takeUntil(this.destroy$)).subscribe({
			next: pagination => {
				if (pagination && pagination.hasOwnProperty("PAYGROUPS")) {
					const paygroupPagination = pagination["PAYGROUPS"];
					this.pageSize = paygroupPagination.pageSize;
					this.pageIndex = paygroupPagination.pageIndex;
					this.getPayGroupsList(paygroupPagination.pageSize, paygroupPagination.pageIndex);
				}
			}
		});
	}

	getPayGroupsList(pageSize: number, pageNumber: number): void {
		const statuses: string = "LIVE, IMPLEMENTATION";
		this.payGroups$ = this.payGroupService
			.getPayGroups(this.selectedCustomerId, this.selectedLegalEntityId, statuses, "", "", pageSize, pageNumber)
			.pipe(
				take(1),
				finalize(() => (this.busy = false)),
				map(response => {
					this.totalPageCount = response.totalPages;
					return response.items;
				})
			);
	}

	resetPagination(): void {
		this.busy = true;
		const paginationSelection: PaginationData = {
			pageSize: this.pageSize,
			pageIndex: 0
		};

		this.paginationService.updatePaginationSate(this.paginationType, paginationSelection);
	}

	navigateToEntitySetup() {
		this.router.navigate(["/legal-entities"]);
	}

	paygroupSelected(payGroup: PayGroup) {
		this.store.dispatch(
			new UpdateCustomerEntityGroupAction({
				customerId: payGroup.legalEntity.customerId,
				legalEntityId: payGroup.legalEntity.id,
				payGroupId: payGroup.id,
				customerName: payGroup.customer.name
			})
		);

		this.router.navigate(["/global-dashboard/dashboard"], { state: { payGroup } });
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
