<div class="main-container">
	<div id="serviceCreationTitleContainer" class="c-title-item">Service Creation</div>
	<div id="serviceCreationContainer" class="container-style">
		<div id="serviceCreationFormContainer" [formGroup]="form" class="flex flex-row flex-align-center-space-between">
			<input-wrapper label="Service Type" class="flex-1" style="--max-width: 24%">
				<input-autocomplete
					id="serviceType"
					formControlName="type"
					label="Select service type"
					[options]="typeSelectOptions$ | async"
					[disabled]="
						(serviceToEdit && serviceToEdit.id !== '' && !canEditService) ||
						(!isNewService && !canEditService) ||
						isViewOnly
					"
				>
				</input-autocomplete>
			</input-wrapper>
			<div id="arrowContainer" class="arrow-container flex-1" style="--max-width: 14%">
				<div id="arrowContainerChild" class="arrow-container-child flex flex-align-stretch-center">
					<div id="arrowLine" class="arrow-line flex-1" style="--max-width: 50%"></div>
					<div id="arrowRightPoint" class="arrow-right"></div>
				</div>
			</div>
			<input-wrapper label="Service Name" class="flex-1" style="--max-width: 24%">
				<input id="ServiceName" type="text" placeholder="Enter service name" formControlName="name" />
			</input-wrapper>
			<div id="arrowContainer" class="arrow-container flex-1" style="--max-width: 14%">
				<div id="arrowContainerChild" class="arrow-container-child flex flex-row flex-align-stretch-center">
					<div id="arrowLine" class="arrow-line flex-1" style="--max-width: 50%"></div>
					<div id="arrowRightPoint" class="arrow-right"></div>
				</div>
			</div>
			<input-wrapper
				label="Service Description"
				class="flex-1"
				style="--max-width: 24%"
				(click)="openDescriptionDialog()"
			>
				<input
					id="ServiceDesc"
					type="text"
					placeholder="Enter a description for the service"
					formControlName="description"
				/>
			</input-wrapper>
		</div>
	</div>
</div>
