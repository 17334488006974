import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { PaginationService } from "@shared/components/pagination/_services/pagination.service";
import { PaginationOptions } from "@shared/constants/pagination";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { PaginationData } from "@store/models/pagination.model";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, finalize, take, takeUntil } from "rxjs/operators";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { CustomerEntityGroupSelect } from "src/app/store/models/customerEnitityGroupSelection.model";
import { getCustomerEntityGroupState, getPagination } from "../../../../store";
import { AppState } from "../../../../store/models/state.model";

@Component({
	selector: "app-pay-elective-pay-groups",
	templateUrl: "./pay-elective-pay-groups.component.html",
	styleUrls: ["./pay-elective-pay-groups.component.scss"]
})
export class PayElectivePayGroupsComponent implements OnInit, OnDestroy {
	legalEntitySelectionOptions$!: Observable<SelectOption[]>;
	destroy$: Subject<void> = new Subject();

	pageIndex: number = 0;
	pageSize: number = 10;
	totalPageCount!: number;
	form!: FormGroup;
	entitySelectForm!: FormGroup;

	readonly ALL_OPTION: SelectOption = {
		value: "ALL_OPTIONS",
		text: "All legal entities"
	};

	customerHasPayGroups: boolean = true;
	customerName = "";

	busy = true;
	legalEntitiesSelectOptions: SelectOption[] = [];
	payGroupsList: PayGroup[] = [];

	customerId = "";
	legalEntityIds: string[] = [];
	allLegalEntityOptions: string[] = [];
	canSeeMap = false;
	queryString = "";
	paginationType: PaginationOptions = PaginationOptions.PAYGROUPS;

	constructor(
		private router: Router,
		private store: Store<AppState>,
		private legalEntityService: LegalEntityService,
		private payGroupsService: PaygroupsService,
		private permissions: PermissionsService,
		private paginationService: PaginationService
	) {}

	ngOnInit(): void {
		this.initalizeComponentData();
		this.permissions
			.canSeeMapAndIsClient()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: { isClient: boolean; canSeeMap: boolean }) => {
				this.canSeeMap = res.canSeeMap;
			});
	}

	initalizeComponentData(): void {
		this.fetchCustomerFromState();
		this.setupPaginationSubscription();
		this.initForm();
	}

	fetchCustomerFromState(): void {
		this.store
			.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState))
			.subscribe((state: CustomerEntityGroupSelect) => {
				if (state?.customerId) {
					this.customerName = state.customerName!;
					this.customerId = state.customerId;
					this.getLegalEntities(this.customerId);
				}
			});
	}

	setupPaginationSubscription(): void {
		this.store.pipe(select(getPagination), takeUntil(this.destroy$)).subscribe({
			next: pagination => {
				if (pagination && pagination.hasOwnProperty("PAYGROUPS")) {
					const paygroupPagination = pagination["PAYGROUPS"];
					this.pageSize = paygroupPagination.pageSize;
					this.pageIndex = paygroupPagination.pageIndex;

					this.getPayGroups(
						[this.customerId],
						this.legalEntityIds,
						paygroupPagination.pageSize,
						paygroupPagination.pageIndex,
						this.queryString
					);
				}
			}
		});
	}

	getLegalEntities(customerId: string): void {
		this.legalEntityIds = [];
		this.legalEntityService
			.getLegalEntitiesForCustomerAsSelectOption(customerId, true)
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe(legalEntities => {
				if (legalEntities.length) {
					legalEntities.forEach(entity => {
						this.allLegalEntityOptions.push(entity.value);
					});

					this.legalEntityIds = [...this.allLegalEntityOptions];
					this.legalEntitiesSelectOptions = legalEntities;
					this.legalEntitiesSelectOptions.unshift(this.ALL_OPTION);
				}
			});
	}

	getPayGroups(
		customerIds: string[],
		legalEntityIds: string[],
		pageSize: number,
		pageIndex: number,
		queryString: string
	): void {
		this.busy = true;
		const statuses: string = "LIVE, IMPLEMENTATION";

		this.payGroupsService
			.getPayGroups(customerIds, legalEntityIds, statuses, pageSize, pageIndex, queryString)
			.pipe(
				take(1),
				takeUntil(this.destroy$),
				finalize(() => (this.busy = false))
			)
			.subscribe(payGroups => {
				this.totalPageCount = payGroups.totalPages;
				this.payGroupsList = payGroups.items;
			});
	}

	initForm(): void {
		this.entitySelectForm = new UntypedFormGroup({
			legal: new UntypedFormControl("", Validators.required),
			paygroup: new UntypedFormControl("")
		});

		this.setupValueChanges();
	}

	setupValueChanges(): void {
		this.entitySelectForm
			.get("legal")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((legalEntity: string) => {
				this.payGroupsList = [];
				if (legalEntity) {
					this.formControlReset("paygroup");
					this.queryString = "";
					legalEntity === "ALL_OPTIONS"
						? (this.legalEntityIds = [...this.allLegalEntityOptions])
						: (this.legalEntityIds = [legalEntity]);

					this.resetPagination();
				}
			});

		this.entitySelectForm
			.get("paygroup")
			?.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
			.subscribe((search: string) => {
				this.legalEntityIds = [];
				if (search !== null) {
					this.legalEntityIds = [...this.allLegalEntityOptions];
					this.formControlReset("legal");
					this.queryString = search;
					this.resetPagination();
				}
			});
	}

	resetPagination(): void {
		const paginationSelection: PaginationData = {
			pageSize: this.pageSize,
			pageIndex: 0
		};

		this.paginationService.updatePaginationSate(this.paginationType, paginationSelection);
	}

	formControlReset(control: string): void {
		const isLegal = control === "legal";
		this.entitySelectForm.get(control)?.setValue(isLegal ? "All Legal Entities" : "", { emitEvent: false });
	}

	resetOptions(): void {
		this.legalEntitiesSelectOptions = [];
		this.payGroupsList = [];
		this.legalEntityIds = [];
		this.resetPagination();

		if (this.entitySelectForm) {
			this.entitySelectForm.reset();
		}
	}

	navigateToEntitySetup(): void {
		this.router.navigate(["/legal-entities"]);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
