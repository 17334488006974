import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog as MatDialog } from "@angular/material/dialog";
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { PayoutsService } from "@shared/services/payouts/payouts.service";
import { InactivateToggleDialogRefData } from "src/app/shared/models/payouts.types";

@Component({
	selector: "app-inactivate-toggle-dialog",
	templateUrl: "./inactivate-toggle-dialog.component.html",
	styleUrls: ["./inactivate-toggle-dialog.component.scss"]
})
export class InactivateToggleDialogComponent implements OnInit {
	destroy$: Subject<void> = new Subject();

	constructor(
		private payoutService: PayoutsService,
		private dialog: MatDialog,
		public dialogRef: MatDialogRef<InactivateToggleDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: InactivateToggleDialogRefData
	) {}

	type: string = "employee";

	ngOnInit(): void {
		if (this.data.type) {
			this.type = this.data.type;
		}
	}

	onCancel(): void {
		this.dialogRef.close();
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onConfirm(): void {
		if (this.data && this.data.payout) {
			this.dialogRef.close(this.data.payout);
		} else {
			this.dialogRef.close(true);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
