<div class="dropdown-container" (click)="$event.stopPropagation()" (mouseleave)="toggleDropDown(false)">
	<div
		class="status-container"
		[ngClass]="{
			'complete-style':
				milestone.milestoneDetails?.status === milestoneStatusesValue.COMPLETE ||
				milestone.milestoneDetails?.status === milestoneStatusesText.COMPLETED,
			'inProgress-style':
				milestone.milestoneDetails?.status === milestoneStatusesValue.IN_PROGRESS ||
				milestone.milestoneDetails?.status === milestoneStatusesText.IN_PROGRESS,
			'pending-style':
				milestone.milestoneDetails?.status === milestoneStatusesValue.PENDING ||
				milestone.milestoneDetails?.status === milestoneStatusesText.PENDING,
			'errors-style':
				milestone.milestoneDetails?.status === milestoneStatusesValue.ERROR ||
				milestone.milestoneDetails?.status === milestoneStatusesText.HAS_ERRORS,
			selected: showDropdown
		}"
	>
		<mat-form-field (click)="toggleDropDown()" [formGroup]="milestoneStatusForm">
			<input
				class="status-input"
				matInput
				type="text"
				[readonly]="true"
				[value]="milestone.milestoneDetails.status"
				[formControlName]="'milestoneStatus'"
			/>

			<app-icon
				matSuffix
				class="drop-file-trigger"
				[lineWidth]="'2.5'"
				[name]="'arrow-down'"
				[size]="'16'"
				[ngClass]="{ selected: showDropdown }"
			>
			</app-icon>
		</mat-form-field>
	</div>

	<div class="status-options-container" *ngIf="showDropdown">
		<div class="status-options">
			<div class="status-option" (click)="setStatusSelection(opt.text)" *ngFor="let opt of milestonesSelection">
				<span
					class="option-text"
					[ngClass]="{
						'complete-option': opt.text === milestoneStatusesText.COMPLETED,
						'inProgress-option': opt.text === milestoneStatusesText.IN_PROGRESS,
						'pending-option': opt.text === milestoneStatusesText.PENDING,
						'errors-option': opt.text === milestoneStatusesText.HAS_ERRORS
					}"
					>{{ opt.text }}</span
				>
			</div>
		</div>
	</div>
</div>
