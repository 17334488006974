import { AbstractControl, FormGroup } from "@angular/forms";

import { ContactPerson } from "@shared/models/contact-person.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";

export const tableColumns: string[] = ["email", "firstName", "lastName", "role", "actions"];

export interface User {
	id?: string;
	externalId?: string;
	email: string;
	phoneNumber?: string;
	firstName: string;
	lastName: string;
	roles: string[];
	roleIds?: string[];
	roleHistory?: RoleHistory[];
	blocked: boolean;
	type: string;
	contactPersons?: ContactPerson[];
	external: boolean;
}

export interface UserState {
	firstName?: string;
	lastName?: string;
	email?: string;
	roles?: string[];
	isForgeRock?: boolean;
	initials?: string;
	canSeeMap?: boolean;
	isClientGlobalDashboard?: boolean;
}

export interface RoleHistory {
	author: string;
	authorName?: string;
	createdAt: string;
	id: string;
	reason: string;
	rolesRemoved: string[];
	rolesAdded: string[];
	ticketNr: string;
	userId: string;
	type: string;
	roleAction?: string;
}

export interface Role {
	id: string;
	name: string;
}

export interface UserPagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: User[];
}

export interface IUserForm extends FormGroup {
	value: User;
	controls: {
		email: AbstractControl;
		phone: AbstractControl;
		firstName: AbstractControl;
		lastName: AbstractControl;
		roles: AbstractControl;
		blocked: AbstractControl;
	};
}

export interface Search {
	search?: string;
}

export interface ISearchUserForm extends FormGroup {
	value: Search;
	controls: {
		search: AbstractControl;
	};
}

export interface Select {
	select?: string;
}

export interface ISelectForm extends FormGroup {
	value: Select;
	controls: {
		select: AbstractControl;
	};
}

export interface status {
	status?: string;
}

export interface IStatusForm extends FormGroup {
	value: status;
	controls: {
		status: AbstractControl;
	};
}

export const StatusOptions: SelectOption[] = [
	{ value: "true", text: "Active" },
	{ value: "false", text: "Inactive" }
];
