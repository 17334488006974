import { Menu } from "../models/menu.interface";

export const MENU: Menu[] = [
	//Temp Work Around
	{
		title: "All Countries",
		icon: "global",
		expanded: true,
		active: false,
		standalone: true,
		shouldShow: false,
		sections: [
			{
				navigations: [
					{
						active: false, // Init value
						url: "/client-landing"
					}
				]
			}
		],
		isAdditionalNavigation: false,
		hideForUser: true
	},

	// {
	// 	title: "Client Summary",
	// 	icon: "global",
	// 	expanded: true,
	// 	active: false,
	// 	standalone: true,
	// 	shouldShow: false,
	// 	sections: [
	// 		{
	// 			navigations: [
	// 				{
	// 					active: false, // Init value
	// 					url: "/client-summary"
	// 				}
	// 			]
	// 		}
	// 	],
	// 	isAdditionalNavigation: false,
	// 	hideForUser: true
	// },

	{
		title: "Global Dashboard",
		icon: "global",
		expanded: true,
		active: false,
		standalone: true,
		shouldShow: false,
		sections: [
			{
				navigations: [
					{
						active: false, // Init value
						url: "/global-dashboard"
					}
				]
			}
		],
		isAdditionalNavigation: false
	},

	{
		title: "Employee Data",
		icon: "description",
		expanded: true,
		shouldShow: false,
		active: false,
		standalone: true,
		sections: [
			{
				navigations: [
					{
						active: false,
						url: "/employee-data",
						additionalNavigation: "/employee-data/paygroups"
					}
				]
			}
		],
		isAdditionalNavigation: false
	},
	{
		title: "Configuration",
		icon: "settings",
		expanded: true,
		shouldShow: false,
		active: true, // Init value
		sections: [
			{
				title: "Customers",
				expanded: true, // Init value
				active: true, // Init value
				standalone: false,
				navigations: [
					{
						title: "Clients",
						active: false, // Init value
						url: "/customers"
					},
					{
						title: "Legal Entities",
						active: false,
						url: "/legal-entities"
					},
					{
						title: "Pay Groups",
						active: false,
						url: "/pay-groups"
					}
				]
			},

			{
				title: "Service Definition",
				expanded: true,
				active: true,
				standalone: false,
				navigations: [
					{
						title: "Net",
						active: false, // Init value
						url: "/service-definition/net"
					},
					{
						title: "TPP",
						active: false, // Init value
						url: "/service-definition/tpp"
					},
					{
						title: "CPNow",
						active: false, // Init value
						url: "/service-definition/cp-now"
					}
				]
			},

			{
				title: "Calendar",
				expanded: true,
				active: false,
				standalone: true,

				navigations: [
					{
						active: false, // Init value
						url: "/calendar"
					}
				]
			}
		],
		isAdditionalNavigation: false
	},

	{
		title: "User management",
		icon: "2user",
		expanded: true,
		active: false,
		standalone: true,
		shouldShow: false,
		sections: [
			{
				navigations: [
					{
						active: false,
						url: "/user-management"
					}
				]
			}
		],
		isAdditionalNavigation: false
	},
	{
		title: "Reporting",
		icon: "task",
		expanded: true,
		active: false,
		standalone: true,
		shouldShow: false,
		sections: [
			{
				navigations: [
					{
						active: false, // Init value
						url: "/reporting"
					}
				]
			}
		],
		isAdditionalNavigation: false
	},
	{
		title: "System",
		icon: "setting-4",
		shouldShow: false,
		expanded: true,
		active: true,
		sections: [
			{
				title: "Service Providers",
				expanded: false,
				active: false,
				standalone: true,
				shouldShow: true,
				navigations: [
					{
						active: false,
						url: "/service-provider-setup"
					}
				]
			},
			{
				title: "Penny Test",
				expanded: false, // Init value
				active: false, // Init value
				standalone: true,
				shouldShow: true,
				navigations: [
					{
						active: false, // Init value
						url: "/penny-test"
					}
				]
			},
			{
				title: "Tpp catalogue",
				expanded: false, // Init value
				active: false, // Init value
				standalone: true,
				shouldShow: true,
				navigations: [
					{
						active: false, // Init value
						url: "/tpp-catalogue"
					}
				]
			},
			{
				title: "Bank Fields",
				expanded: false, // Init value
				active: false, // Init value
				standalone: true,
				shouldShow: true,
				navigations: [
					{
						active: false, // Init value
						url: "/bank-fields"
					}
				]
			},
			{
				title: "Error Management",
				expanded: false, // Init value
				active: false, // Init value
				standalone: true,
				shouldShow: true,
				navigations: [
					{
						active: false, // Init value
						url: "/error-management"
					}
				]
			}
		],
		isAdditionalNavigation: false
	}
];
