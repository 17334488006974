<table
	class="failed-beneficiaries table"
	mat-table
	[dataSource]="dataSourceMissingBankAccounts"
	multiTemplateDataRows
	matSort
>
	<tr mat-header-row *matHeaderRowDef="customerColumns"></tr>
	<tr
		mat-row
		*matRowDef="let element; columns: customerColumns"
		#customerRow
		(click)="toggleRow(element)"
		[ngClass]="{ expanded: isExpanded(element), collapsed: !isExpanded(element) }"
	></tr>
	<tr mat-row class="expanded-row" *matRowDef="let row; columns: ['expandedDetail']"></tr>

	<!--First Layer-->
	<ng-container class="table-container">
		<ng-container matColumnDef="Customers">
			<th mat-header-cell *matHeaderCellDef>Customers</th>
			<td mat-cell class="customer-data first-collumn customerName" *matCellDef="let element">
				<span
					class="text"
					[matTooltip]="element.customerDetails.name"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="'tooltip-FailedBen'"
					attr.data-test="{{ element.customerDetails.name }}"
					>{{ element.customerDetails.name }}</span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="Paygroups">
			<th mat-header-cell *matHeaderCellDef class="second-collumn">Paygroups</th>
			<td class="customer-data second-collumn paygroupCount" mat-cell *matCellDef="let element">
				<span
					class="text"
					attr.data-test="{{ customerNameLocator }}-Paygroup"
					*ngIf="element.payGroups.length > 1"
					>{{ element.payGroups.length }} Paygroups</span
				>
				<span
					class="text"
					attr.data-test="{{ customerNameLocator }}-Paygroup"
					*ngIf="element.payGroups.length < 2"
					>{{ element.payGroups.length }} Paygroup</span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="Failed Beneficiaries">
			<th mat-header-cell *matHeaderCellDef>Failed Beneficiaries</th>
			<td class="customer-data third-collumn failed-beneficiary-count" mat-cell *matCellDef="let element">
				<span
					attr.data-test="{{ customerNameLocator }}-Failed Beneficiary"
					class="text"
					*ngIf="element.totalCount > 1"
					>{{ element.totalCount }} Failed Beneficiaries</span
				>
				<span
					attr.data-test="{{ customerNameLocator }}-Failed Beneficiary"
					class="text"
					*ngIf="element.totalCount < 2"
					>{{ element.totalCount }} Failed Beneficiary</span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="expand">
			<th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
			<td mat-cell *matCellDef="let element" class="button-container fourth-collumn customer-data">
				<button
					#expand
					mat-icon-button
					class="drop-down-arrow-button"
					aria-label="expand row"
					(click)="toggleRow(element); $event.stopPropagation()"
					element="element"
				>
					<app-icon
						class="drop-down-arrow"
						[ngClass]="{ down: isExpanded(element) }"
						[size]="'18px'"
						[lineWidth]="'2.5px'"
						[name]="'arrow-down'"
					></app-icon>
				</button>
			</td>
		</ng-container>
	</ng-container>

	<!--Second Layer-->
	<ng-container class="paygroup-container" matColumnDef="expandedDetail">
		<td
			mat-cell
			*matCellDef="let element"
			class="paygroup-row"
			[ngClass]="{
				'paygroups-expanded': isExpanded(element)
			}"
			[attr.colspan]="paygroupsColumns.length"
		>
			<div class="paygroup-detail" *ngIf="element.payGroups.length" [@detailExpand]="expandedState(element)">
				<div class="inner-table">
					<table
						class="paygroup-table"
						#innerTables
						mat-table
						[dataSource]="element.payGroups"
						multiTemplateDataRows
						matSort
					>
						<ng-container matColumnDef="first-padding">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td class="first-collumn padding" mat-cell *matCellDef="let element">
								<span class="first text padding">{{ element.payGroupDetails.name }}</span>
							</td>
						</ng-container>

						<ng-container matColumnDef="Paygroups">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td class="paygroup-data second-collumn paygroupName" mat-cell *matCellDef="let element">
								<span
									class="payGroup-name text"
									[matTooltip]="
										element.payGroupDetails.externalId + ' ' + element.payGroupDetails.name
									"
									[matTooltipPosition]="'above'"
									[matTooltipClass]="'tooltip-FailedBen'"
									attr.data-test="{{ customerNameLocator }}-{{ element.payGroupDetails.externalId }}"
								>
									<img
										src="assets/svg-country-flags/svg/{{
											element.payGroupDetails.country?.toLowerCase()
										}}.svg"
									/>

									{{ element.payGroupDetails.externalId }} {{ element.payGroupDetails.name }}</span
								>
							</td>
						</ng-container>

						<ng-container matColumnDef="Failed Beneficiaries">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td
								class="paygroup-data third-collumn failed-beneficiary-count"
								mat-cell
								*matCellDef="let element"
							>
								<span
									class="text"
									*ngIf="element.totalCount > 1"
									attr.data-test="{{ customerNameLocator }}-{{
										element.payGroupDetails.externalId
									}}-{{ element.totalCount }}-Failed Beneficiaries"
									>{{ element.totalCount }} Failed Beneficiaries</span
								>
								<span
									class="text"
									*ngIf="element.totalCount < 2"
									attr.data-test="{{ customerNameLocator }}-{{
										element.payGroupDetails.externalId
									}}-{{ element.totalCount }}-Failed Beneficiary"
									>{{ element.totalCount }} Failed Beneficiary</span
								>
							</td>
						</ng-container>

						<ng-container matColumnDef="second-padding">
							<th mat-header-cell *matHeaderCellDef class="hide" aria-label="row actions"></th>
							<td mat-cell *matCellDef="let element" class="button-container fourth-collumn padding">
								<button
									mat-icon-button
									class="drop-down-arrow-button padding"
									aria-label="expand row"
									element="element"
								>
									<app-icon
										class="drop-down-arrow"
										[ngClass]="{ down: isExpanded(element) }"
										[size]="'18px'"
										[lineWidth]="'2.5px'"
										[name]="'arrow-down'"
									></app-icon>
								</button>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="paygroupsColumns"></tr>
						<tr
							mat-row
							*matRowDef="let row; columns: paygroupsColumns"
							(click)="routeToEmployeeData(row)"
						></tr>
					</table>
				</div>
			</div>
		</td>
	</ng-container>
</table>
