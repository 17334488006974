import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SettlementAccountFieldsComponent } from "./settlement-account-fields.component";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatGridListModule } from "@angular/material/grid-list";
import { SettlementAccountFieldItemComponent } from "./_components/settlement-account-field-item/settlement-account-field-item.component";
import { ExpansionPanelModule } from "../expansion-panel/expansion-panel.module";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatDivider, MatDividerModule } from "@angular/material/divider";
import { SharedModule } from "@shared/shared.module";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
	declarations: [SettlementAccountFieldsComponent, SettlementAccountFieldItemComponent],
	imports: [
		CommonModule,
		FormsSharedModule,
		ReactiveFormsModule,
		MatIconModule,
		MatCardModule,
		MatGridListModule,
		MatButtonModule,
		MatSlideToggleModule,
		ExpansionPanelModule,
		MatTooltipModule,
		FlexLayoutModule,
		MatDividerModule,
		ReactiveFormsModule,
		SharedModule,
		MatCheckboxModule
	],
	exports: [SettlementAccountFieldsComponent]
})
export class SettlementAccountFieldsModule {}
