import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { filter, first, map, tap } from "rxjs/operators";
import { ErrorManagementEntityService } from "./error-management-entity.service";

@Injectable({
	providedIn: "root"
})
export class ErrorManagementResolver implements Resolve<boolean> {
	constructor(private errorManagementEntityService: ErrorManagementEntityService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.errorManagementEntityService.loaded$.pipe(
			tap(loaded => {
				console.log(loaded);

				// if (!loaded) {
				// 	this.errorManagementEntityService.getAll();
				// }
			}),
			filter(loaded => !!loaded),
			first()
		);
	}
}
