import { Action } from "@ngrx/store";
import { PaginationOptions } from "@shared/constants/pagination";
import { PaginationActionType } from "@store/actions/pagination.action";
import { PaginationState } from "@store/models/pagination.model";
import { createRehydrateReducer } from "./state-persistance";

const initialState: PaginationState = {};

const localStorageKey = "PaginationState";

// Set default pagination data for each option
Object.keys(PaginationOptions).forEach(option => {
	initialState[option] = { pageSize: 10, pageIndex: 0 };
});

export function paginationReducerFn(state = initialState, action: Action): PaginationState {
	switch (action.type) {
		case PaginationActionType.SET_CUSTOMER_PAGINATION:
		case PaginationActionType.SET_PAYGROUP_PAGINATION:
		case PaginationActionType.SET_LEGAL_ENTITY_PAGINATION:
		case PaginationActionType.SET_EMPLOYEE_PAGINATION:
		case PaginationActionType.SET_MASTER_FILE_PAGINATION:
		case PaginationActionType.SET_PAYMENT_ORDER_PAGINATION:
		case PaginationActionType.SET_NET_PAYMENT_PAGINATION:
		case PaginationActionType.SET_TPP_PAYMENT_PAGINATION:
		case PaginationActionType.SET_GLOBAL_DASHBOARD_PAGINATION:
			return {
				...state,
				[(action.type as PaginationActionType).split(" ")[2].toUpperCase()]: (action as any).payload
			};

		case PaginationActionType.RESET_PAGINATION:
			let newState: PaginationState = {} as PaginationState;

			Object.keys(PaginationOptions).forEach(option => {
				if (state[option]) {
					newState[option] = { pageSize: state[option].pageSize, pageIndex: 0 };
				} else {
					/** initiate the new pagination option state */
					newState[option] = { pageSize: 10, pageIndex: 0 };
				}
			});

			return {
				...newState,
				CUSTOMERS: state["CUSTOMERS"] //Don't reset customers
			};

		default:
			return state;
	}
}

export const paginationReducer = createRehydrateReducer<PaginationState, Action>(localStorageKey, paginationReducerFn);
