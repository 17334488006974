import { Injectable } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { TabStateService } from "@shared/services/tab-state/tab-state.service";
import { Observable, Subject } from "rxjs";
import { delay, filter, map, startWith } from "rxjs/operators";
import { MENU as MENU_ITEM_DEFINITIONS } from "src/app/shared/constants/menu";
import { MenuNavigation } from "src/app/shared/models/menu-navigation.interface";
import { MenuSection } from "src/app/shared/models/menu-section.interface";
import { Menu } from "src/app/shared/models/menu.interface";

@Injectable({
	providedIn: "root"
})
export class MenuService {
	expanded: boolean = true;
	visibility$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private router: Router,
		private tabStateService: TabStateService,
		private permissionsService: PermissionsService
	) {}

	setVisible(visbile: boolean) {
		this.visibility$.next(visbile);
	}

	getVisibility(): Observable<boolean> {
		return this.visibility$.pipe(delay(10));
	}

	initRouterEvents(): Observable<Menu[]> {
		return this.router.events.pipe(
			filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
			map(e => this.configurateMenu(e.url)),
			startWith(this.initConfigurationMenu())
		);
	}

	configurateMenu(url: string): Menu[] {
		const menu: Menu[] = JSON.parse(JSON.stringify(MENU_ITEM_DEFINITIONS));

		// Map for categories
		menu.map((category: Menu) => {
			let isSectionActive: boolean = false;

			// Map for sections
			category.sections.map((section: MenuSection) => {
				let isNavigationActive: boolean = false;

				// Map for navigations
				section.navigations.map((navigation: MenuNavigation) => {
					// Check if current url is like naviagation url to active it

					if (url.includes(navigation.url)) {
						navigation.active = true;
						isNavigationActive = true;
						isSectionActive = true;
						this.tabStateService.setActiveRoute(url);
					}
				});

				// If in the navigations of this sections there is a navigation active, set section as active and expand accordion
				section.active = isNavigationActive;
				section.expanded = isNavigationActive;
			});

			category.active = isSectionActive;
			category.expanded = isSectionActive;
		});

		return menu;
	}

	initConfigurationMenu(): Menu[] {
		// Set the activeRoute to last sessions page
		if (this.tabStateService.getActiveRoute() !== "") {
			let activeRoute: string = this.tabStateService.getActiveRoute();
			return this.configurateMenu(`${activeRoute}`);
		} else {
			return MENU_ITEM_DEFINITIONS;
		}
	}

	editMenuBasedOnUserRole(roles: string[], canSeeMap: boolean): Observable<Menu[]> {
		return this.initRouterEvents().pipe(
			map(menu => {
				return this.setMenuPermissions(roles, menu, canSeeMap);
			})
		);
	}

	setMenuPermissions(userRoles: string[], menu: Menu[], canSeeMap: boolean): Menu[] {
		menu.forEach(menuItem => {
			switch (menuItem.title.toLowerCase()) {
				case "all countries":
					menuItem.shouldShow = canSeeMap;
					break;

				case "client summary":
					menuItem.shouldShow = this.permissionsService.canViewClientSummary(userRoles);
					break;

				case "global dashboard":
					menuItem.shouldShow = this.permissionsService.canViewGlobalDashboard(userRoles);
					break;

				case "employee data":
					menuItem.shouldShow = this.permissionsService.canViewEmployeeData(userRoles);
					break;

				case "configuration":
					menuItem.shouldShow = this.permissionsService.canViewConfiguration(userRoles);
					menuItem.sections.forEach(section => {
						switch (section.title?.toLowerCase()) {
							case "customers":
								section.shouldShow = this.permissionsService.canViewCustomers(userRoles);
								break;
							case "service definition":
								section.shouldShow = this.permissionsService.canViewServiceDefinition(userRoles);
								break;
							case "calendar":
								section.shouldShow = this.permissionsService.canViewCalendar(userRoles);
								break;
						}
					});
					break;

				case "user management":
					menuItem.shouldShow = this.permissionsService.canViewUserManagement(userRoles);
					break;

				case "reporting":
					menuItem.shouldShow = this.permissionsService.canViewReporting(userRoles);
					break;

				case "system":
					menuItem.shouldShow = this.permissionsService.canViewSystem(userRoles);
					menuItem.sections.forEach(section => {
						switch (section.title?.toLowerCase()) {
							case "service providers":
								section.shouldShow = this.permissionsService.canViewServiceProvider(userRoles);
								break;
							case "penny test":
								section.shouldShow = this.permissionsService.canViewPennyTest(userRoles);
								break;
							case "tpp catalogue":
								section.shouldShow = this.permissionsService.canViewTppCatalogue(userRoles);
								break;
							case "bank fields":
								section.shouldShow = this.permissionsService.canViewBankFields(userRoles);
								break;
							case "error management":
								section.shouldShow = this.permissionsService.canViewErrorManagement(userRoles);
								break;
						}
					});
					break;
				case "cp now":
					menuItem.shouldShow = this.permissionsService.canViewCpNowData(userRoles);
					break;

				default:
					menuItem.shouldShow = false;
					break;
			}
		});

		return menu;
	}
}
