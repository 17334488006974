import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { SelectOption } from "@shared/models/select-option.interface";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, pairwise, startWith, take, takeUntil } from "rxjs/operators";
import { CustomerEntitySelection } from "src/app/shared/models/customer-entity-selection.interface";
import { getCustomerEntityGroupState } from "src/app/store";
import { AppState } from "src/app/store/models/state.model";

import { CustomerCacheService } from "@shared/services/customer/customer-cache.service";
import { UpdateCustomerEntityGroupAction } from "@store/actions/customerEntityGroupSelect.action";
import * as _ from "lodash";
import { CustomerEntityGroupSelect } from "src/app/store/models/customerEnitityGroupSelection.model";
import { PaginationService } from "../pagination/_services/pagination.service";
import { CustomerEntitySelectFormService } from "./services/customer-entity-select-form.service";
import { LegalEntitySelectionDataProviderService } from "./services/legal-entity-selection-data-provider.service";

@Component({
	selector: "customer-legal-entity-select",
	templateUrl: "./customer-legal-entity-select.component.html",
	styleUrls: ["./customer-legal-entity-select.component.scss"]
})
export class CustomerLegalEntitySelectComponent implements OnInit, OnDestroy {
	@Input() payGroupSelector: boolean = false;
	@Input() enableSelectAll: boolean = false;
	@Input() gridClass: number = 2;
	@Input() chargeValuesSaved: boolean = true;
	@Input() enablePayGroupSearch: boolean = false;
	@Input() payGroupStatus: string = "";
	@Input() customerPaygroupStatus: string = "";
	@Input() onlyLegalEntitiesWithPaygroups = false;
	@Input() updateCustomerSelectState = true;

	@Output() customerEntitySelection: EventEmitter<CustomerEntitySelection> =
		new EventEmitter<CustomerEntitySelection>();

	@Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();

	payGroupSearchQuery: string = "";

	// Variables
	customerSelectionOptions!: SelectOption[];
	legalEntitySelectionOptions!: SelectOption[];
	payGroupSelectionOptions!: SelectOption[];

	form!: FormGroup;
	formInitialized: boolean = false;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private store: Store<AppState>,
		private legalEntitySelectionDataProvider: LegalEntitySelectionDataProviderService,
		private formService: CustomerEntitySelectFormService,
		private customerCacheService: CustomerCacheService,
		private paginationService: PaginationService
	) {}

	ngOnInit(): void {
		this.setupCustomerEntityGroupStateListener();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private setupCustomerEntityGroupStateListener(): void {
		this.store
			.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState), distinctUntilChanged(_.isEqual))
			.subscribe((state: CustomerEntityGroupSelect) => {
				if (state) {
					var customerId = state.customerId !== "" ? state.customerId : "ALL_OPTIONS";
					var legalEntityId =
						state.legalEntityId && state.legalEntityId !== "" ? state.legalEntityId : "ALL_OPTIONS";
					var paygroupId = state.payGroupId && state.payGroupId !== "" ? state.payGroupId : "ALL_OPTIONS";

					if (this.form == undefined) {
						this.form = this.formService.aFormFromState(state);
					} else {
						this.form.get("customerId")?.patchValue(customerId, { emitEvent: false });
						this.form.get("legalEntityId")?.patchValue(legalEntityId, { emitEvent: false });
						this.form.get("payGroupId")?.patchValue(paygroupId, { emitEvent: false });
					}

					this.emitChangesToParent();
					this.refreshSelectionOptionValues();
				}
			});
	}

	private refreshSelectionOptionValues(): void {
		this.intitalizeDropDownSelectionOptions();

		// only initalize the form once to prevent causing multiple subscriptions
		if (!this.formInitialized) {
			this.onFormFieldValueChanges();
			this.formInitialized = true;
		}
	}

	private intitalizeDropDownSelectionOptions(): void {
		if (this.enableSelectAll) {
			this.legalEntitySelectionDataProvider.enableSelectAll();
		}

		this.getCustomersBySelection();
		this.getLegalEntitiesBySelectedCustomerId();

		if (this.payGroupSelector) {
			this.getPayGroupsBySelectedOptions();
		}
	}

	private getCustomersBySelection(): void {
		this.customerCacheService
			.getCustomersMapper()
			.pipe(takeUntil(this.destroy$))
			.subscribe(customerSelectionOption => (this.customerSelectionOptions = customerSelectionOption));
	}

	private getLegalEntitiesBySelectedCustomerId(): void {
		const SANTIZIED_FORM_FIELDS = this.formService.toCustomerEntitySelection(this.form);

		this.legalEntitySelectionDataProvider
			.getLegalEntitySelectionOptions(SANTIZIED_FORM_FIELDS.customerId, this.onlyLegalEntitiesWithPaygroups)
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe((legalEntities: SelectOption[]) => {
				this.legalEntitySelectionOptions = legalEntities;
			});
	}

	private getPayGroupsBySelectedOptions(): void {
		const SANTIZIED_FORM_FIELDS = this.formService.toCustomerEntitySelection(this.form);

		this.legalEntitySelectionDataProvider
			.getPaygroupSelectionOptions(
				SANTIZIED_FORM_FIELDS.legalEntityId,
				this.customerPaygroupStatus,
				SANTIZIED_FORM_FIELDS.customerId
			)
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe((paygroups: SelectOption[]) => {
				this.payGroupSelectionOptions = paygroups;
			});
	}

	private onFormFieldValueChanges(): void {
		this.onCustomerIdFieldChange();
		this.onLegalEntityFieldChange();
		this.onPayGroupSearchFieldChange();
		if (this.payGroupSelector) {
			this.onPayGroupIdFieldChange();
		}
	}

	private onCustomerIdFieldChange(): void {
		// Changes from Customer Selector
		this.form
			.get("customerId")
			?.valueChanges.pipe(takeUntil(this.destroy$), startWith(""), pairwise())
			.subscribe(([prev, next]: [SelectOption | string, SelectOption | string]) => {
				if (this.notSelectAllAndPreviousOptionIsTheSameAsNext(prev, next)) return;
				this.form.get("legalEntityId")?.patchValue("ALL_OPTIONS", { emitEvent: false });
				this.form.get("payGroupId")?.patchValue("ALL_OPTIONS", { emitEvent: false });
				if (this.updateCustomerSelectState) {
					this.emitSelectionToStore();
				} else {
					this.emitChangesToParent();
					this.getLegalEntitiesBySelectedCustomerId();
					this.getPayGroupsBySelectedOptions();
				}
			});
	}

	private onLegalEntityFieldChange(): void {
		this.form
			.get("legalEntityId")
			?.valueChanges.pipe(takeUntil(this.destroy$), startWith(""), pairwise())
			.subscribe(([prev, next]: [string | SelectOption, string | SelectOption]) => {
				if (this.notSelectAllAndPreviousOptionIsTheSameAsNext(prev, next)) return;
				this.form.get("payGroupId")?.patchValue("ALL_OPTIONS", { emitEvent: false });
				if (this.updateCustomerSelectState) {
					this.emitSelectionToStore();
				} else {
					this.emitChangesToParent();
					this.getPayGroupsBySelectedOptions();
				}
			});
	}

	private onPayGroupIdFieldChange(): void {
		this.form
			.get("payGroupId")
			?.valueChanges.pipe(takeUntil(this.destroy$), startWith(""), pairwise())
			.subscribe(([prev, next]: [string | SelectOption, string | SelectOption]) => {
				if (this.notSelectAllAndPreviousOptionIsTheSameAsNext(prev, next)) return;
				if (this.updateCustomerSelectState) {
					this.emitSelectionToStore();
				} else {
					this.emitChangesToParent();
				}
			});
	}

	private onPayGroupSearchFieldChange(): void {
		this.form
			.get("payGroupSearch")
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
			.subscribe(searchQuery => {
				this.payGroupSearchQuery = searchQuery;
				this.searchChanged.emit(searchQuery);
				this.emitChangesToParent();
			});
	}

	private notSelectAllAndPreviousOptionIsTheSameAsNext(
		prev: string | SelectOption,
		next: string | SelectOption
	): boolean {
		if (prev === "ALL_OPTIONS" || next === "ALL_OPTIONS") return false;
		if (prev == "" || next == "") return false;
		return prev == next;
	}

	emitChangesToParent(): void {
		this.customerEntitySelection.emit(this.formService.toCustomerEntitySelection(this.form));
	}

	private emitSelectionToStore(): void {
		const SANTIZIED_FORM_FIELDS = this.formService.toCustomerEntitySelection(this.form);

		var customerEntity = new UpdateCustomerEntityGroupAction({
			customerId: SANTIZIED_FORM_FIELDS.customerId !== "ALL_OPTIONS" ? SANTIZIED_FORM_FIELDS.customerId : "",
			legalEntityId:
				SANTIZIED_FORM_FIELDS.legalEntityId !== "ALL_OPTIONS" ? SANTIZIED_FORM_FIELDS.legalEntityId : "",
			payGroupId: SANTIZIED_FORM_FIELDS.payGroupId !== "ALL_OPTIONS" ? SANTIZIED_FORM_FIELDS.payGroupId : "",
			customerName:
				this.customerSelectionOptions.find(option => option.value === SANTIZIED_FORM_FIELDS.customerId)?.text ??
				""
		});

		this.store.dispatch(customerEntity);
		this.paginationService.resetPaginationState();
	}
}
