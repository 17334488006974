<modal-wrapper
	[title]="'Master File Management'"
	(onTitleButtonAction)="backFromModal()"
	[fileUpload]="true"
	[modalStyle]="'blue'"
	[noBreadCrumbs]="true"
	[exitIconBool]="true"
>
	<div class="flex flex-column">
		<div class="content-header">
			<button id="refreshButton" class="refresh-button" (click)="refreshFileManager()" mat-icon-button>
				<app-icon
					id="refresh-icon"
					[name]="'rotate-right'"
					[color]="'var(--color-disabled)'"
					[lineWidth]="'2px'"
					[size]="'20'"
				></app-icon>
			</button>

			<div class="button-container">
				<ng-template
					[appUserRolePermissions]
					[action]="'show'"
					[roles]="[
						'SUPER_ADMIN',
						'TS_OCB',
						'TS_IMPLEMENTATION_LEAD',
						'TS_IMPLEMENTATION_CONSULTANT',
						'TS_OPERATION_CONSULTANT',
						'TS_OPERATION_LEAD'
					]"
				>
					<button class="integration-btn" mat-button (click)="refreshIntegration()">
						<app-icon
							class="integrate-icon"
							[name]="'receipt-add'"
							[color]="'var(--color-filter-button)'"
							[lineWidth]="'1.5px'"
						></app-icon>
						<span class="integration-label-color">PAYROLL INTEGRATION</span>
					</button>
				</ng-template>

				<button *ngIf="canUploadFiles" class="upload-btn" mat-button (click)="uploader.click()">
					<span class="uploadText">
						<app-icon
							id="add-master-file-history"
							[name]="'document-upload'"
							[color]="'var(--color-white)'"
							[lineWidth]="'2px'"
						></app-icon>
						<span class="text-spacing">UPLOAD MASTER FILE</span>
					</span>
				</button>
				<input
					id="payElectiveUpload"
					hidden
					type="file"
					#uploader
					(change)="uploadPayElectiveFile(uploader.files)"
					(click)="uploader.value = ''"
				/>
			</div>
		</div>

		<ng-container>
			<no-items-layout
				*ngIf="!fileResults?.length && !fetchingFiles; else showItems"
				message="No files have been uploaded"
			></no-items-layout>
		</ng-container>

		<ng-template #showItems>
			<div class="flex flex-column" *ngIf="!fetchingFiles; else loading">
				<app-files-list [files]="fileResults"></app-files-list>
			</div>
		</ng-template>

		<ng-template #loading>
			<div class="pulsate loader">Fetching files...</div>
		</ng-template>

		<div
			class="load-more-btn-container flex flex-row flex-align-center-center"
			*ngIf="isShowLoadMore && !fetchingFiles"
		>
			<button class="load-more-btn" (click)="loadMoreFilesData()">Load more</button>
		</div>

		<div
			class="max-record-container flex flex-row flex-align-center-center"
			*ngIf="!isShowLoadMore && !fetchingFiles && fileResults?.length"
		>
			<span class="max-record">You have reached the end of the records</span>
		</div>
	</div>
</modal-wrapper>
