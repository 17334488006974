import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PageEvent as PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map, shareReplay, take, takeUntil } from "rxjs/operators";
import { CountriesService } from "@shared/services/countries/countries.service";
import { TppServicesService } from "@shared/services/tpp-services/tpp-services.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { TppService, TppServicePagination } from "src/app/shared/models/tpp-service.interface";
import { OrderServicesTypePipe } from "./pipes/order-services-type.pipe";

import { AppState } from "../../store/models/state.model";
import { Store } from "@ngrx/store";
import { UpdateSelectedTPPService } from "src/app/store/actions/tppServiceSelect.action";
import { TppServiceManagementService } from "./_services/management/tpp-service-management.service";
import { TppAccount } from "src/app/shared/models/tpp-account.interface";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-tpp-catalogue",
	templateUrl: "./tpp-catalogue.component.html",
	styleUrls: ["./tpp-catalogue.component.scss"]
})
export class TppCatalogueComponent implements OnInit, OnDestroy {
	form!: FormGroup;
	country$!: Observable<SelectOption[]>;

	pageSize: number = 10;
	pageIndex: number = 0;
	totalPageCount: number = 1;
	countryCode: string = "";
	tppServicesTypes$!: Observable<Map<string, TppService[]>>;
	disableButton: boolean = true;

	destroy$: Subject<void> = new Subject();

	showInfoArray: Record<string, boolean> = {};
	canCreateNewCatService = false;

	constructor(
		private store: Store<AppState>,
		private formBuilder: FormBuilder,
		private countriesService: CountriesService,
		private router: Router,
		private tppServiceService: TppServicesService,
		private tppServiceManagementService: TppServiceManagementService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canCreateNewCatService()
			.pipe(take(1))
			.subscribe(res => {
				this.canCreateNewCatService = res;
				this.countryCode = this.tppServiceManagementService.countryCodeServiceTPP;
				this.initForm();
				this.setCountrySelection();
				this.tppServiceManagementService.resetTppService();
			});

		this.tppServiceService.updateTPPCatalogueFilterCountry$.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				this.countryCode = res;
				this.disableButton = false;

				this.form.get("country")?.setValue(this.countryCode);
			}
		});
	}

	initForm() {
		this.form = this.formBuilder.group({
			country: [""]
		});

		this.onChanges();
	}

	onChanges() {
		this.form
			.get("country")
			?.valueChanges.pipe(
				map((value: string) => {
					this.countryCode = value;
					this.disableButton = false;
					this.searchTppServices(this.countryCode, this.pageIndex, this.pageSize);
				})
			)
			.subscribe();
	}

	setCountrySelection() {
		this.country$ = this.countriesService.getCountryOptions();
		if (this.countryCode) this.form.patchValue({ country: this.countryCode });
	}

	addNewService() {
		this.dispatchToStore(this.countryCode, null);
		this.router.navigate(["tpp-catalogue/add-new-service"]);
	}

	selectTppService(tppService: TppService) {
		this.dispatchToStore(this.countryCode, tppService);
		this.router.navigate(["tpp-catalogue/edit-service"]);
	}

	dispatchToStore(countryCode: string, tppService: TppService | null): void {
		this.store.dispatch(
			new UpdateSelectedTPPService({
				selectedTPPService: {
					countryCode: countryCode,
					tppService: tppService
				}
			})
		);
	}

	searchTppServices(countryCode: string, pageIndex: number, pageSize: number) {
		this.tppServicesTypes$ = this.tppServiceService
			.searchTPPServiceByCountry(countryCode, pageIndex.toString(), pageSize.toString())
			.pipe(
				shareReplay(),
				map((response: TppServicePagination) => {
					this.totalPageCount = response.totalCount;

					for (let i = 0; i < response.totalCount; i++) {
						if (response.items[i]) {
							this.showInfoArray[response.items[i]?.id] = false;
						}
					}

					return new OrderServicesTypePipe().transform(response.items);
				})
			);
	}

	paginate(pagination: PageEvent) {
		this.pageSize = pagination.pageSize;
		this.pageIndex = pagination.pageIndex;
		this.searchTppServices(this.countryCode, this.pageIndex, this.pageSize);
	}

	showInfoChange(index: string, show: boolean, event: any) {
		this.showInfoArray[index] = show;
		event.stopPropagation();
	}

	showDesc(index: string): boolean {
		return this.showInfoArray[index];
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.tppServiceManagementService.countryCodeServiceTPP = "";
		this.destroy$.complete();
	}
}
