import { Component, OnInit } from "@angular/core";
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ServiceProviderCountryCreateService } from "../../_services/service-provider-country-create/service-provider-country-create.service";

@Component({
	selector: "app-warning-dialog",
	templateUrl: "./warning-dialog.component.html",
	styleUrls: ["./warning-dialog.component.scss"]
})
export class WarningDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<WarningDialogComponent>,
		private serviceProviderCountryCreate: ServiceProviderCountryCreateService
	) {}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}

	onConfirm(): void {
		this.serviceProviderCountryCreate.cancelReset();
		this.dialogRef.close(true);
	}
}
