import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { environment as env } from "../environments/environment";

import { MatCardModule } from "@angular/material/card";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { PagesModule } from "@modules/pages.module";
import { EntityDataModule } from "@ngrx/data";
import { RouterState, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { ToastModule } from "@shared/components/toast/toast.module";
import { paginationReducer } from "@store/reducers/pagination.reducer";
import { userDataReducer } from "@store/reducers/userData.reducer";
import { BREAKPOINTS, FlexLayoutModule } from "ngx-flexible-layout";
import { HttpErrorInterceptor } from "./core/interceptors/http-error.interceptor";
import { HttpLoaderInterceptor } from "./core/interceptors/http-loader.interceptor";
import { getCustomBreakpoints } from "./core/settings/custom-breakpoints";
import { calendarMonthSelectReducer } from "./store/reducers/currentPayCycle.reducer";
import { customerEntityCountrySelectReducer } from "./store/reducers/customerEntityCountrySelect.reducer";
import { customerEntityGroupSelectReducer } from "./store/reducers/customerEntityGroupSelect.reducer";
import { globalDashboardFilterSelectReducer } from "./store/reducers/globalDashboardFilterSelect.reducer";
import { netPaymentReducer } from "./store/reducers/netPayment.reducer";
import { providerSelectReducer } from "./store/reducers/providerSelect.reducer";
import { beneficiarySelectReducer } from "./store/reducers/tppBeneficiarySelect.reducer";
import { tppFormSubServicesSelectReducer } from "./store/reducers/tppFormSubServicesSelect.reducer";
import { tppGroupConfigStepOneSelectReducer } from "./store/reducers/tppGroupConfigStepOneSelect.reducer";
import { tppServiceSelectReducer } from "./store/reducers/tppServiceSelect.reducer";

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AuthModule.forRoot({
			...env.auth,
			httpInterceptor: {
				...env.httpInterceptor
			},
			cacheLocation: "localstorage"
		}),
		PagesModule,
		MatSnackBarModule,
		ToastModule,
		FlexLayoutModule,
		MatCardModule,
		MatDialogModule,
		MatNativeDateModule,
		StoreModule.forRoot(
			{
				customerEntityGroup: customerEntityGroupSelectReducer,
				selectedCalendarMonth: calendarMonthSelectReducer,
				provider: providerSelectReducer,
				selectedTPPService: tppServiceSelectReducer,
				customerEntityCountry: customerEntityCountrySelectReducer,
				beneficiary: beneficiarySelectReducer,
				selectedTPPFormSubServices: tppFormSubServicesSelectReducer,
				tppGroupConfigStepOneData: tppGroupConfigStepOneSelectReducer,
				globalDashboardFilter: globalDashboardFilterSelectReducer,
				netPaymentState: netPaymentReducer,
				userData: userDataReducer,
				pagination: paginationReducer
			},
			{
				runtimeChecks: {
					strictStateImmutability: true,
					strictActionImmutability: true,
					strictActionSerializability: true,
					strictStateSerializability: true
				}
			}
		),
		EntityDataModule.forRoot({}),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: env.production }),
		StoreRouterConnectingModule.forRoot({
			stateKey: "router",
			routerState: RouterState.Minimal
		})
	],

	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthHttpInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpLoaderInterceptor,
			multi: true
		},

		{
			provide: BREAKPOINTS,
			useFactory: getCustomBreakpoints
		},

		{ provide: MAT_DATE_LOCALE, useValue: "en-GB" }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
