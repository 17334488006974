import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";
import { Location } from "@angular/common";
import { map, shareReplay, tap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { FileManagementService } from "../../../../shared/services/file-management/file-management.service";
import {
	EmployeeError,
	FileReport,
	FileTypes,
	IntegrationError,
	tppError
} from "../../../../shared/models/file-manager";
import { PayGroup } from "../../../../shared/models/pay-groups";

@Component({
	selector: "app-file-errors",
	templateUrl: "./file-errors.component.html",
	styleUrls: ["./file-errors.component.scss"]
})
export class FileErrorsComponent implements OnInit {
	@Input() payGroup!: PayGroup;
	@Input() taskId = "";
	@Input() errorType: FileTypes = FileTypes.G2N;
	@Input() integrationErrorMessages!: IntegrationError[];

	reportData$!: Observable<FileReport>;

	filteredEmployees$!: Observable<EmployeeError[]>;
	filteredTPPErrors$!: Observable<tppError[]>;

	filteredPayrollErrors$!: Observable<IntegrationError[]>;

	originalEmployeeArray: EmployeeError[] = [];
	originalTppArray: tppError[] = [];

	searchForm!: FormGroup;

	constructor(
		private fileManagementService: FileManagementService,
		private formBuilder: FormBuilder,
		private _location: Location
	) {}

	ngOnInit(): void {
		if (this.errorType !== FileTypes.PAYROLL_INTEGRATION) this.reportData$ = this.getErrors();

		if (this.errorType === FileTypes.TPP) {
			this.filteredTPPErrors$ = this.reportData$.pipe(
				map(res => {
					return res.tppErrors;
				})
			);
		} else if (this.errorType !== FileTypes.PAYROLL_INTEGRATION) {
			this.filteredEmployees$ = this.reportData$.pipe(
				map(res => {
					return res.employeeErrors;
				})
			);
		} else {
			this.filteredPayrollErrors$ = of(this.integrationErrorMessages);
		}

		this.initForm();
	}

	initForm(): void {
		this.searchForm = this.formBuilder.group({
			search: [""]
		});

		this.searchForm
			.get("search")
			?.valueChanges.pipe(debounceTime(400), distinctUntilChanged())
			.subscribe(searchValue => {
				if (this.errorType === "TPP") {
					this.filterResultsForTPPSearch(searchValue);
				} else if (this.errorType === "Payroll integration") {
					this.filterResultsForPayrollSearch(searchValue);
				} else {
					this.filterResultsForG2NAndEmployeeSearch(searchValue);
				}
			});
	}

	filterResultsForPayrollSearch(searchValue: string): void {
		if (!!searchValue.trim()) {
			this.filteredPayrollErrors$ = of(
				this.integrationErrorMessages.filter(errorMessage => {
					if (errorMessage.message.toLowerCase().includes(searchValue.toLowerCase())) {
						return true;
					} else {
						return null;
					}
				})
			);

			this.reportData$.pipe(
				map((result: FileReport) => {
					return result.employeeErrors.filter(employeeErrors => {
						if (
							employeeErrors.employee.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
							employeeErrors.employee.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
							employeeErrors.employee.externalId.toLowerCase().includes(searchValue.toLowerCase())
						) {
							return true;
						} else {
							return null;
						}
					});
				})
			);
		} else {
			this.filteredPayrollErrors$ = of(this.integrationErrorMessages);
		}
	}

	filterResultsForG2NAndEmployeeSearch(searchValue: string): void {
		if (!!searchValue.trim()) {
			this.filteredEmployees$ = this.reportData$.pipe(
				map((result: FileReport) => {
					return result.employeeErrors.filter(employeeErrors => {
						if (
							employeeErrors.employee.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
							employeeErrors.employee.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
							employeeErrors.employee.externalId.toLowerCase().includes(searchValue.toLowerCase())
						) {
							return true;
						} else {
							return null;
						}
					});
				})
			);
		} else {
			this.filteredEmployees$ = this.reportData$.pipe(map(res => res.employeeErrors));
		}
	}
	filterResultsForTPPSearch(searchValue: string): void {
		if (!!searchValue.trim()) {
			this.filteredTPPErrors$ = this.reportData$.pipe(
				map((result: FileReport) => {
					return result.tppErrors.filter(tppErrors => {
						if (
							tppErrors.service.externalId.toLowerCase().includes(searchValue.toLowerCase()) ||
							tppErrors.service.serviceName.toLowerCase().includes(searchValue.toLowerCase()) ||
							tppErrors.service.subServiceName.toLowerCase().includes(searchValue.toLowerCase()) ||
							tppErrors.service.serviceId.toLowerCase().includes(searchValue.toLowerCase()) ||
							tppErrors.service.subServiceId.toLowerCase().includes(searchValue.toLowerCase())
						) {
							return true;
						} else {
							return null;
						}
					});
				})
			);
		} else {
			this.filteredTPPErrors$ = this.reportData$.pipe(map(res => res.tppErrors));
		}
	}

	getErrors(): Observable<FileReport> {
		return this.fileManagementService.getFileByTaskId(this.taskId, -1, 0).pipe(
			tap(result => {
				if (this.errorType === "TPP") {
					this.originalTppArray = result.tppErrors;
				} else {
					this.originalEmployeeArray = result.employeeErrors;
					//Side note - we're only looking at valiudation errors show everything / warnings / execution errors
				}
			}),
			shareReplay()
		);
	}

	backFromModal() {
		this._location.back();
	}
}
