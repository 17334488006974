<div class="page-title">Settlement Accounts</div>
<div class="container-source-funs">
	<div class="container-header">
		<div class="header-title">GROUP {{ _groupToConfig }}</div>
	</div>
	<div
		id="settlementAccountFormsContainer"
		[formGroup]="formSettlement"
		class="container-body"
		*ngIf="_serviceProvidersOptions.length"
	>
		<div id="serviceProviderInputContainer" class="service-provider-input">
			<input-wrapper label="SERVICE PROVIDERS">
				<input-autocomplete
					id="serviceProviderSelector"
					formControlName="serviceProvider"
					label="Select service provider"
					[options]="_serviceProvidersOptions"
					[defaultValue]="_serviceProviderId"
					[disabled]="!canEditSettlementAccountTPP"
				>
				</input-autocomplete>
			</input-wrapper>
		</div>

		<div id="arrowContainer" class="arrow-container">
			<div id="arrowContainerChild" class="arrow-container-child" fxLayout="row" fxLayoutAlign="center none">
				<div id="arrowLine" class="arrow-line"></div>
				<div id="arrowPoint" class="arrow-right"></div>
			</div>
		</div>

		<div id="paymentCurrencyInputContainer" class="payment-currency-input">
			<input-wrapper label="PAYMENT CURRENCY">
				<input-autocomplete
					id="defaultPaymentCurrency"
					formControlName="defaultPaymentCurrency"
					label="Select payment currency"
					[options]="paymentCurrencyOptions"
					[disabled]="!canEditSettlementAccountTPP"
				>
				</input-autocomplete>
			</input-wrapper>
		</div>

		<!--arrow-->
		<div id="arrowContainer" class="arrow-container">
			<div id="arrowContainerChild" class="arrow-container-child" fxLayout="row" fxLayoutAlign="center none">
				<div id="arrowLine" class="arrow-line"></div>
				<div id="arrowPoint" class="arrow-right"></div>
			</div>
		</div>

		<div id="settlementAccountInputContainer" class="settlement-account-input">
			<input-wrapper label="CHOOSE EXISTING ACCOUNT">
				<input-autocomplete
					id="settlementAccount"
					formControlName="settlementAccount"
					label="Select existing account"
					[options]="_settlementAccountOptions"
					[defaultValue]="_settlementAccountId"
					[disabled]="!canEditSettlementAccountTPP || !this.isActive"
				>
				</input-autocomplete>
				<button
					id="addSettlementAccountButton"
					mat-button
					class="bt-source-of-funds"
					(click)="addNewSettlementAccount(false)"
					[disabled]="
						formSettlement.controls.serviceProvider.invalid ||
						formSettlement.controls.defaultPaymentCurrency.invalid ||
						!canEditSettlementAccountTPP
					"
				>
					+&nbsp;&nbsp;&nbsp;Add new settlement account
				</button>
			</input-wrapper>
		</div>
		<div
			id="editSettlementAccountButtonContainer"
			fxFlex="10"
			class="container-btn"
			fxLayout="row"
			fxLayoutAlign="end start"
		>
			<button
				id="editSettlementAccountButton"
				[disabled]="
					formSettlement.controls.serviceProvider.invalid ||
					formSettlement.controls.defaultPaymentCurrency.invalid ||
					formSettlement.controls.settlementAccount.invalid
				"
				[color]="'none'"
				mat-mini-fab
				aria-label="View Settlement Account"
				(click)="addNewSettlementAccount(true)"
			>
				<app-icon *ngIf="editSettlementAccountEnabled" name="eye" [color]="'#231F20'"></app-icon>
				<app-icon *ngIf="!editSettlementAccountEnabled" name="eye" [color]="'#CDCED9'"></app-icon>
			</button>
		</div>
	</div>
</div>
<div id="buttonsContainer" fxLayout="row" fxLayoutAlign="end center" class="bt-contanier">
	<button id="previousButton" class="cancel-btn" (click)="previous()" action-buttons mat-flat-button>PREVIOUS</button>
	<button
		id="saveSubserviceButton"
		class="mat-stroked-button submit-btn"
		[disabled]="
			formSettlement.controls.serviceProvider.invalid ||
			formSettlement.controls.defaultPaymentCurrency.invalid ||
			formSettlement.controls.settlementAccount.invalid
		"
		(click)="save()"
	>
		FINISH
	</button>
</div>
