import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { Store, select } from "@ngrx/store";
import { isEqual } from "lodash";
import { BehaviorSubject, Observable, Subject, forkJoin } from "rxjs";
import { distinctUntilChanged, map, take, takeUntil } from "rxjs/operators";

import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { LoaderService } from "@shared/services/loader/loader.service";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { Pagination } from "src/app/shared/models/pagination.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { getGlobalDashboardFilterState } from "src/app/store";
import { UpdateDashboardFilterAction } from "src/app/store/actions/globalDashboardFilterSelect.action";
import { AppState } from "src/app/store/models/state.model";
import {
	CardDetails,
	CustomerTableDetailItem,
	CustomerTableDetailWithPagination,
	DashboardFilters,
	EventsTableDetailItem,
	EventsTableDetailsWithPagination,
	FailedBeneficiariesTableDetailItem,
	FailedBeneficiariesTableDetailWithPagination,
	FailedPaymentOrdersTableDetailItem,
	FailedPaymentOrdersTableDetailWithPagination,
	FailedTransactionsTableDetailItem,
	FailedTransactionsTableDetailWithPagination,
	IDashboardFiltersFormGroup,
	LegalEntityDetailsWithPaygroups,
	MissingBankAccountsTableDetailItem,
	MissingBankAccountsTableDetailWithPagination,
	MissingCardTableDetailWithPagination,
	MissingCardsTableDetailItem,
	PayGroupDetails,
	PaygroupTableDetailItem,
	PaygroupTableDetailWithPagination
} from "../models/global-dashboard-interface";
import { DataFormattingService } from "../services/data-fomatting.service";
import { GlobalDashboardApiService } from "../services/global-dashboard-api.service";
import { GlobalDashboardEventsService } from "../services/global-dashboard-events.service";
import { HistoryLogSidePanelService } from "@shared/components/history-log-side-panel/services/history-log-side-panel.service";
import { HistoryLogType } from "@shared/components/history-log-side-panel/models/history-log-interface";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { LegalEntity } from "@shared/models/legal-entity.interface";
import { CountriesService } from "@shared/services/countries/countries.service";
import { Country } from "@shared/models/country.interface";
import { MatDialog } from "@angular/material/dialog";
import { HideEventsDialogComponent } from "../_components/hide-events-dialog/hide-events-dialog.component";
import { MilestoneUpdateIds } from "@shared/models/pay-cycle.interface";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { MilestonesService } from "@shared/services/milestones/milestones.service";

@Component({
	selector: "app-global-dashboard",
	templateUrl: "./global-dashboard.component.html",
	styleUrls: ["./global-dashboard.component.scss"]
})
export class GlobalDashboardComponent implements OnInit, OnDestroy {
	todaysDate: Date = new Date();
	kpiCards: CardDetails[] = this.dataFormattingService.getKPIDefaultValue();
	showDefaultTableView = true;
	filters!: FormGroup;
	kpiSelected: string = "";
	pagination: Pagination = {
		pageSize: 10,
		pageIndex: 0,
		totalPageCount: 0
	};
	tableTitle: string = "";
	icon: string = "";
	dataLandingPageEvents!: EventsTableDetailItem[];
	dataLandingPageCustomers!: CustomerTableDetailItem[];
	dataLandingPagePaygroups!: PaygroupTableDetailItem[];
	dataMissingCards: MissingCardsTableDetailItem[] = [];
	dataMissingBankAccounts: MissingBankAccountsTableDetailItem[] = [];
	dataFailedBeneficiaries: FailedBeneficiariesTableDetailItem[] = [];
	dataFailedTransactions: FailedTransactionsTableDetailItem[] = [];
	dataFailedPaymentOrders: FailedPaymentOrdersTableDetailItem[] = [];
	customersSelectOptions = new BehaviorSubject<SelectOption[]>([]);
	legalEntitiesSelectOptions = new BehaviorSubject<SelectOption[]>([]);
	payGroupsSelectOptions = new BehaviorSubject<SelectOption[]>([]);
	groupSelectOptions = new BehaviorSubject<SelectOption[]>([]);
	statusSelectOptions = new BehaviorSubject<SelectOption[]>([]);
	filterPageSize: number = -1;
	filterPageNumber: number = 0;
	filterTotalCount: number = -1;
	fetchingDataTable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	fetchingDataForFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	noEntriesFound: boolean = false;
	encounteredErrorWhileFecthingData = false;
	showHistoryLogPanel = false;
	isClient = false;
	HistoryLogType = HistoryLogType;
	headerFlag = "";
	canSeeMap = false;
	clientSelectedCountry!: string;
	clientCountryList!: Country[] | undefined;
	newClientCountryList: SelectOption[] = [];
	clientCustomerId!: string;
	isClientGlobalDashboard = false;
	selectedEvents: EventsTableDetailItem[] = [];
	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private formBuilder: FormBuilder,
		private globalDashboardApiService: GlobalDashboardApiService,
		private store: Store<AppState>,
		private legalEntityService: LegalEntityService,
		private paygroupsService: PaygroupsService,
		private cdr: ChangeDetectorRef,
		private dataFormattingService: DataFormattingService,
		private loaderService: LoaderService,
		private globalDashboardEventsService: GlobalDashboardEventsService,
		private historyLogService: HistoryLogSidePanelService,
		private permissionsService: PermissionsService,
		private countriesService: CountriesService,
		private payCycleService: PayCycleService,
		private milestonesService: MilestonesService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.permissionsService
			.canSeeMapAndIsClient()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: { isClient: boolean; canSeeMap: boolean }) => {
				this.canSeeMap = res.canSeeMap;
				this.isClient = res.isClient;
				this.setupGlobalDashboard();
			});

		this.permissionsService
			.enableClientGlobalFilter()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.isClientGlobalDashboard = res.isClientGlobalDashboard!;
			});
	}

	setupGlobalDashboard(): void {
		this.initialiseForm();
		this.getSelectionOptions();
		this.setupStateListeners();
		this.setupHistoryLogSubscription();
		this.getClientLegalEntities(this.filters.get("customers")?.value[0]);
	}

	initialiseForm(): void {
		this.filters = this.formBuilder.group({
			customers: new FormControl([]),
			legalEntities: new FormControl([]),
			paygroups: new FormControl([]),
			statuses: new FormControl([]),
			groups: new FormControl([]),
			deadline: new FormControl(""),
			milestoneTypes: new FormControl([])
		}) as IDashboardFiltersFormGroup;

		this.filters.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(form => {
			this.cdr.detectChanges(); //Prevents ExpressionChangedAfterItHasBeenCheckedError
		});

		//Break up valueChanges so that all endpoints don't get run when chnaging one dropdown's value

		this.filters
			.get("customers")
			?.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
			.subscribe(customerIds => {
				if (customerIds.length > 0) {
					this.legalEntityService
						.getLegalEntitiesForCustomersAsSelectOption(
							this.checkIfAllIsSelected([...customerIds], "customers")
						)
						.pipe(
							takeUntil(this.destroy$),
							map(legalEntities => {
								if (this.canSeeMap)
									this.setHeaderFlag(legalEntities, this.filters.get("legalEntities")?.value[0]);
								this.legalEntitiesSelectOptions.next(legalEntities);
							})
						)
						.subscribe();
				} else {
					this.legalEntitiesSelectOptions.next([]);
				}
			});

		this.filters
			.get("legalEntities")
			?.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
			.subscribe(legalEntityIds => {
				if (legalEntityIds.length > 0) {
					this.paygroupsService
						.getPaygroupsByGlobalDasboardFiltersAsSelectOptions(
							this.checkIfFilterHasAllSelected(this.filters)
						)
						.pipe(
							takeUntil(this.destroy$),
							map(payGroups => {
								this.payGroupsSelectOptions.next(payGroups);
							})
						)
						.subscribe();
				} else {
					this.payGroupsSelectOptions.next([]);
				}
			});
	}

	/**
	 * Generate the svg file path of the client selected country
	 * @param allLegalEntityOptions : array of legal entities of the customer
	 * @param legalEntityIdForCountry : entity id of the selected country
	 */
	setHeaderFlag(allLegalEntityOptions: SelectOption[], legalEntityIdForCountry: string) {
		let countryIcon = allLegalEntityOptions.filter(f => {
			return f.value == legalEntityIdForCountry;
		})[0]?.imagePath;
		this.headerFlag = countryIcon ? countryIcon : "";
	}

	/**
	 * Use to get client entities
	 * @param customerId: Client customer ID
	 */
	getClientLegalEntities(customerId: string): void {
		this.clientCustomerId = customerId;
		this.legalEntityService
			.getLegalEntitiesForCustomer(customerId)
			.pipe(take(1))
			.subscribe({
				next: res => {
					const legalEntities: LegalEntity[] = res.items;
					this.newFetchClientCountryList(legalEntities);
				}
			});
	}

	/**
	 * Fetch client country list based on it's legal entities
	 * @param legalEntities: Client legal entities
	 */
	newFetchClientCountryList(legalEntities: LegalEntity[]): void {
		this.countriesService
			.getCountries()
			.pipe(take(1))
			.subscribe({
				next: countries => {
					this.newClientCountryList = [];
					legalEntities.forEach(legalEntity => {
						const existingIndex: number = this.newClientCountryList.findIndex(
							(country: SelectOption) => country.value === legalEntity.data.country
						);

						if (existingIndex === -1) {
							this.newClientCountryList.push(
								this.countriesService.filterCountryByCode(countries, legalEntity.data.country)
							);

							this.newClientCountryList[this.newClientCountryList.length - 1].valueArray = [
								legalEntity.id
							];
						} else {
							this.newClientCountryList[existingIndex].valueArray?.push(legalEntity.id);
						}
					});

					this.clientSelectedCountry = this.newClientCountryList.find(legalEntity => {
						const legalEntities = Array.isArray(this.filters.get("legalEntities")?.value)
							? this.filters.get("legalEntities")?.value[0]
							: this.filters.get("legalEntities")?.value;
						return legalEntity.valueArray?.includes(legalEntities.toString());
					})?.text as "";
				}
			});
	}

	/**
	 * Fetch client country list to use in breadcrumb modal
	 * @param legalEntities: Client legal entities
	 */
	fetchClientCountryList(legalEntities: LegalEntity[]): void {
		this.countriesService
			.getCountries()
			.pipe(take(1))
			.subscribe({
				next: countries => {
					this.clientCountryList = [
						...new Set(legalEntities.map(m => countries.find(f => f.country.code === m.data.country)))
					] as Country[];
				}
			});
	}

	checkIfFilterHasAllSelected(filter: FormGroup<any>): DashboardFilters {
		const copiedFilter = filter.getRawValue(); // prevent manipulating the this.filters value

		const formKeys = Object.keys(copiedFilter);
		const endIndex = 5;

		for (let i = 0; i < endIndex; i++) {
			const key = formKeys[i];
			let keyValue = copiedFilter[key];

			if (
				keyValue.some(
					(value: string) =>
						value === "customers" ||
						value === "legal entities" ||
						value === "paygroups" ||
						value === "statuses" ||
						value === "groups"
				)
			) {
				copiedFilter[key] = [];
			}
		}

		return copiedFilter;
	}
	checkIfAllIsSelected(selection: string[], allValue: string): string[] {
		return selection.includes(allValue) ? [""] : selection;
	}

	getSelectionOptions(): void {
		this.setCustomerSelectOptions();
		this.setGroupSelectOptions();
		this.setStatusSelectOptions();
	}

	setCustomerSelectOptions(): void {
		this.globalDashboardApiService
			.getCustomerSelectOptions(this.filterPageSize, this.filterPageNumber)
			.pipe(takeUntil(this.destroy$))
			.subscribe(customers => {
				this.customersSelectOptions.next(customers);
			});
	}

	setGroupSelectOptions(): void {
		this.groupSelectOptions.next(this.globalDashboardApiService.getGroupSelectionOptions());
	}

	setStatusSelectOptions(): void {
		this.statusSelectOptions.next(this.globalDashboardApiService.getStatusSelectionOptions());
	}

	setupStateListeners(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe(state => {
			if (state && state.globalDashboard && state.globalDashboard.filters) {
				if (this.kpiSelected !== state.globalDashboard.kpiSelected) {
					this.resetTableComponent();
					this.resetPagination();
					this.showDefaultTableView = true;
				}

				if (!isEqual(state.globalDashboard.filters, this.filters.getRawValue())) {
					this.filters.patchValue(state.globalDashboard.filters);
				}

				if (this.isClient && state.globalDashboard.kpiSelected === "Customers") {
					this.kpiSelected = "Paygroups";
				} else if (!this.isClient && state.globalDashboard.kpiSelected === "Paygroups") {
					this.kpiSelected = "Customers";
				} else {
					this.kpiSelected = state.globalDashboard.kpiSelected;
				}
			} else {
				this.kpiSelected = "";
			}
		});

		//Hide blue spinner
		this.loaderService.visibility$
			.pipe(takeUntil(this.destroy$))
			.subscribe(showSpinner => (showSpinner ? this.loaderService.visibility$.next(false) : false));
	}

	setupHistoryLogSubscription(): void {
		this.historyLogService.milestoneEventHistoryLogSubject.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				if (res.milestoneId !== "") {
					this.showHistoryLogPanel = true;
				} else {
					this.showHistoryLogPanel = false;
				}
			}
		});
	}

	patchNewData(filters: DashboardFilters): void {
		if (!this.canSeeMap && !this.isClientGlobalDashboard) {
			/** Handling for Client user role */
			if (!isEqual(this.filters.getRawValue(), filters)) {
				this.filters.patchValue(filters);
			}
		}
	}

	applyFilters(): void {
		if (this.filters.get("customers")?.value.length > 0) {
			this.fetchingDataForFilters.next(true);
			this.loadDataBasedOnKPI(this.kpiSelected);
			this.showDefaultTableView = false;
		} else {
			this.resetTableComponent();
			this.resetPagination();
			this.kpiCards = this.dataFormattingService.getKPIDefaultValue();
			this.showDefaultTableView = true;
		}
	}

	/**
	 * Trigger applyFilters() after a second when updating
	 * in global dashboard event list
	 */
	milestoneDropdownFilterUpdate(): void {
		this.fetchingDataForFilters.next(true);
		this.fetchingDataTable.next(true);
		setTimeout(() => {
			this.applyFilters();
		}, 1000);
	}

	selectEvents(events: EventsTableDetailItem[]): void {
		this.selectedEvents = events;
	}

	hideEvent(element?: EventsTableDetailItem): void {
		const dialogRef = this.dialog.open(HideEventsDialogComponent, {
			autoFocus: true,
			disableClose: true,
			panelClass: "hide-events-dialog-container",
			width: "90vw",
			maxWidth: "769px",
			height: "80vh",
			maxHeight: "409px",
			data: element ? 1 : this.selectedEvents.length
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result) return;
			const selectedMilestones: MilestoneUpdateIds = { ids: [] };

			selectedMilestones.ids = element
				? [element.milestoneDetails.id]
				: this.selectedEvents.map(milestone => milestone.milestoneDetails.id);

			this.milestonesService.hideMilestones(selectedMilestones).subscribe({
				next: res => {
					this.milestoneDropdownFilterUpdate();
				}
			});
		});
	}

	getCardDetails(): Observable<CardDetails[]> {
		return this.globalDashboardApiService
			.getCardDetails(
				this.checkIfFilterHasAllSelected(this.filters),
				this.dataFormattingService.getAvailableFiltersLayers(this.kpiSelected)
			)
			.pipe(
				map(cards => {
					this.fetchingDataForFilters.next(false);
					this.kpiCards = cards;
					return this.kpiCards;
				})
			);
	}

	loadMissingCardsTableDetails(): Observable<MissingCardsTableDetailItem[]> {
		this.resetTableComponent();
		return this.globalDashboardApiService
			.getMissingCardsTableDetails(
				this.checkIfFilterHasAllSelected(this.filters),
				this.pagination.pageSize,
				this.pagination.pageIndex
			)
			.pipe(
				map(cards => {
					this.icon = "card";
					this.tableTitle = "Missing Cards";
					if ("failed" in cards && cards.failed) {
						this.encounteredErrorWhileFecthingData = true;
						this.dataMissingCards = [];
						return this.dataMissingCards;
					} else {
						cards = cards as MissingCardTableDetailWithPagination;
						this.pagination.totalPageCount = cards.totalCount;
						this.dataMissingCards = cards.items;
						this.noEntriesFound = this.showNoEntries(this.dataMissingCards.length);
						return this.dataMissingCards;
					}
				})
			);
	}

	loadMissingBankAccountsTableDetails(): Observable<MissingBankAccountsTableDetailItem[]> {
		this.resetTableComponent();
		return this.globalDashboardApiService
			.getMissingBankAccountsTableDetails(
				this.checkIfFilterHasAllSelected(this.filters),
				this.pagination.pageSize,
				this.pagination.pageIndex
			)
			.pipe(
				map(bankAccounts => {
					this.icon = "bank-figma";
					this.tableTitle = "Missing Bank Accounts";
					if ("failed" in bankAccounts && bankAccounts.failed) {
						this.encounteredErrorWhileFecthingData = true;
						this.dataMissingBankAccounts = [];
						return this.dataMissingBankAccounts;
					} else {
						bankAccounts = bankAccounts as MissingBankAccountsTableDetailWithPagination;
						this.pagination.totalPageCount = bankAccounts.totalCount;
						this.dataMissingBankAccounts = bankAccounts.items;
						this.noEntriesFound = this.showNoEntries(this.dataMissingBankAccounts.length);
						return this.dataMissingBankAccounts;
					}
				})
			);
	}

	loadFailedBeneficiariesTableDetails(): Observable<FailedBeneficiariesTableDetailItem[]> {
		this.resetTableComponent();
		return this.globalDashboardApiService
			.getFailedBeneficiariesTableDetails(
				this.checkIfFilterHasAllSelected(this.filters),
				this.pagination.pageSize,
				this.pagination.pageIndex
			)
			.pipe(
				map(failedBens => {
					this.icon = "profile-2user";
					this.tableTitle = "Failed beneficiaries";
					if ("failed" in failedBens && failedBens.failed) {
						this.encounteredErrorWhileFecthingData = true;
						this.dataFailedBeneficiaries = [];
						return this.dataFailedBeneficiaries;
					} else {
						failedBens = failedBens as FailedBeneficiariesTableDetailWithPagination;
						this.pagination.totalPageCount = failedBens.totalCount;
						this.dataFailedBeneficiaries = failedBens.items;
						this.noEntriesFound = this.showNoEntries(this.dataFailedBeneficiaries.length);
						return this.dataFailedBeneficiaries;
					}
				})
			);
	}

	loadFailedTransactionsTableDetails(): Observable<FailedTransactionsTableDetailItem[]> {
		this.resetTableComponent();
		return this.globalDashboardApiService
			.getFailedTransactionsTableDetails(
				this.checkIfFilterHasAllSelected(this.filters),
				this.pagination.pageSize,
				this.pagination.pageIndex
			)
			.pipe(
				map(failedTransactions => {
					this.icon = "wallet-add";
					this.tableTitle = "Failed Transactions";
					if ("failed" in failedTransactions && failedTransactions.failed) {
						this.encounteredErrorWhileFecthingData = true;
						this.dataFailedTransactions = [];
						return this.dataFailedTransactions;
					} else {
						failedTransactions = failedTransactions as FailedTransactionsTableDetailWithPagination;
						this.pagination.totalPageCount = failedTransactions.totalCount;
						this.dataFailedTransactions = this.dataFormattingService.formatFailedTransactions(
							failedTransactions.items
						);
						this.noEntriesFound = this.showNoEntries(this.dataFailedTransactions.length);
						return this.dataFailedTransactions;
					}
				})
			);
	}

	loadFailedPaymentOrdersTableDetails(): Observable<FailedPaymentOrdersTableDetailItem[]> {
		this.resetTableComponent();
		return this.globalDashboardApiService
			.getFailedPaymentOrdersTableDetails(
				this.checkIfFilterHasAllSelected(this.filters),
				this.pagination.pageSize,
				this.pagination.pageIndex
			)
			.pipe(
				map(failedPaymentOrders => {
					this.icon = "document-copy";
					this.tableTitle = "Failed Payment Orders";
					if ("failed" in failedPaymentOrders && failedPaymentOrders.failed) {
						this.encounteredErrorWhileFecthingData = true;
						this.dataFailedPaymentOrders = [];
						return this.dataFailedPaymentOrders;
					} else {
						failedPaymentOrders = failedPaymentOrders as FailedPaymentOrdersTableDetailWithPagination;
						this.pagination.totalPageCount = failedPaymentOrders.totalCount;
						this.dataFailedPaymentOrders = this.dataFormattingService.formatFailedPaymentOrders(
							failedPaymentOrders.items
						);
						this.noEntriesFound = this.showNoEntries(this.dataFailedPaymentOrders.length);
						return this.dataFailedPaymentOrders;
					}
				})
			);
	}

	loadEventTableDetails(): Observable<EventsTableDetailItem[]> {
		this.resetTableComponent();
		return this.globalDashboardApiService
			.getEventsTableDetails(
				this.checkIfFilterHasAllSelected(this.filters),
				this.pagination.pageSize,
				this.pagination.pageIndex
			)
			.pipe(
				map(response => {
					this.icon = "";
					this.tableTitle = "Events";
					if ("failed" in response && response.failed) {
						this.encounteredErrorWhileFecthingData = true;
						this.dataLandingPageEvents = [];
						return this.dataLandingPageEvents;
					} else {
						response = response as EventsTableDetailsWithPagination;
						this.pagination.totalPageCount = response.totalCount;
						this.dataLandingPageEvents = response.items;
						this.noEntriesFound = this.showNoEntries(this.dataLandingPageEvents.length);
						return this.dataLandingPageEvents;
					}
				})
			);
	}

	loadCustomerTableDetails(): Observable<CustomerTableDetailItem[]> {
		this.resetTableComponent();

		return this.globalDashboardApiService
			.getCustomerDetails(
				this.checkIfAllIsSelected([...this.filters.get("customers")?.value], "customers"),
				this.pagination.pageSize,
				this.pagination.pageIndex,
				this.filters.get("legalEntities")?.value,
				this.legalEntitiesSelectOptions.value,
				this.filters.get("paygroups")?.value,
				this.payGroupsSelectOptions.value
			)
			.pipe(
				map(response => {
					this.icon = "";
					this.tableTitle = "Customers";
					if ("failed" in response && response.failed) {
						this.encounteredErrorWhileFecthingData = true;
						this.dataLandingPageCustomers = [];
						return this.dataLandingPageCustomers;
					} else {
						response = response as CustomerTableDetailWithPagination;
						this.pagination.totalPageCount = response.totalCount;
						this.dataLandingPageCustomers = response.items;
						this.noEntriesFound = this.showNoEntries(this.dataLandingPageCustomers.length);
						return this.dataLandingPageCustomers;
					}
				})
			);
	}

	loadPaygroupTableDetails(): Observable<PaygroupTableDetailItem[]> {
		this.resetTableComponent();
		return this.globalDashboardApiService
			.getLegalEntitiesWithPagination(
				this.filters.get("customers")?.value,
				this.pagination.pageSize,
				this.pagination.pageIndex,
				this.filters.get("legalEntities")?.value,
				this.payGroupsSelectOptions.value,
				this.filters.get("paygroups")?.value
			)
			.pipe(
				map(response => {
					this.icon = "";
					this.tableTitle = "Paygroups";
					if ("failed" in response && response.failed) {
						this.encounteredErrorWhileFecthingData = true;
						this.dataLandingPagePaygroups = [];
						return this.dataLandingPagePaygroups;
					} else {
						response = response as PaygroupTableDetailWithPagination;
						this.pagination.totalPageCount = response.totalCount;
						this.dataLandingPagePaygroups = response.items;
						this.noEntriesFound = this.showNoEntries(this.dataLandingPagePaygroups.length);
						return this.dataLandingPagePaygroups;
					}
				})
			);
	}

	fetchLegalEntitiesData(customerId: string): void {
		this.globalDashboardApiService
			.getLegalEntities(
				customerId,
				-1,
				0,
				this.filters.get("legalEntities")?.value,
				this.payGroupsSelectOptions.value,
				this.filters.get("paygroups")?.value
			)
			.pipe(take(1))
			.subscribe((legalEntites: LegalEntityDetailsWithPaygroups[]) => {
				const index = this.dataLandingPageCustomers.findIndex(
					customerData => customerData.customerDetails.id === customerId
				);

				this.dataLandingPageCustomers[index].customerDetails.legalEntities = legalEntites;
			});
	}

	fetchPaygroupsDataForCustomerTable(eventData: [string, string]): void {
		const [customerId, legalEnitityId] = eventData;

		this.globalDashboardApiService
			.getPaygroups(legalEnitityId, this.filters.get("paygroups")?.value)
			.pipe(take(1))
			.subscribe((paygroups: PayGroupDetails[]) => {
				const customerIndex = this.dataLandingPageCustomers.findIndex(
					customerData => customerData.customerDetails.id === customerId
				);

				if (this.dataLandingPageCustomers[customerIndex].customerDetails.legalEntities) {
					const legalEntityIndex = this.dataLandingPageCustomers[
						customerIndex
					].customerDetails.legalEntities!.findIndex(legalEnitity => legalEnitity.id === legalEnitityId);

					this.dataLandingPageCustomers[customerIndex].customerDetails.legalEntities![
						legalEntityIndex
					].payGroups = paygroups;
				}
			});
	}

	fetchPaygroupsDataForPaygroupTable(eventData: [string, string]): void {
		const [customerId, legalEnitityId] = eventData;

		this.globalDashboardApiService
			.getPaygroups(legalEnitityId, this.filters.get("paygroups")?.value)
			.pipe(take(1))
			.subscribe((paygroups: PayGroupDetails[]) => {
				const legalEntityIndex = this.dataLandingPagePaygroups.findIndex(
					legalEnitityData => legalEnitityData.legalEntityDetails.id === legalEnitityId
				);
				this.dataLandingPagePaygroups[legalEntityIndex].legalEntityDetails.payGroups = paygroups;
			});
	}

	switchToCustomerTable(loadCustomer: boolean): void {
		if (loadCustomer) {
			this.isClient ? this.setSelectedKPI("Paygroups") : this.setSelectedKPI("Customers");
		} else {
			this.setSelectedKPI("");
		}
	}

	setSelectedKPI(kpi: string): void {
		if (kpi !== this.kpiSelected) {
			this.store.dispatch(
				new UpdateDashboardFilterAction({
					globalDashboard: {
						kpiSelected: kpi,
						filters: this.filters.getRawValue()
					}
				})
			);
		}
	}

	loadDataBasedOnKPI(kpi: string): void {
		this.encounteredErrorWhileFecthingData = false;
		switch (kpi) {
			case "Missing Cards":
				this.fetchingDataTable.next(true);
				forkJoin([this.getCardDetails(), this.loadMissingCardsTableDetails()])
					.pipe(take(1))
					.subscribe(_ => this.fetchingDataTable.next(false));
				break;
			case "Failed Beneficiaries":
				this.fetchingDataTable.next(true);
				forkJoin([this.getCardDetails(), this.loadFailedBeneficiariesTableDetails()])
					.pipe(take(1))
					.subscribe(_ => this.fetchingDataTable.next(false));
				break;

			case "Missing Bank Acc.":
				this.fetchingDataTable.next(true);
				forkJoin([this.getCardDetails(), this.loadMissingBankAccountsTableDetails()])
					.pipe(take(1))
					.subscribe(_ => this.fetchingDataTable.next(false));
				break;

			case "Failed Transactions":
				this.fetchingDataTable.next(true);
				forkJoin([this.getCardDetails(), this.loadFailedTransactionsTableDetails()])
					.pipe(take(1))
					.subscribe(_ => this.fetchingDataTable.next(false));
				break;

			case "Failed Payment Orders":
				this.fetchingDataTable.next(true);
				forkJoin([this.getCardDetails(), this.loadFailedPaymentOrdersTableDetails()])
					.pipe(take(1))
					.subscribe(_ => this.fetchingDataTable.next(false));

				break;
			case "Customers":
				this.fetchingDataTable.next(true);
				forkJoin([this.getCardDetails(), this.loadCustomerTableDetails()])
					.pipe(take(1))
					.subscribe(_ => this.fetchingDataTable.next(false));
				break;
			case "Paygroups":
				this.fetchingDataTable.next(true);
				forkJoin([this.getCardDetails(), this.loadPaygroupTableDetails()])
					.pipe(take(1))
					.subscribe(_ => this.fetchingDataTable.next(false));
				break;
			default:
				this.fetchingDataTable.next(true);
				forkJoin([this.getCardDetails(), this.loadEventTableDetails()])
					.pipe(take(1))
					.subscribe(_ => this.fetchingDataTable.next(false));
		}
	}

	selected(kpi: string): boolean {
		return this.kpiSelected === kpi;
	}

	showNoEntries(length: number): boolean {
		return length > 0 ? false : true;
	}

	paginate(pagination: PageEvent): void {
		this.pagination.pageSize = pagination.pageSize;
		this.pagination.pageIndex = pagination.pageIndex;
		this.loadDataBasedOnKPI(this.kpiSelected);
	}

	resetTableComponent(): void {
		this.globalDashboardApiService.cancelPendingRequest();
		this.encounteredErrorWhileFecthingData = false;
		this.dataLandingPageEvents = [];
		this.dataLandingPageCustomers = [];
		this.dataLandingPagePaygroups = [];
		this.dataMissingCards = [];
		this.dataMissingBankAccounts = [];
		this.dataFailedTransactions = [];
		this.dataFailedBeneficiaries = [];
		this.dataFailedPaymentOrders = [];
		this.selectedEvents = [];
	}

	resetPagination(): void {
		this.pagination = {
			pageSize: 10,
			pageIndex: 0,
			totalPageCount: 0
		};
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
