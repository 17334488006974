import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { GlobalDashboardEventsService } from "@modules/global-dashboard/services/global-dashboard-events.service";

import {
	FailedBeneficiariesTableDetailItem,
	PayGroupDetailsWithFailedBeneficiariesCount
} from "@modules/global-dashboard/models/global-dashboard-interface";
import { getTableExpandAndCollapseAnimation } from "src/app/shared/utils/mat-table-animation.util";

@Component({
	selector: "app-failed-beneficiaries-table",
	templateUrl: "./failed-beneficiaries-table.component.html",
	styleUrls: ["./failed-beneficiaries-table.component.scss"],
	animations: [getTableExpandAndCollapseAnimation]
})
export class FailedBeneficiariesTableComponent {
	@Input() set failedBeneficiaries(failedBeneficiaries: FailedBeneficiariesTableDetailItem[]) {
		this.dataSourceMissingBankAccounts.data = failedBeneficiaries;
		this.customerColumns = ["Customers", "Paygroups", "Failed Beneficiaries", "expand"];
		this.paygroupsColumns = ["first-padding", "Paygroups", "Failed Beneficiaries", "second-padding"];

		this.toggleElement(failedBeneficiaries[0]);
	}

	dataSourceMissingBankAccounts: MatTableDataSource<FailedBeneficiariesTableDetailItem> = new MatTableDataSource();
	customerColumns: string[] = [];
	paygroupsColumns: string[] = [];
	expandedElement!: FailedBeneficiariesTableDetailItem | null;
	customerNameLocator!: string;

	constructor(private cdr: ChangeDetectorRef, private globalDashboardEventsService: GlobalDashboardEventsService) {}

	toggleRow(element: FailedBeneficiariesTableDetailItem): void {
		element.payGroups && element.payGroups.length ? this.toggleElement(element) : null;
		this.cdr.detectChanges();
	}

	expandedState(row: FailedBeneficiariesTableDetailItem): string {
		return row &&
			row.customerDetails &&
			this.expandedElement &&
			this.expandedElement.customerDetails.id === row.customerDetails.id
			? "expanded"
			: "collapsed";
	}

	isExpanded(row: FailedBeneficiariesTableDetailItem): boolean {
		return row &&
			row.customerDetails &&
			this.expandedElement &&
			this.expandedElement.customerDetails.id === row.customerDetails.id
			? true
			: false;
	}

	toggleElement(row: FailedBeneficiariesTableDetailItem): void {
		row &&
		row.customerDetails &&
		this.expandedElement &&
		this.expandedElement.customerDetails.id === row.customerDetails.id
			? (this.expandedElement = null)
			: ((this.expandedElement = row), (this.customerNameLocator = row.customerDetails.name));
	}

	routeToEmployeeData(row: PayGroupDetailsWithFailedBeneficiariesCount) {
		this.globalDashboardEventsService.routeFromGlobalDashboardToAnotherPage(
			row.payGroupDetails.id,
			"/global-dashboard/master-data"
		);
	}
}
