import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule as MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule as MatFormFieldModule } from "@angular/material/form-field";
import { UserCreationDialogComponent } from "./user-creation-dialog.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { MatInputModule as MatInputModule } from "@angular/material/input";
import { InputAutocompleteModule } from "@shared/components/input-autocomplete/input-autocomplete.module";
import { SharedModule } from "@shared/shared.module";
import { SelectModule } from "../select/select.module";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
//import { RolesPipe } from "../../_pipes/roles.pipe";

@NgModule({
	declarations: [UserCreationDialogComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		FormsModule,
		InputAutocompleteModule,
		InputWrapperModule,
		InputSelectModule,
		MatInputModule,
		MatButtonModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		ReactiveFormsModule,
		SharedModule,
		SelectModule,
		PipesModule
	]
})
export class UserCreationDialogModule {}
