<div [formGroup]="searchForm" class="search-section" fxLayoutAlign="space-between center">
	<mat-form-field class="search-field" id="master-search-field" appearance="outline">
		<app-icon
			id="master-search-icon"
			[name]="'search-normal-1'"
			[size]="'25'"
			[lineWidth]="'2px'"
			[color]="'var(--color-search-icon)'"
			matPrefix
		></app-icon>
		<input id="searchEmployees" matInput placeholder="Search by..." formControlName="search" />
	</mat-form-field>
</div>

<div fxLayout="row" fxLayoutAlign="start center" class="history-header-container">
	<div id="employee" fxFlex="22">Employee N°</div>
	<div id="firstName" fxFlex="30.5">Full Name</div>
	<div id="description" fxFlex="45">Description</div>
</div>
<div class="scroll-container">
	<div *ngIf="reportData$ | async as reportData">
		<ng-container *ngIf="!reportData.employeeErrors.length; else errorTemplate">
			<div fxLayout="row" fxLayoutAlign="start center" class="history-item-container">
				<p>There is no data to display</p>
			</div>
		</ng-container>
		<ng-template #errorTemplate>
			<div
				*ngIf="!(filteredEmployees$ | async)?.length"
				fxLayout="row"
				fxLayoutAlign="start center"
				class="history-item-container"
			>
				<p>No Employees match your search</p>
			</div>
			<ng-container *ngFor="let data of filteredEmployees$ | async">
				<div
					*ngIf="(filteredEmployees$ | async)?.length"
					fxLayout="row"
					fxLayoutAlign="start center"
					class="history-item-container"
					[class.error]="
						data.validationErrors.length ||
						data.employee.id === 'EMPLOYEE_NOT_FOUND' ||
						!!data.executionError
					"
				>
					<div
						fxLayoutAlign="center"
						class="data-row item-employee"
						*ngIf="data.employee.id !== 'EMPLOYEE_NOT_FOUND'; else notFoundTemplate"
					>
						<div id="employee" fxLayoutAlign="start center" fxFlex="21.5">
							{{ data.employee.externalId }}
						</div>
						<div id="firstName" fxLayoutAlign="start center" fxFlex="30.3">
							{{ data.employee.firstName }} {{ data.employee.lastName }}
						</div>
						<div id="description" fxLayout="column" fxFlex="45">
							<div fxLayout="column" *ngFor="let error of data.validationErrors">
								<span>{{ error.field ? error.field + " - " : "" }}{{ error.message }} </span>
							</div>
							<div fxLayout="column" *ngIf="data.executionError">
								<span>{{ data.executionError.message }}</span>
							</div>
						</div>
					</div>
					<ng-template #notFoundTemplate>
						<div id="employee" class="first-collumn" fxLayoutAlign="start center">
							{{ data.executionError.message.split(" ").pop() }}
						</div>
						<div id="firstName" class="second-collumn" fxLayoutAlign="start center"></div>
						<div id="description" class="third-collumn" fxLayout="column">
							<div>Employee Not Found</div>
						</div>
					</ng-template>
				</div>
				<div
					fxLayout="row"
					class="history-item-container"
					[class.warning]="
						data.warnings.length || data.employee.id === 'EMPLOYEE_NOT_FOUND' || !!data.warnings
					"
					*ngIf="data.warnings && data.warnings.length > 0"
				>
					<div
						fxLayoutAlign="center"
						class="data-row"
						*ngIf="data.employee.id !== 'EMPLOYEE_NOT_FOUND'; else notFoundTemplate"
					>
						<div id="employee" fxLayoutAlign="start center" fxFlex="21.5">
							{{ data.employee.externalId }}
						</div>
						<div id="firstName" fxLayoutAlign="start center" fxFlex="30.3">
							{{ data.employee.firstName }} {{ data.employee.lastName }}
						</div>
						<div id="description" fxLayout="column" fxFlex="45">
							<div fxLayout="column" *ngFor="let warning of data.warnings">
								<span
									><span *ngIf="warning.code">{{ warning.code }}-</span>{{ warning.message }}</span
								>
							</div>
						</div>
					</div>
					<ng-template #notFoundTemplate>
						<div id="employee" class="first-collumn" fxLayoutAlign="start center" *ngIf="data.warnings">
							{{ data.warnings[0].message.split(" ").pop() }}
						</div>
						<div id="firstName" class="second-collumn" fxLayoutAlign="start center"></div>
						<div id="description" class="third-collumn" fxLayout="column">
							<div>Employee Not Found</div>
						</div>
					</ng-template>
				</div>
			</ng-container>
		</ng-template>
	</div>
</div>
