import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, take, takeUntil } from "rxjs/operators";

import {
	DropDownData,
	DropDownDataExpanded,
	paygroupDropdownDataExpanded,
	DropDownFilterFunctions,
	IDBreadCrumb
} from "src/app/shared/models/breadcrumbs.interface";
import { CustomerEntityGroupSelect } from "src/app/store/models/customerEnitityGroupSelection.model";
import { BreadcrumbService } from "../../services/breadcrumb.service";
import { BreadcrumbsMenuRoutingService } from "../../services/breadcrumbs-menu-routing.service";
import { TabStateService } from "@shared/services/tab-state/tab-state.service";
import { Menu } from "@shared/models/menu.interface";
import { PaginationData } from "@store/models/pagination.model";
import { select, Store } from "@ngrx/store";
import { AppState } from "@store/models/state.model";
import { ResetPagination } from "@store/actions/pagination.action";
import { SelectOption } from "@shared/models/select-option.interface";
import { getGlobalDashboardFilterState } from "@store/index";
import { GlobalDashboardFilterSelect } from "@store/models/globalDashboardFilterSelection.model";
import { UpdateDashboardFilterAction } from "@store/actions/globalDashboardFilterSelect.action";
import { IDashboardFiltersFormGroup } from "@modules/global-dashboard/models/global-dashboard-interface";
import { Router } from "@angular/router";

@Component({
	selector: "app-breadcrumbs-modal",
	templateUrl: "./breadcrumbs-modal.component.html",
	styleUrls: ["./breadcrumbs-modal.component.scss"]
})
export class BreadcrumbsModalComponent implements OnInit {
	@Input()
	set dropDownData(value: DropDownData) {
		if (value) {
			this._dropDownData = value;
			this.dropDownDataCustomersFilterCopy = value.customers!;
			this.dropDownDataLegalEntitiesFilterCopy = value.legalEntities!;
			this.paygroupDropdownDataFilter = value.paygroupDropdownData!;
		}
	}
	get dropDownData(): DropDownData {
		return this._dropDownData;
	}

	@Input()
	set modalOpenPosition(value: { x: number; y: number }) {
		this._modalOpenPosition = value;
		this.updateModalPosition();
	}
	get modalOpenPosition(): { x: number; y: number } {
		return this._modalOpenPosition;
	}

	@Input()
	set idKey(value: string) {
		this._idKey = value;
	}
	get idKey(): string {
		return this._idKey;
	}

	@Input()
	set externalDropDownData(value: DropDownDataExpanded[]) {
		this._externalDropDownData = value;
		this.externalDropDownDataFilterCopy = value!;
	}
	get externalDropDownData(): DropDownDataExpanded[] {
		return this._externalDropDownData;
	}

	@Input() clientCountryList: SelectOption[] = [];
	@Input() clientCustomerId!: string;
	@Input() zIndex!: number;

	@Output() clientSelectedCountry: EventEmitter<string> = new EventEmitter<string>();
	@Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onDropDownCrumbItemSelected = new EventEmitter<boolean>();
	@Output() onExternalDropDownCrumbItemSelected = new EventEmitter<string>();

	private _dropDownData!: DropDownData;
	private _modalOpenPosition!: { x: number; y: number };
	private _idKey!: string;
	private _externalDropDownData!: DropDownDataExpanded[];
	private destroy$: Subject<void> = new Subject<void>();

	dropDownDataCustomersFilterCopy!: DropDownDataExpanded[];
	dropDownDataLegalEntitiesFilterCopy!: DropDownDataExpanded[];
	paygroupDropdownDataFilter!: paygroupDropdownDataExpanded[];
	externalDropDownDataFilterCopy!: DropDownDataExpanded[];

	dropDownDataMain: DropDownData = {
		mainMenu: [
			{ name: "Dashboard", id: "/global-dashboard/dashboard" },
			{ name: "Employee Data", id: "/employee-data" },
			{ name: "Clients", id: "/customers" },
			{ name: "Legal Entities", id: "/legal-entities" },
			{ name: "Pay Groups", id: "/pay-groups" },
			{ name: "Nets Service Definition", id: "/service-definition/net" },
			{ name: "TPP Service Definition", id: "/service-definition/tpp" },
			{ name: "CPNow Service Definition", id: "/service-definition/cp-now" },
			{ name: "Calendar", id: "/calendar" },
			{ name: "User Management", id: "/user-management" },
			{ name: "Reporting", id: "/reporting" },
			{ name: "Service Provider", id: "/service-provider-setup" },
			{ name: "Penny Test", id: "/penny-test" },
			{ name: "TPP Catalogue", id: "/tpp-catalogue" },
			{ name: "Bank Fields", id: "/bank-fields" },
			{ name: "Error Management", id: "/error-management" }
		]
	};

	inputForm!: FormGroup;
	selectedCustomerId!: string;

	idCrumbUpdateArray: { key: string; spawn: string }[] = [];

	filters!: FormGroup;

	constructor(
		private breadCrumbService: BreadcrumbService,
		private breadCrumbsMenuRoutingService: BreadcrumbsMenuRoutingService,
		private tabStateService: TabStateService,
		private store: Store<AppState>,
		private formBuilder: FormBuilder,
		private router: Router
	) {}

	ngOnInit() {
		this.setupMainMenuDropDown();

		this.selectedCustomerId = this.tabStateService.getCustomerEntityGroupSelect().customerId;
		this.updateModalPosition();

		this.inputForm = new FormGroup({
			customerSearch: new FormControl(""),
			payGroupSearch: new FormControl(""),
			legalSearch: new FormControl(""),
			externalSearch: new FormControl("")
		});

		this.inputForm
			.get("customerSearch")!
			.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe({
				next: value => {
					this.dropDownDataCustomersFilterCopy = this.dropDownData.customers!.filter(
						obj =>
							obj.name.toLowerCase().includes(value.toLowerCase()) ||
							obj.externalId?.toLowerCase().includes(value.toLowerCase())
					);
				}
			});

		this.inputForm
			.get("payGroupSearch")!
			.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe({
				next: value => {
					this.paygroupDropdownDataFilter = this.dropDownData.paygroupDropdownData!.filter(
						obj =>
							obj.name.toLowerCase().includes(value.toLowerCase()) ||
							obj.paygroupId?.toLowerCase().includes(value.toLowerCase()) ||
							obj.entityId?.toLowerCase().includes(value.toLowerCase())
					);
				}
			});

		this.inputForm
			.get("legalSearch")!
			.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe({
				next: value => {
					this.dropDownDataLegalEntitiesFilterCopy = this.dropDownData.legalEntities!.filter(
						obj =>
							obj.name.toLowerCase().includes(value.toLowerCase()) ||
							obj.externalId?.toLowerCase().includes(value.toLowerCase())
					);
				}
			});

		this.inputForm
			.get("externalSearch")!
			.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe({
				next: value => {
					this.externalDropDownDataFilterCopy = this.externalDropDownData!.filter(
						obj =>
							obj.name.toLowerCase().includes(value.toLowerCase()) ||
							(obj.externalId && obj.externalId.toLowerCase().includes(value.toLowerCase()))
					);
				}
			});

		this.filters = this.formBuilder.group({
			customers: new FormControl([]),
			legalEntities: new FormControl([]),
			paygroups: new FormControl([]),
			statuses: new FormControl([]),
			groups: new FormControl([]),
			deadline: new FormControl(""),
			milestoneStartDate: new FormControl(""),
			milestoneTypes: new FormControl([])
		}) as IDashboardFiltersFormGroup;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * Change filter state when selecting country in breadcrumb modal
	 * @param selectedCountry: Client selected country in breadcrumb modal
	 * @param legalEntity: Client legal entities
	 */
	selectClientLegalEntity(selectedCountry: string, legalEntity: string[]): void {
		this.store.pipe(take(1), takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe({
			next: (state: GlobalDashboardFilterSelect) => {
				if (state.globalDashboard && this.router.url !== "/global-dashboard/dashboard") {
					this.filters.get("customers")?.patchValue([this.clientCustomerId]);
					this.filters.get("legalEntities")?.patchValue(legalEntity);

					this.store.dispatch(
						new UpdateDashboardFilterAction({
							globalDashboard: {
								kpiSelected: state.globalDashboard.kpiSelected,
								filters: this.filters.getRawValue()
							}
						})
					);

					this.clientSelectedCountry.emit(selectedCountry);
				}
			}
		});
	}

	setupMainMenuDropDown(): void {
		const menu = this.breadCrumbsMenuRoutingService.getMenu();

		const filters: DropDownFilterFunctions = {
			"Global Dashboard": (item: { name: string; id: string }) => item.name !== "Global Dashboard",
			"Employee Data": (item: { name: string; id: string }) => item.name !== "Employee Data",
			"User management": (item: { name: string; id: string }) => item.name !== "User Management",
			Reporting: (item: { name: string; id: string }) => item.name !== "Reporting",
			"Service Providers": (item: { name: string; id: string }) => item.name !== "Service Provider",
			"Penny Test": (item: { name: string; id: string }) => item.name !== "Penny Test",
			"Tpp catalogue": (item: { name: string; id: string }) => item.name !== "TPP Catalogue",
			"Bank Fields": (item: { name: string; id: string }) => item.name !== "Bank Fields",
			"Error Management": (item: { name: string; id: string }) => item.name !== "Error Management",
			"CPNow Service Definition": (item: { name: string; id: string }) => item.name !== "CPNow Service Definition"
		};

		menu.forEach(element => {
			if (element.title in filters && !element.shouldShow) {
				const filterFunction = filters[element.title];
				this.dropDownDataMain.mainMenu = this.dropDownDataMain.mainMenu?.filter(filterFunction);
			}
			if (element.title === "System" || element.title === "Configuration") {
				if (!element.shouldShow) {
					element.title === "System" ? this.hideSystem() : this.hideConfig();
				} else {
					element.sections.forEach(section => {
						if (section.title! in filters && !section.shouldShow) {
							const filterFunction = filters[section.title!];
							this.dropDownDataMain.mainMenu = this.dropDownDataMain.mainMenu?.filter(filterFunction);
						}
					});
				}
			}
		});
	}

	hideSystem(): void {
		let keysToRemove = ["Service Provider", "Penny Test", "TPP Catalogue", "Bank Fields", "Error Management"];

		this.dropDownDataMain.mainMenu = this.dropDownDataMain.mainMenu?.filter(
			item => !keysToRemove.includes(item.name)
		);
	}

	hideConfig(): void {
		const keysToRemove = [
			"Clients",
			"Legal Entities",
			"Pay Groups",
			"Nets Service Definition",
			"TPP Service Definition",
			"Calendar"
		];
		this.dropDownDataMain.mainMenu = this.dropDownDataMain.mainMenu?.filter(
			item => !keysToRemove.includes(item.name)
		);
	}

	updateModalPosition() {
		if (this.idKey === "mainMenu") {
			const modal = document.getElementById("wpay-breadcrumb-modal")!;
			modal.style.left = this.modalOpenPosition.x + 20 + "px";
		} else {
			const modal = document.getElementById("wpay-breadcrumb-modal")!;
			modal.style.left = this.modalOpenPosition.x + "px";
		}
	}

	navigateToMainMenuRoute(key: string, route: string) {
		const freshStateRoutes = [
			"/global-dashboard/dashboard",
			"/user-management",
			"/reporting",
			"/service-provider-setup",
			"/penny-test",
			"/tpp-catalogue",
			"/bank-fields",
			"/error-management"
		];

		const buildableRoutes = ["/customers", "/legal-entities", "/pay-groups", "/service-definition/net"];

		if (freshStateRoutes.includes(route)) {
			if (route === "/global-dashboard") {
				this.breadCrumbsMenuRoutingService.initGlobalDashboardRouting();
			} else if (route === "/global-dashboard/dashboard") {
				this.breadCrumbsMenuRoutingService.navigateToDashboard();
			} else {
				this.breadCrumbsMenuRoutingService.navigateToRoute(route);
			}
		} else if (!buildableRoutes.includes(route) && !freshStateRoutes.includes(route)) {
			// paygroup based routes

			if (this.tabStateService.getCustomerEntityGroupSelect().payGroupId === "") {
				this.breadCrumbService.convertLegalToPayGroup();
				setTimeout(() => {
					this.breadCrumbsMenuRoutingService.navigateToMainMenuRoute(key, route);
				}, 3000);
			} else {
				this.breadCrumbsMenuRoutingService.navigateToMainMenuRoute(key, route);
			}
		}

		if (buildableRoutes.includes(route)) {
			this.breadCrumbsMenuRoutingService.navigateToBuildableRoute(key, route);
		}
	}

	resetStateAndReroute(key: string, route: string): void {
		this.breadCrumbService.resetCustomerEntityGroupState();
		this.breadCrumbsMenuRoutingService.navigateToMainMenuRoute(key, route);
	}

	changeCustomerEntityGroup(key: string, id: string): void {
		this.onDropDownCrumbItemSelected.emit(true);
		this.closeModal.emit(true);

		// Retrieve data from localStorage
		const idCrumbData: IDBreadCrumb[] = this.tabStateService.getBreadcrumbIdentifier();

		// Update customerName if it exists
		const customerNameData = idCrumbData.find(res => res.key === "customerName");
		if (customerNameData) {
			const oldSpawn = customerNameData.spawn;
			this.idCrumbUpdateArray.push({ key: "customerName", spawn: oldSpawn });
		}

		// Update payGroupId if it exists
		const payGroupIdData = idCrumbData.find(res => res.key === "payGroupId");
		if (payGroupIdData) {
			const oldSpawn = payGroupIdData.spawn;
			this.idCrumbUpdateArray.push({ key: "payGroupId", spawn: oldSpawn });
		}

		// Update legalEntityId if it exists
		const legalEntityIdData = idCrumbData.find(res => res.key === "legalEntityId");
		if (legalEntityIdData) {
			const oldSpawn = legalEntityIdData.spawn;
			this.idCrumbUpdateArray.push({ key: "legalEntityId", spawn: oldSpawn });
		}

		const stateSetupData: string[] = [];
		// Set state based on idCrumbUpdate
		this.idCrumbUpdateArray.forEach((element, index) => {
			if (element.key === "customerName") {
				stateSetupData.push("customerName");
			} else if (element.key === "payGroupId") {
				stateSetupData.push("payGroupId");
			} else if (element.key === "legalEntityId") {
				stateSetupData.push("legalEntityId");
			}

			// Check if it's the last iteration before calling updateStateFromBreadCrumb
			if (index === this.idCrumbUpdateArray.length - 1) {
				this.breadCrumbService.updateStateFromBreadCrumb(
					stateSetupData,
					id!,
					key,
					this.idCrumbUpdateArray,
					this.selectedCustomerId
				);
				this.idCrumbUpdateArray = [];
			}
		});
	}

	externalListItemSelected(id: string) {
		this.onExternalDropDownCrumbItemSelected.emit(id);
	}
}
