import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from "rxjs/operators";
import { SelectOption } from "./model/SelectOption";
import { AutoCompleteService } from "./_services/auto-complete.service";

/**
 * A auto-complete form control binds to the hosts Form with the
 * `formControlName` directive so that it can be used in a reactive form
 * like a native element that supports form control. (single item select)
 *
 * N.B. As a consumer of this component, you may want to use @see AutoCompleteUtil to extract the value from the form as it could be a string or a SelectOption.
 *
 * @see SelectOption - The interface for the options that can be passed to the component.
 * @see ControlValueAccessor - Exports this components state changes so that it can be used in a reactive form.
 * @see FormBuilder - Build a reactive form.
 */
@Component({
	selector: "wpay-auto-complete",
	templateUrl: "./wpay-auto-complete.component.html",
	styleUrls: ["./wpay-auto-complete.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: WpayAutoCompleteComponent,
			multi: true
		}
	]
})
export class WpayAutoCompleteComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
	@Input() options: SelectOption[] = [];
	@Input() label?: string;
	@Input() required?: boolean;
	@Input() defaultValue: string = "";
	@Input() disabled: boolean = false;
	@Input() loading: boolean = false;

	@Output() inputTextChange: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
	@ViewChild("input") input!: ElementRef<HTMLInputElement>;

	control: FormControl = new FormControl();
	selectedOptions: String[] = [];
	hideLabel?: boolean = true;
	onChange: any = (value: SelectOption) => {};
	onTouched: any = () => {};

	filteredOptions$: Observable<SelectOption[]> | undefined;
	destroy$: Subject<void> = new Subject();

	constructor(private inputAutocompleteService: AutoCompleteService) {}

	ngOnInit() {
		this.initalizeControl();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.refreshInternalControlState();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	formatOptionForDisplay(option: SelectOption): string {
		return option && option.text ? option.text : "";
	}

	onClick() {
		if (this.disabled) return;
		this.highlightInputText();
		this.control.setErrors(null);
		this.autocomplete.openPanel();
	}

	onOptionSelected(event: any) {
		this.onChange(event);
		this.onTouched();
	}

	// <--  ControlValueAccessor context for associating this component with a form control.
	writeValue(value: any): void {
		if (typeof value == "string") {
			this.selectedOptions = [value];
		} else {
			if (Array.isArray(value)) {
				this.selectedOptions = value.map(option => option.value);
			} else {
				this.selectedOptions = [value.value];
			}
		}
	}

	registerOnChange(fn: (value: SelectOption[]) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
	// -->

	private refreshInternalControlState() {
		// Handle API Abuse where `options` || defaultValue are set at different times.
		this.initalizeControl();
	}

	private initalizeControl() {
		this.setupControlGivenComponentInputs();
		this.onInputTextChanged();
	}

	private setupControlGivenComponentInputs() {
		if (this.required) {
			this.control.setValidators([Validators.required]);
			this.control.updateValueAndValidity();
		}
		if (this.disabled) {
			this.control.disable();
		} else {
			this.control.enable();
		}

		const output = this.inputAutocompleteService.writeValueById(this.options, this.defaultValue);
		if (output.selectOptions) {
			if (Array.isArray(output.selectOptions)) {
				const values = output.selectOptions.map(option => option.value);
				this.control.patchValue(values);
			} else {
				this.control.patchValue(output.selectOptions);
			}
		}
	}

	private onInputTextChanged() {
		this.filteredOptions$ = this.control.valueChanges.pipe(
			takeUntil(this.destroy$),
			debounceTime(500),
			distinctUntilChanged(),
			startWith(""),
			map((value: string | SelectOption[]) => {
				if (typeof value != "string" || value == "") return this.options;
				return this.filterByOptionText(value);
			})
		);
	}

	private filterByOptionText(value: string): SelectOption[] {
		if (!value) return this.options;

		const filterValue = value.toLowerCase();
		var filtered = this.options.filter(option => {
			return option.text.toLowerCase().includes(filterValue);
		});
		return filtered;
	}

	private highlightInputText() {
		const autoComplete = document.getElementById(this.input.nativeElement.id) as HTMLInputElement;
		autoComplete.setSelectionRange(0, 200);
	}
}
