<!-- payout information -->

<div class="payout-container" #payoutContainer>
	<app-payment-top-cards
		[milestoneReport]="milestoneReport"
		[_updating]="updating"
		[title]="title"
		[subTitle]="subtitle"
		[expandMilestoneGroupWithName]="milestoneGroup"
		[flag]="flag"
		[kpiSelected]="selectedKPI"
		[checkDistance]="emitScroll"
		(emitSticky)="setFiltersToFixed = $event"
	></app-payment-top-cards>

	<app-payout-process
		*ngIf="milestoneReport && runSelection && payGroup"
		(processed)="processed()"
		(runChanged)="changeRun($event)"
		(refresh)="refresh()"
		(cancelBookingClicked)="cancelBooking()"
		[milestoneReport]="milestoneReport"
		[payCycleId]="payCycleId"
		[payGroupId]="payGroup.id"
		[payGroup]="payGroup"
		[buttonState]="bookingButtonState"
		[payCycleMilestones]="payCycleMilestones"
		[runSelection]="runSelection"
		[isCancelledRun]="isCancelledRun"
		[defualtStatusFilterValue]="statusFilter"
		[fixedContainerStyle]="setFiltersToFixed"
		[allFailedTransaction]="allFailedTransaction"
		[allFallbackTransactionIds]="allFallbackTransactionIds"
	></app-payout-process>

	<div
		class="table-header flex flex-row flex-align-start"
		*ngIf="setFiltersToFixed"
		[ngClass]="{ 'table-header-fixed': setFiltersToFixed }"
	>
		<mat-checkbox
			class="checkbox-select-all"
			color="primary"
			(change)="clearSelection($event)"
			[disabled]="!allFailedTransaction.length || isCancelledRun"
			[checked]="allTransactionsAreSelected"
			[ngClass]="{
				unchecked: !allTransactionsAreSelected,
				disabled: !allFailedTransaction.length || isCancelledRun
			}"
		></mat-checkbox>

		<button
			class="options-dropdown-button"
			(click)="openSelect()"
			[disabled]="!allFailedTransaction.length || isCancelledRun"
			[ngClass]="{
				disabled: !allFailedTransaction.length || isCancelledRun
			}"
		>
			<app-icon
				class="select-options-arrow"
				[ngClass]="{ 'up-arrow': showSelectOptions }"
				[name]="'arrow-down'"
				[size]="'20'"
				[lineWidth]="'3px'"
			></app-icon>
		</button>

		<div class="select-options">
			<mat-select
				class="options-dropdown"
				trigger="trigger"
				#options
				(selectionChange)="selectAllFailedTransactions($event)"
				(closed)="showSelectOptions = false"
			>
				<mat-option value="visible">Select visible</mat-option>
				<mat-option value="all">Select all</mat-option>
			</mat-select>
		</div>

		<div class="header-container" *ngIf="!allTransactionsAreSelected">
			<div class="table-header-item first-column dymanic-left-padding">EMPLOYEE ID</div>
			<div class="table-header-item second-column">NAME</div>
			<div class="table-header-item third-column">SURNAME</div>
			<div class="table-header-item fourth-column">PAYMENT METHOD</div>
			<div class="table-header-item fifth-column">STATUS</div>
			<div class="table-header-item sixth-column">TOTAL NET</div>
		</div>

		<div class="header-container" *ngIf="allTransactionsAreSelected">
			<div class="selected-transacion-count dymanic-left-padding">
				All visible transactions selected ({{ allFailedTransaction.length }})
			</div>
		</div>
	</div>

	<div class="container" #scrollContainer [ngStyle]="{ 'margin-top': setFiltersToFixed ? '95px' : '0px' }">
		<div class="cancelRunBanner" *ngIf="isCancelledRun">
			<span class="bannerText"
				><strong>{{ runName }}:</strong> Cancelled on {{ cancelDate | date : "MMMM d"
				}}{{ cancelDate | date : "d" | datePosition | lowercase }} by
				{{ cancellerName | userFullName | async }}.
				<strong>Reason: </strong>
				{{ reason }}
			</span>
		</div>

		<div
			class="table-header flex flex-row flex-align-start"
			[ngStyle]="{ visibility: setFiltersToFixed ? 'hidden' : 'visible' }"
		>
			<mat-checkbox
				class="checkbox-select-all"
				color="primary"
				(change)="clearSelection($event)"
				[disabled]="!allFailedTransaction.length || isCancelledRun"
				[checked]="allTransactionsAreSelected"
				[ngClass]="{
					unchecked: !allTransactionsAreSelected,
					disabled: !allFailedTransaction.length || isCancelledRun
				}"
			></mat-checkbox>

			<button
				class="options-dropdown-button"
				(click)="openSelect()"
				[disabled]="!allFailedTransaction.length || isCancelledRun"
				[ngClass]="{
					disabled: !allFailedTransaction.length || isCancelledRun
				}"
			>
				<app-icon
					class="select-options-arrow"
					[ngClass]="{ 'up-arrow': showSelectOptions }"
					[name]="'arrow-down'"
					[size]="'20'"
					[lineWidth]="'3px'"
					[color]="'var(--color-basic-800)'"
				></app-icon>
			</button>

			<div class="select-options">
				<mat-select
					class="options-dropdown"
					trigger="trigger"
					#options
					(selectionChange)="selectAllFailedTransactions($event)"
					(closed)="showSelectOptions = false"
				>
					<mat-option value="visible">Select visible</mat-option>
					<mat-option value="all">Select all</mat-option>
				</mat-select>
			</div>
			<div class="header-container" *ngIf="!allTransactionsAreSelected">
				<div class="table-header-item first-column dymanic-left-padding">EMPLOYEE ID</div>
				<div class="table-header-item second-column">NAME</div>
				<div class="table-header-item third-column">SURNAME</div>
				<div class="table-header-item fourth-column">PAYMENT METHOD</div>
				<div class="table-header-item fifth-column">STATUS</div>
				<div class="table-header-item sixth-column">TOTAL NET</div>
			</div>

			<div class="header-container" *ngIf="allTransactionsAreSelected">
				<div class="selected-transacion-count dymanic-left-padding">
					All visible transactions selected ({{ allFailedTransaction.length }})
				</div>
			</div>
		</div>

		<div class="table-contents flex flex-column" *ngIf="payouts$ | async as payouts">
			<ng-container *ngIf="payouts.length > 0; else noItems">
				<app-payout-list-item
					*ngFor="let payout of payouts; last as isLast"
					[payout]="payout"
					[last]="isLast"
					[cycleName]="payCycleName"
					[_isCancelledRun]="isCancelledRun"
				></app-payout-list-item>
			</ng-container>
			<ng-template #noItems>
				<no-items-layout message="No bookings have been uploaded"></no-items-layout>
			</ng-template>
		</div>
	</div>

	<div class="paginator-container flex flex-row flex-align-center-end">
		<mat-paginator
			id="paginator"
			*ngIf="payoutsPagination as payouts"
			[length]="payouts.totalCount"
			[pageSizeOptions]="[5, 10, 25, 100, 500]"
			[pageSize]="pageSize"
			[pageIndex]="payouts.pageNumber"
			(page)="paginate($event)"
			aria-label="Select page"
		>
		</mat-paginator>
	</div>
	<!-- </modal-wrapper> -->
	<app-history-log-side-panel [historyType]="HistoryLogType.TRANSACTION" *ngIf="showHistoryLogPanel" />
</div>
