import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { cancelBookingDialog, providerMessage } from "./model/cancel-booking.interface";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { debounceTime, distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { TppPaymentsApiService } from "@modules/dashboard/services/tpp-payments-api.service";

@Component({
	selector: "app-cancel-booking",
	templateUrl: "./cancel-booking.component.html",
	styleUrls: ["./cancel-booking.component.scss"]
})
export class CancelBookingComponent implements OnInit, OnDestroy {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: cancelBookingDialog,
		public dialogRef: MatDialogRef<CancelBookingComponent>,
		private formBuilder: FormBuilder,
		private tppPaymentsApiService: TppPaymentsApiService
	) {}

	providerMessages: providerMessage[] = [];

	milestoneId: string = "";
	reason: string = "";

	form!: FormGroup;

	destroy$ = new Subject<void>();

	wordCount: number = 0;
	totalWordCount: number = 150;

	disableContinue: boolean = true;

	ngOnInit(): void {
		//get Provider message and g2n/data input id

		this.providerMessages = this.data.providerMessages;
		this.milestoneId = this.data.milestoneId;

		this.initForm();
	}

	initForm() {
		this.form = this.formBuilder.group({
			reason: ["", Validators.required]
		});

		this.form.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(values => {
			this.wordCount = values.reason.length;

			if (this.wordCount > 0 && this.wordCount <= this.totalWordCount) {
				this.disableContinue = false;
			} else {
				this.disableContinue = true;
			}
		});
	}

	onCancelClick() {
		this.dialogRef.close(true);
		let reason: string = this.form.get("reason")?.value;
		this.tppPaymentsApiService
			.cancelBookings(this.milestoneId, reason)
			.pipe(take(1))
			.subscribe(res => this.tppPaymentsApiService.reloadPayoutAfterCancelling.emit());
	}

	onBackClick() {
		this.dialogRef.close(false);
	}

	close() {
		this.dialogRef.close(false);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
