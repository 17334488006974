import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TooltipModule } from "@shared/components/tooltip/tooltip.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { InputAutocompleteModule } from "@shared/components/input-autocomplete/input-autocomplete.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { PaygroupStatusHistoryModule } from "@shared/components/paygroup-status-history/paygroup-status-history.module";
import { SharedModule } from "@shared/shared.module";
import { ContactsModule } from "@shared/components/contacts/contacts.module";
import { CustomerLegalEntitySelectModule } from "@shared/components/customer-legal-entity-select/customer-legal-entity-select.module";
import { CustomerListModule } from "@shared/components/customer-list/customer-list.module";
import { ExpansionPanelModule } from "@shared/components/expansion-panel/expansion-panel.module";
import { FloatingActionButtonModule } from "@shared/components/floating-action-button/floating-action-button.module";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { PaginationModule } from "@shared/components/pagination/pagination.module";
import { PipesModule } from "@shared/pipes/pipes.module";
import { PayGroupListFiltersComponent } from "./_components/pay-group-list/pay-group-list-filters/pay-group-list-filters.component";
import { PayGroupListItemComponent } from "./_components/pay-group-list/pay-group-list-item/pay-group-list-item.component";
import { PayGroupListComponent } from "./_components/pay-group-list/pay-group-list.component";
import { PayGroupComponent } from "./_components/pay-group/pay-group.component";
import { PayGroupsRoutingModule } from "./pay-groups-routing.module";
import { PayGroupsComponent } from "./pay-groups.component";

@NgModule({
	declarations: [
		PayGroupsComponent,
		PayGroupListComponent,
		PayGroupComponent,
		PayGroupListFiltersComponent,
		PayGroupListItemComponent
	],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		InputWrapperModule,
		MatInputModule,
		MatFormFieldModule,
		MatMenuModule,
		FlexLayoutModule,
		PaginationModule,
		FormsModule,
		ReactiveFormsModule,
		CustomerListModule,
		InputSelectModule,
		MatCardModule,
		MatListModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		PipesModule,
		PaginationModule,
		CustomerLegalEntitySelectModule,
		NoItemsLayoutModule,
		MatDatepickerModule,
		FloatingActionButtonModule,
		ExpansionPanelModule,
		InputAutocompleteModule,
		MatPaginatorModule,
		ContactsModule,
		MatRadioModule,
		ModalWrapperModule,
		SharedModule,
		MatSlideToggleModule,
		PaygroupStatusHistoryModule,
		PayGroupsRoutingModule,
		BreadcrumbsModule,
		MatTooltipModule,
		TooltipModule
	]
})
export class PayGroupsModule {}
