import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Milestone, PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { Subject, forkJoin } from "rxjs";
import { TppFileAPI } from "../../../services/tpp-files-api.service";
import { TppSubService, downloadTPPFile, tppFile, tppFileWithPagination } from "../../models/tpp-data.interface";
import { map, takeUntil } from "rxjs/operators";
import { TppDataInputApi } from "../../../services/tpp-data-input-api.service";
import { MatDialog } from "@angular/material/dialog";
import { FileErorrDialogComponent } from "@modules/file-management/_components/file-error-dialog/file-erorr-dialog/file-erorr-dialog.component";
import { FileManagementService } from "@shared/services/file-management/file-management.service";
import { PageEvent as PageEvent } from "@angular/material/paginator";
import { TppPaymentsApiService } from "../../../services/tpp-payments-api.service";
import { cancelReport, cancelReportWithPagination } from "../../models/tpp-payment.interface";
import { ToastService } from "@shared/services/toast/toast.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-tpp-file-history",
	templateUrl: "./tpp-file-history.component.html",
	styleUrls: ["./tpp-file-history.component.scss"]
})
export class TppFileHistoryComponent implements OnInit, OnDestroy {
	payGroup!: PayGroup;
	payCycleId: string = "";
	payCycleName: string = "";
	milestones!: Milestone[];
	milestone!: Milestone;
	payCycle!: PayCycle;
	subServices!: TppSubService[];
	files!: tppFile[];

	bannerShow: boolean = false;

	pageSize: number = 10;
	pageNumber: number = 0;
	totalPages: number = 0;
	totalCount: number = 0;

	cancelReports: cancelReport[] = [];
	bannerShowReset: boolean = false;
	bannerShowCancel: boolean = false;

	lastResetIndex: number = 10;
	canUploadFile = false;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private router: Router,
		private tppFileAPI: TppFileAPI,
		private tppDataInputApi: TppDataInputApi,
		public dialog: MatDialog,
		private fileManagementApiService: FileManagementService,
		private tppPaymentsApiService: TppPaymentsApiService,
		private toast: ToastService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canUploadDataInputFile()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canUploadFile = res;
				this.getStatesFromMilestone();
			});
	}

	getStatesFromMilestone() {
		if (history.state) {
			this.payGroup = history.state.payGroup;
			this.payCycle = history.state.payCycle;
			this.milestones = history.state.milestones;
			this.milestone = history.state.milestone;

			this.payCycleId = history.state.payCycle.id;
			this.payCycleName = history.state.payCycle.name;
			this.subServices = history.state.subServices;

			this.loadFiles();
		}
	}

	uploadFile(file: any) {
		if (file[0].type !== "text/csv") {
			this.throwUploadError();
		} else {
			this.uploadCSVFile(file);
		}
	}

	throwUploadError() {
		this.toast.showError("Incorrect file format. CSV required.");
	}

	uploadCSVFile(file: any): void {
		this.tppDataInputApi
			.uploadFile(file, this.payCycleId, this.milestone.group)
			.pipe(takeUntil(this.destroy$))
			.subscribe(_ => this.loadFiles());
	}

	loadFiles(): void {
		forkJoin([
			this.tppFileAPI.getFiles(
				this.payGroup.id,
				this.payCycleId,
				this.milestone.id!,
				"TPP",
				this.pageSize,
				this.pageNumber
			),
			this.tppPaymentsApiService.getCancelReport(this.payGroup.id, this.milestone.id!, 1000, 0)
		])
			.pipe(
				takeUntil(this.destroy$),
				map((data: [tppFileWithPagination, cancelReportWithPagination]) => {
					if (data[0].items.length) {
						this.totalCount = data[0].totalCount;
						this.totalPages = data[0].totalPages;
						this.pageNumber = data[0].pageNumber;
						this.files = data[0].items;

						if (data[1].items.length) {
							this.cancelReports = data[1].items;
						}

						this.assignSubserviceInfo(this.files);
					}
				})
			)
			.subscribe();
	}
	setResetAndCancelledEntries() {
		this.lastResetIndex = 10;

		for (let i = 0; i < this.milestone.resetHistory.length; i++) {
			this.files.push({
				userReset: this.milestone.resetHistory[i].userId,
				createdAt: this.milestone.resetHistory[i].resetAt //create common attribute between files and resetHistory data
			});
		}

		if (this.cancelReports.length) {
			for (let i = 0; i < this.cancelReports.length; i++) {
				this.files.push({
					userCancelled: this.cancelReports[i].canceledBy,
					createdAt: this.cancelReports[i].canceledAt //create common attribute between files and resetHistory data
				});
			}
		}

		this.files.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

		//Check to see if reset banner should show
		if (this.files[0].userReset) {
			this.bannerShow = true;
			this.bannerShowReset = true;
			this.lastResetIndex = 0;
		}

		if (this.files[0].userCancelled && !this.bannerShowReset) {
			this.bannerShow = true;
			this.bannerShowCancel = true;
			this.lastResetIndex = 0;
		}

		//Check to see from where the files entries should be greyed out - if first entry is not a reset

		if (this.lastResetIndex !== 0) {
			let resetFound: boolean = false;
			let i: number = 0;
			while (i < this.files.length && !resetFound) {
				if (this.files[i].userReset || this.files[i].userCancelled) {
					this.lastResetIndex = i;
					resetFound = true;
				}

				i++;
			}
		}
	}
	assignSubserviceInfo(files: tppFile[]) {
		files.forEach(file => {
			let subIds: string[] = [];

			this.subServices.forEach(subService => {
				if (subService.subService.id) {
					subIds.push(subService.subService.id);
				}
			});

			for (var i = 0; i < file.meta!.SERVICES.length; i++) {
				if (!subIds.includes(file.meta!.SERVICES[i])) {
					file.meta!.SERVICES[i] = "Subservice not found";
				}
			}

			if (file.meta && file.meta.SERVICES && file.meta.SERVICES.length) {
				for (var i = 0; i < file.meta.SERVICES.length; i++) {
					this.subServices.forEach(sub => {
						if (sub.subService.id === file.meta!.SERVICES[i]) {
							file.meta!.SERVICES[i] = sub.subService.name + " - " + sub.subService.externalId;
						}
					});
				}
			} else if (file.meta!.SERVICES.length === 0) {
				file.meta!.SERVICES.push("No subservices found");
			}
		});

		this.setResetAndCancelledEntries();
	}

	viewFileErrors(taskId: string): void {
		const dialogRef = this.dialog.open(FileErorrDialogComponent, {
			width: "995px",
			height: "570px",
			panelClass: "dialog-container",

			data: {
				taskId: taskId,
				errorType: "TPP",
				payGroup: this.payGroup
			}
		});
	}

	downloadFile(fileInfo: downloadTPPFile): void {
		this.fileManagementApiService
			.getFileObject(fileInfo.fileId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((response: Blob) => {
				let downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(response);
				if (fileInfo.fileName) downloadLink.setAttribute("download", fileInfo.fileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			});
	}

	goBack(): void {
		let route = "payments";

		if (this.router.url === "/global-dashboard/tpp-file-history") route = "global-dashboard";

		this.router.navigate([`/${route}/tpp-data-input`], {
			state: {
				payGroup: this.payGroup,
				payCycle: this.payCycle,
				milestones: this.milestones,
				milestone: this.milestone
			}
		});
	}

	closeBanner(): void {
		this.bannerShow = false;
	}

	paginate(pagination: PageEvent) {
		this.pageSize = pagination.pageSize;
		this.pageNumber = pagination.pageIndex;
		this.loadFiles();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
