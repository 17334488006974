import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { PaginationService } from "@shared/components/pagination/_services/pagination.service";
import { PaginationOptions } from "@shared/constants/pagination";
import { SelectOption } from "@shared/models/select-option.interface";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { PaginationData } from "@store/models/pagination.model";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, finalize, take, takeUntil } from "rxjs/operators";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { getCustomerEntityGroupState, getPagination } from "../../../../store";
import { UpdateCustomerEntityGroupAction } from "../../../../store/actions/customerEntityGroupSelect.action";
import { AppState } from "../../../../store/models/state.model";

@Component({
	selector: "app-payment-calendar-paygroup-list",
	templateUrl: "./payment-calendar-paygroup-list.component.html",
	styleUrls: ["./payment-calendar-paygroup-list.component.scss"]
})
export class PaymentCalendarPaygroupListComponent implements OnInit, OnDestroy {
	//pagination
	pageIndex: number = 0;
	pageSize: number = 10;
	totalPageCount!: number;
	customerName = "";

	private destroy$: Subject<void> = new Subject<void>();

	customerId = "";
	entitySelectForm!: FormGroup;

	busy = true;
	legalEntitiesSelectOptions: SelectOption[] = [];
	payGroupsList: PayGroup[] = [];

	legalEntityIds: string[] = [];
	allLegalEntityOptions: string[] = [];

	paginationType: PaginationOptions = PaginationOptions.PAYGROUPS;
	queryString = "";

	ALL_OPTION: SelectOption = {
		value: "ALL_OPTIONS",
		text: "All legal entities"
	};

	constructor(
		private router: Router,
		private store: Store<AppState>,
		private payGroupsService: PaygroupsService,
		private legalEntityService: LegalEntityService,
		private paginationService: PaginationService
	) {}

	ngOnInit(): void {
		this.fetchCustomerFromState();
		this.setupPaginationSubscription();
		this.initForm();
	}

	fetchCustomerFromState(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			if (state?.customerId) {
				this.customerName = state.customerName!;
				this.customerId = state.customerId;
				this.getLegalEntities(this.customerId);
			}
		});
	}

	getLegalEntities(customerId: string): void {
		this.legalEntityIds = [];
		this.legalEntityService
			.getLegalEntitiesForCustomerAsSelectOption(customerId, true)
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe(legalEntities => {
				if (legalEntities.length) {
					legalEntities.forEach(entity => {
						this.allLegalEntityOptions.push(entity.value);
					});

					this.legalEntityIds = [...this.allLegalEntityOptions];
					this.legalEntitiesSelectOptions = legalEntities;
					this.legalEntitiesSelectOptions.unshift(this.ALL_OPTION);
				}
			});
	}

	setupPaginationSubscription(): void {
		this.store.pipe(select(getPagination), takeUntil(this.destroy$)).subscribe({
			next: pagination => {
				if (pagination && pagination.hasOwnProperty("PAYGROUPS")) {
					const paygroupPagination = pagination["PAYGROUPS"];

					this.pageSize = paygroupPagination.pageSize;
					this.pageIndex = paygroupPagination.pageIndex;

					this.getPayGroups(
						[this.customerId],
						this.legalEntityIds,
						paygroupPagination.pageSize,
						paygroupPagination.pageIndex,
						this.queryString
					);
				}
			}
		});
	}

	getPayGroups(
		customerIds: string[],
		legalEntityIds: string[],
		pageSize: number,
		pageIndex: number,
		queryString: string
	): void {
		this.busy = true;
		const statuses: string = "LIVE, IMPLEMENTATION";
		legalEntityIds = this.payGroupsService.searchPaygroupInLargeEntities(legalEntityIds);

		this.payGroupsService
			.getPayGroups(customerIds, legalEntityIds, statuses, pageSize, pageIndex, queryString)
			.pipe(
				take(1),
				takeUntil(this.destroy$),
				finalize(() => (this.busy = false))
			)
			.subscribe(payGroups => {
				this.totalPageCount = payGroups.totalPages;
				this.payGroupsList = payGroups.items;
			});
	}

	initForm(): void {
		this.entitySelectForm = new UntypedFormGroup({
			paygroup: new UntypedFormControl(""),
			legal: new UntypedFormControl("", Validators.required)
		});

		this.setupValueChanges();
	}

	setupValueChanges(): void {
		this.entitySelectForm
			.get("legal")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((legalEntity: string) => {
				this.payGroupsList = [];
				if (legalEntity) {
					legalEntity === "ALL_OPTIONS"
						? (this.legalEntityIds = [...this.allLegalEntityOptions])
						: (this.legalEntityIds = [legalEntity]);

					this.resetPagination();
				}
			});

		this.entitySelectForm
			.get("paygroup")
			?.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
			.subscribe((search: string) => {
				if (search !== null) {
					this.queryString = search;
					this.resetPagination();
				}
			});
	}

	resetPagination(): void {
		const paginationSelection: PaginationData = {
			pageSize: this.pageSize,
			pageIndex: 0
		};

		this.paginationService.updatePaginationSate(this.paginationType, paginationSelection);
	}

	paygroupSelected(payGroup: PayGroup): void {
		this.store.dispatch(
			new UpdateCustomerEntityGroupAction({
				customerId: this.customerId,
				customerName: payGroup.customer.name,
				legalEntityId: payGroup.legalEntityId,
				payGroupId: payGroup.id
			})
		);

		this.router.navigate(["/calendar/milestone-list"], { state: { payGroup } });
	}

	resetOptions(): void {
		this.legalEntitiesSelectOptions = [];
		this.payGroupsList = [];
		this.legalEntityIds = [];
		this.resetPagination();

		if (this.entitySelectForm) {
			this.entitySelectForm.reset();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
