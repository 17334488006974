import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonLegalEntityService } from "@modules/legal-entity-setup/_services/common/common-legal-entity.service";
import { Store, select } from "@ngrx/store";
import { PaginationService } from "@shared/components/pagination/_services/pagination.service";
import { PaginationOptions } from "@shared/constants/pagination";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { PaginationData } from "@store/models/pagination.model";
import { Subject, debounceTime, distinctUntilChanged, finalize, take, takeUntil } from "rxjs";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { getCustomerEntityGroupState, getPagination } from "src/app/store";
import { UpdateCustomerEntityGroupAction } from "src/app/store/actions/customerEntityGroupSelect.action";
import { CustomerEntityGroupSelect } from "src/app/store/models/customerEnitityGroupSelection.model";
import { AppState } from "src/app/store/models/state.model";

@Component({
	selector: "app-paygroup-select",
	templateUrl: "./paygroup-select.component.html",
	styleUrls: ["./paygroup-select.component.scss"]
})
export class PaygroupSelectComponent implements OnInit, OnDestroy {
	@Output() selectedPayGroup: EventEmitter<void> = new EventEmitter<void>();

	// Public variables

	busy: boolean = true;
	entitySelectForm!: FormGroup;

	title: string = "Countries and partners";

	customerId!: string;
	customerName!: string;

	//pagination
	pageIndex: number = 0;
	pageSize: number = 10;
	totalPageCount!: number;

	// Private variables

	legalEntitiesSelectOptions: SelectOption[] = [];
	payGroupsList: PayGroup[] = [];

	legalEntityIds: string[] = [];

	allLegalEntityOptions: string[] = [];
	queryString = "";
	paginationType: PaginationOptions = PaginationOptions.PAYGROUPS;

	private destroy$: Subject<void> = new Subject<void>();

	readonly ALL_OPTION: SelectOption = {
		value: "ALL_OPTIONS",
		text: "All legal entities"
	};

	constructor(
		private readonly legalEntityService: LegalEntityService,
		private router: Router,
		private store: Store<AppState>,
		public commonLegalEntityService: CommonLegalEntityService,
		private payGroupsService: PaygroupsService,
		private paginationService: PaginationService
	) {}

	ngOnInit() {
		this.fetchCustomerFromState();
		this.setupPaginationSubscription();
		this.initForm();
	}

	fetchCustomerFromState(): void {
		this.store
			.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState))
			.subscribe((state: CustomerEntityGroupSelect) => {
				if (state?.customerId) {
					this.customerName = state.customerName!;
					this.customerId = state.customerId;
					this.getLegalEntities(this.customerId);
				}
			});
	}

	getLegalEntities(customerId: string): void {
		this.legalEntityIds = [];
		this.legalEntityService
			.getLegalEntitiesForCustomerAsSelectOption(customerId, true)
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe(legalEntities => {
				if (legalEntities.length) {
					legalEntities.forEach(entity => {
						this.allLegalEntityOptions.push(entity.value);
					});

					this.legalEntityIds = [...this.allLegalEntityOptions];
					this.legalEntitiesSelectOptions = legalEntities;
					this.legalEntitiesSelectOptions.unshift(this.ALL_OPTION);
				}
			});
	}

	setupPaginationSubscription(): void {
		this.store.pipe(select(getPagination), takeUntil(this.destroy$)).subscribe({
			next: pagination => {
				if (pagination && pagination.hasOwnProperty("PAYGROUPS")) {
					const paygroupPagination = pagination["PAYGROUPS"];
					this.pageSize = paygroupPagination.pageSize;
					this.pageIndex = paygroupPagination.pageIndex;

					this.getPayGroups(
						[this.customerId],
						this.legalEntityIds,
						paygroupPagination.pageSize,
						paygroupPagination.pageIndex,
						this.queryString
					);
				}
			}
		});
	}

	initForm() {
		this.entitySelectForm = new UntypedFormGroup({
			paygroup: new UntypedFormControl(""),
			legal: new UntypedFormControl("", Validators.required)
		});

		this.setupValueChanges();
	}

	setupValueChanges(): void {
		this.entitySelectForm
			.get("legal")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((legalEntity: string) => {
				this.payGroupsList = [];
				if (legalEntity) {
					legalEntity === "ALL_OPTIONS"
						? (this.legalEntityIds = [...this.allLegalEntityOptions])
						: (this.legalEntityIds = [legalEntity]);

					this.resetPagination();
				}
			});

		this.entitySelectForm
			.get("paygroup")
			?.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
			.subscribe((search: string) => {
				if (search !== null) {
					this.queryString = search;
					this.resetPagination();
				}
			});
	}

	resetPagination(): void {
		this.busy = true;
		const paginationSelection: PaginationData = {
			pageSize: this.pageSize,
			pageIndex: 0
		};

		this.paginationService.updatePaginationSate(this.paginationType, paginationSelection);
	}

	filterPayGroupsBySearch(search: string): void {
		this.queryString = search;
		this.resetPagination();
	}

	filterPayGroupsByLegalEntityId(id: string): void {
		this.legalEntityIds = [id];
		this.resetPagination();
	}

	getPayGroups(
		customerIds: string[],
		legalEntityIds: string[],
		pageSize: number,
		pageIndex: number,
		queryString: string
	) {
		const statuses: string = "LIVE, IMPLEMENTATION";
		legalEntityIds = this.payGroupsService.searchPaygroupInLargeEntities(legalEntityIds);

		this.payGroupsService
			.getPayGroups(customerIds, legalEntityIds, statuses, pageSize, pageIndex, queryString)
			.pipe(
				take(1),
				takeUntil(this.destroy$),
				finalize(() => (this.busy = false))
			)
			.subscribe(payGroups => {
				this.totalPageCount = payGroups.totalPages;
				this.payGroupsList = payGroups.items;
			});
	}

	selectpayGroup(payGroup: PayGroup) {
		this.store.dispatch(
			new UpdateCustomerEntityGroupAction({
				customerId: this.customerId,
				customerName: payGroup.customer.name,
				legalEntityId: payGroup.legalEntityId,
				payGroupId: payGroup.id
			})
		);

		this.selectedPayGroup.emit();
	}

	viewEntitiesClicked() {
		this.router.navigate(["/legal-entities/setup"]);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
