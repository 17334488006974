import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule, UntypedFormBuilder } from "@angular/forms";
import { EventsTableDetailItem } from "@modules/global-dashboard/models/global-dashboard-interface";
import { UserRole } from "@shared/constants/roles";
import {
	IStatusForm,
	MilestoneStatusOptions,
	MilestoneTypeName,
	MilestoneUpdate,
	MilestoneUpdateDTO,
	PayCycle,
	MilestoneStatusesValue,
	MilestoneStatusesText
} from "@shared/models/pay-cycle.interface";
import { FundingDateMilestoneStatusOptions } from "@shared/models/pay-cycle.interface";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { debounceTime, distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { MilestonesService } from "@shared/services/milestones/milestones.service";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import { MatInputModule } from "@angular/material/input";

@Component({
	selector: "app-milestone-status-dropdown",
	templateUrl: "./milestone-status-dropdown.component.html",
	styleUrls: ["./milestone-status-dropdown.component.scss"],
	standalone: true,
	imports: [CommonModule, SharedModule, ReactiveFormsModule, MatInputModule]
})
export class MilestoneStatusDropdownComponent implements OnInit {
	@Input() set _milestone(value: EventsTableDetailItem) {
		this.milestone = value;

		this.permissions
			.canEditFundingDateStatus()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditFundingDateSatus = res;
				this.setupFundingSelectionOption(value.milestoneDetails.type);
			});
	}

	@Output() updateStatus: EventEmitter<void> = new EventEmitter<void>();

	milestone!: EventsTableDetailItem;
	milestonesSelection!: SelectOption[];
	milestoneStatusForm!: FormGroup;
	payCycle!: PayCycle;
	milestoneStatusesValue = MilestoneStatusesValue;
	milestoneStatusesText = MilestoneStatusesText;

	showDropdown = false;
	canEditFundingDateSatus = false;
	calendarData: any;

	destroy$ = new Subject<void>();

	private isDisabledField = false;

	constructor(
		private permissions: PermissionsService,
		private formBuilder: UntypedFormBuilder,
		private payCycleService: PayCycleService,
		private milestonesService: MilestonesService
	) {
		this.permissions
			.disableAccessToEditableFormField([UserRole.TS_IMPLEMENTATION_LEAD, UserRole.TS_VO, UserRole.CLIENT])
			.subscribe(isDisabled => (this.isDisabledField = isDisabled));
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * Use to toggle milestone status dropdown option
	 * @param show: value to show/hide dropdown option
	 */
	toggleDropDown(show?: boolean): void {
		if (
			(!this.canEditFundingDateSatus && this.milestone.milestoneDetails.type === "FUNDING_DATE") ||
			this.isDisabledField
		) {
			this.showDropdown = false;
		} else {
			show !== undefined ? (this.showDropdown = show) : (this.showDropdown = !this.showDropdown);
		}
	}

	/**
	 * Use to get milestone status to display (ex. 'Completed')
	 * @param statusText: milestone status text
	 * @returns return milestone status string to display
	 */
	getTextOfMilestoneText(statusText?: string): string {
		return statusText
			? FundingDateMilestoneStatusOptions.filter(option => option.value === statusText)[0].text
			: "Not Found";
	}

	/**
	 * Use to get milestone status value (ex. 'COMPLETE')
	 * @param statusValue: milestone status value
	 * @returns return milestone status value
	 */
	getValueOfMilestoneValue(statusValue: string): string {
		return FundingDateMilestoneStatusOptions.filter(option => option.text === statusValue)[0].value;
	}

	/**
	 * Use to set selected milestone status
	 * @param status: miles stone status value (ex. 'COMPLETE')
	 */
	setStatusSelection(status: string): void {
		this.milestone.milestoneDetails.status = this.getTextOfMilestoneText(this.transformMilestoneStatus(status));
		this.milestoneStatusForm.get("milestoneStatus")?.patchValue({
			value: this.getTextOfMilestoneText(this.transformMilestoneStatus(status)),
			disabled:
				(!this.canEditFundingDateSatus && this.milestone.milestoneDetails.type === "FUNDING_DATE") ||
				this.isDisabledField
		});

		this.updateStatus.emit();
	}

	/**
	 * Use to initialize milestone dropdown options status
	 * @param milestoneType: milestone type
	 */
	private setupFundingSelectionOption(milestoneType: MilestoneTypeName): void {
		if (milestoneType === "FUNDING_DATE") {
			this.milestonesSelection = FundingDateMilestoneStatusOptions;
		} else {
			this.milestonesSelection = MilestoneStatusOptions;
		}

		this.updateMilestoneStatus();
	}

	/**
	 * Use to update milestone status
	 */
	private updateMilestoneStatus(): void {
		if (this.milestone.milestoneDetails.status === this.milestoneStatusesValue.UNDEFINED) {
			this.milestone.milestoneDetails.status = this.milestoneStatusesValue.PENDING;
		} else if (this.milestone.milestoneDetails.status === this.milestoneStatusesValue.HAS_ERRORS) {
			this.milestone.milestoneDetails.status = this.milestoneStatusesValue.ERROR;
		} else if (this.milestone.milestoneDetails.status === this.milestoneStatusesValue.COMPLETE) {
			this.milestone.milestoneDetails.status = this.milestoneStatusesValue.COMPLETE;
		} else if (this.milestone.milestoneDetails.status === this.milestoneStatusesValue.IN_PROGRESS) {
			this.milestone.milestoneDetails.status = this.milestoneStatusesValue.IN_PROGRESS;
		} else {
			this.milestone.milestoneDetails.status = this.milestone.milestoneDetails.status;
		}

		this.initMilestoneStatusForm();
	}

	/**
	 * Use to iniatilize milestone status dropdown form values
	 */
	private initMilestoneStatusForm(): void {
		this.milestoneStatusForm = this.formBuilder.group({
			milestoneStatus: {
				value: this.getTextOfMilestoneText(this.milestone.milestoneDetails.status),
				disabled:
					(!this.canEditFundingDateSatus && this.milestone.milestoneDetails.type === "FUNDING_DATE") ||
					this.isDisabledField
			}
		}) as IStatusForm;

		this.milestoneStatusForm
			.get("milestoneStatus")
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
			.subscribe(status => {
				this.updateStatusMilestonePayCycle(
					this.getValueOfMilestoneValue(status.value),
					this.milestone.milestoneDetails.type
				);
			});
	}

	/**
	 * Use to update milestone status selected
	 * @param status: milestone status (ex. 'COMPLETE')
	 * @param milestoneType
	 */
	private updateStatusMilestonePayCycle(status: string, milestoneType: string): void {
		const milestoneUpdateDTO: MilestoneUpdateDTO = {
			milestones: []
		};

		const payDate = this.payCycleService.formatPayDateForMilestones(this.milestone.milestoneDetails.deadline!);

		const milestoneToUpdate: MilestoneUpdate = {
			id: this.milestone.milestoneDetails.id,
			version: this.milestone.milestoneDetails.version,
			type: this.milestone.milestoneDetails.type,
			group: this.milestone.group,
			date: payDate,
			status: status,
			params: []
		};

		milestoneUpdateDTO.milestones.push(milestoneToUpdate);
		this.milestonesService.updateMilestones(milestoneUpdateDTO).subscribe();
	}

	/**
	 * Use to convert milestone status text to value (ex. 'COMPLETED')
	 * @param value: milestone status text (ex. 'Complete')
	 * @returns
	 */
	transformMilestoneStatus(value: string): string {
		switch (value) {
			case this.milestoneStatusesText.PENDING:
				return this.milestoneStatusesValue.PENDING;
			case this.milestoneStatusesText.COMPLETED:
				return this.milestoneStatusesValue.COMPLETE;
			case this.milestoneStatusesText.HAS_ERRORS:
				return this.milestoneStatusesValue.ERROR;
			case this.milestoneStatusesText.IN_PROGRESS:
				return this.milestoneStatusesValue.IN_PROGRESS;
		}

		return value;
	}
}
