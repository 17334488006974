import { Observable } from "rxjs";

export enum FileTypes {
	G2N = "G2N",
	TPP = "TPP",
	PAY_ELECTIVES = "PAY_ELECTIVES",
	PAYROLL_INTEGRATION = "Payroll integration"
}

export enum FileStatus {
	PENDING = "PENDING",
	SUCCESS = "SUCCESS",
	Failed = "FAILED",
	Error = "ERROR"
}

export type FileManagerTaskPagination = {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: FileManagerTask[];
};

export type FileManagerPlaceholdersPagination = {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: FileManagerPlaceholder[];
};

export type FileManagerPlaceholder = {
	id: string;
	payGroupId: string;
	type: string;
	attributes: {};
	integrationTaskId: string;
	createdBy: string;
	createdAt: string;
	status: {
		name: FileStatus;
		messages: string[];
	};
};

export type FileManagerTask = {
	taskId: string;
	createdAt: Date;
	processedAt?: Date;
	fileId: string;
	fileName: string;
	fileType: FileTypes;
	status: FileStatus;
	meta?: {};
	attributes?: {
		INTEGRATION_ID: string;
	};
};

export type FileHistoryPagination = {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: FileHistory[];
};

export type FileHistory = {
	taskId?: string;
	createdAt: Date;
	processedAt?: Date;
	fileId?: string;
	fileName?: string;
	fileType?: FileTypes;
	status?: FileStatus;
	integrationErrorMessages?: IntegrationError[];
	userReset?: string;
	userCancelled?: string;
	attributes?: {
		INTEGRATION_ID: string;
	};
};

export type IntegrationError = {
	timeStamp: string;
	fileName: string;
	message: string;
};

export type FileReport = {
	id: string;
	employeeErrors: EmployeeError[];
	tppErrors: tppError[];
};

export type EmployeeError = {
	employee: {
		id: string;
		externalId: string;
		firstName: string;
		lastName: string;
	};
	validationErrors: {
		code: string;
		field: string;
		value: string;
		message: string;
	}[];
	executionError: {
		message: string;
	};
	warnings?: Warning[];
};

export type GeneralErrorType = {
	code: string;
	field: string;
	value: string;
	message: string;
};

export type tppError = {
	service: {
		serviceId: string;
		serviceName: string;
		subServiceId: string;
		subServiceName: string;
		externalId: string;
	};
	validationErrors: {
		code: string;
		field: string;
		value: string;
		message: string;
		attributes: {};
	}[];
	warnings: Warning[];
	executionError: {
		message: string;
	};
};

export type Warning = {
	code: string;
	message: string;
};
