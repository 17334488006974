import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CalendarComponent } from "@shared/components/calendar/calendar.component";
import { Milestone, MilestoneGroup, PayCycle, MilestoneGroupName } from "src/app/shared/models/pay-cycle.interface";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { setFormatDate } from "src/app/shared/utils/date.util";
import { MatDialog as MatDialog } from "@angular/material/dialog";
import { CreateOffcycleDialogComponent } from "../create-offcycle-dialog/create-offcycle-dialog.component";
import { Location } from "@angular/common";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import { map, take, takeUntil } from "rxjs/operators";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { Subject, Subscription } from "rxjs";
import { MilestonesService } from "@shared/services/milestones/milestones.service";
import { ApiService } from "@modules/pay-groups/services/api.service";
import { AppState } from "src/app/store/models/state.model";
import { select, Store } from "@ngrx/store";
import { getCustomerEntityGroupState } from "src/app/store";
import { StateService } from "@shared/services/state/state.service";
import { CalendarService } from "@shared/services/calendar/calendar.service";
import { breadCrumbButtonIcon } from "@shared/models/breadcrumbs.interface";

@Component({
	selector: "app-payment-calendar-milestone-list",
	templateUrl: "./payment-calendar-milestone-list.component.html",
	styleUrls: ["./payment-calendar-milestone-list.component.scss"]
})
export class PaymentCalendarMilestoneListComponent implements OnInit, OnDestroy {
	@ViewChild(CalendarComponent) calendarComponent: CalendarComponent | undefined;

	payGroup!: PayGroup;
	showNetCreation: boolean = false;
	showMilestoneEdition: boolean = false;
	payCycleSelected: PayCycle | undefined;
	dateSelected!: Date;
	milestoneSelected!: Milestone;
	todaysDate: Date = new Date();
	milestones: Milestone[] = [];
	payCycle!: PayCycle | undefined;

	canCreateCalendarEvents: boolean = false;
	canEditCalendarEvents: boolean = false;
	canCreateCalendarOffCycles: boolean = false;

	private stateSubscription!: Subscription;

	private destroy$: Subject<void> = new Subject<void>();

	selectedMilestoneGroups!: MilestoneGroup | undefined;
	milestoneGroupsToCreate!: MilestoneGroup | undefined;
	milestoneGroups: MilestoneGroup[] = [];
	firstUnconfiguredMilestoneGroup!: MilestoneGroup | undefined;

	breadCrumbIcon: breadCrumbButtonIcon = {
		name: "add",
		lineWidth: "2.5",
		color: "var(--color-primary-1000)",
		size: "16"
	};

	constructor(
		private router: Router,
		public dialog: MatDialog,
		private readonly location: Location,
		private permissions: PermissionsService,
		private milestonesService: MilestonesService,
		private payCycleService: PayCycleService,
		private payGroupService: ApiService,
		private store: Store<AppState>,
		private stateService: StateService,
		private calendarService: CalendarService
	) {}

	ngOnInit(): void {
		this.permissions
			.canCreateCalendarEvents()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canCreateCalendarEvents = res;
			});

		this.permissions
			.canEditCalendarEvents()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canEditCalendarEvents = res;
			});

		this.permissions
			.canCreateCalendarOffCycles()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canCreateCalendarOffCycles = res;
			});

		this.setupFromStoreState();
	}

	setupFromStoreState(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			if (state && state.payGroupId) {
				this.getPayGroup(state.payGroupId);
			}
		});
	}

	getPayGroup(id: string): void {
		this.payGroupService
			.getPayGroup(id)
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.payGroup = res;
				this.resetVaribles();
			});
	}

	resetVaribles(): void {
		this.milestoneGroups = [];

		this.payCycle = undefined;
		this.milestones = [];
	}

	getMilestones(payCycleId: string): void {
		this.firstUnconfiguredMilestoneGroup = undefined;
		this.milestonesService
			.getMilestones(payCycleId)
			.pipe(take(1))
			.subscribe(milestones => {
				this.milestones = milestones;

				this.milestoneGroups.forEach(group => {
					let milestone = milestones.filter(milestone => milestone.group === group.group);

					if (milestone) {
						group.milestone = milestone;

						this.calendarService.setMilestones(this.milestones);
					}

					if (this.selectedMilestoneGroups && this.selectedMilestoneGroups.group === group.group) {
						this.selectedMilestoneGroups = group;
					}
				});

				if (!this.selectedMilestoneGroups || Object.keys(this.selectedMilestoneGroups).length === 0) {
					this.firstUnconfiguredMilestoneGroup = this.getFirstUnconfiguredMilestoneGroup();
				}
			});
	}
	getFirstUnconfiguredMilestoneGroup(): MilestoneGroup {
		let unconfigureMilestone!: MilestoneGroup;

		for (const milestone of this.milestoneGroups) {
			if (milestone.milestone?.length === 0) {
				unconfigureMilestone = milestone;
				break;
			}
		}

		return unconfigureMilestone;
	}

	backFromModal(): void {
		this.router.navigate(["/calendar"]);
	}

	createOffcycle(): void {
		this.openDialog("CREATE");
	}

	editCycle(payCycle: PayCycle): void {
		this.openDialog("EDIT", payCycle);
	}

	openDialog(dialogType: string, payCycle?: PayCycle): void {
		let dialogRefData;
		if (dialogType === "CREATE") {
			dialogRefData = {
				customerId: this.payGroup.customer.id,
				legalEntityId: this.payGroup.legalEntityId,
				payGroupId: this.payGroup.id,
				externalId: this.payCycleSelected?.externalId,
				dialogType: dialogType
			};
		} else {
			dialogRefData = {
				customerId: this.payGroup.customer.id,
				legalEntityId: this.payGroup.legalEntityId,
				payGroupId: this.payGroup.id,
				externalId: payCycle?.externalId,
				dialogType: dialogType,
				payCycle: payCycle
			};
		}

		const dialogRef = this.dialog.open(CreateOffcycleDialogComponent, {
			width: "750px",
			panelClass: "dialog-container",
			data: dialogRefData
		});

		dialogRef.afterClosed().subscribe((result: string) => {
			if (result) {
				this.payCycleService.viewCreatedOffCycle(result);
			}
		});
	}

	setMilestoneGroup(milestoneGroup: MilestoneGroup): void {
		if (!this.showNetCreation && !this.showMilestoneEdition) {
			milestoneGroup ? this.setSelectedMilestone(milestoneGroup) : this.resetSelectedMilestone();
		}
	}
	resetSelectedMilestone(): void {
		this.selectedMilestoneGroups = {} as MilestoneGroup;
		this.firstUnconfiguredMilestoneGroup = {} as MilestoneGroup;
	}
	setSelectedMilestone(milestoneGroup: MilestoneGroup): void {
		this.selectedMilestoneGroups = milestoneGroup;
		this.firstUnconfiguredMilestoneGroup = {} as MilestoneGroup;
	}

	openDetails(milestone: Milestone): void {
		this.milestoneSelected = milestone;
		this.showNetCreation = false;
		this.showMilestoneEdition = true;
	}

	dayClick(date: Date): void {
		if (this.canCreateCalendarEvents) {
			this.dateSelected = date;
			this.showMilestoneEdition = false;

			if (this.hasMilestoneSelected()) {
				this.showNetCreation = true;
			} else {
				this.showNetCreation = false;
			}
		}
	}
	hasMilestoneSelected(): boolean {
		if (!!this.payCycleSelected) {
			if (this.selectedMilestoneGroups && this.selectedMilestoneGroups.milestone?.length === 0) {
				this.milestoneGroupsToCreate = this.selectedMilestoneGroups;
				return true;
			} else if (
				this.firstUnconfiguredMilestoneGroup &&
				this.firstUnconfiguredMilestoneGroup.milestone?.length === 0
			) {
				this.milestoneGroupsToCreate = this.firstUnconfiguredMilestoneGroup;
				return true;
			} else {
				this.milestoneGroupsToCreate = {} as MilestoneGroup;
				return false;
			}
		} else {
			this.milestoneGroupsToCreate = {} as MilestoneGroup;
			return false;
		}
	}

	payCycleChange(payCycle: PayCycle): void {
		if (payCycle) {
			this.payCycleSelected = payCycle;

			this.payCycle = payCycle;

			// Sort milestones by date
			this.milestones.sort((milestoneA: Milestone, milestoneB: Milestone) => {
				const milestoneDateA: Date = setFormatDate(milestoneA.date);
				const milestoneDateB: Date = setFormatDate(milestoneB.date);
				return milestoneDateA.getTime() > milestoneDateB.getTime() ? 1 : -1;
			});

			this.showNetCreation = false;
			this.showMilestoneEdition = false;

			//get filtered TPP groups for paycycleGroup

			this.getFilteredTPPGroup(payCycle.id);
		} else {
			this.noPayCycleSelected(true);
		}
	}

	getFilteredTPPGroup(payCycleId: string): void {
		this.payCycleService
			.getOrganisedTPPGroupsForPaycycle(payCycleId)
			.pipe(
				take(1),
				map(milestoneGroups => {
					this.milestoneGroups = [];
					milestoneGroups.forEach(milestoneGroup => {
						this.milestoneGroups.push(milestoneGroup);
					});
				})
			)
			.subscribe(milestoneGroups => {
				this.getMilestones(payCycleId);
			});
	}

	noPayCycleSelected(noCycle: boolean): void {
		if (noCycle) {
			this.payCycleSelected = undefined;
			this.milestoneGroups = [];
		}
	}

	onCloseCreation(reload: { reload: boolean }): void {
		this.showNetCreation = false;
		if (reload.reload) {
			this.calendarComponent?.initialiseCalendar();
		}
	}

	onCloseEdition(reload: { reload: boolean }): void {
		this.showMilestoneEdition = false;
		if (reload.reload) {
			this.calendarComponent?.initialiseCalendar();
		}
	}

	goHome(): void {
		this.router.navigate(["/calendar"]);
	}

	ngOnDestroy(): void {
		if (this.stateSubscription) {
			this.stateSubscription.unsubscribe();
		}

		this.destroy$.next();
		this.destroy$.complete();
	}
}
