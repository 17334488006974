<div class="header">
	<h3 class="dialog-text flex flex-1" style="--max-width: 95%" *ngIf="errorType === 'PAY_ELECTIVES'">
		MASTER DATA ERRORS
	</h3>
	<h3
		class="dialog-text flex flex-1"
		style="--max-width: 95%"
		*ngIf="errorType === 'G2N' || errorType === 'Payroll integration'"
	>
		G2N ERRORS
	</h3>
	<h3 class="dialog-text flex flex-1" style="--max-width: 95%" *ngIf="errorType === 'TPP'">TPP DATA ERRORS</h3>
	<app-icon
		id="master-close-icon"
		[name]="'close'"
		[size]="'70px'"
		[color]="'white'"
		[lineWidth]="'0.8px'"
		(click)="closeDialog()"
		class="flex flex-1"
		style="--max-width: 5%"
	></app-icon>
</div>
<div class="history-container">
	<app-file-detail *ngIf="errorType === 'PAY_ELECTIVES'" [taskId]="taskId"></app-file-detail>

	<app-file-errors
		*ngIf="errorType === 'G2N' && !Object.keys(handShakeResults).length"
		[taskId]="taskId"
		[payGroup]="payGroup"
	></app-file-errors>

	<app-file-errors
		*ngIf="errorType === 'Payroll integration' && integrationErrorMessages && !Object.keys(handShakeResults).length"
		[integrationErrorMessages]="integrationErrorMessages"
		[taskId]="taskId"
		[errorType]="errorType"
		[payGroup]="payGroup"
	></app-file-errors>

	<app-file-errors
		*ngIf="Object.keys(handShakeResults).length"
		[handShakeResults]="handShakeResults"
		[taskId]="taskId"
		[errorType]="errorType"
		[payGroup]="payGroup"
	></app-file-errors>

	<app-file-errors
		*ngIf="errorType === 'TPP'"
		[taskId]="taskId"
		[errorType]="errorType"
		[payGroup]="payGroup"
	></app-file-errors>
</div>
