import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, take } from "rxjs";

import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MilestonesService } from "@shared/services/milestones/milestones.service";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { MilestoneGroupName, PayCycle } from "@shared/models/pay-cycle.interface";
import { UpdateCustomerEntityGroupAction } from "@store/actions/customerEntityGroupSelect.action";
import { AppState } from "@store/models/state.model";
import { EventsTableDetailItem, MilestoneEventHistoryLogData } from "../models/global-dashboard-interface";
import { UpdateSelectedCalendarMonth } from "@store/actions/payCycleSelect.action";

@Injectable({
	providedIn: "root"
})
export class GlobalDashboardEventsService {
	constructor(
		private store: Store<AppState>,
		private payCycleService: PayCycleService,
		private paygroupsService: PaygroupsService,
		private milestonesService: MilestonesService,
		private router: Router
	) {}

	initialData: MilestoneEventHistoryLogData = {
		milestoneId: "",
		milestoneType: "",
		customerExternalId: "",
		customerName: "",
		payGroupId: "",
		paygroupName: "",
		cycleName: "",
		group: ""
	};
	expandMilestone = "";
	milestoneEventHistoryLogSubject = new BehaviorSubject<MilestoneEventHistoryLogData>(this.initialData);

	setElementData(element: EventsTableDetailItem): void {
		const milestoneHistoryLogHeaderData = {
			milestoneId: element.milestoneDetails.id,
			milestoneType: element.milestoneDetails.type,
			customerExternalId: element.customerDetails.externalId,
			customerName: element.customerDetails.name,
			payGroupId: element.payGroupDetails.id,
			paygroupName: element.payGroupDetails.name,
			cycleName: element.payCycleDetails.name,
			group: element.group
		};
		this.milestoneEventHistoryLogSubject.next(milestoneHistoryLogHeaderData);
	}

	resetElementData(): void {
		this.milestoneEventHistoryLogSubject.next(this.initialData);
	}

	routeToSpecifiedScreen(routeTo: string, payGroupId: string, payCycleId: string, group: MilestoneGroupName): void {
		const milestones$ = this.milestonesService.getMilestones(payCycleId);
		const payCycle$ = this.payCycleService.getPayCycleById(payCycleId);
		const payGroup$ = this.paygroupsService.getPaygroupWithPaygroupId(payGroupId);

		forkJoin([milestones$, payCycle$, payGroup$])
			.pipe(take(1))
			.subscribe(([milestonesResult, payCycleResult, paygroupResult]) => {
				this.store.dispatch(
					new UpdateCustomerEntityGroupAction({
						customerName: paygroupResult.customer.name,
						customerId: paygroupResult.customer.id,
						legalEntityId: "",
						payGroupId: ""
					})
				);

				setTimeout(() => {
					this.store.dispatch(
						new UpdateCustomerEntityGroupAction({
							customerName: paygroupResult.customer.name,
							customerId: paygroupResult.customer.id,
							legalEntityId: paygroupResult.legalEntityId,
							payGroupId: paygroupResult.id
						})
					);

					switch (routeTo) {
						case "payments":
							{
								if (group === "NETS") {
									this.router.navigate(["/global-dashboard/payouts"], {
										state: {
											payGroup: paygroupResult,
											payCycleId: payCycleResult.id,
											payCycleName: payCycleResult.name,
											milestones: milestonesResult,
											milestone: milestonesResult.filter(
												milestone => milestone.group === group
											)[0]
										}
									});
								} else {
									this.router.navigate([`/global-dashboard/tpp-payments`], {
										state: {
											payGroup: paygroupResult,
											payCycle: payCycleResult,
											milestones: milestonesResult,
											milestone: milestonesResult.filter(
												milestone => milestone.group === group
											)[0]
										}
									});
								}
							}
							break;
						case "paymentOrders":
							{
								if (group === "NETS") {
									this.router.navigate(["/global-dashboard/file-history"], {
										state: {
											payGroup: paygroupResult,
											payCycleId: payCycleResult.id,
											payrollCycleId: payCycleResult.payrollCycleId,
											payCycleExternalId: payCycleResult.externalId,
											payCycle: payCycleResult,
											milestone: milestonesResult.filter(
												milestone => milestone.group === group
											)[0]
										}
									});
								} else {
									this.router.navigate([`/global-dashboard/tpp-data-input`], {
										state: {
											payGroup: paygroupResult,
											payCycle: payCycleResult,
											milestones: milestonesResult,
											milestone: milestonesResult.filter(
												milestone => milestone.group === group
											)[0]
										}
									});
								}
							}
							break;
					}
				}, 1000);
			});
	}

	routeFromGlobalDashboardToAnotherPage(paygroupId: string, routeTo: string): void {
		this.paygroupsService
			.getCustomerEntityGroupSelectByPaygroupId(paygroupId)
			.pipe(take(1))
			.subscribe({
				next: entityGroupSelect => {
					this.store.dispatch(
						new UpdateCustomerEntityGroupAction({
							customerName: entityGroupSelect.customerName,
							customerId: entityGroupSelect.customerId,
							legalEntityId: "",
							payGroupId: ""
						})
					);

					setTimeout(() => {
						this.store.dispatch(new UpdateCustomerEntityGroupAction(entityGroupSelect));
						this.router.navigate([routeTo]);
					}, 1000);
				}
			});
	}

	/**
	 * Update Dashboard state when user redirect from Global Dashboard
	 * @param paygroupId: Customer paygroup ID
	 * @param payCycleId: Customer paycycle ID
	 * @param milestoneGroup: Customer event milestone group
	 */
	navigateToDashboard(paygroupId: string[], payCycleId: string, milestoneGroup: string): void {
		this.expandMilestone = milestoneGroup;
		this.paygroupsService
			.getCustomerEntityGroupSelectByPaygroupId(paygroupId[0])
			.pipe(take(1))
			.subscribe({
				next: entityGroupSelect => {
					this.store.dispatch(
						new UpdateCustomerEntityGroupAction({
							customerName: entityGroupSelect.customerName,
							customerId: entityGroupSelect.customerId,
							legalEntityId: entityGroupSelect.legalEntityId,
							payGroupId: entityGroupSelect.payGroupId
						})
					);
				}
			});

		this.payCycleService
			.getPayCyclesByPaygroupIds(paygroupId)
			.pipe(take(1))
			.subscribe({
				next: payCycleSelect => {
					const selectedPaycycle: PayCycle = payCycleSelect.items.find(
						payCycle => payCycle.id === payCycleId
					)!;

					this.store.dispatch(
						new UpdateSelectedCalendarMonth({
							selectedMonth: {
								month: new Date(selectedPaycycle.start).getMonth(),
								paygroupId: paygroupId[0],
								payCycle: selectedPaycycle
							}
						})
					);
				}
			});

		this.router.navigate(["/global-dashboard/dashboard"]);
	}
}
