import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { MilestoneGroupsService } from "@modules/payment-calendar/_services/milestone-groups.service";
import { PayoutsService } from "@shared/services/payouts/payouts.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { Milestone, MilestoneGroup, PayCycle } from "@shared/models/pay-cycle.interface";
import { PayGroup } from "@shared/models/pay-groups";
import { GlobalDashboardEventsService } from "@modules/global-dashboard/services/global-dashboard-events.service";

@Component({
	selector: "app-event-details-container",
	templateUrl: "./event-details-container.component.html",
	styleUrls: ["./event-details-container.component.scss"]
})
export class EventDetailsContainerComponent implements OnInit, OnDestroy {
	@Input() milestones: Milestone[] = [];
	@Input() group!: MilestoneGroup;
	@Input() payCycleSelected!: PayCycle;
	@Input() payGroup!: PayGroup;

	@Input() set expandGroupWithName(groupName: string | undefined) {
		if (groupName == undefined) return;

		var groupIsNotAlreadyExpanded = !this.isMilestoneExpanded;
		if (groupName === this.group.group && groupIsNotAlreadyExpanded) {
			this.toggleMilestoneExpansion();
		}
	}

	@Input() triggerResetPayoutsAsObservable!: Observable<void>;

	@Output() onMilestoneGroupSelected: EventEmitter<MilestoneGroup> = new EventEmitter<MilestoneGroup>();
	@Output() onUpdateMilestone: EventEmitter<Milestone[]> = new EventEmitter<Milestone[]>();
	@Output() onUpdatePayCycle: EventEmitter<PayCycle> = new EventEmitter<PayCycle>();
	@Output() onCard: EventEmitter<Milestone> = new EventEmitter<Milestone>();

	isMilestoneExpanded: boolean = false;
	canResetRun: boolean = false;
	tooltip: string = "This action is disabled because some payments have been processed";

	destroy$: Subject<void> = new Subject<void>();
	canReset$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); //behavour Subject
	closeSubscription!: Subscription;
	resetResultSubscription!: Subscription;
	tooltipText!: string;

	@ViewChild("contentDiv", { static: false }) contentDiv!: ElementRef;

	constructor(
		private milestoneGroupService: MilestoneGroupsService,
		private payoutService: PayoutsService,
		private permissions: PermissionsService,
		private router: Router,
		private globalDashboardEventsService: GlobalDashboardEventsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canResetRuns()
			.pipe(takeUntil(this.destroy$))
			.subscribe(canResetRun => (this.canResetRun = canResetRun));

		this.closeSubscription = this.milestoneGroupService.close.subscribe(() => {
			(this.isMilestoneExpanded = false), (this.contentDiv.nativeElement.style.overflow = "hidden");
		});

		this.resetResultSubscription = this.milestoneGroupService.onResetMilestonesResult.subscribe(
			isMilestonesReset => {
				this.updatePayCycleStatusForCurrentGroup();
			}
		);

		if (this.payCycleSelected && this.group) {
			this.updatePayCycleStatusForCurrentGroup();
		}

		if (this.globalDashboardEventsService.expandMilestone && this.group) {
			let groupIsNotExpanded = !this.isMilestoneExpanded;
			if (this.globalDashboardEventsService.expandMilestone === this.group.group && groupIsNotExpanded) {
				this.onMilestoneGroupSelected.emit(this.group);
				this.isMilestoneExpanded = true;
				this.globalDashboardEventsService.expandMilestone = "";
				setTimeout(() => {
					this.contentDiv.nativeElement.style.overflow = "visible";
				}, 250);
			}
		}
	}

	ngOnDestroy(): void {
		this.closeSubscription.unsubscribe();
		this.resetResultSubscription.unsubscribe();

		this.destroy$.next();
		this.destroy$.complete();
	}

	toggleMilestoneExpansion() {
		if (!this.isMilestoneExpanded) {
			this.milestoneGroupService.close.emit();

			this.onMilestoneGroupSelected.emit(this.group);
			this.isMilestoneExpanded = true;

			// Delay setting overflow to "visible" by 0.25 seconds
			setTimeout(() => {
				this.contentDiv.nativeElement.style.overflow = "visible";
			}, 250);
		} else {
			this.onMilestoneGroupSelected.emit();
			this.isMilestoneExpanded = false;
			this.contentDiv.nativeElement.style.overflow = "hidden";
		}
	}

	canResetPayCycle(): boolean {
		return this.canReset$.value;
	}

	resetMilestones(event: any) {
		event.stopPropagation();
		if (this.canResetPayCycle()) {
			this.milestoneGroupService.onResetMilestones.emit(this.group.group);
		}
	}

	goToCustomerPage() {
		this.router.navigate(["/calendar/milestone-list"]);
	}

	private updatePayCycleStatusForCurrentGroup() {
		this.payoutService
			.getPayCycleStatusByIdAndGroup(this.payCycleSelected!.id, this.group.group)
			.pipe(
				map(status => {
					if (status.totalCount === 0) {
						this.canReset$.next(false);
						this.tooltipText = "This action is disabled because no payments have been created";
					} else {
						if (
							status.countByStatus.CREATED &&
							status.countByStatus.FAILED_TO_BOOK &&
							status.countByStatus.CREATED + status.countByStatus.FAILED_TO_BOOK === status.totalCount &&
							(status.countByStatus.CREATED > 0 || status.countByStatus.FAILED_TO_BOOK > 0)
						) {
							this.canReset$.next(true);
						} else if (
							status.countByStatus.CREATED &&
							status.countByStatus.CREATED === status.totalCount &&
							status.countByStatus.CREATED > 0
						) {
							this.canReset$.next(true);
						} else if (
							status.countByStatus.FAILED_TO_BOOK &&
							status.countByStatus.FAILED_TO_BOOK === status.totalCount &&
							status.countByStatus.FAILED_TO_BOOK > 0
						) {
							this.canReset$.next(true);
						} else {
							this.canReset$.next(false);
						}

						if (this.canReset$.value === false) {
							this.tooltipText = "This action is disabled because some payments have been processed";
						}
					}
				})
			)
			.subscribe();
	}
}
