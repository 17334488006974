import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { BankAccountsService } from "@shared/services/bank-accounts/bank-accounts.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";

@Component({
	selector: "bank-account-fields",
	templateUrl: "./bank-account-fields.component.html",
	styleUrls: ["./bank-account-fields.component.scss"]
})
export class BankAccountFieldsComponent implements OnInit, OnChanges, OnDestroy {
	@Input() country: string | SelectOption | undefined;
	@Input() bankName: string | undefined;

	private _values: Record<string, string | number | boolean> | undefined;

	@Input()
	set values(newValues: Record<string, string | number | boolean> | undefined) {
		this._values = newValues;
	}

	get values(): Record<string, string | number | boolean> | undefined {
		return this._values;
	}

	@Input() editing: boolean = false;

	@Input() canEdit: boolean = false;

	@Input() fromTPPService: boolean = false;

	@Output() isTppServiceBankFieldsUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

	public bankAccountFields: Array<any> = [];
	public bankAccountForm!: FormGroup;

	bankFormInitialsCount = 0;
	bankFormInitialValue = {};

	private destroy$: Subject<void> = new Subject();

	constructor(private bankAccountsService: BankAccountsService, private formBuilder: FormBuilder) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.initializeForm();
	}

	ngOnInit(): void {
		this.initializeForm();
		this.initializeSubscriptions();
	}

	initializeForm() {
		this.bankAccountFields = [];
		this.bankAccountForm = this.formBuilder.group({
			bankName: [{ value: this.bankName || null, disabled: !this.canEdit }, Validators.required]
		});

		this.bankAccountForm.valueChanges.subscribe(() => {
			this.bankAccountsService.formUpdated$.emit(this.bankAccountForm.pristine);
			this.bankAccountsService.formUpdatedValidity$.emit(this.bankAccountForm.valid);
			this.isTppBankFieldsUpdated();
		});

		this.bankAccountForm
			.get("bankName")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => this.bankAccountsService.bankNameChanged$.emit(value));

		if (this.country) {
			const countryCode: string = typeof this.country === "string" ? this.country : this.country.value;
			this.bankAccountsService
				.getBankAccountFieldsByCountryCode(countryCode)
				.pipe(take(1))
				.subscribe(fieldsData => {
					fieldsData.fields.forEach((field: any) => {
						// Format fields
						field.label =
							field.rules.REQUIRED && !field.unique
								? field.name.toUpperCase() + " *"
								: field.name.toUpperCase();
						field.type = this.getFieldType(field);

						// Set up form control

						let formControlToBeAdded: FormControl = new FormControl();

						if (this.fromTPPService && field.name.toUpperCase() === "RECIPIENT REFERENCE") {
							const value: string = "Pre Populated";

							formControlToBeAdded = new FormControl(
								{ value, disabled: true },
								this.getFieldValidators(field)
							);
						} else {
							const value = this.values ? this.values[field.key] : null;
							formControlToBeAdded = new FormControl(
								{ value, disabled: (this.editing && field.unique) || !this.canEdit },
								this.getFieldValidators(field)
							);
						}

						this.bankAccountForm.addControl(field.key, formControlToBeAdded);
					});

					this.bankAccountFields = fieldsData.fields;
				});
		}
		// if (!this.canEdit) {
		// 	this.bankAccountForm.disable();
		// }
	}

	initializeSubscriptions(): void {
		this.bankAccountsService.countryChanged$.pipe(takeUntil(this.destroy$)).subscribe(value => {
			this.country = value;
			this.initializeForm();
		});
	}

	getFieldValidators(field: any): ValidatorFn[] {
		let validators: ValidatorFn[] = [];
		if (field.rules.REQUIRED) {
			validators.push(Validators.required);
		}
		if (field.rules?.MIN) {
			validators.push(Validators.minLength(field.rules.MIN));
		}
		if (field.rules?.MAX) {
			validators.push(Validators.maxLength(field.rules.MAX));
		}
		if (field.rules?.EXACT_LENGTH) {
			validators.push(Validators.minLength(field.rules.EXACT_LENGTH));
			validators.push(Validators.maxLength(field.rules.EXACT_LENGTH));
		}
		return validators;
	}

	getFieldType(field: any): string {
		switch (field.type) {
			case "STRING":
				return "text";
			case "INT":
				return "number";
			case "DOUBLE":
				return "number";
			case "DATE":
				return "string";
			case "DATETIME":
				return "string";
			default:
				return "text";
		}
	}

	getBankAccount(): { bankName: string; fields: { key: string; value: string }[] } {
		let bankAccount: { bankName: string; fields: { key: string; value: string }[] } = {
			bankName: this.bankAccountForm.controls.bankName?.value,
			fields: []
		};

		Object.keys(this.bankAccountForm.controls).forEach(field => {
			if (
				field !== "bankName" &&
				this.bankAccountForm.controls[field].value !== null &&
				this.bankAccountForm.controls[field].value !== undefined &&
				this.bankAccountForm.controls[field].value !== ""
			) {
				bankAccount.fields.push({
					key: field,
					value: this.bankAccountForm.controls[field].value
				});
			}
		});

		return bankAccount;
	}

	/**
	 * Checked if user has changed in TPP Bank fields
	 */
	isTppBankFieldsUpdated(): void {
		this.bankFormInitialsCount += 1;

		if (this.fromTPPService && this.bankAccountFields.length > 0) {
			if (this.bankFormInitialsCount <= this.bankAccountFields.length * 2) {
				this.bankFormInitialValue = this.bankAccountForm.value;
			} else {
				this.isTppServiceBankFieldsUpdated.emit(
					JSON.stringify(this.bankAccountForm.value) === JSON.stringify(this.bankFormInitialValue)
				);
			}
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
