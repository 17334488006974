import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { PaginationOptions } from "@shared/constants/pagination";
import {
	ResetPagination,
	SetCustomerPagination,
	SetEmployeePagination,
	SetLegalEntityPagination,
	SetPaygroupPagination,
	SetGlobalDashboardPagination
} from "@store/actions/pagination.action";
import { PaginationData } from "@store/models/pagination.model";
import { AppState } from "@store/models/state.model";

@Injectable({
	providedIn: "root"
})
export class PaginationService {
	constructor(private store: Store<AppState>) {}

	updatePaginationSate(type: PaginationOptions, pagination: PaginationData): void {
		switch (type) {
			case PaginationOptions.CUSTOMERS:
				this.store.dispatch(new SetCustomerPagination(pagination));
				break;
			case PaginationOptions.LEGAL_ENTITIES:
				this.store.dispatch(new SetLegalEntityPagination(pagination));
				break;
			case PaginationOptions.PAYGROUPS:
				this.store.dispatch(new SetPaygroupPagination(pagination));
				break;
			case PaginationOptions.EMPLOYEES:
				this.store.dispatch(new SetEmployeePagination(pagination));
				break;
			case PaginationOptions.GLOBAL_DASHBOARD:
				this.store.dispatch(new SetGlobalDashboardPagination(pagination));
			// Add cases for other options as needed
		}
	}

	resetPaginationState(): void {
		const paginationSelection: PaginationData = {
			pageSize: 0,
			pageIndex: 0
		};

		this.store.dispatch(new ResetPagination(paginationSelection));
	}
}
