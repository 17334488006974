<div>
	<div class="content-header" fxLayout="row" fxLayoutAlign="space-between center">
		<button (click)="refreshFiles()" id="refreshButton" class="refresh-button" mat-icon-button>
			<app-icon
				id="refresh-icon"
				[name]="'rotate-right'"
				[color]="'var(--color-disabled)'"
				[lineWidth]="'2px'"
				[size]="'28'"
			></app-icon>
		</button>

		<div class="button-container">
			<ng-template
				[appUserRolePermissions]
				[action]="'show'"
				[roles]="['SUPER_ADMIN', 'TS_OPERATION_CONSULTANT', 'TS_OPERATION_LEAD']"
			>
				<button id="integrationBtn" class="integration-btn" mat-button (click)="runPayrollIntegration()">
					<app-icon
						class="integrate-icon"
						[name]="'receipt-add'"
						[color]="'var(--color-filter-button)'"
						[lineWidth]="'1.5px'"
					></app-icon>

					<span class="integration-label-color">PAYROLL INTEGRATION</span>
				</button>
			</ng-template>

			<ng-template
				[appUserRolePermissions]
				[action]="'show'"
				[roles]="[
					'SUPER_ADMIN',
					'TS_IMPLEMENTATION_CONSULTANT',
					'TS_OPERATION_CONSULTANT',
					'TS_OPERATION_LEAD'
				]"
			>
				<button id="uploadBtnG2n" class="upload-btn-g2n" mat-raised-button (click)="fileClick()">
					<span class="uploadText">
						<app-icon
							id="add-g2n-file-history"
							[name]="'document-upload'"
							[color]="'var(--color-white)'"
							[lineWidth]="'2px'"
						></app-icon>
						<span class="text-spacing">UPLOAD G2N FILE</span>
					</span>
				</button>
			</ng-template>
		</div>
	</div>
	<div class="table-header-g2n">
		<div id="date" class="table-header-item">DATE</div>
		<div id="name" class="table-header-item">FILE NAME</div>
		<div id="type" class="table-header-item">FILE TYPE</div>
		<div id="status" class="table-header-item">STATUS</div>
		<div id="status" class="table-header-item">E. HANDSHAKE</div>
		<div id="detail" class="table-header-item"></div>
		<div id="download" class="table-header-item"></div>
	</div>

	<ng-container class="table-body" *ngIf="history$ | async as files; else loading">
		<div *ngIf="!files.length">
			<no-items-layout message="No files have been uploaded"></no-items-layout>
		</div>
		<div
			[ngClass]="{
				'active-list-item': file.status === 'FAILED'
			}"
			class="data-row row-item-section"
			*ngFor="let file of files; let i = index"
		>
			<div class="table-body" [ngClass]="{ setOpacity: i > lastResetIndex }" *ngIf="file.taskId">
				<div class="table-row-item" id="dateCell">
					{{ file.createdAt | date : "medium" }}
				</div>

				<div
					[matTooltip]="isXlsxFile(file.fileName!) ? file.fileName! : '-'"
					class="table-row-item"
					id="nameCell"
				>
					{{ isXlsxFile(file.fileName!) ? file.fileName : "-" }}
				</div>

				<div [matTooltip]="file.fileType!" class="table-row-item" id="type-cell">
					{{ isXlsxFile(file.fileName!) ? "G2N" : "Payroll Integration" }}
				</div>
				<div
					class="table-row-item"
					id="statusCell"
					[matTooltip]="file.status!"
					[ngClass]="{
						'status-green': file.status === 'SUCCESS',
						'status-red': file.status === 'FAILED' || file.status === 'ERROR',
						'status-pending': file.status === 'PENDING'
					}"
				>
					{{ file.status! | fileStatus }}
				</div>
				<div
					*ngIf="
						file?.attributes &&
						file.attributes!['NET_HANDSHAKE'] &&
						Object.keys(file.attributes!['NET_HANDSHAKE']).length
					"
					class="table-row-item"
				>
					<app-icon
						*ngIf="
							file.attributes!['NET_HANDSHAKE'].status === 'COMPLETED' &&
							file.attributes!['NET_HANDSHAKE'].allMatch
						"
						[name]="'tick-circle'"
						[color]="'#287d3c'"
						id="tickCircle"
					></app-icon>
					<app-icon
						*ngIf="
							file.attributes!['NET_HANDSHAKE'].status === 'ERROR' ||
							!file.attributes!['NET_HANDSHAKE'].allMatch
						"
						[name]="'close-circle'"
						[color]="'#DA1414'"
						id="closeCircle"
					></app-icon>
				</div>
				<div id="detailCell" class="table-row-item">
					<app-icon
						*ngIf="
							((file.status === 'FAILED' || file.status === 'ERROR') && file.taskId) ||
							(file.attributes!['NET_HANDSHAKE'] &&
								Object.keys(file.attributes!['NET_HANDSHAKE']).length &&
								file.attributes!['NET_HANDSHAKE'].status === 'ERROR') ||
							file.attributes!['NET_HANDSHAKE']?.results?.length
						"
						[name]="'eye'"
						id="eyeIcon"
						[matTooltip]="'Detail'"
						(click)="viewErrors(file)"
					></app-icon>
				</div>
				<div id="downloadCell" class="table-row-item">
					<app-icon
						*ngIf="file.status !== 'PENDING' && file?.fileId"
						[name]="'document-download'"
						id="downloadIcon"
						[matTooltip]="'Download'"
						class="material-icons-outlined"
						(click)="downloadFile(file.fileId!, file.fileName!)"
					></app-icon>
				</div>
			</div>
			<div class="data-row run-reset" fxLayoutAlign="center center" *ngIf="file.userReset">
				<span class="run-reset-text" fxFlexAlign="center start"
					>RUN HAS BEEN RESET LAST {{ file.createdAt | date : "MMMM" | uppercase }}
					{{ file.createdAt | date : "d" }}
					<div id="positionUserReset" class="position">{{ file.createdAt | date : "d" | datePosition }}</div>
					&nbsp;BY {{ file.userReset | userFullName | async | uppercase }}</span
				>
			</div>

			<div class="data-row run-reset" fxLayoutAlign="center center" *ngIf="file.userCancelled">
				<span class="run-reset-text" fxFlexAlign="center start"
					>RUN HAS BEEN CANCELLED LAST {{ file.createdAt | date : "MMMM" | uppercase }}
					{{ file.createdAt | date : "d" }}
					<div id="positionUserCancel" class="position">{{ file.createdAt | date : "d" | datePosition }}</div>
					&nbsp;BY {{ file.userCancelled | userFullName | async | uppercase }}</span
				>
			</div>
		</div>
	</ng-container>

	<ng-template #loading>
		<div class="pulsate loader">Fetching files...</div>
	</ng-template>
</div>
