import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ServiceProviderSetupPage } from "./service-provider-setup.page";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule as MatButtonModule } from "@angular/material/button";
import { ExpansionPanelModule } from "@shared/components/expansion-panel/expansion-panel.module";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { MatStepperModule } from "@angular/material/stepper";
import { ServiceProviderSetupRoutingModule } from "./service-provider-setup-routing.module";
import { MatTooltipModule as MatTooltipModule } from "@angular/material/tooltip";
import { MatBadgeModule } from "@angular/material/badge";
import { ServiceProviderCreationComponent } from "./_components/service-provider-creation/service-provider-creation.component";
import { SettlementAccountFieldsModule } from "@shared/components/settlement-account-fields/settlement-account-fields.module";
import { ServiceProviderListComponent } from "./_components/service-provider-list/service-provider-list.component";
import { MatListModule as MatListModule } from "@angular/material/list";
import { AddProviderCountryComponent } from "./_components/add-provider-country/add-provider-country.component";
import { AvailableProviderCountriesComponent } from "./_components/available-provider-countries/available-provider-countries.component";
import { AvailableCountryCardComponent } from "./_components/available-country-card/available-country-card.component";
import { MatCardModule } from "@angular/material/card";
import { ServiceProviderEditComponent } from "./_components/service-provider-edit/service-provider-edit.component";
import { AddCountryComponent } from "./_pages/add-country/add-country.component";
import { ServiceProviderCapabilitiesComponent } from "./_components/service-provider-capabilities/service-provider-capabilities.component";
import { CapabilitiesFormComponent } from "./_components/service-provider-capabilities/capabilities-form/capabilities-form.component";
import { ServiceProviderCostsComponent } from "./_components/service-provider-costs/service-provider-costs.component";
import { CostFormComponent } from "./_components/service-provider-costs/cost-form/cost-form.component";
import { EditCountryComponent } from "./_pages/edit-country/edit-country.component";
import { MatTabsModule as MatTabsModule } from "@angular/material/tabs";
import { ServiceProvidersCapabilitiesEditComponent } from "./_components/service-providers-capabilities-edit/service-providers-capabilities-edit.component";
import { CapabiltiesEditFormComponent } from "./_components/service-providers-capabilities-edit/capabilties-edit-form/capabilties-edit-form.component";
import { ServiceProvidersCostsEditComponent } from "./_components/service-providers-costs-edit/service-providers-costs-edit.component";
import { CostEditFormComponent } from "./_components/service-providers-costs-edit/cost-edit-form/cost-edit-form.component";
import { MatInputModule as MatInputModule } from "@angular/material/input";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { HeadcountConfigurationEditComponent } from "./_components/service-providers-costs-edit/headcount-configuration-edit/headcount-configuration-edit.component";
import { HeadcountConfigurationComponent } from "./_components/service-provider-costs/headcount-configuration/headcount-configuration.component";
import { CostsNotesDialogComponent } from "./_components/costs-notes-dialog/costs-notes-dialog.component";
import { FormsModule } from "@angular/forms";
import { WarningDialogComponent } from "./_components/warning-dialog/warning-dialog.component";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { MatPaginatorModule as MatPaginatorModule } from "@angular/material/paginator";
import { ContactsModule } from "@shared/components/contacts/contacts.module";
import { SharedModule } from "@shared/shared.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";

@NgModule({
	declarations: [
		ServiceProviderSetupPage,
		ServiceProviderCreationComponent,
		ServiceProviderListComponent,
		AddProviderCountryComponent,
		AvailableProviderCountriesComponent,
		AvailableCountryCardComponent,
		ServiceProviderEditComponent,
		AddCountryComponent,
		ServiceProviderCapabilitiesComponent,
		CapabilitiesFormComponent,
		ServiceProviderCostsComponent,
		CostFormComponent,
		HeadcountConfigurationComponent,
		EditCountryComponent,
		ServiceProvidersCapabilitiesEditComponent,
		CapabiltiesEditFormComponent,
		ServiceProvidersCostsEditComponent,
		CostEditFormComponent,
		HeadcountConfigurationEditComponent,
		CostsNotesDialogComponent,
		WarningDialogComponent
	],
	imports: [
		CommonModule,
		ServiceProviderSetupRoutingModule,
		FormsSharedModule,
		MatIconModule,
		MatButtonModule,
		ExpansionPanelModule,
		MatTooltipModule,
		MatListModule,
		SettlementAccountFieldsModule,
		MatCardModule,
		MatStepperModule,
		MatTabsModule,
		MatBadgeModule,
		MatInputModule,
		FlexLayoutModule,
		FormsModule,
		NoItemsLayoutModule,
		MatPaginatorModule,
		ContactsModule,
		SharedModule,
		ModalWrapperModule,
		BreadcrumbsModule
	]
})
export class ServiceProviderSetupPageModule {}
