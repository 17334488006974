import { Location } from "@angular/common";
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";

import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { MenuService } from "@modules/config/_services/menu/menu.service";
import { HistoryLogType } from "@shared/components/history-log-side-panel/models/history-log-interface";
import { HistoryLogSidePanelService } from "@shared/components/history-log-side-panel/services/history-log-side-panel.service";
import { ModalLauncherService } from "@shared/components/wpay-ui/modal/modal-launcher.service";
import {
	cancelBookingDialog,
	providerMessage
} from "@shared/components/wpay-ui/modal/templates/cancel-booking/model/cancel-booking.interface";
import { Milestone } from "@shared/models/pay-cycle.interface";
import { PayGroup } from "@shared/models/pay-groups";
import { MilestoneReport, Payout, PayoutPagination, PayoutStatusName, Transaction } from "@shared/models/payouts.types";
import { SelectOption } from "@shared/models/select-option.interface";
import { PayoutsService } from "@shared/services/payouts/payouts.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { SetStatusAction } from "@store/actions/statusNetPayment.action";
import { getGlobalDashboardFilterState, selectStatusNetPayment } from "@store/index";
import { AppState } from "@store/models/state.model";
import { PaymentsApiService } from "../../services/payments-api.service";
import { TppPaymentsApiService } from "../../services/tpp-payments-api.service";
import { cancelReport } from "../../tpp-payments/models/tpp-payment.interface";

@Component({
	selector: "app-payouts",
	templateUrl: "./payouts.component.html",
	styleUrls: ["./payouts.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class PayoutsComponent implements OnInit, OnDestroy, AfterViewInit {
	payoutsPagination!: PayoutPagination;
	payGroup!: PayGroup;
	payCycleId = "";
	reProcessIds: string[] = [];
	bookingButtonState = "inactive";
	payCycleMilestones!: Milestone[];
	payouts!: Payout[];

	//Transactions
	milestone!: Milestone;
	g2nMilestoneId = "";

	/**Pagination*/
	pageSize = 100;
	pageNumber = 0;

	milestoneReport!: MilestoneReport;
	runSelection!: SelectOption[];
	isCancelledRun = false;
	cancelReport: cancelReport[] = [];
	statusFilter: string[] = [];
	reProcesMapPayoutsAndTxId: Map<string, string[]> = new Map();

	//Top card info
	selectedKPI: string = "";
	payCycleName = "";
	updating = true;
	title = "Net payment / ";
	subtitle = "";
	milestoneGroup = "NETS";
	flag = "GP";

	//Cancelation variables
	runName = "";
	cancelDate!: Date;
	cancellerName = "";
	reason = "";
	cancelId = "reset";

	showHistoryLogPanel = false;
	HistoryLogType = HistoryLogType;

	setFiltersToFixed = false;
	emitScroll: number = 0;
	allFailedTransaction: Transaction[] = [];
	allTransactionsAreSelected = false;
	showSelectOptions = false;

	//Private

	private destroy$: Subject<void> = new Subject<void>();
	private dataSource: BehaviorSubject<Payout[]> = new BehaviorSubject<Payout[]>([]);

	payouts$: Observable<Payout[]> = this.dataSource.asObservable();

	@ViewChild("payoutContainer") payoutContainer!: ElementRef;
	@ViewChild("options") options!: MatSelect;

	constructor(
		private payoutsService: PayoutsService,
		private paymentApiService: PaymentsApiService,
		private router: Router,
		private tppPaymentsApiService: TppPaymentsApiService,
		private modalLauncherService: ModalLauncherService,
		private store: Store<AppState>,
		private menuService: MenuService,
		private historyLogService: HistoryLogSidePanelService,
		private toast: ToastService,
		private readonly _location: Location
	) {}

	ngOnInit(): void {
		//if dashboard route
		if (this.router.url === "/global-dashboard/payouts") this.getSelectedKPI();
		else this.setHistory();
		this.menuService.setVisible(false); //hide sidenav

		this.historyLogService.transactionHistoryLogSubject.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				if (res.payoutId !== "") {
					this.showHistoryLogPanel = true;
				} else {
					this.showHistoryLogPanel = false;
				}
			}
		});
	}

	ngAfterViewInit() {
		this.attachScrollListener();
	}

	attachScrollListener(): void {
		const scrollContainer = this.payoutContainer?.nativeElement;
		if (scrollContainer) {
			scrollContainer.addEventListener("scroll", this.onScroll.bind(this));
		}
	}

	onScroll(event: Event): void {
		const scrollContainer = event.target as HTMLElement;
		this.emitScroll = scrollContainer.scrollTop;
	}

	setHistory(): void {
		if (
			history?.state?.payCycleId &&
			history?.state?.payGroup &&
			history?.state?.milestone &&
			this.hasG2NMilestone(history?.state?.milestones)
		) {
			this.payGroup = history.state.payGroup;
			this.payCycleName = history.state.payCycleName;
			this.payCycleId = history.state.payCycleId;
			this.payCycleMilestones = history.state.milestones;
			this.milestone = history.state.milestone;

			this.getMilestoneReport(this.milestone?.id ? this.milestone.id : "");

			if (this.payGroup?.data && this.payGroup?.legalEntity?.data && this.milestone?.group) {
				this.setupTopCardInfo();
			}

			this.processed();
			this.setupSubscriptions();
		} else {
			this._location.historyGo(-1);
			this.toast.showError("Something went wrong while trying to load the page. Please try again later.");
		}
	}

	hasG2NMilestone(milestones: Milestone[]): boolean {
		let g2nMilestone = milestones?.find((milestone: Milestone) => milestone.type === "G2N_FILE");

		if (g2nMilestone?.id) {
			this.g2nMilestoneId = g2nMilestone?.id;
			return true;
		} else {
			return false;
		}
	}

	setupTopCardInfo() {
		this.title = this.title + this.payGroup.data.name;
		this.subtitle =
			this.payGroup.legalEntity.data.name + " - " + this.payCycleName + " - Group " + this.milestone.group;
		this.flag = this.payGroup.legalEntity.data.country;
		this.milestoneGroup = this.milestone.group;
	}

	getSelectedKPI(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe(state => {
			if (state?.globalDashboard?.kpiSelected !== undefined) {
				this.selectedKPI = state.globalDashboard.kpiSelected;
				if (state.globalDashboard.kpiSelected === "Failed Transactions") {
					this.title = "Failed transactions / ";
					this.store.dispatch(
						new SetStatusAction({
							statusPayment: ["FAILED,ATTEMPTS_LIMIT_EXCEEDED", "FAILED_TO_BOOK", "PROCESSING,PENDING"]
						})
					);
				}
				this.setHistory();
			} else {
				this.router.navigate(["/global-dashboard"]);
			}
		});
	}

	setupSubscriptions(): void {
		this.store.pipe(takeUntil(this.destroy$), select(selectStatusNetPayment)).subscribe(({ statusPayment }) => {
			this.statusFilter = statusPayment ? statusPayment : [];
			this.getPayouts();
		});

		this.payoutsService
			.reprocessIdsObservable()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: Map<string, string[]>) => {
				if (res.size > 0) {
					this.bookingButtonState = "reprocess";
					this.allTransactionsAreSelected = this.allFailedTransaction.length === res.size;
				} else {
					this.allTransactionsAreSelected = false;
					this.bookingButtonState = this.payoutsService.getBookingState(this.payouts);
				}
			});

		this.tppPaymentsApiService.reloadPayoutAfterCancelling.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.payoutsService.reloadPayoutData.emit();
		});

		this.payoutsService.reloadPayoutData.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.processed();
			this.updating = true;
			setTimeout(() => {
				this.resetCardInfo();
			}, 6000);
		});
	}

	getMilestoneReport(milestoneId: string): void {
		this.updating = true;
		if (milestoneId) {
			this.tppPaymentsApiService
				.getMilestoneReport(milestoneId)
				.pipe(takeUntil(this.destroy$))
				.subscribe(res => {
					this.milestoneReport = res;
					this.updating = false;
				});
		} else {
			this.milestoneReport = this.tppPaymentsApiService.DEFAULT_MILESTONE_VALUES;
			this.updating = false;
		}
	}

	resetCardInfo(): void {
		this.getMilestoneReport(this.milestone?.id ? this.milestone.id : "");
	}

	getPayouts(selectFailedTransactions = false) {
		this.payoutsService
			.getPayoutsWithPagination(
				this.payGroup.id,
				this.payCycleId,
				this.statusFilter,
				this.pageSize.toString(),
				this.pageNumber.toString()
			)
			.pipe(
				take(1),
				takeUntil(this.destroy$),
				map(response => {
					this.payoutsPagination = response;
					let payouts: Payout[] = response.items;
					this.payouts = payouts;

					this.allFailedTransaction = [];

					if (payouts.length > 0) {
						payouts.forEach(payout => {
							payout.transactions = this.paymentApiService.filterTransactions(payout.transactions!);
							payout.status.name = this.payoutsService.getPayoutStatus(payout, payout.transactions);

							if (payout.status.name !== PayoutStatusName.INACTIVE) {
								payout.transactions.forEach(transaction => {
									if (this.paymentApiService.canReprocess(transaction.status.name))
										this.allFailedTransaction.push(transaction);
								});
							}
						});

						if (selectFailedTransactions) {
							this.payoutsService.selectAllReprocessIds(this.allFailedTransaction);
							this.bookingButtonState = "reprocess";
						} else {
							this.payoutsService.clearReprocessIds();
							this.bookingButtonState = this.payoutsService.getBookingState(payouts);
						}
					} else {
						this.bookingButtonState = "inactive";
					}

					this.dataSource.next(response.items);
				})
			)
			.subscribe();
	}

	getCancelledPayouts(cancelledId: string) {
		this.tppPaymentsApiService
			.getCancelledNetPayoutsWithPagination(cancelledId, this.pageSize, this.pageNumber)
			.pipe(
				takeUntil(this.destroy$),
				map(response => {
					this.payoutsPagination = response;
					this.bookingButtonState = "inactive";
					this.dataSource.next(response.items);
				})
			)
			.subscribe();
	}

	setupCancelBanner(cancelId: string) {
		let currentReport = this.cancelReport.find(report => report.id === cancelId)!;
		this.runName = this.runSelection.find(run => run.value === cancelId)!.text;
		this.cancelDate = currentReport.canceledAt;
		this.cancellerName = currentReport.canceledBy;
		this.reason = currentReport.reason;
	}

	processed(): void {
		this.reProcessIds = [];
		this.getCancelReport(this.payGroup?.id, this.g2nMilestoneId);
	}

	refresh(): void {
		if (this.cancelId !== "reset") {
			this.getCancelledPayouts(this.cancelId);
		} else {
			this.resetCardInfo();
			this.getPayouts();
		}
	}

	changeRun(cancelId: string) {
		this.cancelId = cancelId;

		if (cancelId !== "reset") {
			this.getCancelledPayouts(cancelId);
			this.setupCancelBanner(cancelId);
			this.isCancelledRun = true;
		} else {
			this.getPayouts();
			this.isCancelledRun = false;
		}
	}

	getCancelReport(paygroupId: string, g2nMilestoneId: string) {
		this.runSelection = [
			{
				text: "Current Run",
				value: "reset"
			}
		];
		this.tppPaymentsApiService
			.getCancelReport(paygroupId, g2nMilestoneId, 1000, 0)
			.pipe(take(1))
			.subscribe(cancelledRuns => {
				if (cancelledRuns.items.length) {
					cancelledRuns.items.sort(
						(a, b) => new Date(a.canceledAt).getTime() - new Date(b.canceledAt).getTime()
					);

					this.cancelReport = cancelledRuns.items;

					let i: number = 0;
					cancelledRuns.items.forEach(cancelledRun => {
						i++;

						this.runSelection.push({
							text: "Cancelled run " + i,
							value: cancelledRun.id
						});
					});
				}
			});
	}

	selectAllFailedTransactions(event: MatSelectChange): void {
		if (event.value === "visible") {
			this.payoutsService.selectAllReprocessIds(this.allFailedTransaction);
			this.allTransactionsAreSelected = true;
		} else if (event.value === "all") {
			if (this.pageSize >= this.payoutsPagination.totalCount) {
				this.payoutsService.selectAllReprocessIds(this.allFailedTransaction);
			} else {
				this.pageSize = this.payoutsPagination.totalCount;
				this.getPayouts(true);
			}
			this.allTransactionsAreSelected = true;
		}
	}

	clearSelection(event: MatCheckboxChange, manualClear = false): void {
		this.options.value = null;
		this.allTransactionsAreSelected = false;
		if (!event.checked || manualClear) {
			this.payoutsService.clearReprocessIds();
		} else {
			this.openSelect();
		}
	}

	cancelBooking() {
		let providerMessages: providerMessage[] = [
			{
				provider: "Either Transfermate, Checkout or Ebury",
				message: "After canceling this batch, it is necessary to contact your Service Provider"
			}
		];

		let cancelBooking: cancelBookingDialog = {
			milestoneId: this.g2nMilestoneId,
			providerMessages: providerMessages
		};

		this.modalLauncherService.showCancelBookingModal(cancelBooking);
	}

	paginate(pagination: PageEvent) {
		this.pageSize = pagination.pageSize;
		this.pageNumber = pagination.pageIndex;
		this.clearSelection({} as MatCheckboxChange, true);
		this.refresh();
	}

	openSelect() {
		this.showSelectOptions = true;
		if (this.options) this.options.open();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
