import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import { NG_VALUE_ACCESSOR, FormControl, SelectControlValueAccessor, Form, Validators } from "@angular/forms";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { Subject } from "rxjs";
import { InputSelectService } from "@shared/services/input-select.service";
import { takeUntil } from "rxjs/operators";
import { MatSelect } from "@angular/material/select";

@Component({
	selector: "input-select",
	templateUrl: "./input-select.component.html",
	styleUrls: ["./input-select.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputSelectComponent),
			multi: true
		}
	]
})
export class InputSelectComponent extends SelectControlValueAccessor implements OnInit, OnChanges {
	// Inputs
	@Input() options: SelectOption[] | null = [];

	@Input() set _options(values: SelectOption[] | null) {
		this.options = values;
	}

	@Input() width: string = "100%";

	disabled: boolean = false;

	// Optional Inputs
	@Input() set _disabled(disable: boolean) {
		this.disabled = disable;
		if (disable) {
			this.control.disable();
		} else {
			this.control.enable();
		}
	}
	@Input() label?: string;
	@Input() multiple: boolean = false;
	@Input() labelDisappears?: boolean = true;
	@Input() required?: boolean;
	@Input() disableOptionCentering: boolean = false;
	@Input() resetOption: boolean = false;
	@Input() icon: string = "";
	@Input() placeholder: string = "";
	@Input() value: string | undefined;

	@Input() set dynamicValue(value: string | undefined) {
		if (value) {
			this.control.setValue(value);
		}
	}

	// Outputs
	@Output() onSelectionChange: EventEmitter<string> = new EventEmitter<string>();

	control: FormControl = new FormControl();
	optionsObject: { [key: string]: { imagePath: string; text: string } } = {};

	private destroy$ = new Subject<void>();

	onChange: any = () => {};
	onTouched: any = () => {};

	@ViewChild("mySelect") mySelect!: MatSelect;

	constructor(
		private render: Renderer2,
		private elementRef: ElementRef,
		private inputSelectService: InputSelectService
	) {
		super(render, elementRef);
	}

	set compareWith(fn: (o1: any, o2: any) => boolean) {
		throw new Error("Method not implemented.");
	}

	protected setProperty(key: string, value: any): void {
		throw new Error("Method not implemented.");
	}

	ngOnInit(): void {
		this.createCustomOptionsObject();

		this.inputSelectService.clearSelection.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.control.setValue("");
		});
		if (this.value) {
			this.control.setValue(this.value);
		}

		if (this.required) {
			this.control.setValidators([Validators.required]);
			this.control.updateValueAndValidity();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.options && !changes.options.firstChange) {
			this.control.setValue(this.value);
		}
	}

	checkToResetOption(select: MatSelect) {
		select.value = "";
		this.control.setValue(null);
	}

	writeValue(value: any): void {
		this.control.setValue(value);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private createCustomOptionsObject() {
		if (this.options) {
			for (const option of this.options) {
				if (option.imagePath) {
					this.optionsObject[option.value] = {
						imagePath: option.imagePath,
						text: option.text
					};
				}
			}
		}
	}
}
