import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { GlobalDashboardEventsService } from "@modules/global-dashboard/services/global-dashboard-events.service";
import { getTableExpandAndCollapseAnimation } from "src/app/shared/utils/mat-table-animation.util";
import {
	FailedTransactionsTableDetailItem,
	PayGroupDetailsWithTransactionsCount
} from "../../../models/global-dashboard-interface";

@Component({
	selector: "app-failed-transactions-table",
	templateUrl: "./failed-transactions-table.component.html",
	styleUrls: ["./failed-transactions-table.component.scss"],
	animations: [getTableExpandAndCollapseAnimation]
})
export class FailedTransactionsTableComponent {
	@Input() set failedTransactions(failedTransactions: FailedTransactionsTableDetailItem[]) {
		this.dataSourceFailedTransactions.data = failedTransactions;
		this.customerColumns = ["Customers", "Paygroups", "Paycycles", "Payments", "Transactions", "expand"];
		this.paygroupsColumns = ["first-padding", "Paygroups", "Paycycles", "Group", "Transactions", "second-padding"];
	}

	dataSourceFailedTransactions: MatTableDataSource<FailedTransactionsTableDetailItem> = new MatTableDataSource();
	customerColumns: string[] = [];
	paygroupsColumns: string[] = [];
	expandedElement!: FailedTransactionsTableDetailItem | null;

	constructor(private cdr: ChangeDetectorRef, private globalDashboardEventsService: GlobalDashboardEventsService) {}

	toggleRow(element: FailedTransactionsTableDetailItem): void {
		element.payGroups && element.payGroups.length ? this.toggleElement(element) : null;
		this.cdr.detectChanges();
	}

	expandedState(row: FailedTransactionsTableDetailItem): string {
		return row &&
			row.customerDetails &&
			this.expandedElement &&
			this.expandedElement.customerDetails.id === row.customerDetails.id
			? "expanded"
			: "collapsed";
	}

	isExpanded(row: FailedTransactionsTableDetailItem): boolean {
		return row &&
			row.customerDetails &&
			this.expandedElement &&
			this.expandedElement.customerDetails.id === row.customerDetails.id
			? true
			: false;
	}

	toggleElement(row: FailedTransactionsTableDetailItem): void {
		row &&
		row.customerDetails &&
		this.expandedElement &&
		this.expandedElement.customerDetails.id === row.customerDetails.id
			? (this.expandedElement = null)
			: (this.expandedElement = row);
	}

	routeToFailedTransations(row: PayGroupDetailsWithTransactionsCount) {
		this.globalDashboardEventsService.routeToSpecifiedScreen(
			"payments",
			row.payGroupDetails.id,
			row.payCycleDetails.id,
			row.milestoneDetails.group!
		);
	}
}
