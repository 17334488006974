import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ServiceDefinitionPage } from "./service-definition.page";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { ExpansionPanelModule } from "@shared/components/expansion-panel/expansion-panel.module";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { ServiceDefinitionRoutingModule } from "./service-definition-routing.module";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { EntitySelectComponent } from "./_components/entity-select/entity-select.component";
import { MatStepperModule } from "@angular/material/stepper";
import { TypeRoutesComponent } from "./_components/service-definition-entry/type-routes/type-routes.component";
import { CustomerCostsComponent } from "./_components/customer-costs/customer-costs.component";
import { SettlementAccountsComponent } from "./_components/service-definition-entry/settlement-accounts/settlement-accounts.component";
import { SourceAccountsComponent } from "./_components/service-definition-entry/source-accounts/source-accounts.component";
import { MatCardModule } from "@angular/material/card";
import { PipesModule } from "@shared/pipes/pipes.module";
import { BankAccountFieldsModule } from "@shared/components/bank-account-fields/bank-account-fields.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { AccessFieldsModule } from "@modules/service-definition/_components/access-fields/access-fields.module";
import { MatRadioModule } from "@angular/material/radio";
import { PaginationModule } from "@shared/components/pagination/pagination.module";
import { ServiceDefinitionEntryComponent } from "./_components/service-definition-entry/service-definition-entry.component";
import { CustomerListModule } from "@shared/components/customer-list/customer-list.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SharedModule } from "@shared/shared.module";
import { PaymentTypePipe } from "./pipes/payment-type.pipe";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { CustomerNamePipe } from "./pipes/customer-name.pipe";
import { TppServiceSelectionComponent } from "./_components/tpp-service-selection/tpp-service-selection.component";
import { TppServiceConfigurationComponent } from "./_components/tpp-service-configuration/tpp-service-configuration.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TooltipModule } from "@shared/components/tooltip/tooltip.module";
import { SubServiceItemComponent } from "./_components/tpp-service-configuration/sub-service-item/sub-service-item.component";
import { TppaccountSelectOptionsPipe } from "./pipes/tppaccount-select-options.pipe";
import { MatDividerModule } from "@angular/material/divider";
import { SubServiceItemFrequencyComponent } from "./_components/tpp-service-configuration/sub-service-item-frequency/sub-service-item-frequency.component";
import { MatMenuModule } from "@angular/material/menu";
import { TppServiceSettlementAccountComponent } from "./_components/tpp-service-group-config/tpp-service-settlement-account/tpp-service-settlement-account.component";
import { TppServiceSourceFundComponent } from "./_components/tpp-service-group-config/tpp-service-source-fund/tpp-service-source-fund.component";
import { TppServiceGroupConfigComponent } from "./_components/tpp-service-group-config/tpp-service-group-config.component";
import { AddSourceOfFundComponent } from "./_components/tpp-service-group-config/add-source-of-fund/add-source-of-fund.component";
import { InputSelectTppGroupComponent } from "./_components/input-select-tpp-group/input-select-tpp-group.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AddSettlementAccountComponent } from "./_components/tpp-service-group-config/add-settlement-account/add-settlement-account.component";
import { TppServiceConfigEditBeneficiaryComponent } from "./_components/tpp-service-configuration/tpp-service-config-edit-beneficiary/tpp-service-config-edit-beneficiary.component";
import { TppServiceAdhocServicesComponent } from "./_components/tpp-service-adhoc-services/tpp-service-adhoc-services.component";
import { TppCatalogueModule } from "../tpp-catalogue/tpp-catalogue.module";
import { TppSelectPaygroupComponent } from "./_components/tpp-select-paygroup/tpp-select-paygroup.component";
import { BeneficaryStatusByProviderSummaryComponent } from "./_components/tpp-service-selection/beneficary-status-by-provider-summary/beneficary-status-by-provider-summary.component";
import { TtpServiceListComponent } from "./_components/tpp-service-selection/ttp-service-list/ttp-service-list.component";

import { TtpGroupBadgeComponent } from "./_components/ttp-group-badge/ttp-group-badge.component";
import { WpayModalModule } from "@shared/components/wpay-ui/modal/wpay-modal.module";
import { WpayTextTruncateModule } from "@shared/components/wpay-ui/wpay-text-truncate/wpay-text-truncate.module";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { LegalEntityItemModule } from "@shared/components/wpay-ui/legal-entity-item/legal-entity-item.module";
import { PaygroupSelectComponent } from "./_components/paygroup-select/paygroup-select.component";
import { WpayAutoCompleteModule } from "@shared/components/wpay-ui/base-components/wpay-auto-complete/wpay-auto-complete.module";

@NgModule({
	declarations: [
		ServiceDefinitionPage,
		EntitySelectComponent,
		TypeRoutesComponent,
		CustomerCostsComponent,
		SettlementAccountsComponent,
		SourceAccountsComponent,
		ServiceDefinitionEntryComponent,
		PaymentTypePipe,
		CustomerNamePipe,
		TppServiceSelectionComponent,
		TppServiceConfigurationComponent,
		SubServiceItemComponent,
		TppaccountSelectOptionsPipe,
		SubServiceItemFrequencyComponent,
		TppServiceSettlementAccountComponent,
		TppServiceSourceFundComponent,
		TppServiceGroupConfigComponent,
		AddSourceOfFundComponent,
		InputSelectTppGroupComponent,
		AddSettlementAccountComponent,
		TppServiceConfigEditBeneficiaryComponent,
		TppServiceAdhocServicesComponent,
		TppSelectPaygroupComponent,
		BeneficaryStatusByProviderSummaryComponent,
		TtpServiceListComponent,
		TtpGroupBadgeComponent,
		PaygroupSelectComponent
	],
	imports: [
		CommonModule,
		FlexLayoutModule,
		ServiceDefinitionRoutingModule,
		FormsSharedModule,
		MatIconModule,
		MatButtonModule,
		ExpansionPanelModule,
		MatIconModule,
		MatCheckboxModule,
		MatStepperModule,
		MatCardModule,
		PipesModule,
		BankAccountFieldsModule,
		AccessFieldsModule,
		ModalWrapperModule,
		MatRadioModule,
		PaginationModule,
		CustomerListModule,
		MatPaginatorModule,
		MatSlideToggleModule,
		SharedModule,
		MatTooltipModule,
		TooltipModule,
		MatDividerModule,
		MatMenuModule,
		MatAutocompleteModule,
		MatFormFieldModule,
		MatInputModule,
		SharedModule,
		TppCatalogueModule,
		WpayModalModule,
		WpayTextTruncateModule,
		MatProgressBarModule,
		NoItemsLayoutModule,
		BreadcrumbsModule,
		LegalEntityItemModule,
		WpayAutoCompleteModule,
		PaginationModule
	]
})
export class ServiceDefinitionModule {}
