import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { select, Store } from "@ngrx/store";

import { environment } from "@environments/environment";
import { EmployeeCardAndAccountCounts } from "@shared/models/counts";
import {
	PayGroup,
	PayGroupCreateDTO,
	PayGroupPagination,
	PayGroupUpdateDTO,
	updateEmployeePayGroupDTO
} from "../../../shared/models/pay-groups";
import { SelectOption } from "@shared/models/select-option.interface";
import { formatDataForSelect } from "@shared/utils/format-select-data.util";
import { Employee } from "src/app/shared/models/employee.interface";
import { AppState } from "@store/models/state.model";
import { getUserDataState } from "@store/index";
import { UserRole } from "@shared/constants/roles";

@Injectable({
	providedIn: "root"
})
export class ApiService {
	private sortField = "data.externalId";
	private sortDir = "DESC";
	private isClientRole = false;

	constructor(private http: HttpClient, private store: Store<AppState>) {
		this.store.pipe(select(getUserDataState)).subscribe({
			next: res => {
				if (res.roles?.includes(UserRole.CLIENT)) {
					this.isClientRole = true;
				}
			}
		});
	}

	getPayGroupsByLegalEntityId(legalEntityId: string, status: string, customerId: string): Observable<PayGroup[]> {
		return this.http
			.get<PayGroupPagination>(
				`${environment.apiUri}/v1/pay-groups?legalEntityIds=${legalEntityId}&customerIds=${customerId}&statuses=${status}&sortField=${this.sortField}&sortDir=${this.sortDir}`
			)
			.pipe(
				map((res: PayGroupPagination) => {
					return res.items;
				})
			);
	}

	getFirstFiftyPayGroupsByLegalEntityId(
		legalEntityId: string,
		status: string,
		customerId: string
	): Observable<PayGroup[]> {
		return this.http
			.get<PayGroupPagination>(
				`${environment.apiUri}/v1/pay-groups?legalEntityIds=${legalEntityId}&customerIds=${customerId}&statuses=${status}&pageSize=50&sortField=${this.sortField}&sortDir=${this.sortDir}`
			)
			.pipe(
				map((res: PayGroupPagination) => {
					return res.items;
				})
			);
	}

	getPayGroupsByLegalEntityIds(legalEntityIds: string[], status: string, customerId: string): Observable<PayGroup[]> {
		return this.http
			.get<PayGroupPagination>(
				`${environment.apiUri}/v1/pay-groups?legalEntityIds=${legalEntityIds}&customerIds=${customerId}&statuses=${status}&sortField=${this.sortField}&sortDir=${this.sortDir}`
			)
			.pipe(
				//we don't need the pagination data so only return the items array from the object
				map((res: PayGroupPagination) => {
					return res.items;
				})
			);
	}

	getPayGroupsByLegalEntityIdOnly(legalEntityId: string): Observable<PayGroup[]> {
		let params = new HttpParams();
		params = params.append("legalEntityIds", legalEntityId);
		params = params.append("sortField", this.sortField);
		params = params.append("sortDir", this.sortDir);
		params = params.append("pageSize", -1);

		if (this.isClientRole) {
			params = params.append("statuses", "LIVE");
		}

		return this.http.get<PayGroupPagination>(`${environment.apiUri}/v1/pay-groups`, { params }).pipe(
			//we don't need the pagination data so only return the items array from the object
			map((res: PayGroupPagination) => {
				return res.items;
			})
		);
	}

	getPayGroupsByLegalEntityIdwithPagination(
		legalEntityId: string,
		pageSize: number,
		pageNumber: number
	): Observable<PayGroupPagination> {
		return this.http.get<PayGroupPagination>(
			`${environment.apiUri}/v1/pay-groups?legalEntityIds=${legalEntityId}&pageSize=${pageSize}&pageNumber=${pageNumber}&sortField=${this.sortField}&sortDir=${this.sortDir}`
		);
	}

	getPayGroupsByCustomerId(customerId: string): Observable<PayGroup[]> {
		return this.http
			.get<PayGroupPagination>(
				`${environment.apiUri}/v1/pay-groups?customerIds=${customerId}&sortField=${this.sortField}&sortDir=${this.sortDir}`
			)
			.pipe(
				//we don't need the pagination data so only return the items array from the object
				map((res: PayGroupPagination) => {
					return res.items;
				})
			);
	}

	getAllPayGroups(pageSize: number, pageNumber: number): Observable<PayGroupPagination> {
		return this.http.get<PayGroupPagination>(
			`${environment.apiUri}/v1/pay-groups?&pageSize=${pageSize}&pageNumber=${pageNumber}&sortField=${this.sortField}&sortDir=${this.sortDir}`
		);
	}

	getPayGroup(payGroupId: string): Observable<PayGroup> {
		return this.http.get<PayGroup>(`${environment.apiUri}/v1/pay-groups/${payGroupId}`);
	}

	getPayGroups(
		customerId: string,
		legalEntityId: string,
		status: string,
		goLiveFrom: string,
		goLiveTo: string,
		pageSize: number,
		pageNumber: number,
		queryString: string = ""
	): Observable<PayGroupPagination> {
		return this.http.get<PayGroupPagination>(
			`${environment.apiUri}/v1/pay-groups?legalEntityIds=${legalEntityId}&customerIds=${customerId}&statuses=${status}&goLiveFrom=${goLiveFrom}&goLiveTo=${goLiveTo}&pageSize=${pageSize}&pageNumber=${pageNumber}&sortField=${this.sortField}&sortDir=${this.sortDir}&queryString=${queryString}`
		);
	}

	getPayGroupsByLegalEntityIdPagination(
		legalEntityId: string,
		status: string,
		pageSize: number,
		pageNumber: number
	): Observable<PayGroupPagination> {
		return this.http.get<PayGroupPagination>(
			`${environment.apiUri}/v1/pay-groups?legalEntityIds=${legalEntityId}&statuses=${status}&pageSize=${pageSize}&pageNumber=${pageNumber}&sortField=${this.sortField}&sortDir=${this.sortDir}`
		);
	}

	getPayGroupsByLegalEntityIdsPagination(
		legalEntityIds: string[],
		status: string,
		pageSize: number,
		pageNumber: number
	): Observable<PayGroupPagination> {
		return this.http.get<PayGroupPagination>(
			`${environment.apiUri}/v1/pay-groups?legalEntityIds=${legalEntityIds}&statuses=${status}&pageSize=${pageSize}&pageNumber=${pageNumber}&sortField=${this.sortField}&sortDir=${this.sortDir}`
		);
	}

	//**
	// * @deprecated this function doesn't belong in a rest client service, TODO: extract to feature module / adapter.
	//*
	getPayGroupsByLegalEntityIdAsSelectOptions(
		legalEntityIds: string[],
		status: string,
		customerId: string
	): Observable<SelectOption[]> {
		return this.getPayGroupsByLegalEntityIds(legalEntityIds, status, customerId).pipe(
			map((paygroups: PayGroup[]) => {
				return paygroups.map(paygroup => {
					const option: SelectOption = {
						value: paygroup.id!,
						text: `${paygroup.externalId} - ${paygroup.data.name}`
					};

					return formatDataForSelect(option);
				});
			})
		);
	}

	getEmployeeCountWithNoBandOrCard(payGroupId: string): Observable<EmployeeCardAndAccountCounts> {
		return this.http.get<EmployeeCardAndAccountCounts>(
			`${environment.apiUri}/v1/pay-groups/${payGroupId}/pay-methods/counts`
		);
	}

	getEmployee(employeeId: string): Observable<Employee> {
		return this.http.get<Employee>(`${environment.apiUri}/v1/employees/${employeeId}`);
	}

	updateEmployeePayGroup(updateEmployeePayGroupDTO: updateEmployeePayGroupDTO): Observable<Employee> {
		return this.http.patch<Employee>(`${environment.apiUri}/v1/employees/pay-groups`, updateEmployeePayGroupDTO);
	}

	createPayGroup(payGroupCreateData: PayGroupCreateDTO): Observable<PayGroup> {
		return this.http.post<PayGroup>(`${environment.apiUri}/v1/pay-groups`, payGroupCreateData);
	}

	updatePayGroup(payGroupCreateData: PayGroupUpdateDTO): Observable<PayGroup> {
		return this.http.put<PayGroup>(`${environment.apiUri}/v1/pay-groups`, payGroupCreateData);
	}

	deletePayGroup(payGroupId: string): Observable<PayGroup> {
		return this.http.delete<PayGroup>(`${environment.apiUri}/v1/pay-groups/${payGroupId}`);
	}
}
