<app-breadcrumbs
	[headerTitle]="'USER MANAGEMENT'"
	[buttonText]="'ADD NEW USER'"
	(onHeaderButtonAction)="addNewUser()"
	[buttonIconObj]="breadCrumbIcon"
/>

<div class="main-container">
	<div [formGroup]="searchForm">
		<mat-form-field appearance="outline">
			<app-icon [name]="'search-normal-1'" size="17" matPrefix></app-icon>
			<input id="search" matInput placeholder="Enter your search" formControlName="search" />
		</mat-form-field>
	</div>

	<div *ngIf="rolesList && statusOption" class="section flex flex-row flex-align-center-start">
		<div class="flex flex-row flex-align-center-start flex-1" style="--max-width: 65%">
			<div class="title">User List</div>
			<div class="role-select" [formGroup]="selectForm">
				<input-wrapper class="input-wrapper">
					<input-select
						id="roles"
						formControlName="select"
						[label]="'Roles'"
						[options]="rolesList | roles"
						[resetOption]="true"
					>
					</input-select>
				</input-wrapper>
			</div>
			<div [formGroup]="statusForm">
				<input-wrapper class="input-wrapper">
					<input-select
						id="status"
						formControlName="status"
						[label]="'Status'"
						[options]="statusOption"
						[resetOption]="true"
					>
					</input-select>
				</input-wrapper>
			</div>
		</div>
		<div class="flex flex-row flex-align-stretch-end flex-1" style="--max-width: 35%">
			<button class="download-users-report" mat-raised-button (click)="downloadReport()">
				<span class="uploadText">
					<app-icon
						[name]="'document-download'"
						id="downloadIcon"
						[color]="'var(--color-white)'"
						[lineWidth]="'2px'"
					></app-icon>
					<span class="text-spacing">USERS REPORT</span>
				</span>
			</button>
		</div>
	</div>

	<ng-container *ngIf="rolesList && statusOption && (userPagination$ | async) as userPagination">
		<app-user-management-table
			[dataSource]="userPagination.items"
			(editUserSelectedEvent)="editUser($event)"
			(changeStatusEvent)="toggleUser($event)"
			(showUserLogInfo)="showUserLogInfo($event)"
		></app-user-management-table>
		<mat-paginator
			id="paginator"
			[length]="userPagination.totalCount"
			[pageSizeOptions]="[5, 10, 25, 100]"
			[pageSize]="pageSize"
			[pageIndex]="userPagination.pageNumber"
			(page)="paginate($event)"
			aria-label="Select page"
		>
		</mat-paginator>
	</ng-container>

	<app-user-log-list (closeLog)="closeLog()" *ngIf="showLog" [user]="userLog"></app-user-log-list>
</div>
