import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { GlobalDashboardEventsService } from "@modules/global-dashboard/services/global-dashboard-events.service";
import { getTableExpandAndCollapseAnimation } from "src/app/shared/utils/mat-table-animation.util";
import {
	MissingBankAccountsTableDetailItem,
	PayGroupDetailsWithMissingBankAccountsCount
} from "../../../models/global-dashboard-interface";
@Component({
	selector: "app-missing-bank-accounts-table",
	templateUrl: "./missing-bank-accounts-table.component.html",
	styleUrls: ["./missing-bank-accounts-table.component.scss"],
	animations: [getTableExpandAndCollapseAnimation]
})
export class MissingBankAccountsTableComponent {
	@Input() set missingBankAccounts(missingBankAccounts: MissingBankAccountsTableDetailItem[]) {
		this.dataSourceMissingBankAccounts.data = missingBankAccounts;
		this.customerColumns = ["Customers", "Paygroups", "Missing Bank Accounts", "expand"];
		this.paygroupsColumns = ["first-padding", "Paygroups", "Missing Bank Accounts", "second-padding"];
	}

	dataSourceMissingBankAccounts: MatTableDataSource<MissingBankAccountsTableDetailItem> = new MatTableDataSource();
	customerColumns: string[] = [];
	paygroupsColumns: string[] = [];
	expandedElement!: MissingBankAccountsTableDetailItem | null;

	constructor(private cdr: ChangeDetectorRef, private globalDashboardEventsService: GlobalDashboardEventsService) {}

	toggleRow(element: MissingBankAccountsTableDetailItem): void {
		element.payGroups && element.payGroups.length ? this.toggleElement(element) : null;
		this.cdr.detectChanges();
	}

	expandedState(row: MissingBankAccountsTableDetailItem): string {
		return row &&
			row.customerDetails &&
			this.expandedElement &&
			this.expandedElement.customerDetails.id === row.customerDetails.id
			? "expanded"
			: "collapsed";
	}

	isExpanded(row: MissingBankAccountsTableDetailItem): boolean {
		return row &&
			row.customerDetails &&
			this.expandedElement &&
			this.expandedElement.customerDetails.id === row.customerDetails.id
			? true
			: false;
	}

	toggleElement(row: MissingBankAccountsTableDetailItem): void {
		row &&
		row.customerDetails &&
		this.expandedElement &&
		this.expandedElement.customerDetails.id === row.customerDetails.id
			? (this.expandedElement = null)
			: (this.expandedElement = row);
	}

	routeToEmployeeData(row: PayGroupDetailsWithMissingBankAccountsCount) {
		this.globalDashboardEventsService.routeFromGlobalDashboardToAnotherPage(
			row.payGroupDetails.id,
			"/global-dashboard/master-data"
		);
	}
}
