import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";

import { TabStateService } from "@shared/services/tab-state/tab-state.service";
import { IDBreadCrumb } from "@shared/models/breadcrumbs.interface";
import { BreadcrumbService } from "./breadcrumb.service";
import { Menu } from "@shared/models/menu.interface";
import { IDashboardFiltersFormGroup } from "@modules/global-dashboard/models/global-dashboard-interface";

@Injectable({
	providedIn: "root"
})
export class BreadcrumbsMenuRoutingService {
	constructor(
		private router: Router,
		private tabStateService: TabStateService,
		private breadCrumbService: BreadcrumbService,
		private formBuilder: FormBuilder
	) {}

	sideMenu$ = new Subject<Menu[]>();

	menu!: Menu[];

	setMenu(menu: Menu[]): void {
		this.menu = menu;
	}

	getMenu(): Menu[] {
		return this.menu;
	}

	getIndices(storedIdentifier: IDBreadCrumb[]) {
		const nameIndex = storedIdentifier.findIndex(idObj => idObj.key === "customerName");
		const groupIndex = storedIdentifier.findIndex(idObj => idObj.key === "payGroupId");

		return { nameIndex, groupIndex };
	}

	updateSpawnForKey(key: string, storedIdentifier: IDBreadCrumb[], nameIndex: number, groupIndex: number) {
		const keyMapping: { [key: string]: string[] } = {
			Dashboard: ["/payments/paygroups", "/payments/dashboard"],
			"Employee Data": ["/employee-data/paygroups", "/employee-data/master-data"],
			"Nets Service Definition": ["/net", "/net/entity-select"],
			"TPP Service Definition": [
				"/service-definition/tpp/select-paygroup",
				"/service-definition/tpp/select-service"
			],
			Clients: ["/customers"],
			"Legal Entities": ["/legal-entities"],
			"Pay Groups": ["/pay-groups"],
			Calendar: ["/calendar/paygroup-list", "/calendar/milestone-list"],
			"User Management": ["/user-management"],
			Reporting: ["/reporting"],
			"Service Provider": ["/service-provider-setup"],
			"Penny Test": ["/penny-test"],
			"TPP Catalogue": ["/tpp-catalogue"],
			"Bank Fields": ["/bank-fields"],
			"Error Management": ["/error-management"],
			"CPNow Service Definition": ["/cp-now"]
		};

		if (keyMapping.hasOwnProperty(key)) {
			storedIdentifier[nameIndex].spawn = keyMapping[key][0];
			if (storedIdentifier.length > 1) {
				if (storedIdentifier[groupIndex]) {
					storedIdentifier[groupIndex].spawn = keyMapping[key][1];
				} else {
					const legalIndex = storedIdentifier.findIndex(idObj => idObj.key === "legalEntityId");
					if (legalIndex) {
						this.breadCrumbService.updateCustomerEntityGroupState();
						storedIdentifier.splice(legalIndex, 1);
					}
				}
			}
		}

		return storedIdentifier;
	}

	navigateToRoute(route: string): void {
		this.router.navigate([route]);
	}

	navigateToMainMenuRoute(key: string, route: string) {
		let storedIdentifier = this.tabStateService.getBreadcrumbIdentifier();
		const { nameIndex, groupIndex } = this.getIndices(storedIdentifier);

		this.checkForPayGroupExistance();
		if (storedIdentifier.length) {
			storedIdentifier = this.updateSpawnForKey(key, storedIdentifier, nameIndex, groupIndex);

			if (!storedIdentifier[groupIndex]) {
				this.tabStateService.setBreadcrumbIdentifier(storedIdentifier);
				this.router.navigate([storedIdentifier[nameIndex].spawn]);
				return;
			} else if (storedIdentifier[groupIndex]) {
				this.tabStateService.setBreadcrumbIdentifier(storedIdentifier);
				this.router.navigate([storedIdentifier[groupIndex].spawn]);
			}
		} else {
			this.tabStateService.setBreadcrumbIdentifier(storedIdentifier);
			this.router.navigate([route]);
		}
	}

	checkForPayGroupExistance(): void {
		if (this.tabStateService.getCustomerEntityGroupSelect().payGroupId !== "") {
			this.breadCrumbService
				.findLegalEntityByPayGroupIdObservable(this.tabStateService.getCustomerEntityGroupSelect().payGroupId)
				.pipe(take(1))
				.subscribe({
					next: res => {
						this.breadCrumbService.updateCustomerEntityGroupStateLegalEntity(res.legalEntityId);
					}
				});
		}
	}

	initGlobalDashboardRouting(): void {
		const entityGroupSelect = this.tabStateService.getCustomerEntityGroupSelect();
		const globalDashboardStateSelect = this.tabStateService.getGlobalDashboardStateSelect();
		const kpiSelected = globalDashboardStateSelect.globalDashboard.kpiSelected;

		if (entityGroupSelect.payGroupId !== "" && (kpiSelected === "Customers" || kpiSelected === "Paygroups")) {
			// Paygroup exists
			this.router.navigate(["/global-dashboard/dashboard"]);
		} else if (
			entityGroupSelect.payGroupId === "" &&
			entityGroupSelect.legalEntityId !== "" &&
			(kpiSelected === "Customers" || kpiSelected === "Paygroups")
		) {
			// Legal entity exists, no paygroup
			// Convert legal entity to a paygroup
			this.breadCrumbService.convertLegalToPayGroup();
			setTimeout(() => {
				this.router.navigate(["/global-dashboard/dashboard"]);
			}, 1000);
		} else {
			this.router.navigate(["/global-dashboard"]);
		}
	}

	/**
	 * Use to redirect user to dashboard page
	 */
	navigateToDashboard(): void {
		const filters: FormGroup = this.formBuilder.group({
			customers: new FormControl([]),
			legalEntities: new FormControl([]),
			paygroups: new FormControl([]),
			statuses: new FormControl([]),
			groups: new FormControl([]),
			deadline: new FormControl(""),
			milestoneStartDate: new FormControl(""),
			milestoneTypes: new FormControl([])
		}) as IDashboardFiltersFormGroup;

		const entityGroupSelect = this.tabStateService.getCustomerEntityGroupSelect();

		filters.patchValue(this.tabStateService.getGlobalDashboardStateSelect().globalDashboard.filters);

		if (entityGroupSelect.customerId) {
			if (entityGroupSelect.payGroupId !== "") {
				this.router.navigate(["/global-dashboard/dashboard"]);
			} else {
				this.breadCrumbService.getPaygroupByCustomerId(entityGroupSelect.customerId);

				setTimeout(() => {
					this.router.navigate(["/global-dashboard/dashboard"]);
				}, 1000);
			}
		} else if (filters.get("customers")?.value[0]) {
			this.breadCrumbService.getPaygroupByCustomerId(filters.get("customers")?.value[0]);

			setTimeout(() => {
				this.router.navigate(["/global-dashboard/dashboard"]);
			}, 1000);
		} else {
			this.router.navigate(["/global-dashboard"]);
		}
	}

	navigateToBuildableRoute(key: string, route: string): void {
		if (route === "/customers") {
			this.navigateToCustomers(route);
		} else if (route === "/legal-entities") {
			this.navigateToLegals(route);
		} else if (route === "/pay-groups") {
			this.navigateToPayGroups(route);
		} else if (route === "/service-definition/net") {
			this.navigateToNets(route);
		}
	}

	navigateToCustomers(route: string): void {
		if (this.tabStateService.getCustomerEntityGroupSelect().customerId !== "") {
			this.breadCrumbService.updateCustomerEntityGroupState();
			this.router.navigate(["/customers/edit"]);
		} else {
			this.router.navigate([route]);
		}
	}

	navigateToLegals(route: string): void {
		// if you have customer - land on /legal-entities/select
		// if you have paygroup - find paygroup's legal entity and land on /legal-entities/update
		// if you have nothing - land on /legal-entities

		if (
			this.tabStateService.getCustomerEntityGroupSelect().customerId !== "" &&
			this.tabStateService.getCustomerEntityGroupSelect().payGroupId === "" &&
			this.tabStateService.getCustomerEntityGroupSelect().legalEntityId === ""
		) {
			this.router.navigate(["/legal-entities/select"]);
		} else if (
			this.tabStateService.getCustomerEntityGroupSelect().customerId !== "" &&
			this.tabStateService.getCustomerEntityGroupSelect().payGroupId === "" &&
			this.tabStateService.getCustomerEntityGroupSelect().legalEntityId !== ""
		) {
			this.router.navigate(["/legal-entities/update"]);
		} else if (this.tabStateService.getCustomerEntityGroupSelect().payGroupId !== "") {
			this.breadCrumbService
				.findLegalEntityByPayGroupIdObservable(this.tabStateService.getCustomerEntityGroupSelect().payGroupId)
				.pipe(take(1))
				.subscribe({
					next: res => {
						this.breadCrumbService.updateCustomerEntityGroupStateLegalEntityOnNavigation(res.legalEntityId);

						const storedIdentifier = this.tabStateService.getBreadcrumbIdentifier();
						const index = storedIdentifier.findIndex(res => res.key === "payGroupId");
						const customerIndex = storedIdentifier.findIndex(res => res.key === "customerName");

						storedIdentifier[customerIndex].spawn = "/legal-entities/select";
						if (index) {
							storedIdentifier.splice(index, 1);
							this.tabStateService.setBreadcrumbIdentifier(storedIdentifier);
						}

						this.router.navigate(["/legal-entities/update"]);
					}
				});
		} else {
			this.router.navigate([route]);
		}
	}

	navigateToPayGroups(route: string): void {
		if (
			this.tabStateService.getCustomerEntityGroupSelect().customerId !== "" &&
			this.tabStateService.getCustomerEntityGroupSelect().payGroupId !== ""
		) {
			const storedIdentifier = this.tabStateService.getBreadcrumbIdentifier();
			const customerIndex = storedIdentifier.findIndex(res => res.key === "customerName");
			storedIdentifier[customerIndex].spawn = "/pay-groups/list";
			this.tabStateService.setBreadcrumbIdentifier(storedIdentifier);
			this.router.navigate(["/pay-groups/group"]);
		} else if (
			this.tabStateService.getCustomerEntityGroupSelect().customerId !== "" &&
			this.tabStateService.getCustomerEntityGroupSelect().payGroupId === ""
		) {
			this.router.navigate(["/pay-groups/list"]);
		} else {
			this.router.navigate([route]);
		}
	}

	navigateToNets(route: string): void {
		// if you have customer - land on /service-definition/net/entity-select
		// if you have paygroup - find paygroup's legal entity and land on /service-definition/net/entity-select
		// if you have nothing - land on /service-definition/net

		if (
			this.tabStateService.getCustomerEntityGroupSelect().customerId !== "" &&
			this.tabStateService.getCustomerEntityGroupSelect().payGroupId === "" &&
			this.tabStateService.getCustomerEntityGroupSelect().legalEntityId === ""
		) {
			this.router.navigate(["/service-definition/net/entity-select"]);
		} else if (
			this.tabStateService.getCustomerEntityGroupSelect().customerId !== "" &&
			this.tabStateService.getCustomerEntityGroupSelect().payGroupId === "" &&
			this.tabStateService.getCustomerEntityGroupSelect().legalEntityId !== ""
		) {
			this.router.navigate(["/service-definition/net/entity-select"]);
		} else if (this.tabStateService.getCustomerEntityGroupSelect().payGroupId !== "") {
			this.breadCrumbService
				.findLegalEntityByPayGroupIdObservable(this.tabStateService.getCustomerEntityGroupSelect().payGroupId)
				.pipe(take(1))
				.subscribe({
					next: res => {
						// this.breadCrumbService.updateCustomerEntityGroupStateLegalEntity(res.legalEntityId);
						// this.router.navigate(["/service-definition/net/entity-select"]);

						this.breadCrumbService.updateCustomerEntityGroupStateLegalEntityOnNavigation(res.legalEntityId);

						const storedIdentifier = this.tabStateService.getBreadcrumbIdentifier();
						const index = storedIdentifier.findIndex(res => res.key === "payGroupId");
						const customerIndex = storedIdentifier.findIndex(res => res.key === "customerName");

						storedIdentifier[customerIndex].spawn = "/service-definition/net/entity-select";
						if (index) {
							storedIdentifier.splice(index, 1);
							this.tabStateService.setBreadcrumbIdentifier(storedIdentifier);
						}

						this.router.navigate(["/service-definition/net/entity-select"]);
					}
				});
		} else {
			this.router.navigate([route]);
		}
	}
}
