import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpContextToken, HttpInterceptor, HttpResponse } from "@angular/common/http";
import { AuthService } from "@auth0/auth0-angular";
import { HttpRequest } from "@angular/common/http";
import { HttpHandler } from "@angular/common/http";
import { HttpEvent } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { LoaderService } from "../../shared/services/loader/loader.service";

@Injectable()
export class HttpLoaderInterceptor implements HttpInterceptor {
	constructor(private loaderService: LoaderService, private readonly auth: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.loaderService.show();
		return next.handle(request).pipe(
			tap(
				(event: HttpEvent<any>) => {
					if (event instanceof HttpResponse && event.status === 401) {
						this.auth.logout({ returnTo: document.location.origin });
						this.loaderService.hide();
					}

					if (event instanceof HttpResponse) {
						this.loaderService.hide();
					}
				},
				error => {
					this.loaderService.hide();
				}
			)
		);
	}
}
