import { AbstractControl, FormGroup } from "@angular/forms";
import { MilestoneGroupName, MilestoneTypeName } from "src/app/shared/models/pay-cycle.interface";
import { Image } from "src/app/shared/models/image.interface";

//Generic

export enum LayoutType {
	EVENTS = "events",
	CUSTOMERS = "customers",
	PAYGROUPS = "paygroups",
	PAYCYCLES = "paycyles"
}

//Filter Objects

export interface CustomerFilter extends CustomerDetails {
	legalEntities: LegalEntityFilter[];
}

export interface LegalEntityFilter extends LegalEntityDetails {
	payGroups: PayGroupDetails[];
}

//Dashboard Objects

export interface CustomerDetailsWithLegalEntity extends CustomerDetails {
	legalEntityCount: number;
	payGroupCount: number;
	legalEntities?: LegalEntityDetailsWithPaygroups[];
}

export interface LegalEntityDetailsWithPaygroups extends LegalEntityDetails {
	paygroupCount: number;
	payGroups?: PayGroupDetails[];
}

export interface CustomerDetails {
	id: string;
	externalId: string;
	name: string;
	logo?: Image;
	imagePath?: string;
}

export interface LegalEntityDetails {
	id: string;
	externalId: string;
	name: string;
	country: string;
}
export interface PayGroupDetails {
	id: string;
	externalId: string;
	name: string;
	country: string;
	legalEntityId?: string;
}

export interface PayGroupDetailsWithMissingCardCount {
	payGroupDetails: PayGroupDetails;
	missingCardCount: number;
}

export interface PayGroupDetailsWithMissingBankAccountsCount {
	payGroupDetails: PayGroupDetails;
	missingBankAccountCount: number;
}

export interface PayGroupDetailsWithFailedBeneficiariesCount {
	payGroupDetails: PayGroupDetails;
	totalCount: number;
}

export interface PayGroupDetailsWithTransactionsCount {
	payGroupDetails: PayGroupDetails;
	milestoneDetails: MilestoneDetails;
	payCycleDetails: PayCycleDetails;
	transactionCount?: number;
}

export interface PayGroupDetailsWithFailedPaymentOrdersCount {
	payGroup: PayGroupDetails;
	payCycle: PayCycleDetails;
	group: MilestoneGroupName;
	failedPaymentOrdersCount?: number;
}

export interface MilestoneDetails {
	id: string;
	type: MilestoneTypeName;
	group?: MilestoneGroupName;
	status?: string;
	deadline?: Date;
	version?: number;
}
export interface PayCycleDetails {
	id: string;
	externalId: string;
	name: string;
}

//Table interfaces with pagination
export interface EventsTableDetailsWithPagination {
	totalPages: number;
	totalCount: number;
	currentPage: number;
	items: EventsTableDetailItem[];
}

export interface CustomerTableDetailWithPagination {
	totalPages: number;
	totalCount: number;
	currentPage: number;
	items: CustomerTableDetailItem[];
}

export interface PaygroupTableDetailWithPagination {
	totalPages: number;
	totalCount: number;
	currentPage: number;
	items: PaygroupTableDetailItem[];
}

export interface MissingCardTableDetailWithPagination {
	totalPages: number;
	totalCount: number;
	currentPage: number;
	items: MissingCardsTableDetailItem[];
}

export interface MissingBankAccountsTableDetailWithPagination {
	totalPages: number;
	totalCount: number;
	currentPage: number;
	items: MissingBankAccountsTableDetailItem[];
}

export interface FailedBeneficiariesTableDetailWithPagination {
	totalPages: number;
	totalCount: number;
	currentPage: number;
	items: FailedBeneficiariesTableDetailItem[];
}

export interface FailedTransactionsTableDetailWithPagination {
	totalPages: number;
	totalCount: number;
	currentPage: number;
	items: FailedTransactionsTableDetailItem[];
}

export interface FailedPaymentOrdersTableDetailWithPagination {
	totalPages: number;
	totalCount: number;
	currentPage: number;
	items: FailedPaymentOrdersTableDetailItem[];
}

//Table interfaces
export interface EventsTableDetailItem {
	customerDetails: CustomerDetails;
	payGroupDetails: PayGroupDetails;
	milestoneDetails: MilestoneDetails;
	payCycleDetails: PayCycleDetails;
	group: MilestoneGroupName;
}

export interface PaygroupTableDetailItem {
	legalEntityDetails: LegalEntityDetailsWithPaygroups;
}

export interface CustomerTableDetailItem {
	customerDetails: CustomerDetailsWithLegalEntity;
}

export interface MissingCardsTableDetailItem {
	customerDetails: CustomerDetails;
	payGroups: PayGroupDetailsWithMissingCardCount[];
	totalMissingCardCount: number;
}

export interface MissingBankAccountsTableDetailItem {
	customerDetails: CustomerDetails;
	payGroups: PayGroupDetailsWithMissingBankAccountsCount[];
	totalMissingBankAccountCount: number;
}

export interface FailedBeneficiariesTableDetailItem {
	customerDetails: CustomerDetails;
	payGroups: PayGroupDetailsWithFailedBeneficiariesCount[];
	totalCount: number;
}

export interface FailedTransactionsTableDetailItem {
	customerDetails: CustomerDetails;
	payGroups: PayGroupDetailsWithTransactionsCount[];
	totalTransactionCount: number;
	payCycleCount: number;
	paymentCount?: number;
	payGroupCount?: number;
}

export interface FailedPaymentOrdersTableDetailItem {
	customerDetails: CustomerDetails;
	payGroupsCount: number;
	payCycleCount: number;
	groupsCount: number;
	payGroups: PayGroupDetailsWithFailedPaymentOrdersCount[];
	totalfailedPaymentOrdersCount: number;
}

//Card details
export interface CardDetails {
	kpiType: KpiData;
	icon?: string;
	theme?: string;
}

export enum KpiTypes {
	banks = "Missing Bank Acc.",
	beneficiaries = "Failed Beneficiaries",
	cards = "Missing Cards",
	dataInputMilestones = "Failed Payment Orders",
	transactions = "Failed Transactions"
}

export interface KpiData {
	[key: string]: {
		count: number;
		totalCount: number;
		percentage?: number;
	};
}

//Filter Table

export interface DashboardFilters {
	customers: string[];
	legalEntities: string[];
	paygroups: string[];
	statuses: string[];
	groups: MilestoneGroupName[];
	deadline: string;
	milestoneTypes: string[];
}

export enum FilterStatusses {
	HASERRORS = "ERROR",
	PENDING = "PENDING",
	COMPLETED = "COMPLETE",
	IN_PROGRESS = "IN_PROGRESS"
}

export enum FilterMilestoneTypes {
	PAYMENTORDERS = "G2N_FILE,DATA_INPUT",
	FUNDING = "FUNDING_DATE",
	PAYMENTS = "NETS,PAYMENT"
}

export interface MilestoneTypes {
	paymentOrders: boolean;
	funding: boolean;
	payments: boolean;
}

export interface StatusTypes {
	hasErrors: boolean;
	pending: boolean;
	completed: boolean;
}

export enum DashboardMultiSelectFilters {
	CUSTOMERS = "customers",
	LEGALENTITIES = "legalEntities",
	PAYGROUPS = "paygroups",
	STATUSES = "statuses",
	GROUPS = "groups"
}

export interface IDashboardFiltersFormGroup extends FormGroup {
	value: DashboardFilters; //our customer interface

	controls: {
		customers: AbstractControl;
		legalEntities: AbstractControl;
		paygroups: AbstractControl;
		statuses: AbstractControl;
		groups: AbstractControl;
		deadline: AbstractControl;
		milestoneTypes: AbstractControl;
	};
}

export interface IStatusSelectionFormGroup extends FormGroup {
	value: StatusTypes; //our customer interface
	controls: {
		hasErrors: AbstractControl;
		pending: AbstractControl;
		completed: AbstractControl;
	};
}

export interface IMilestoneTypesSelectionFormGroup extends FormGroup {
	value: MilestoneTypes; //our customer interface
	controls: {
		paymentOrders: AbstractControl;
		funding: AbstractControl;
		payments: AbstractControl;
	};
}

export interface globalDashboardState {
	kpiSelected: string;
	filters: IDashboardFiltersFormGroup;
}

export interface MilestoneEventHistoryLogData {
	milestoneId: string;
	milestoneType: string;
	customerExternalId: string;
	customerName: string;
	payGroupId: string;
	paygroupName: string;
	cycleName: string;
	group: string;
}
