import { Injectable } from "@angular/core";
import { AppState, AuthService } from "@auth0/auth0-angular";
import { Store } from "@ngrx/store";
import { Observable, filter, map, switchMap, take, tap } from "rxjs";
import { TabStateService } from "../../shared/services/tab-state/tab-state.service";
import { environment as env } from "@environments/environment";
import { UpdateUserData, UpdateUserRole } from "@store/actions/userData.action";
import { UserService } from "../../modules/user-management/_services/user.service";

@Injectable({
	providedIn: "root"
})
export class AuthorizationService {
	private redirectUrlForForgeRockUser!: string;

	constructor(
		private authService: AuthService,
		private store: Store<AppState>,
		private tabStateService: TabStateService,
		private userService: UserService
	) {}

	login(): Observable<void> {
		//all the roles
		// "SUPER_ADMIN"
		// "TS_ADMIN"
		// "TS_IMPLEMENTATION_CONSULTANT"
		// "TS_IMPLEMENTATION_LEAD"
		// "TS_OPERATION_CONSULTANT"
		// "TS_OPERATION_LEAD"
		// "TS_MANAGER"
		// "CLIENT"
		// "TS_OCB"
		// "TS_VO"

		return this.authService.getAccessTokenSilently().pipe(
			take(1),
			switchMap(_ => this.userService.getUserDetails()),
			map(userDetail => {
				this.store.dispatch(
					new UpdateUserRole({
						userData: { roles: userDetail.roles }
					})
				);
			})
		);
	}

	watchWhenSessionExpired(): void {
		this.authService.error$
			.pipe(
				filter((e: any) => e?.error.includes("login_required") || e?.error.includes("Login required")),
				tap(() => this.logout())
			)
			.subscribe(_ => {});
	}

	isJustLoggedIn(): Observable<any> {
		return this.authService.user$.pipe(
			take(1),
			map(user => {
				if (!user) return user;

				const initials = `${user?.given_name ? user.given_name[0] : ""}${
					user?.family_name ? user.family_name[0] : ""
				}`;

				this.store.dispatch(
					new UpdateUserData({
						userData: {
							firstName: user?.given_name ? user?.given_name : "",
							lastName: user?.family_name ? user?.family_name : "",
							initials,
							email: user?.email,
							isForgeRock: env.auth.connection.length > 0
						}
					})
				);

				return user;
			})
		);
	}

	logout(): void {
		this.redirectUrlForForgeRockUser = `https://${env.typeEnvForgeRock}platform.cloudpay.net/sessionclosed?initiator=wpay`;

		this.tabStateService
			.clearSessionStorage()
			.pipe(take(1))
			.subscribe(_ => {
				if (env.auth.connection !== "") {
					this.authService.logout({ returnTo: this.redirectUrlForForgeRockUser });
				} else {
					this.authService.logout({ returnTo: `${window.location.origin}` });
				}
			});
	}
}
