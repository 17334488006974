import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";

interface Description {
	description?: string;
}

interface IDescriptionForm extends FormGroup {
	value: Description;
	controls: {
		description: AbstractControl;
	};
}

@Component({
	selector: "app-tpp-description-dialog",
	templateUrl: "./tpp-description-dialog.component.html",
	styleUrls: ["./tpp-description-dialog.component.scss"]
})
export class TppDescriptionDialogComponent implements OnInit {
	form!: FormGroup;
	btnDisabled: boolean = true;
	destroy$ = new Subject<void>();

	constructor(
		private formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<TppDescriptionDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: string
	) {}

	ngOnInit(): void {
		this.initForm();
	}

	initForm() {
		this.form = this.formBuilder.group({
			description: ["", Validators.required]
		}) as IDescriptionForm;

		if (this.data) this.form.patchValue({ description: this.data });

		this.form.valueChanges
			.pipe(takeUntil(this.destroy$), debounceTime(700), distinctUntilChanged())
			.subscribe(values => {
				if (this.form.valid) this.btnDisabled = false;
			});
	}

	enter() {
		this.dialogRef.close(this.form.value);
	}

	close() {
		this.dialogRef.close(null);
	}
}
