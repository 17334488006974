<ng-container *ngIf="filteredEmployees$ | async as filteredEmployees">
	<div [formGroup]="searchForm" class="search-section">
		<mat-form-field class="search-field" id="g2n-search-field" appearance="outline">
			<app-icon
				id="g2n-search-icon"
				[name]="'search-normal-1'"
				[size]="'25'"
				[lineWidth]="'2px'"
				[color]="'var(--color-search-icon)'"
				matPrefix
			></app-icon>
			<input id="searchEmployees" matInput placeholder="Search by..." formControlName="search" />
		</mat-form-field>
	</div>

	<div fxLayout="row" fxLayoutAlign="start center" class="history-header-container">
		<div id="employee" fxFlex="21.5">Employee N°</div>
		<div id="firstName" fxFlex="30">Full Name</div>
		<div id="description" fxFlex="45">Description</div>
	</div>
	<div class="scroll-container">
		<div *ngIf="reportData$ | async as reportData">
			<ng-container *ngIf="!reportData.employeeErrors.length; else errorTemplate">
				<no-items-layout [message]="'There is no data to display'" [minHeight]="'35vh'"></no-items-layout>
			</ng-container>
			<ng-template #errorTemplate>
				<no-items-layout
					*ngIf="!(filteredEmployees$ | async)?.length"
					[message]="'No Employees match your search'"
					[minHeight]="'35vh'"
				></no-items-layout>

				<div
					fxLayout="row"
					fxLayoutAlign="start center"
					class="history-item-container"
					[class.error]="data.validationErrors.length || !!data.executionError"
					*ngFor="let data of filteredEmployees$ | async"
				>
					<div fxLayoutAlign="center" class="data-row item-employee">
						<div id="employee" fxLayoutAlign="start center" fxFlex="21.5">
							<span *ngIf="data.employee.id !== 'EMPLOYEE_NOT_FOUND'">{{
								data.employee.externalId
							}}</span>
						</div>
						<div id="firstName" fxLayoutAlign="start center" fxFlex="30.3">
							{{ data.employee.firstName }} {{ data.employee.lastName }}
						</div>
						<div id="description" fxLayout="column" fxFlex="45">
							<div fxLayout="column" *ngFor="let error of data.validationErrors">
								<span>
									<span *ngIf="error.field">{{ error.field }}-</span>{{ error.message }}</span
								>
							</div>
							<div *ngIf="data.executionError">
								<span>{{ data.executionError.message }}</span>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</ng-container>

<!-- TPP errors -->

<ng-container *ngIf="filteredTPPErrors$ | async as filteredTPPErrors">
	<div [formGroup]="searchForm" class="search-section">
		<mat-form-field class="search-field" id="g2n-search-field" appearance="outline">
			<app-icon
				id="g2n-search-icon"
				[name]="'search-normal-1'"
				[size]="'25'"
				[lineWidth]="'2px'"
				[color]="'var(--color-search-icon)'"
				matPrefix
			></app-icon>
			<input id="searchEmployees" matInput placeholder="Search by..." formControlName="search" />
		</mat-form-field>
	</div>

	<div fxLayout="row" fxLayoutAlign="start center" class="history-header-container">
		<div id="externalId" fxFlex="19">External Id</div>
		<div id="serviceName" fxFlex="19">Service Name</div>

		<div id="subServiceName" fxFlex="21">Sub Service Name</div>

		<div id="description" fxFlex="40">Description</div>
	</div>

	<div class="scroll-container">
		<div *ngIf="reportData$ | async as reportData">
			<ng-container *ngIf="!reportData.tppErrors.length; else errorTemplate">
				<no-items-layout [message]="'There is no data to display'" [minHeight]="'35vh'"></no-items-layout>
			</ng-container>

			<ng-template #errorTemplate>
				<no-items-layout
					*ngIf="!(filteredTPPErrors$ | async)?.length"
					[message]="'No TPP errors match your search'"
					[minHeight]="'35vh'"
				></no-items-layout>

				<div
					fxLayout="column"
					fxLayoutAlign="start center"
					class="history-item-container"
					*ngFor="let data of filteredTPPErrors$ | async"
				>
					<!--Validation Errors-->
					<div
						fxLayout="row"
						*ngIf="data.validationErrors"
						fxLayoutAlign="center"
						[ngStyle]="{ width: '100%' }"
						class="data-row item-employee"
					>
						<div id="externalIdVal" fxFlex="19">
							{{ data.service.externalId }}
						</div>
						<div id="serviceNameVal" fxFlex="19">{{ data.service.serviceName }}</div>

						<div id="subServiceNameVal" fxFlex="21">{{ data.service.subServiceName }}</div>

						<div id="descriptionVal" fxLayout="column" fxFlex="40">
							<div fxLayout="column" *ngFor="let error of data.validationErrors">
								<span> {{ error.message }} - {{ error.value }} </span>
							</div>
						</div>
					</div>

					<!-- data.warning -->
					<div
						fxLayout="row"
						*ngFor="let error of data.warnings"
						fxLayoutAlign="center"
						[ngStyle]="{ width: '100%' }"
						class="data-row item-employee"
					>
						<div id="externalIdVal" fxFlex="19">
							{{ data.service.externalId }}
						</div>
						<div id="serviceNameVal" fxFlex="19">{{ data.service.serviceName }}</div>

						<div id="subServiceNameVal" fxFlex="21">{{ data.service.subServiceName }}</div>

						<div id="descriptionVal" fxLayout="column" fxFlex="40">
							<div fxLayout="column">
								<span>{{ error.code }} - {{ error.message }} </span>
							</div>
						</div>
					</div>

					<!-- data.executionError -->
					<div
						fxLayout="row"
						*ngIf="data.executionError"
						fxLayoutAlign="center"
						[ngStyle]="{ width: '100%' }"
						class="data-row item-employee"
					>
						<div id="externalIdVal" fxFlex="19">
							{{ data.service.externalId }}
						</div>
						<div id="serviceNameVal" fxFlex="19">{{ data.service.serviceName }}</div>

						<div id="subServiceNameVal" fxFlex="21">{{ data.service.subServiceName }}</div>

						<div id="descriptionVal" fxLayout="column" fxFlex="40">
							<div fxLayout="column" *ngFor="let error of data.validationErrors">
								<span>{{ data.executionError.message }}</span>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="filteredPayrollErrors$ | async as integrationMessages">
	<div [formGroup]="searchForm" class="search-section">
		<mat-form-field class="search-field" id="g2n-search-field" appearance="outline">
			<app-icon
				id="g2n-search-icon"
				[name]="'search-normal-1'"
				[size]="'25'"
				[lineWidth]="'2px'"
				[color]="'var(--color-search-icon)'"
				matPrefix
			></app-icon>
			<input id="searchEmployees" matInput placeholder="Search by..." formControlName="search" />
		</mat-form-field>
	</div>
	<div class="history-header-container integration-header">
		<div class="first-column">Date</div>
		<div class="second-column">File Name</div>
		<div class="third-column">Description</div>
	</div>
	<div class="scroll-container">
		<no-items-layout
			*ngIf="!integrationMessages.length"
			[message]="'No errors match your search'"
			[minHeight]="'35vh'"
		></no-items-layout>

		<div class="integration-record-container" *ngFor="let message of integrationMessages">
			<div class="data-row item-employee integration-item">
				<div id="date" class="first-column intergration-column">
					{{ message.timeStamp | date : "MMM d, y, h:mm:ss a" }}
				</div>
				<div id="fileName" class="second-column intergration-column">
					{{ message.fileName }}
				</div>
				<div id="description" class="third-column intergration-column">
					{{ message.message }}
				</div>
			</div>
		</div>
	</div>
</ng-container>
