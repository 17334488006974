import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { CommonService } from "@modules/dashboard/services/common.service";
import { PayGroup } from "@shared/models/pay-groups";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { Milestone } from "src/app/shared/models/pay-cycle.interface";
import { MilestoneReport, Transaction } from "src/app/shared/models/payouts.types";
import { DefinitionsData } from "src/app/shared/models/service-definition.interface";
import { PaymentMethodType, PaymentName } from "src/app/shared/models/service-provider.type";
import { PayoutsService } from "../../../../../shared/services/payouts/payouts.service";
import { PermissionsService } from "../../../../../shared/services/permissions/permissions.service";
import { SelectOption } from "../../../../../shared/models/select-option.interface";
import { PaymentTypeCapabilities } from "../../../../../shared/models/service-provider.interface";
import { PaymentsApiService } from "../../../services/payments-api.service";
import { PayoutProcessDialogComponent } from "./payout-process-dialog/payout-process-dialog/payout-process-dialog.component";

@Component({
	selector: "app-payout-process",
	templateUrl: "./payout-process.component.html",
	styleUrls: ["./payout-process.component.scss"]
})
export class PayoutProcessComponent implements OnInit, OnDestroy {
	@Input() payCycleId = "";
	@Input() payGroupId = "";

	@Input() payGroup!: PayGroup;

	@Input() defualtStatusFilterValue = [""];

	@Input() set allFailedTransaction(transaction: Transaction[]) {
		this.failedTransactions = transaction;
	}

	@Input() set milestoneReport(report: MilestoneReport) {
		this.failedPayoutCount = report.failedTransactionCount;
		this.headCount = report.totalHeadCount;
	}

	@Input() set buttonState(state: string) {
		this._buttonState = state;
	}

	@Input() set isCancelledRun(cancelled: boolean) {
		this._isCancelledRun = cancelled;
	}

	@Input() payCycleMilestones!: Milestone[];

	@Input() set runSelection(selection: SelectOption[]) {
		this._runSelection = selection;
	}

	@Input() set fixedContainerStyle(fixed: boolean) {
		this.fixedContainer = fixed;
	}

	@Input() set allFallbackTransactionIds(transactionIds: string[]) {
		this.fallbackTransactionIds = transactionIds;
		this.isReprocessedByFallback = transactionIds && transactionIds.length > 0;
	}

	@Output() processed: EventEmitter<void> = new EventEmitter<void>();
	@Output() filterByStatus: EventEmitter<string> = new EventEmitter<string>();
	@Output() runChanged: EventEmitter<string> = new EventEmitter<string>();
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();
	@Output() cancelBookingClicked: EventEmitter<void> = new EventEmitter<void>();

	failedTransactions: Transaction[] = [];
	failedPayoutCount: number = 0;
	headCount: number = 0;
	_buttonState: string = "inactive";
	_isCancelledRun: boolean = false;
	_runSelection!: SelectOption[];
	destroy$ = new Subject<void>();
	form!: FormGroup;
	fixedContainer = false;
	fallbackTransactionIds: string[] = [];

	statusOptions: SelectOption[] = [];

	allSelectableValues: string[] = [];

	reProcesMapPayoutsAndTxId: Map<string, string[]> = new Map();
	disableProcessButton = true;

	processPaymentMenu: string[] = [];
	reprocessPaymentMenu: string[] = [];
	canProcessPayment = false;
	canSwitchRoute = false;
	bankOptions: SelectOption[] = [];
	cardOptions: SelectOption[] = [];
	bankOptionsPAs: string[] = [];
	cardOptionsPAs: string[] = [];
	openBankList = false;
	openCardList = false;
	isCardListAvailable = false;
	isBankListAvailable = false;
	selectedBankRoutes: string[] = [];
	selectedCardRoutes: string[] = [];
	selectedBankPAs: string[] = [];
	selectedCardPAs: string[] = [];
	paymentTypeCapabilities: PaymentTypeCapabilities[] = [];
	/**Pagination*/
	pageSize: number = 10;
	pageNumber: number = 0;
	paymentTypeDefinitionsFromProvider: PaymentTypeCapabilities | undefined;
	readonly PaymentMethodType = PaymentMethodType;
	isDisabled = false;
	isReprocessedByFallback = false;

	constructor(
		private payoutService: PayoutsService,
		private dialog: MatDialog,
		private permissions: PermissionsService,
		private serviceProviderService: ServiceProviderService,
		private paymentsApiService: PaymentsApiService,
		private formBuilder: FormBuilder,
		private commonService: CommonService
	) {}

	ngOnInit(): void {
		this.statusOptions = this.commonService.getStatusOptions();
		this.allSelectableValues = this.commonService.getAllSelectableValues();

		this.permissions
			.canTriggerPaymentsNETS()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canProcessPayment = res));

		this.permissions
			.canSwitchRoute()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canSwitchRoute = res));

		this.reProcesMapPayoutsAndTxId.clear();
		this.payoutService.updateReprocessIds(this.reProcesMapPayoutsAndTxId);

		this.setupForm();
		this.getMilestones();
		this.getPaymentTypes();
		this.getReprocessIds();
		this.getDefinitions(this.payGroup.legalEntityId);

		this.permissions
			.disableAccessToEditableFormField(["TS_IMPLEMENTATION_LEAD"])
			.subscribe(isDisabled => (this.isDisabled = isDisabled));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	setupForm() {
		this.form = this.formBuilder.group({
			selectedRun: [this._runSelection[0].value], // Null value shows the placeholder,
			statusPayment: [this.defualtStatusFilterValue]
		});

		this.form
			.get("selectedRun")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(milestoneId => {
				this.runChanged.emit(milestoneId);
			});
	}

	getDefinitions(legalEntityId: string) {
		this.getPaymentTypeCapabilities();
		this.serviceProviderService
			.getDefinitionsByEntityId(legalEntityId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(x => {
				const paymentTypeDefinitions: DefinitionsData | undefined = x.paymentTypeDefinitions.find(x => {
					return x.paymentType!.toString() === PaymentName.NETS;
				});

				let bankOptions: string[] = [];
				let cardOptions: string[] = [];

				paymentTypeDefinitions!.definitions.forEach(filterRoute => {
					bankOptions = this.getBankOptionsPrepareWithoutDuplicates(bankOptions, filterRoute);
					cardOptions = this.getCardOptionsPrepareWithoutDuplicates(cardOptions, filterRoute);
				});

				bankOptions.forEach(x => {
					this.bankOptions.push({
						text: x.replace(/_/g, " "),
						value: x
					});
				});

				cardOptions.forEach(x => {
					this.cardOptions.push({
						text: x.replace(/_/g, " "),
						value: x
					});
				});
				this.getRouteListAvailability();
			});
	}

	getRouteListAvailability(): void {
		if (this.cardOptions.length > 0) {
			this.isCardListAvailable = true;
		}

		if (this.bankOptions.length > 0) {
			this.isBankListAvailable = true;
		}
	}

	getReprocessIds() {
		this.payoutService
			.reprocessIdsObservable()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: Map<string, string[]>) => {
				this.reProcesMapPayoutsAndTxId = res;
			});
	}

	getPaymentTypes(): void {
		//processing
		this.payoutService
			.getPaymentTypes(this.payCycleId, "PROCESSING")
			.pipe(
				takeUntil(this.destroy$),
				map(x => {
					this.processPaymentMenu = x;
				})
			)
			.subscribe();

		//reprocessing

		this.payoutService
			.getPaymentTypes(this.payCycleId, "REPROCESSING")
			.pipe(
				takeUntil(this.destroy$),
				map(x => {
					this.reprocessPaymentMenu = x;
				})
			)
			.subscribe();
	}

	getMilestones(): void {
		if (this.payCycleMilestones) {
			const g2n_milestone = this.payCycleMilestones.find(x => {
				return x.type === "G2N_FILE";
			});
			const funding_milestone = this.payCycleMilestones.find(x => {
				return x.type === "FUNDING_DATE";
			});

			if (
				g2n_milestone?.status === "COMPLETE" &&
				(funding_milestone?.status === "COMPLETE" || funding_milestone?.status === "IN_PROGRESS")
			) {
				this.disableProcessButton = false;
			}
		}
	}

	openDialog(processPaymentMenuSelection: string, isReprocessButton: boolean): void {
		let dialogRefData;

		dialogRefData = {
			processPaymentMenuSelection: processPaymentMenuSelection,
			payCycleId: this.payCycleId,
			payGroupId: this.payGroupId,
			isReprocessButton: isReprocessButton,
			bankRoute: this.selectedBankRoutes[0],
			cardRoute: this.selectedCardRoutes[0],
			reProcesMapPayoutsAndTxId: this.reProcesMapPayoutsAndTxId,
			allFailedTransaction: this.failedTransactions
		};

		const dialogRef = this.dialog.open(PayoutProcessDialogComponent, {
			width: "666px",
			height: "441px",
			panelClass: "dialog-container",
			data: dialogRefData
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				this.paymentsApiService.updateReprocessTransactions(true);
				this.reProcesMapPayoutsAndTxId.clear();
				this.payoutService.updateReprocessIds(this.reProcesMapPayoutsAndTxId);
				this.payoutService.reload();
			}
		});
	}

	renderFailedCount(): string {
		return this.failedPayoutCount === 1
			? `There is ${this.failedPayoutCount} failed transaction`
			: `There are ${this.failedPayoutCount} failed transactions`;
	}

	selectionChange(): void {
		this.commonService.storeSelectionChange(
			this.form.get("statusPayment")?.value ? this.form.get("statusPayment")?.value : []
		);
	}

	selectAllStatusses(): void {
		this.form.get("statusPayment")?.patchValue(this.allSelectableValues);
		this.selectionChange();
	}

	refreshPayouts() {
		this.refresh.emit();
	}

	showList(type: string) {
		if (type === PaymentMethodType.BANK) {
			this.openBankList = true;
		} else {
			this.openCardList = true;
		}
	}

	addSelection(item: string, payoutAccount: string, type: PaymentMethodType) {
		if (type === PaymentMethodType.BANK) {
			if (this.selectedBankRoutes.includes(item) && this.selectedBankPAs.includes(payoutAccount)) {
				this.selectedBankRoutes = [];
				this.selectedBankPAs = [];
				this.selectedBankRoutes.push(item);
				this.selectedBankPAs.push(payoutAccount);
			} else {
				if (this.selectedBankRoutes.length > 0 && this.selectedBankPAs.length > 0) {
					this.selectedBankRoutes = [];
					this.selectedBankPAs = [];
				}
				this.selectedBankRoutes.push(item);
				this.selectedBankPAs.push(payoutAccount);
			}

			this.openBankList = false;
		}

		if (type === PaymentMethodType.CARD) {
			if (this.selectedCardRoutes.includes(item) && this.selectedCardPAs.includes(payoutAccount)) {
				this.selectedCardRoutes = [];
				this.selectedCardPAs = [];
				this.selectedCardRoutes.push(item);
				this.selectedCardPAs.push(payoutAccount);
			} else {
				if (this.selectedCardRoutes.length > 0 && this.selectedCardPAs.length > 0) {
					this.selectedCardRoutes = [];
					this.selectedCardPAs = [];
				}
				this.selectedCardRoutes.push(item);
				this.selectedCardPAs.push(payoutAccount);
			}

			this.openCardList = false;
		}
	}

	deleteSelection(type: string, index: number) {
		if (this.canSwitchRoute) {
			if (type === PaymentMethodType.BANK) {
				this.selectedBankRoutes =
					this.selectedBankRoutes.length > 1 ? this.selectedBankRoutes.splice(--index, 1) : [];
			} else {
				this.selectedCardRoutes =
					this.selectedCardRoutes.length > 1 ? this.selectedCardRoutes.splice(--index, 1) : [];
			}
		}
	}

	private getBankOptionsPrepareWithoutDuplicates(bankOptions: Array<string>, filterRoute: any): Array<string> {
		let tempBankOptions: Array<string> = [];

		this.paymentTypeDefinitionsFromProvider!.payoutAccountRoutes[filterRoute.payoutAccount!].filter(
			payment => payment.paymentMethodType === PaymentMethodType.BANK
		).forEach(element => {
			if (!tempBankOptions.includes(element.name)) {
				tempBankOptions.push(element.name);
			}
		});

		if (tempBankOptions.includes(filterRoute.route!.toString())) {
			bankOptions.push(filterRoute.route!.toString());
			this.bankOptionsPAs.push(filterRoute.payoutAccount!.toString());
			if (filterRoute.default) {
				this.selectedBankRoutes.push(filterRoute.route!.toString());
				this.selectedBankPAs.push(filterRoute.payoutAccount!.toString());
			}

			return [...new Set(bankOptions)];
		}

		return [];
	}

	private getCardOptionsPrepareWithoutDuplicates(cardOptions: Array<string>, filterRoute: any): Array<string> {
		this.paymentTypeDefinitionsFromProvider!.payoutAccountRoutes[filterRoute.payoutAccount!].filter(
			payment => payment.paymentMethodType === PaymentMethodType.CARD
		).forEach(element => {
			if (!cardOptions.includes(element.name)) {
				cardOptions.push(element.name);
			}
		});

		if (cardOptions.includes(filterRoute.route!.toString())) {
			cardOptions.push(filterRoute.route!.toString());
			this.cardOptionsPAs.push(filterRoute.payoutAccount!);

			if (filterRoute.default) {
				this.selectedCardRoutes.push(filterRoute.route!);
				this.selectedCardPAs.push(filterRoute.payoutAccount!);
			}

			return [...new Set(cardOptions)];
		}

		return [];
	}

	private getPaymentTypeCapabilities(): void {
		this.serviceProviderService
			.getPaymentProvidersSettings()
			.pipe(takeUntil(this.destroy$))
			.subscribe((paymentTypeCapabilities: PaymentTypeCapabilities[]) => {
				this.paymentTypeCapabilities = paymentTypeCapabilities;
				this.paymentTypeDefinitionsFromProvider = this.paymentTypeCapabilities.find(x => {
					return x.paymentType! === PaymentName.NETS;
				});
			});
	}
}
