export enum PaginationOptions {
	CUSTOMERS = "customers",
	PAYGROUPS = "paygroups",
	LEGAL_ENTITIES = "legalEntities",
	EMPLOYEES = "employees",
	MASTER_FILES = "masterFiles",
	PAYMENT_ORDERS = "paymentOrders",
	NET_PAYMENTS = "netPayments",
	TPP_PAYMENTS = "tppPayments",
	GLOBAL_DASHBOARD = "globalDashboard"
}
