import { AbstractControl, FormGroup } from "@angular/forms";
import { Customer } from "./customer.interface";
import { LegalEntityType } from "./legal-entity.interface";
import { SelectOption } from "./select-option.interface";
import { CurrencyCode } from "./service-provider.type";

export type Frequency = "MONTHLY" | "FOUR_WEEKLY" | "BI-MONTHLY" | "BI_WEEKLY" | "WEEKLY";
export type PayGroupStatus = "IMPLEMENTATION" | "LIVE" | "INACTIVE";
export type PayGroupPaymentType = "BANK" | "CARD";
export type Days = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
export type StartWeek = "FIRST_WEEK" | "SECOND_WEEK" | "THIRD_WEEK" | "FOURTH_WEEK";

export interface PayGroupItem {
	name: string;
	currency: CurrencyCode;
	frequency: Frequency;
	goLiveAt: string;
	treasuryGoLiveAt: string;
	runStartDay?: Days;
	calendarYearStartWeek?: StartWeek;
	avoidPennyTest: boolean;
	paymentAutomation: boolean;
}

export interface PayGroup {
	id: string;
	externalId: string;
	legalEntity: LegalEntityType;
	customer: Customer;
	legalEntityId: string;
	customerId: string;
	supportedPayMethodTypes: PayGroupPaymentType[];
	version: number;
	status: PayGroupStatus;
	treasuryStatus: PayGroupStatus;
	data: PayGroupItem;
	inUse: boolean;
	integrationFrequency: number;
}

export type PayGroupSnippet = Pick<PayGroup, "id" | "customerId" | "legalEntityId" | "externalId"> &
	Pick<PayGroupItem, "name">;

export interface PayGroupPagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: PayGroup[];
}

export interface PayGroupCreateData {
	legalEntityId: string;
	data: PayGroupItem;
}

export interface PayGroupCreateRawFormData {
	id?: string;
	version?: string;
	externalId: string;
	legalEntityId?: string;
	name: string;
	status: PayGroupStatus;
	treasuryStatus: PayGroupStatus;
	supportedPayMethodTypes: PayGroupPaymentType;
	frequency: Frequency;
	runStartDay?: Days;
	calendarYearStartWeek?: StartWeek;
	currency: string;
	goLiveAt: string;
	treasuryGoLiveAt: string;
	avoidPennyTest: boolean;
	integrationFrequency: number;
	paymentAutomation: boolean;
}

export interface IPayGroupRawForm extends FormGroup {
	value: PayGroupCreateRawFormData; //our paygroup interface

	controls: {
		// these are the controls I will create on my form
		id?: AbstractControl;
		version?: AbstractControl;
		externalId: AbstractControl;
		legalEntityId?: AbstractControl;
		name: AbstractControl;
		status: AbstractControl;
		treasuryStatus: AbstractControl;
		supportedPayMethodTypes: AbstractControl;
		frequency: AbstractControl;
		runStartDay?: AbstractControl;
		calendarYearStartWeek?: AbstractControl;
		currency: AbstractControl;
		goLiveAt: AbstractControl;
		treasuryGoLiveAt: AbstractControl;
		avoidPennyTest: AbstractControl;
		integrationFrequency: AbstractControl;
		paymentAutomation: AbstractControl;
	};
}

export interface PayGroupCreateDTO {
	externalId: string;
	legalEntityId?: string;
	status: PayGroupStatus;
	treasuryStatus: PayGroupStatus;
	supportedPayMethodTypes?: PayGroupPaymentType[];
	data: {
		name: string;
		frequency: Frequency;
		currency: string;
		goLiveAt: string | null;
		treasuryGoLiveAt: string | null;
		runStartDay?: Days;
		calendarYearStartWeek?: StartWeek;
		avoidPennyTest: boolean;
		paymentAutomation: boolean;
	};
	integrationFrequency: number;
}

export interface PayGroupUpdateDTO extends PayGroupCreateDTO {
	id: string;
	version: string;
	status: PayGroupStatus;
	treasuryStatus: PayGroupStatus;
}

export interface updateEmployeePayGroupDTO {
	payGroupId: string;
	employeeId: string;
	version: number;
}

export interface PayGroupTPPService {
	payGroupId: string;
	serviceId: string;
	serviceName: string;
	serviceDescription: string;
	serviceType: string;
	groups: string[];
}

export interface PayGroupHistoryReport {
	title: string;
	payGroupId: string;
	history: PayGroupHistory[];
	type: statusType;
}

export interface PayGroupHistory {
	goLiveAt?: Date;
	status?: PayGroupStatus;
	createdAt: Date;
	createdBy: {
		type: string;
		value: string;
	};
}

export interface PaygroupHistoryOptions {
	statusHistory: boolean;
	goLiveDateHistory: boolean;
	treasuryGoLiveDateHistory: boolean;
}

export interface PaygroupHistoryEndpoint {
	endpoint: string;
	errorMessage: string;
}

export enum statusType {
	STATUS = "STATUS",
	GOLIVE = "GOLIVE",
	TREASURYGOLIVE = "TREASURYGOLIVE"
}

export enum GoLiveFormField {
	TreasuryGoLiveAt = "treasuryGoLiveAt",
	GoLiveAt = "goLiveAt"
}
