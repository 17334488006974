import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import { Subscription, Subject, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { ServiceProviderCountryCapability } from "../../../../../shared/models/service-provider-country-types";
import { PaymentTypeName } from "../../../../../shared/models/service-provider.type";
import { ServiceProviderCountryEditService } from "../../../_services/service-provider-country-edit/service-provider-country-edit.service";
import { atLeastOneValidator } from "../../../_validators/at-least-one/at-least-one.service";
import {
	MatDialog as MatDialog,
	MatDialogRef as MatDialogRef,
	MAT_DIALOG_DATA as MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { CostsNotesDialogComponent } from "../../costs-notes-dialog/costs-notes-dialog.component";

@Component({
	selector: "app-cost-edit-form",
	templateUrl: "./cost-edit-form.component.html",
	styleUrls: ["./cost-edit-form.component.scss"]
})
export class CostEditFormComponent implements OnInit {
	@Input() isSuperUser: boolean = false;
	createdCapabilites: ServiceProviderCountryCapability[] = [];

	costForm!: FormGroup;

	countryChangeSubscription: Subscription = new Subscription();
	costsChangedSubscription: Subscription = new Subscription();
	subCosts: Subscription = new Subscription();
	updateCostsSubscription: Subscription = new Subscription();
	createCostsSubscription: Subscription = new Subscription();
	destroy$: Subject<void> = new Subject();

	currencyOptions$: Observable<SelectOption[]> = new Observable<SelectOption[]>();

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly serviceProviderCountryEditService: ServiceProviderCountryEditService,
		private readonly currencyService: CurrencyService,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.currencyOptions$ = this.currencyService.getCurrencyOptions();
		this.initForm();
		this.initSubscriptions();
	}

	initForm(): void {
		//this.createdCapabilites = [];
		this.costForm = this.formBuilder.group({});
		this.createCapabilityGroups(this.serviceProviderCountryEditService.getPaymentTypeCapabilities());
		this.onFormChanges();
	}

	createCapabilityGroups(capabilities: ServiceProviderCountryCapability[]): void {
		capabilities?.filter(capability => {
			const group = this.formBuilder.group({
				paymentType: [{ value: capability.paymentType.toString().replace("_", " "), disabled: true }],
				costs: this.formBuilder.array([])
			});

			const costArray = group.get("costs") as FormArray;

			capability.capabilities.map(capability => {
				capability.costs.filter(cost => {
					const costControl = this.formBuilder.group(
						{
							billingType: [
								{
									value:
										!cost.billingType?.to && !cost.billingType?.from
											? "unlimited"
											: `${cost.billingType?.from} - ${
													cost.billingType?.to === null ? "unlimited" : cost.billingType?.to
											  }`,
									disabled: true
								}
							],
							payoutAccount: [{ value: capability.payoutAccount, disabled: true }],
							route: [{ value: capability.route, disabled: true }],
							currency: [{ value: cost.currency, disabled: this.isSuperUser }],
							paymentFee: [cost.paymentFee, [Validators.required, Validators.min(0)]],
							monthlyFee: [cost.monthlyFee, [Validators.required, Validators.min(0)]],
							notes: [{ value: cost.notes, disabled: true }]
						},
						{
							validator: atLeastOneValidator
						}
					);
					costArray.push(costControl);
				});
			});

			this.costForm.addControl(capability.paymentType.toString(), group);
		});
	}

	getCostFormGroupArray(paymentType: PaymentTypeName) {
		return this.costForm.get(`${paymentType}.costs`) as FormArray;
	}

	initSubscriptions(): void {
		this.subCosts = this.serviceProviderCountryEditService.costData$
			.pipe(takeUntil(this.destroy$))
			.subscribe((capabilities: ServiceProviderCountryCapability[] | null) => {
				if (capabilities) {
					capabilities.filter(c => {
						const control = this.costForm.get(c.paymentType.toString())?.value;

						if (control) {
							this.costForm.removeControl(c.paymentType.toString());
						}
					});
					this.createdCapabilites = capabilities;
					this.createCapabilityGroups(capabilities);
				}
			});

		this.updateCostsSubscription = this.serviceProviderCountryEditService.updateProviderCountry$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.serviceProviderCountryEditService.updateProviderCountry(this.costForm.getRawValue());
			});
	}

	onFormChanges() {
		this.costForm.valueChanges
			.pipe(takeUntil(this.destroy$), debounceTime(100), distinctUntilChanged())
			.subscribe(() => {
				this.serviceProviderCountryEditService.costFormEditChanged(this.costForm.valid);
				this.serviceProviderCountryEditService.initCostsChanged$.emit();
			});
	}

	openDialog(type: PaymentTypeName, notes: string, index: number): void {
		const dialogRef = this.dialog.open(CostsNotesDialogComponent, {
			width: "650px",
			data: { notes }
		});
		dialogRef.afterClosed().subscribe(result => {
			if (!!result) {
				const control = this.getCostFormGroupArray(type).at(index);
				control.get("notes")?.patchValue(result);
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
